import React, { useCallback, useState, useEffect } from "react";
import { onSnapshot } from "firebase/firestore";
import { useDispatch } from "react-redux";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../async/asyncReducer";
import { dataFromSnapshot } from "../firestore/firestoreService";

export default function useFirestoreCollection({ query, data, deps, limit }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncActionStart());
    const unsubscribe = onSnapshot(
      query(0),
      (snapshot) => {
        if (!snapshot.docs) {
          data([]);
        } else {
          const docs = snapshot.docs.map((doc) =>
            dataFromSnapshot(doc, snapshot)
          );
          data(docs);
        }
        dispatch(asyncActionFinish());
      },
      (error) => dispatch(asyncActionError(error))
    );
    return () => {
      unsubscribe();
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  console.log(data);
}
