import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import { socialLogin } from "../../app/firestore/firebaseService";

export const SocialLogin = () => {
  const dispatch = useDispatch();

  function handleSocialLogin(provider) {
    dispatch(closeModal());
    socialLogin(provider);
  }

  return (
    <>
      <button onClick={() => handleSocialLogin("facebook")}>
        Login w/ Facebook
      </button>
      <button onClick={() => handleSocialLogin("google")}>
        Login w/ Google
      </button>
    </>
  );
};
