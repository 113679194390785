import React, { useState } from "react";
import { MdAdd, MdClear } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTable } from "react-table";
import { listenToTrucksFromFirestore } from "../../../app/firestore/firestoreService";
import useFirestoreCollection from "../../../app/hooks/useFirestoreCollection";
import { listenToTrucks } from "../warehouseActions";

export const TrucksPage = () => {
  const dispatch = useDispatch();
  const { trucks } = useSelector((state) => state.warehouse);
  const [showAddForm, setShowAddForm] = useState(false);

  // const { loading } = useSelector((state) => state.async);

  useFirestoreCollection({
    query: () => listenToTrucksFromFirestore(),
    data: (trucks) => dispatch(listenToTrucks(trucks)),
    deps: [dispatch],
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Truck",
        accessor: (row) => {
          return <Link to={`/w/truck/${row?.id}`}>{row?.name}</Link>;
        },
      },
      {
        Header: "VIN",
        accessor: "vin",
      },
      {
        Header: "Ownership",
        accessor: "type",
      },
      {
        Header: "Light Weight",
        accessor: "lightWeight",
      },
    ],
    []
  );
  // function customAccessor(row) {
  //   return Number(row?.units?.length || (row?.units && Object.keys(row.units).length) || 0);
  // }

  const data = React.useMemo(() => [...trucks], [trucks]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  if (!trucks) return <div>Loading trucks</div>;

  return (
    <div className="bg-blue-900 m-1 rounded-md pb-10  h-full">
      <div className="bg-white rounded-t-md h-24  flex items-center px-16 relative justify-between">
        <div className="flex items-center select-none">
          <img src="/img/pts-logo.png" className="object-contain h-24 " alt="PTS Logo" />
          <h1 className="font-bold px-4 py-2 text-2xl">PARAMOUNT JOBS</h1>
        </div>
        <button className="bg-blue-500 px-4 py-2 rounded-md text-white" onClick={(e) => setShowAddForm((v) => !v)}>
        {showAddForm ? <MdClear /> : <MdAdd />}
          {showAddForm ? "Close" : "New"}
        </button>
      </div>
      {showAddForm && <AddTruck />}
      {trucks && trucks.length > 0 ? (
        <table {...getTableProps()} className="mx-auto my-5  rounded-md overflow-hidden transition-all bg-white mb-10">
          <thead className=" bg-white bg-opacity-50  ">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    className="font-bold px-4 py-2 hover:bg-opacity-50 hover:bg-white bg-opacity-0 transition-all cursor-pointer "
                    key={i}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="bg-white even:bg-gray-200 hover:bg-blue-200 ">
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="px-2 py-1">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No trucks found</div>
      )}
    </div>
  );
};

const AddTruck = (props) => {
  return <div>Add Truck</div>;
};
