import React from "react";
// import { HotKeys } from "react-hotkeys";
import { GlobalHotKeys, getApplicationKeyMap } from "react-hotkeys";
import cuid from "cuid";
import { Hint } from "react-autocomplete-hint";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import { PageLayout } from "../../../app/layout/PageLayout";
import { FileUpload } from "../../../app/common/form/FileUpload";
import { MdClear, MdDeleteForever } from "react-icons/md";
// const packingMaterials = [
//   {
//     id: "q3lYEM",
//     name: "Small Box",
//     volume: 1.5,
//     w: "16 3/8",
//     d: "12 5/8",
//     h: "12 5/8",
//     units: 0,
//     img: "img/small.png",
//     history: {},
//     rate: 3,
//   },
//   {
//     id: "KX1q8A",
//     name: "Medium Box",
//     volume: 3.0,
//     w: "18",
//     d: "18",
//     h: "16",
//     units: 0,
//     img: "img/medium.png",
//     history: {},
//     rate: 4,
//   },
//   {
//     id: "AT9F5y",
//     name: "Large Box",
//     volume: 4.5,
//     w: "18",
//     d: "18",
//     h: "24",
//     units: 0,
//     img: "img/large.png",
//     history: {},
//     rate: 5,
//   },
//   {
//     id: "L2NPOm",
//     name: "Mattress Bag",
//     description: "For use on mattresses & box springs",
//     units: 0,
//     img: "img/matbags.png",
//     history: {},
//     rate: 5,
//   },
//   {
//     id: "Zb53TO",
//     name: `Wardrobe (Rent)`,
//     rent: true,
//     volume: 13,
//     w: "24",
//     d: "24",
//     h: "40",

//     units: 0,
//     img: "img/wardrobe.png",
//     history: {},
//     rate: 5,
//   },
//   {
//     id: "jj4ghI",
//     name: "TV Box S",
//     units: 0,
//     img: "img/tv.png",
//     //     small 46 x 6 x30
//     // large 56 x 6 x 36
//     // xl 64 x 6 x40
//     w: "46",
//     d: "6",
//     h: "30",
//     history: {},
//     rate: 25,
//   },

//   {
//     id: "fjCPOT",
//     name: "Small Mirror Pak",
//     volume: 13,
//     w: "37 5/16",
//     d: "4 3/8",
//     h: "26 15/16",

//     units: 0,
//     img: "img/smirror.png",
//     history: {},
//     rate: 5,
//   },
//   {
//     id: "UmttCz",
//     name: "Large Mirror Pak",
//     volume: 13,
//     w: "48",
//     d: "4 1/2",
//     h: "33",

//     units: 0,
//     img: "img/lmirror.png",
//     history: {},
//     rate: 10,
//   },
//   {
//     id: "Aic8ie",
//     name: "Flat Wardrobe",
//     volume: 13,
//     w: "35",
//     d: "20",
//     h: "9",

//     units: 0,
//     img: "img/laydown.png",
//     history: {},
//     rate: 5,
//   },
//   {
//     id: "XjYPgM",
//     name: "Wardrobe (Buy)",
//     volume: 13,
//     w: "24",
//     d: "24",
//     h: "40",

//     units: 0,
//     img: "img/wardrobe.png",
//     history: {},
//     rate: 15,
//   },
//   {
//     id: "dW96Oj",
//     name: "Paper Pads",
//     units: 0,
//     img: "img/pads.png",
//     history: {},
//     rate: 2,
//   },
//   {
//     id: "K6HCr1",
//     name: "Stretch Wrap",
//     units: 0,
//     img: "img/wrap.png",
//     history: {},
//     rate: 20,
//   },
//   {
//     id: "s1s12u",
//     name: "Carpet Protection",
//     units: 0,
//     img: "img/carpet.png",
//     history: {},
//     rate: 45,
//   },
//   {
//     id: "INaIjO",
//     name: "Ropes",
//     units: 0,
//     img: "img/rope.png",
//     history: {},
//     rate: 5,
//   },
//   {
//     id: "s0mSbR",
//     name: "Blankets",
//     units: 0,
//     img: "img/blanket.png",
//     history: {},
//     rate: 10,
//   },
// ];

const options = ["sofa", "dresser", "nightstand", "tote", "dining table", "desk"];

const images = [9, 8, 7, 6, 5].map((number) => ({
  src: `https://placedog.net/${number}00/${number}00?id=${number}`,
}));

const reducer = (state, { payload, type }) => {
  if (!type && payload.field && payload.option) {
    return { ...state, [payload.field]: payload.option };
  }

  switch (type) {
    case "ADD_MATERIALS":
      return {
        ...state,
        ...Object.keys(payload).reduce((a, m) => {
          a[m] = state[m] + payload[m] >= 0 ? state[m] + payload[m] : 0;
          return a;
        }, {}),
      };
    case "CLEAR":
      return initialState;
    case "ADD_ARTICLE":
      return { ...state, articles: { ...state.articles, [cuid.slug()]: payload } };

    default:
      return state;
  }
};

const initialState = {
  smalls: 0,
  mediums: 0,
  larges: 0,
  flatWardrobes: 0,
  wardrobes: 0,
  mattressBags: 0,
  sMirrorPak: 0,
  lMirrorPak: 0,
  tvS: 0,
  tvXL: 0,
  articles: {
    dfghty: {
      name: "Dresser",
      volume: 24,
      w: 3,
      l: 4,
      d: 2,
      room: null,
      notes: "Very Valuable heirloom",
      exceptions: "leg br, marred",
    },
  },
};

const keyMap = {
  SNAP_LEFT: ["command+left", "ctrl+left"],
  DELETE_NODE: ["del", "backspace", "a"],
  MOVE_UP: "up",
  INSERT: ["Insert", "+", " "],
  ADD_SMALLS: {
    name: "Add one small box",
    sequence: "q",
    action: "keypress",
  },
  ADD_MEDIUMS: {
    name: "Add one medium box",
    sequence: "w",
    action: "keypress",
  },
  ADD_LARGES: {
    name: "Add one large box",
    sequence: "e",
    action: "keypress",
  },
  ADD_FLAT_WARDROBES: ["r"],
  ADD_WARDROBES: ["t"],
  ADD_MATTRESS_BAG: ["y"],
  ADD_S_MIRROR_PAK: ["u"],
  ADD_L_MIRROR_PAK: ["i"],
  ADD_TV_CARTON_S: ["o"],
  ADD_TV_CARTONXL: ["p"],
  REMOVE_SMALLS: {
    name: "Remove one Small",
    sequence: "shift+q",
    action: "keypress",
  },
  ADD_CUSTOM: {
    name: "Add Custom Piece",
    sequence: "ctrl+space",
    action: "keypress",
  },
  SHOW_DIALOG: {
    name: "Display keyboard shortcuts",
    sequence: "shift+/",
    action: "keypress",
  },
  GALLERY_UP_INDEX: {
    name: "Gallery Forward",
    sequence: "PageUp",
    action: "keypress",
  },
  GALLERY_DOWN_INDEX: {
    name: "GAllery Back",
    sequence: "PageDown",
    action: "keypress",
  },
};

const initialArticle = {
  name: "",
  volume: 0,
  w: 0,
  l: 0,
  d: 0,
  room: null,
  notes: "",
  exceptions: "",
};

export const CreateSurvey = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [showDialog, setShowDialog] = React.useState(false);
  // const [showInput, setShowInput] = React.useState(false);
  const inputRef = React.useRef();

  const [galleryIndex, setGalleryIndex] = React.useState(0);

  const [article, d] = React.useReducer((s, { type, payload }) => {
    switch (type) {
      case "UPDATE_ARTICLE":
        return { ...s, ...payload };
      case "CLEAR":
        return initialArticle;

      default:
        return s;
    }
  }, initialArticle);

  // const deleteNode = React.useCallback(() => {
  //   setState("SHIT CHANGED");
  //   console.log("lalalalalala");
  //   // logic here
  // }, []);
  const handlers = {
    DELETE_NODE: (event) => console.log("DELETE"),
    MOVE_UP: (event) => console.log("UP"),
    INSERT: (event) => console.log("Insert"),
    SNAP_LEFT: (event) => console.log("SNAP_LEFT"),
    ADD_ARTICLE: (event) => dispatch({ type: "ADD_ARTICLE", payload: article }),
    ADD_CUSTOM: (event) => {
      if (document.activeElement === inputRef.current) {
        inputRef.current.blur();
      } else {
        inputRef.current.focus();
      }
    },
    GALLERY_DOWN_INDEX: (event) => setGalleryIndex((v) => (v + 2 > images.length ? 0 : v + 1)),
    GALLERY_UP_INDEX: (event) => setGalleryIndex((v) => (v - 1 < 0 ? images.length - 1 : v - 1)),
    ADD_SMALLS: (event) => dispatch({ type: "ADD_MATERIALS", payload: { smalls: 1 } }),
    ADD_MEDIUMS: (event) => dispatch({ type: "ADD_MATERIALS", payload: { mediums: 1 } }),
    ADD_LARGES: (event) => dispatch({ type: "ADD_MATERIALS", payload: { larges: 1 } }),
    ADD_FLAT_WARDROBES: (event) => dispatch({ type: "ADD_MATERIALS", payload: { flatWardrobes: 1 } }),
    ADD_WARDROBES: (event) => dispatch({ type: "ADD_MATERIALS", payload: { wardrobes: 1 } }),
    ADD_MATTRESS_BAG: (event) => dispatch({ type: "ADD_MATERIALS", payload: { mattressBags: 1 } }),
    ADD_S_MIRROR_PAK: (event) => dispatch({ type: "ADD_MATERIALS", payload: { sMirrorPak: 1 } }),
    ADD_L_MIRROR_PAK: (event) => dispatch({ type: "ADD_MATERIALS", payload: { lMirrorPak: 1 } }),
    ADD_TV_CARTON_S: (event) => dispatch({ type: "ADD_MATERIALS", payload: { tvS: 1 } }),
    ADD_TV_CARTONXL: (event) => dispatch({ type: "ADD_MATERIALS", payload: { tvXL: 1 } }),
    REMOVE_SMALLS: (event) => dispatch({ type: "ADD_MATERIALS", payload: { smalls: -1 } }),
    SHOW_DIALOG: () => setShowDialog((v) => !v),
  };

  const RenderDialog = ({ setShowDialog }) => {
    const keyMap = getApplicationKeyMap();

    return (
      <div className="bg-black bg-opacity-20 text-white p-4 rounded-xl">
        <div className="flex justify-between">
          <h2 className="py-2 px-4 text-indigo-500 text-xl">Keyboard shortcuts</h2>
          <button type="button" onClick={() => setShowDialog(false)}>
            <MdClear />
          </button>
        </div>
        <table>
          <tbody>
            {Object.keys(keyMap).reduce((memo, actionName) => {
              const { sequences, name } = keyMap[actionName];

              memo.push(
                <tr key={name || actionName} className="hover:bg-black">
                  <td className="px-4 py-1">{name}</td>
                  <td>
                    {sequences.map(({ sequence }) => (
                      <span key={sequence}>
                        <pre>{sequence}</pre>
                      </span>
                    ))}
                  </td>
                </tr>
              );

              return memo;
            }, [])}
          </tbody>
        </table>
      </div>
    );
  };

  const handleChange = (e) => d({ type: "UPDATE_ARTICLE", payload: { [e.target.name]: e.target.value } });
  const handleSubmit = (e) => {
    if (e.key === "Enter") {
      if (article.name.length > 0) {
        dispatch({ type: "ADD_ARTICLE", payload: article });
        d({ type: "CLEAR" });
      }
    } else if (e.key === "Escape") {
      e.target.blur();
    } else if (e.key === "PageUp") {
      e.preventDefault();
      handlers.GALLERY_UP_INDEX(e);
    } else if (e.key === "PageDown") {
      e.preventDefault();
      handlers.GALLERY_DOWN_INDEX(e);
    }
  };

  return (
    <PageLayout title="Survey / Inventory">
      <div className="bg-yellow-300 flex py-2 rounded-t-lg mt-2 mb-2 px-2 justify-between border-b-2 border-yellow-400">
        <span>
          <MdDeleteForever /> This survey is not attached to a operation!
        </span>
        <MdClear />
      </div>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
        <div className="flex justify-between flex-col lg:flex-row ">
          <div>
            <div className="w-full ">
              {Object.keys(state).map((m) => {
                if (typeof state[m] === "object") return null;
                return (
                  <div className="flex" key={m}>
                    <span className="flex-grow">{m}</span>
                    <span className="px-4 py-0">{state[m]}</span>
                  </div>
                );
              })}
            </div>
            <form
              id="form"
              className="w-full "
              onSubmit={(e) => {
                e.preventDefault();
                document.getElementById("form").reset();
                inputRef.current.focus();
              }}
            >
              <label htmlFor="">
                Add furniture
                <div className="focus-within:border-indigo-400 border-2 flex items-center px-2 rounded-md bg-white">
                  <div className="flex-grow">
                    <Hint options={options} allowTabFill>
                      <input
                        name="name"
                        ref={inputRef}
                        className="outline-none  bg-transparent py-2 w-full"
                        autoComplete="off"
                        value={article.name}
                        onChange={handleChange}
                        onKeyDown={handleSubmit}
                      />
                    </Hint>
                  </div>
                  <input
                    name="volume"
                    className="outline-none w-16 bg-transparent border-l text-center"
                    placeholder="cu ft"
                    type="number"
                    value={article.volume}
                    onChange={handleChange}
                    onKeyDown={handleSubmit}
                  />
                  <input className="hidden" type="submit" />
                  {/* <span>=</span>
              <input className="outline-none w-10 bg-transparent  text-center" placeholder="W" />
              <span>x</span>
              <input className="outline-none w-10 bg-transparent  text-center" placeholder="L" />
              <span>x</span>
              <input className="outline-none w-10 bg-transparent  text-center" placeholder="D" /> */}
                </div>
              </label>
            </form>
            <div>
              {Object.keys(state.articles).map((a, i) => (
                <div className="border border-black my-1 px-4 py-1 w-96" key={i}>
                  {state.articles[a]?.name}
                </div>
              ))}
            </div>
            {showDialog && <RenderDialog setShowDialog={setShowDialog} />}
          </div>
          <div>
            <FileUpload>
              <Carousel
                index={galleryIndex}
                images={images}
                style={{ height: 500, width: 800 }}
                onIndexChange={({ curIndex }) => setGalleryIndex(curIndex)}
              />
            </FileUpload>
          </div>
        </div>
      </GlobalHotKeys>
    </PageLayout>
  );
};

//TODO ADD HiGHLITING AFTER KEYBOARD CLICK
