const initialState = {
  jobs: [],
};

export function ptsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "LISTEN_TO_PTS_JOBS":
      return {
        ...state,
        jobs: payload,
      };

    default:
      return state;
  }
}
