import { useState } from "react";
import { Material } from "./Material";
import { defaultMaterials } from "../../app/api/defaultMaterials";
import { useMove, useMoveDispatch } from "./MoveProvider";

import { SectionTitle } from "../../app/layout/SectionTitle";
import { TableFooter } from "../../app/layout/TableFooter";
import { MdDelete } from "react-icons/md";
import { useGroup } from "../../app/hooks/useGroup";
import { filterGroup } from "../../app/common/util/helperFunctions";

export const MaterialsWProvider = ({
  groupName = "materials",
  showMoreDefault = true,
  state,
  dispatch,
  ...rest
}) => {
  const { totalMaterials = 0, materials: materialsState = [] } = state;
  const [materials, , update, add, remove, clear] = useGroup(
    groupName,
    defaultMaterials,
    materialsState,
    dispatch,
    ["units", "rate", "total"]
  );
  const [showMore, setShowMore] = useState(showMoreDefault);
  return (
    <>
      <SectionTitle title="Materials Used" Icon={MdDelete} onClick={clear} />
      <div className="flex flex-col mt-2 w-full  mx-auto shadow-sm bg-white rounded-md">
        {(showMore
          ? materials
          : filterGroup(materials, 8, "units", (v) => v > 0)
        ).map((m, i) => {
          return (
            <Material
              key={m.id}
              handleRemove={remove}
              handleChange={update}
              {...m}
            />
          );
        })}
      </div>
      <TableFooter
        showMore={showMore}
        setShowMore={setShowMore}
        total={totalMaterials}
        handleAdd={add}
      />
    </>
  );
};

export const Materials = (props) => {
  const dispatch = useMoveDispatch();
  const move = useMove();
  return <MaterialsWProvider state={move} dispatch={dispatch} {...props} />;
};
