import cuid from "cuid";
import React from "react";
import { Form } from "react-final-form";
import { MdChevronRight } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Button } from "../../../app/common/components/Button";
import { Select } from "../../../app/common/components/Select";
import { FFInput } from "../../../app/common/form/Input";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { ModalWrapper } from "../../../app/common/modals/ModalWrapper";
import { setUnitTypeInFirestore } from "../../../app/firestore/firestoreService";
import { defaultUnitTypes } from "../../warehouse/units/UnitPreview";
import { FFCheckbox } from "../../../app/common/form/Checkbox";

export const AddCustomUnitTypeModal = ({ unit, opId, shipmentId }) => {
  const modalDispatch = useDispatch();

  const onSubmit = async (values) => {
    console.log({ unit, data: values, opId, shipmentId });
    setUnitTypeInFirestore({ unit, data: { ...values, isCustom: true }, opId, shipmentId });
    modalDispatch(closeModal());
  };

  //TODO add validation

  return (
    <ModalWrapper header="Add Custom Unit Type">
      <div className="bg-white p-4 relative">
        <Form
          initialValues={{
            unitType: { id: /*unit?.unitType?.id ||*/ cuid.slug(), label: unit?.unitType?.label || "" },
            weight: { tare: unit?.weight?.tare || "" },
            dimms: { l: unit?.dimms?.l || "", w: unit?.dimms?.w || "", h: unit?.dimms?.h || "", units: unit?.dimms?.units || "inches" },
            isNew: unit?.isNew || false,
          }}
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Select
                selected={{ label: "Select from template" }}
                onSelect={({ id, label, weight, ...selection }) =>
                  form.reset({ unitType: { id: cuid.slug(), label }, ...selection, weight: weight() })
                }
                className="w-32"
                suffix={<MdChevronRight className={`transform rotate-90 transition-all ml-2`} />}
                options={Object.values(defaultUnitTypes).sort()}
              />
              <FFInput name="unitType.label" label="Unit Type" placeholder="Cardboard Liftvan" />
              <FFInput name="weight.tare" label="Tare Weight" placeholder="300" type="number" />
              <div className="flex space-x-2">
                <FFInput name="dimms.l" label="Length" type="number" />
                <FFInput name="dimms.w" label="Width" type="number" />
                <FFInput name="dimms.h" label="Height" type="number" />
              </div>
              <div className="mt-4">
                <FFCheckbox name="isNew" label="New container" />
              </div>
              <div className="mt-10 flex justify-center">
                <Button type="submit" color="green" disabled={submitting}>
                  Submit
                </Button>
              </div>
              {/* <div className="text-[8px]">
                <pre>{JSON.stringify(values, null, 2)}</pre>
              </div> */}
            </form>
          )}
        />
      </div>
    </ModalWrapper>
  );
};
