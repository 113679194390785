import React from "react";

export const ProfileHeader = ({ profile }) => {
  return (
    <section>
      <img alt="profile" src={profile.photoURL || "/img/user.png"} />
      {profile.displayName}
    </section>
  );
};
