import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { listenToTruckFromFirestore } from "../../../app/firestore/firestoreService";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";
import { LoadingComponent } from "../../../app/layout/LoadingComponent";
import { listenToTruck } from "../warehouseActions";
import { FaTruck } from "react-icons/fa";
import { PageLayout } from "../../../app/layout/PageLayout";
import { FileUpload } from "../../../app/common/form/FileUpload";

export const TruckDetailsPage = ({ match }) => {
  const dispatch = useDispatch();
  const { truck } = useSelector((state) => state.warehouse);
  
  useFirestoreDoc({
    query: () => listenToTruckFromFirestore(match.params.truckId),
    data: (truck) => dispatch(listenToTruck(truck)),
    deps: [dispatch, match.params.truckId],
  });
  
  const { loading, error } = useSelector((state) => state.async);
  if ((loading && !truck) || (!truck && !error))
    return <LoadingComponent content="Loading truck..." />;

  // if (error) return <Redirect to="/error" />;

  return (
    <PageLayout title={truck?.name || "Truck"} IconTitle={FaTruck}>
      <div className="flex flex-col  justify-around bg-gray-100 p-1 rounded-lg m-1">
        <div>TRUCK: {`${truck?.year} ${truck?.make} ${truck?.model}`}</div>
        <div>VIN: {`${truck?.vin} `}</div>
        <div>Ownership: {`${truck?.type} `}</div>
        <div>Clearance (Height): {`${truck?.clearance} `}</div>
        <div>
          Engine:{" "}
          {`${truck?.cylinders} cylinders, ${truck?.engine}, Model: ${truck?.engineModel} `}
        </div>
        <div>
          Weight:
          <span>Empty: {truck?.lightWeight},</span>
          <span>GVWR: {truck?.gvwr}</span>
          <span>GAWR Front: {truck?.gawrFront}</span>
          <span>GAWR Rear: {truck?.gawrRear}</span>
        </div>
        <div>
          Tire Size: Front: {truck?.frontTireSize}, Rear: {truck?.rearTireSize}
        </div>

        {/* TODO ADD PATHS */}
        <FileUpload>
          <div className="p-2">
            {truck.files && Object.keys(truck.files).length > 0 ? (
              <div>
                {Object.keys(truck.files).map((file, i) => (
                  <div key={file.name || i}>
                    <pre>{JSON.stringify(truck.files[file], null, 2)}</pre>
                  </div>
                  // <img src={file.thumbUrl ?? "/img/pdf-placeholder.jpg"} alt={file.name} key={file.id} />
                ))}
              </div>
            ) : (
              <div>No files</div>
            )}
          </div>
        </FileUpload>
      </div>
    </PageLayout>
  );
};
