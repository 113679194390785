import React from "react";
import { ModalWrapper } from "../modals/ModalWrapper";

import { useDispatch } from "react-redux";
import { closeModal } from "../modals/modalReducer";

import { MdOutlineQrCodeScanner } from "react-icons/md";

import { QRScanner } from "./QRScanner";
import { Button } from "../components/Button";

import { MdClear } from "react-icons/md";
import { Link } from "react-router-dom";

export const QRModal = () => {
  const dispatch = useDispatch();
  const [lastDetected, setLastDetected] = React.useState(null);
  return (
    <ModalWrapper
      size="mini"
      header="QR Scanner"
      HeaderIcon={MdOutlineQrCodeScanner}
    >
      <QRScanner
        lastDetected={lastDetected}
        setLastDetected={setLastDetected}
      />
      {lastDetected && <p className="text-xs">{lastDetected}</p>}

      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse w-full">
        {lastDetected && (
          <Link
            to={lastDetected.replace("sfmtools.com/", "/").concat("?t=qr")}
            onClick={() => dispatch(closeModal())}
          >
            <Button label="Go to last scanned" />{" "}
          </Link>
        )}
        <Button
          onClick={() => dispatch(closeModal())}
          color="gray"
          Icon={MdClear}
          label="Cancel"
        />
      </div>
    </ModalWrapper>
  );
};
