import React from "react";
import { Form } from "react-final-form";
import { MdRefresh } from "react-icons/md";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { uniqueNamesGenerator, adjectives, colors } from "unique-names-generator";
import { Button } from "../../../app/common/components/Button";
import { FFInput } from "../../../app/common/form/Input";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { ModalWrapper } from "../../../app/common/modals/ModalWrapper";
import { addNewPTSOperation } from "../../../app/firestore/firestoreService";

export const AddOperationModal = ({ searchValue = "", setSelected }) => {
  const [showCodeName, setShowCodeName] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = async (values) => {
    console.log(values);
    const opId = await addNewPTSOperation(values);
    history.push(`/warehouse/labels/${opId}`);
    dispatch(closeModal());
  };
  const validate = (values) => {
    let errors = {};
    if (!values.displayName && !values.refNumber && !values?.codeName) {
      errors.displayName = "Please add display name.";
      errors.refNumber = "Please add reference number.";
    }

    if ((!values.displayName || !values.refNumber) && values.codeName?.length > 0) {
      // errors.displayName = null;
      // errors.refNumber = null;
      errors = {};
    }

    return errors;
  };

  const setCodeName = (args, state, { setIn, changeValue }) => {
    // alert("hi");
    // changeValue(state, "lastName", () => "xxx");
    const field = state.fields["codeName"];
    field.change(generateCodeName());
    state.formState.submitFailed = true;
    // field.data = {...field.data, initialError: 'x0x0x'};
  };

  const clearCodeName = (args, state) => {
    const field = state.fields["codeName"];
    field.change(null);
  };

  const upper = (value) => (value ? value.toUpperCase() : "");

  return (
    <ModalWrapper header="Add Paramount Operation">
      <div className="bg-white p-4">
        <Form
          initialValues={{ displayName: "", refNumber: "", ...parseSearchValue(searchValue) }}
          onSubmit={onSubmit}
          validate={validate}
          mutators={{ setCodeName, clearCodeName }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <FFInput name="displayName" placeholder="Shipper Shipperson" label="Shipper" />
              <FFInput name="refNumber" placeholder="SE-000000-X1" label="Full Reference Number" parse={upper} />
              {showCodeName ? (
                <FFInput
                  name="codeName"
                  placeholder="Codename"
                  label="Code Name"
                  defaultValue={() => generateCodeName()}
                  ActionButton={() => (
                    <button type="button" className="flex border rounded px-1 py-0.5 " onClick={form.mutators.setCodeName}>
                      <MdRefresh /> <span className="text-xs">Generate</span>
                    </button>
                  )}
                  helperText="This is for internal reference only! Please make sure you choose a non offensive one."
                  labelAction={(e) => {
                    form.mutators.clearCodeName(e);
                    setShowCodeName(false);
                  }}
                />
              ) : (
                <div
                  className="text-xs text-blue-700  hover:text-blue-400 cursor-pointer  px-2 pt-2 underline"
                  onClick={() => {
                    setShowCodeName(true);
                  }}
                >
                  No Shipper Name or Ref Number? Add a Codename.
                </div>
              )}
              <div className="my-10">
                <Button color="gray" onClick={form.reset} disabled={submitting}>
                  Clear
                </Button>
                <Button type="submit" color="green" disabled={submitting}>
                  Submit
                </Button>
              </div>
              {/* {JSON.stringify(values, null, 2)} */}
            </form>
          )}
        />
      </div>
    </ModalWrapper>
  );
};

const parseSearchValue = (searchValue) => {
  /**
   * check if has number then intial refnumber value
   * if not - shipper name
   */
  if (hasNumber(searchValue) || searchValue.toLowerCase().startsWith("SE-")) {
    if (!isNaN(searchValue[0])) searchValue = "SE-" + searchValue;
    return { refNumber: searchValue.trim() };
  } else {
    return { displayName: searchValue.trim() };
  }
};

function hasNumber(myString) {
  return /\d/.test(myString);
}

function generateCodeName() {
  return uniqueNamesGenerator({
    dictionaries: [adjectives, colors],
    style: "capital",
    separator: " ",
  });
}

/**
 * ideally having all the information shippers full name, refNumber would be great,
 * but that is not the case
 *
 * sometimes no shipper name is available
 * or alien ref number only available
 * or not event that is available
 *
 * validate if shipper name is not available
 * validate if shipper is already in database
 *
 * if no name, but reference number is added
 * check if ref number / shipment is already in system
 *
 * if none of the above is available, generate a codename
 * allow edit of codename
 *
 */
