import React, { useState } from "react";
import { MdAdd, MdClear } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useTable } from "react-table";
import { listenToPTSJobsFromFirestore } from "../../../app/firestore/firestoreService";
// import useFirestoreCollection from "../../app/hooks/useFirestoreCollection";
// import { AddJob } from "./AddJob";
import { listenToOperations } from "../paramountActions";
import useFirestoreCollection from "../../../app/hooks/useFirestoreCollection";
import { Link } from "react-router-dom";

const downloadState = (exportObj) => {
  var dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(exportObj));
  var downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", "state.json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const Operations = (props) => {
  const dispatch = useDispatch();
  const { operations } = useSelector((state) => state.paramount);
  const [showAddForm, setShowAddForm] = useState(false);

  // const { loading } = useSelector((state) => state.async);

  useFirestoreCollection({
    query: () => listenToPTSJobsFromFirestore(),
    data: (ops) => dispatch(listenToOperations(ops)),
    deps: [dispatch],
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: function (row) {
          return (
            <>
              {Object.entries(row?.shippers).map(([shipperId, shipper], i) => {
                console.log({ shipperId, shipper });
                return (
                  <Link to={`/paramount/shipper/${shipperId}`}>
                    {`${i > 0 ? ", " : ""}${shipper?.displayName}` || ""}
                  </Link>
                );
              })}
            </>
          );
        },
      },
      // {
      //   Header: "Customer",
      //   accessor: "fullName",
      // },
      {
        Header: "Ref Number",
        accessor: function (row) {
          return (
            <Link to={`/paramount/operation/${row.id}`}>{row?.refNumber}</Link>
          );
        },
      },
      // {
      //   Header: "Type, OS/DS",
      //   accessor: "type",
      // },
      // {
      //   Header: "Units",
      //   accessor: customAccessor,
      // },
      // {
      //   Header: "Keyword",
      //   accessor: setKeywords,
      // },
    ],
    []
  );
  // function customAccessor(row) {
  //   return Number(
  //     row?.units?.length || (row?.units && Object.keys(row.units).length) || 0
  //   );
  // }

  // function uniq(a) {
  //   var seen = {};
  //   return a.filter(function (item) {
  //     return seen.hasOwnProperty(item) ? false : (seen[item] = true);
  //   });
  // }

  const data = React.useMemo(() => [...operations], [operations]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  if (!operations) return <div>Loading jobs</div>;

  return (
    <div className="bg-blue-900 m-1 rounded-md pb-10  h-full">
      <div className="bg-white rounded-t-md h-24  flex items-center px-16 relative justify-between">
        <div className="flex items-center select-none">
          <img
            src="/img/pts-logo.png"
            className="object-contain h-24 "
            alt="PTS Logo"
          />
          <h1 className="font-bold px-4 py-2 text-2xl">PARAMOUNT JOBS</h1>
        </div>
        <button
          onClick={(e) => {
            downloadState(operations);
          }}
        >
          Download Data
        </button>
        <button
          className="bg-blue-500 px-4 py-2 rounded-md text-white"
          onClick={(e) => setShowAddForm((v) => !v)}
        >
          {showAddForm ? <MdClear /> : <MdAdd />}
          {showAddForm ? "Close" : "New"}
        </button>
      </div>
      {/* {showAddForm && <AddJob />} */}
      {operations && operations.length > 0 ? (
        <table
          {...getTableProps()}
          className="mx-auto my-5  rounded-md overflow-hidden transition-all bg-white mb-10"
        >
          <thead className=" bg-white bg-opacity-50  ">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    className="font-bold px-4 py-2 hover:bg-opacity-50 hover:bg-white bg-opacity-0 transition-all cursor-pointer "
                    key={i}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="bg-white even:bg-gray-200 hover:bg-blue-200 "
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="px-2 py-1">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No jobs found</div>
      )}
    </div>
  );
};

const json = [
  {
    created: {
      displayName: "Arnas Jelizarovas",
      at: { seconds: 1636567653, nanoseconds: 629000000 },
      userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
    },
    foreignRef: "NY-459203-S1",
    shipments: {
      ckz7kh9j500hzm0eaflyb347x: {
        created: {
          at: { seconds: 1636567653, nanoseconds: 0 },
          userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
          displayName: "Arnas Jelizarovas",
        },
        units: { ckvtu4jct0000386dykwp9vhw: { number: 1 } },
        refNumber: "NY-459203-S1",
        unitCount: 1,
        updated: {
          j5hxmadw: {
            userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
            displayName: "Arnas Jelizarovas",
            at: { seconds: 1636659450, nanoseconds: 802000000 },
          },
        },
      },
    },
    shippers: { ckz7kh9j500hym0ea5cc97vjf: { displayName: "Nguyen-Khoa Ho" } },
    id: "1QvcCjFUBXkI4E7vzZfR",
  },
  {
    created: {
      userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
      at: { seconds: 1636390563, nanoseconds: 767000000 },
      displayName: "Arnas Jelizarovas",
    },
    shippers: {
      ckz7kh8xx0001m0eadx6ncjhr: { displayName: "Slathish Planisamy" },
    },
    shipments: {
      ckz7kh8xx0002m0ea42k75lgq: {
        created: {
          at: { seconds: 1636390564, nanoseconds: 0 },
          displayName: "Arnas Jelizarovas",
          userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
        },
        refNumber: "SE-461874-R1",
        updated: {
          xz4may4: {
            at: { seconds: 1636390564, nanoseconds: 0 },
            userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
            displayName: "Arnas Jelizarovas",
          },
        },
        unitCount: 3,
        units: {
          ckvqwow3k00013864ahbo26d0: { number: 2 },
          ckvqwow6y000238640mgrkqgc: { number: 3 },
          ckvqwovxz00003864t4jlgozu: { number: 1 },
        },
      },
    },
    refNumber: "461874",
    id: "2KfejRZ1ory8NVXRM279",
  },
  {
    created: {
      displayName: "Arnas Jelizarovas",
      userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
      at: { seconds: 1636992943, nanoseconds: 878000000 },
    },
    refNumber: "462331",
    shipments: {
      ckz7kh8yb001bm0ea1bpnc92p: {
        refNumber: "SE-462331-R1",
        created: {
          displayName: "Arnas Jelizarovas",
          at: { seconds: 1636992943, nanoseconds: 558000000 },
          userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
        },
        updated: {
          yb1cmatj: {
            userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
            displayName: "Arnas Jelizarovas",
            at: { seconds: 1636992943, nanoseconds: 558000000 },
          },
        },
        unitCount: 2,
        units: {
          ckw0vbzev000138612yoegydh: { number: 2 },
          ckw0vbzeu00003861xaig4rrn: { number: 1 },
        },
      },
    },
    shippers: {
      ckz7kh8yb001am0ea66kr7c3n: { displayName: 'Wenting "Stella" Su' },
    },
    id: "2NQkAK4X1CMPhfXPV5Ry",
  },
  {
    created: {
      at: { seconds: 1637000073, nanoseconds: 837000000 },
      userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
      displayName: "Arnas Jelizarovas",
    },
    shippers: { ckz7kh8yb001em0eaeuoyfhyd: { displayName: "Michael Brown" } },
    shipments: {
      ckz7kh8yb001fm0eaf2zoewxc: {
        updated: {
          yb1hma30: {
            displayName: "Arnas Jelizarovas",
            at: { seconds: 1637000073, nanoseconds: 610000000 },
            userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
          },
        },
        created: {
          displayName: "Arnas Jelizarovas",
          userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
          at: { seconds: 1637000073, nanoseconds: 610000000 },
        },
        refNumber: "SE-463673-R1",
        units: {
          ckw0zksx900013861a79r0itd: { number: 2 },
          ckw0zksx900003861nby249v6: { number: 1 },
          ckw0zksx900023861s766bvma: { number: 3 },
        },
        unitCount: 3,
      },
    },
    refNumber: "463673",
    id: "3ftQFJaz7LFULvdaYyA0",
  },
  {
    refNumber: "462243",
    shipments: {
      ckz7kh8y7000qm0ea3fii7dem: {
        units: { ckvshhll000003864cqclxiky: { number: 1 } },
        unitCount: 1,
        updated: {
          y7omaja: {
            at: { seconds: 1636485963, nanoseconds: 0 },
            displayName: "Arnas Jelizarovas",
            userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
          },
        },
        created: {
          at: { seconds: 1636485963, nanoseconds: 0 },
          displayName: "Arnas Jelizarovas",
          userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
        },
        refNumber: "SE-462243-A1",
      },
    },
    shippers: { ckz7kh8y7000pm0ea51rdhjje: { displayName: "David Zheng" } },
    created: {
      userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
      at: { seconds: 1636485961, nanoseconds: 860000000 },
      displayName: "Arnas Jelizarovas",
    },
    id: "8oKoQNLAxeIp5q7XiqqE",
  },
  {
    created: {
      userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
      displayName: "Arnas Jelizarovas",
      at: { seconds: 1640299718, nanoseconds: 734000000 },
    },
    shipments: {
      ckz7kh8y1000jm0eaedob9qjf: {
        created: {
          displayName: "Arnas Jelizarovas",
          userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
          at: { seconds: 1636479435, nanoseconds: 0 },
        },
        units: {
          ckvsdlp8000063864v82zgvth: { number: 2 },
          ckvsdlpbj000938644efxeclq: { number: 5 },
          ckvsdlpa500083864oufcpzzb: { number: 4 },
          ckvsdlp6t00053864jtg1hu5b: { number: 1 },
          ckvsdlp9b00073864n68zpq9r: { number: 3 },
          ckvsdlpd4000a3864egse1ueo: {
            number: 6,
            thumb:
              "https://firebasestorage.googleapis.com/v0/b/sfm-tools-448ab.appspot.com/o/companies%2Fparamount%2Funits%2Fckvsdlpd4000a3864egse1ueo%2Frl09av7%2Fthumb_001_16366601312331443458557746334793.jpg-9j031h8.jpg?alt=media&token=cl1gvg3z1000001s669qk6z6i",
          },
        },
        unitCount: 6,
        updated: {
          pzilmaof: {
            userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
            at: { seconds: 1636666625, nanoseconds: 536000000 },
            displayName: "Arnas Jelizarovas",
          },
        },
        refNumber: "SE-462582-S1",
      },
      ckz7kh8zu009am0eac4grdsgc: {
        created: {
          at: { seconds: 1640299719, nanoseconds: 147000000 },
          displayName: "Arnas Jelizarovas",
          userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
        },
        unitCount: 1,
        refNumber: "SE-462582-A1",
        units: {
          ckxjk3n7y0006395x9wkgdiag: {
            dimms: { units: "inches", h: 56, w: 54, l: 54 },
            number: 1,
            weight: { tare: 88 },
            unitType: { label: "LDN", id: "ldn" },
          },
        },
        updated: {
          zu99maz2: {
            userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
            at: { seconds: 1640299719, nanoseconds: 147000000 },
            displayName: "Arnas Jelizarovas",
          },
        },
      },
    },
    updated: {
      te037xu: {
        description: "changed unit #1  to LDN",
        userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
        at: { seconds: 1648247311, nanoseconds: 449000000 },
        displayName: "Arnas Jelizarovas",
      },
    },
    shippers: {
      ckz7kh8y1000im0ea6y6y0hok: { displayName: "Mikko Saakslahti" },
    },
    refNumber: "462582",
    id: "BLfqBllEWVQVWA5254OI",
  },
  {
    shipments: {
      ckz7kh8y8000tm0eac9tlarsy: {
        updated: {
          y9wmaxu: {
            displayName: "Arnas Jelizarovas",
            at: { seconds: 1636757359, nanoseconds: 904000000 },
            userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
          },
        },
        unitCount: 4,
        created: {
          displayName: "Arnas Jelizarovas",
          at: { seconds: 1636757359, nanoseconds: 904000000 },
          userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
        },
        units: {
          ckvwkyoxb0007386dlrphc4yo: { number: 4 },
          ckvwkyosl0005386d3bnh9fb1: { number: 2 },
          ckvwkyoof0004386d16o0rgu5: { number: 1 },
          ckvwkyov20006386d8wlvkyqw: { number: 3 },
        },
        refNumber: "SE-463420-S1",
      },
    },
    created: {
      displayName: "Arnas Jelizarovas",
      userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
      at: { seconds: 1636733662, nanoseconds: 575000000 },
    },
    shippers: { ckz7kh8y8000sm0eaayyt0h3c: { displayName: "Emma Avery" } },
    refNumber: "463420",
    id: "Fx86qujKucrSu8vzBRCO",
  },
  {
    created: {
      displayName: "Arnas Jelizarovas",
      at: { seconds: 1639079625, nanoseconds: 139000000 },
      userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
    },
    shippers: { ckz7kh8zc0068m0ea7vu26iq0: { displayName: "Samuel Blazes" } },
    refNumber: "465621",
    shipments: {
      ckz7kh8zc0069m0eahd3qhni6: {
        refNumber: "SE-465621-R1",
        created: {
          displayName: "Arnas Jelizarovas",
          at: { seconds: 1639079625, nanoseconds: 530000000 },
          userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
        },
        unitCount: 1,
        units: { ckwzdosz70000385xrbfedzlq: { number: 1 } },
        updated: {
          zc67ma0g: {
            at: { seconds: 1639079625, nanoseconds: 530000000 },
            userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
            displayName: "Arnas Jelizarovas",
          },
        },
      },
    },
    id: "KKpxku6fU7uhoyCNsuM5",
  },
  {
    refNumber: "459985",
    shippers: { ckz7kh8yg001um0ea1nk5fosu: { displayName: "Hossam El-Shikh" } },
    created: {
      displayName: "Arnas Jelizarovas",
      userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
      at: { seconds: 1637078357, nanoseconds: 753000000 },
    },
    shipments: {
      ckz7kh8yg001vm0eahjpv7l3v: {
        created: {
          userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
          at: { seconds: 1637078357, nanoseconds: 209000000 },
          displayName: "Arnas Jelizarovas",
        },
        refNumber: "SE-459985-R1",
        unitCount: 4,
        updated: {
          yh1ymaqn: {
            displayName: "Arnas Jelizarovas",
            at: { seconds: 1637078357, nanoseconds: 209000000 },
            userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
          },
        },
        units: {
          ckw2a6p6h0007386dffx1xnqa: { number: 2 },
          ckw2a6p6h0009386dfo52opgl: { number: 4 },
          ckw2a6p6h0008386dnbeklt3p: { number: 3 },
          ckw2a6p6h0006386d52n63361: { number: 1 },
        },
      },
    },
    id: "RABWvtEinfPrsv70Vmep",
  },
  {
    created: {
      userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
      displayName: "Arnas Jelizarovas",
      at: { seconds: 1636393256, nanoseconds: 7000000 },
    },
    shippers: { ckz7kh8y00006m0eaeo2s936v: { displayName: "Ali Vest" } },
    refNumber: "461614",
    shipments: {
      ckz7kh8y00007m0eag8abds9q: {
        units: {
          ckvqyalep00063864o5yxrk0z: { number: 2 },
          ckvqyalgx00073864tcyz7s6y: { number: 3 },
          ckvqyalaf000538649j76jhon: { number: 1 },
        },
        unitCount: 3,
        updated: {
          y09madm: {
            displayName: "Arnas Jelizarovas",
            at: { seconds: 1636393256, nanoseconds: 0 },
            userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
          },
        },
        refNumber: "SE-461614-R1",
        created: {
          userId: "NxACZod7bSRBWPFj75wmjUYj3dN2",
          displayName: "Arnas Jelizarovas",
          at: { seconds: 1636393256, nanoseconds: 0 },
        },
      },
    },
    id: "Wj5yjByNz296iy73RbYr",
  },
];
