import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "../../../app/common/form/Checkbox";
import { Input } from "../../../app/common/form/Input";
import { getUnitCount } from "../../../app/firestore/firestoreService";
import { PageLayout } from "../../../app/layout/PageLayout";
// import { PageLayout } from "../../../app/layout/PageLayout";
// import { arnasSignature } from "../../sailor/arnasSignature";

export const ShipmentReleaseFormstack = ({ ...props }) => {
  const { shippers, refNumber, shipmentDate, shipmentId } = props.location.state;
  const [unitCount, setUnitCount] = useState("");
  const [warning, setWarning] = useState(null);
  const [ignoreWarning, setIgnoreWarning] = useState(false);
  const [unitsReleased, setUnitsReleased] = useState(0);
  const { currentUserProfile } = useSelector((state) => state.profile);

  React.useLayoutEffect(() => {
    const fetchData = async () => setUnitCount(await getUnitCount(shipmentId));
    try {
      fetchData();
    } catch (error) {
      setWarning(error);
    }
  }, [shipmentId]);
  React.useEffect(() => {
    if (unitsReleased && Number(unitCount) !== Number(unitsReleased)) {
      setWarning(`Releasing ${unitCount > unitsReleased ? "less" : "more"} than we have in the system`);
    }
    return () => {
      setWarning(null);
    };
  }, [unitCount, unitsReleased]);
  // getUnitCount(shipmentId)
  return (
    <PageLayout title="Shipment Release">
      {/* <div className="text-xs">
        <pre>{JSON.stringify(props?.location?.state, null, 2)}</pre>
      </div> */}
      {/* <div className="text-xs">
        <pre>{JSON.stringify(key, null, 2)}</pre>
      </div> */}

      <form
        method="post"
        action="https://superfriendsmoving.formstack.com/forms/warehouse_release_shipment"
        className="flex flex-col max-w-sm mx-auto py-2 px-2"
        target="_blank"
      >
        <table className=" my-4 mx-auto border  bg-white shadow-sm">
          <tbody>
            {[
              ["Shipper", shippers],
              ["Reference", refNumber],
              ["Shipment Date", shipmentDate],
              ["Units in system", unitCount],
            ].map(([label, value], i) => (
              <TableRow key={label} label={label} value={value} />
            ))}
          </tbody>
        </table>
        <input type="hidden" name="form" value="4750356" />
        <input type="hidden" name="viewkey" value="QjamwWbt9k" />
        <Input containerClassName="hidden" type="hidden" label="Shipper" name="shipper" value={shippers} />
        <Input containerClassName="hidden" type="hidden" label="Reference Number" name="reference_number" value={refNumber} />
        <Input containerClassName="hidden" type="hidden" label="Units in the system" name="units_in_system" value={unitCount} />
        <Input
          autoFocus={true}
          // type="text"
          type="number"
          step={1}
          label="How many units Released"
          name="units_released"
          value={unitsReleased}
          inputMode="numeric"
          pattern="[0-9]*"
          containerClassName="w-48 mx-auto my-10"
          className="text-right text-xl"
          // onInput={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
          onChange={(e) => setUnitsReleased(e.target.value)}
        />
        <Input containerClassName="hidden" type="hidden" label="Shipment receive date" name="shipment_date" value={shipmentDate} />
        {/* <Input name="bol" value="" />
        <Input name="truck_photo" value="" />
        <Input name="driver_name" value="" />
        <Input name="company" value="" /> */}
        <Input containerClassName="hidden" type="hidden" label="Released by" name="released_by" value={currentUserProfile?.displayName} />
        {warning && (
          <div className="bg-yellow-300 bg-opacity-50 rounded border border-yellow-600 p-4">
            {warning}
            <Checkbox checked={ignoreWarning} onChange={() => setIgnoreWarning((v) => !v)} label="Ignore warning" />
          </div>
        )}
        {/* <input name="customer_signature" value={arnasSignature} readOnly onChange={() => {}} /> */}
        <input
          className={`text-white mx-2 py-1 rounded mt-10 ${
            (!ignoreWarning && warning) || !unitsReleased || unitsReleased === 0 ? "bg-gray-300" : "bg-green-600 hover:bg-green-500 shadow-green-700 shadow-sm  cursor-pointer transition-all"
          }`}
          type="submit"
          disabled={(!ignoreWarning && warning) || !unitsReleased || unitsReleased === 0}
        />
      </form>
    </PageLayout>
  );
};

const TableRow = ({ label = "", value = "" }) => {
  return (
    <tr>
      <td className="px-4 text-right text-slate-500 py-2">{label}</td>
      <td className="px-4 text-center py-2">{value}</td>
    </tr>
  );
};

// const Input = ({ name, value = "", label = "", placeholder = "", icon = "", type, ...props }) => {
//   const [inputValue, setInputValue] = React.useState(value);
//   if (type === "hidden") return null;
//   return (
//     <label htmlFor={name} className="flex flex-col">
//       {label && <span>{label}</span>}
//       <div className="rounded-md border px-4 py-2 mb-1 bg-white focus-within:border-indigo-400 ">
//         <input
//           name={name}
//           {...props}
//           onChange={(e) => setInputValue(e.target.value)}
//           value={value}
//           placeholder={placeholder}
//           className={`bg-transparent outline-none ${!icon ? "ml-7" : "ml-2"}`}
//         />
//       </div>
//     </label>
//   );
// };
