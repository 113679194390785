import React, { useEffect } from "react";
import {
  MdAccessTime,
  MdLocalShipping,
  MdCreditCard,
  MdLocalOffer,
} from "react-icons/md";
import { SectionTitle } from "../../app/layout/SectionTitle";

import { useMove, useMoveDispatch } from "./MoveProvider";

// import { ChipsInput } from "../../app/common/form/ChipsInput";
// import { ButtonSelect } from "../../app/common/form/ButtonSelect";
import { NoInput } from "../../app/common/form/NoInput";
import { Local } from "./Local";
import { LongDistance } from "./LongDistance";
import { FlatRate } from "./FlatRate";
import { Radio } from "../../app/common/form/Radio";
// import { TimeInput } from "../../app/common/form/TimeInput";

export const Rates = () => {
  const client = useMove();
  const dispatch = useMoveDispatch();

  const onChange = (e) =>
    dispatch({ field: e.target.name, value: e.target.value });

  const { totalTransportation, jobType } = client;

  useEffect(() => {
    if (!jobType)
      dispatch({
        field: "jobType",
        value: "local",
      });
    return () => {};
  }, [jobType, dispatch]);

  return (
    <form method="post">
      <SectionTitle title="Job Type" hidePlus={true} />
      <JobType onClick={onChange} value={jobType} />

      {/* <SectionTitle title="Crew" hidePlus={true} /> */}
      {/* <ChipsInput name="personnel" chips={personnel} /> */}
      {/* <DropDown /> */}

      {jobType === "flatRate" && <FlatRate />}
      {jobType === "longDistance" && <LongDistance />}
      {jobType === "local" && <Local />}
      <NoInput
        value={totalTransportation}
        Icon={MdLocalOffer}
        unit="$"
        label="Total Transportation"
      />
    </form>
  );
};

// function timeToDecimal(t) {
//   var arr = t.split(":");
//   var dec = parseInt((arr[1] / 6) * 10, 10);

//   return parseFloat(parseInt(arr[0], 10) + "." + (dec < 10 ? "0" : "") + dec);
// }

const JobType = (props) => {
  const client = useMove();
  const dispatch = useMoveDispatch();

  const { jobType } = client;

  const jobTypes = [
    { value: "local", name: "Local (Hourly)", Icon: MdAccessTime },
    { value: "flatRate", name: "Flat Rate", Icon: MdCreditCard },
    { value: "longDistance", name: "Long Distance", Icon: MdLocalShipping },
  ];

  return (
    <Radio
      name="jobType"
      value={jobType}
      dispatch={dispatch}
      options={jobTypes}
      row="true"
      dense="true"
      {...props}
    />
  );
};
