import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import * as Yup from "yup";
// import { MyTextInput } from "../../../../app/common/form/MyTextInput";
import {
  addPacketToFirestore,
  listenToPacketFromFirestore,
  listenToPDFsFromFirestore,
  updatePacketSelectedFilesInFirestore,
} from "../../../app/firestore/firestoreService";
import useFirestoreCollection from "../../../app/hooks/useFirestoreCollection";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";
import { FILE_PLACEHOLDER_THUMB } from "../../../config";
import { MyTextInput } from "../../../app/common/form/MyTextInput";
import { listenToPacket, listenToPDFs } from "../settingsActions";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdEdit,
  MdInfo,
  MdSearch,
} from "react-icons/md";

function updateVehicleInFirestore(updatedVehicle) {
  console.log(updatedVehicle);
}

export const Packet = ({ match, history, location }) => {
  const dispatch = useDispatch();

  // const id = match.params.packetId;

  const selectedPacket = useSelector((state) => state.manage.packet);

  const initialValues = selectedPacket ?? {
    isStarred: false,
    name: "",
    files: [],
    tags: [],
    usedCounter: 0,
    usedFrequency: "",
    createdBy: "",
    createdAt: "",
  };

  const validationSchema = Yup.object({
    isStarred: Yup.boolean(),
    name: Yup.string().required(),
    tags: Yup.lazy((val) =>
      Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.string()
    ),
    description: Yup.string(),
    // directives: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.string())),
  });

  useFirestoreDoc({
    query: () => listenToPacketFromFirestore(match.params.packetId),
    data: (packet) => dispatch(listenToPacket(packet)),
    deps: [match.params.packetId, dispatch],
  });

  const [inputValue, setInputValue] = useState("");

  const [selectedFiles, setSelectedFiles] = useState([]);
  const { pdfs } = useSelector((state) => state.manage);
  const [filteredPdfs, setFilteredPdfs] = useState(pdfs || []);

  useFirestoreCollection({
    query: () => listenToPDFsFromFirestore(),
    data: (pdfs) => dispatch(listenToPDFs(pdfs)),
    deps: [dispatch],
  });

  useEffect(() => {
    setFilteredPdfs(
      pdfs.filter((p) =>
        p.fileName.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
    return () => {};
  }, [pdfs, inputValue]);

  useEffect(() => {
    updatePacketSelectedFilesInFirestore(match.params.packetId, selectedFiles);
  }, [selectedFiles, match.params.packetId]);

  // if (loading) return <LoadingComponent content="Loading event..." />;

  // if (error) return <Redirect to="/error" />;

  return (
    <>
      {/* {loading.toString()} */}
      {!!selectedPacket &&
        Object.entries(selectedPacket).map(([key, val]) => (
          <h2 key={key}>
            {key}: {val.toString()}
          </h2>
        ))}
      <div centered>
        <div width={8}>
          <section>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  selectedPacket
                    ? await updateVehicleInFirestore(values)
                    : await addPacketToFirestore(values);
                  setSubmitting(false);
                } catch (error) {
                  toast.error(error.message);
                }
              }}
            >
              {({ isSubmitting, dirty, isValid, values }) => (
                <Form className="form ui">
                  <h2>Packet</h2>
                  <MyTextInput name="name" placeholder="Name" />
                  <MyTextInput name="tags" placeholder="Tags" />

                  <button
                    //loading={isSubmitting}
                    disabled={!isValid || !dirty || isSubmitting}
                    type="submit"
                  >
                    {selectedPacket ? "Edit" : "Create"}
                  </button>
                </Form>
              )}
            </Formik>
          </section>
        </div>
        <div width={8}></div>
      </div>
      <Files
        packet={selectedPacket}
        inputValue={inputValue}
        setInputValue={setInputValue}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        filteredPdfs={filteredPdfs}
        setFilteredPdfs
      />
      <PacketFields selectedFiles={selectedFiles} pdfs={pdfs} />
    </>
  );
};

const Files = ({
  inputValue,
  setInputValue,
  selectedFiles,
  setSelectedFiles,
  filteredPdfs,
  setFilteredPdfs,
}) => {
  function search(e) {
    setInputValue(e.target.value);
  }

  function handleClick(e) {
    e.preventDefault();

    if (e.type === "dblclick") {
      console.log("Double click");
    }
    if (e.type === "click") {
      console.log("Left click");
    } else if (e.type === "contextmenu") {
      console.log("Right click");
    }
  }

  function clearInput(e) {
    if (e.key === "Escape") {
      e.target.blur();
    }
    if (e.ctrlKey && e.key === "x") {
      setInputValue("");
    }
    if (e.shiftKey && e.key === "Enter") {
      selectAll();
    }
    if (e.shiftKey && e.key === "Delete") {
      selectNone();
    }
  }

  function toggleFileSelection(fileId) {
    return function (e) {
      if (selectedFiles.includes(fileId)) {
        setSelectedFiles((files) => [
          ...files.filter((file) => file !== fileId),
        ]);
      } else {
        setSelectedFiles((files) => [...files, fileId]);
      }
    };
  }

  function selectAll() {
    filteredPdfs.forEach(({ id }) => {
      if (!selectedFiles.includes(id)) {
        setSelectedFiles((files) => [...files, id]);
      }
    });
  }
  function selectNone() {
    filteredPdfs.forEach(({ id }) => {
      if (selectedFiles.includes(id)) {
        setSelectedFiles((files) => [...files.filter((file) => file !== id)]);
      }
    });
  }
  const [isMyInputFocused, setIsMyInputFocused] = useState(false);

  return (
    <section>
      <h2>Files</h2>
      <div className="flex justify-between">
        <div>
          <div className="px-2 py-2 shadow-md border-2 border-grey-500 rounded-md w-96 flex  focus-within:text-purple-600 focus-within:ring-2 focus-within:ring-purple-600 focus:border-transparent">
            <input
              className="w-full   focus:outline-none focus:ring-0  "
              type="search"
              onChange={search}
              value={inputValue}
              placeholder="Filter Files..."
              onKeyDown={clearInput}
              onBlur={() => setIsMyInputFocused(false)}
              onFocus={() => setIsMyInputFocused(true)}
            />
            <MdSearch />
          </div>
          {isMyInputFocused && (
            <span className="text-xs text-gray-400 pl-4">
              <kbd className="bg-gray-100 shadow-sm rounded-sm px-1">Ctrl</kbd>{" "}
              + <kbd className="bg-gray-100 shadow-sm rounded-sm px-1">x</kbd>{" "}
              to clear search
            </span>
          )}
        </div>
        <div className="flex">
          <div className="flex flex-col">
            <button color="green" basic onClick={selectAll}>
              Select All ({filteredPdfs.length.toString() || ""})
            </button>
            {isMyInputFocused && (
              <span className="text-xs text-gray-400 pl-4 mt-1">
                <kbd className="bg-gray-100 shadow-sm rounded-sm px-1">
                  Shift
                </kbd>{" "}
                +{" "}
                <kbd className="bg-gray-100 shadow-sm rounded-sm px-1">
                  Enter
                </kbd>
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <button basic onClick={selectNone}>
              Deselect ({filteredPdfs.length.toString() || ""})
            </button>
            {isMyInputFocused && (
              <span className="text-xs text-gray-400 pl-4 mt-1">
                <kbd className="bg-gray-100 shadow-sm rounded-sm px-1">
                  Shift
                </kbd>{" "}
                +{" "}
                <kbd className="bg-gray-100 shadow-sm rounded-sm px-1">
                  Delete
                </kbd>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="flex  flex-wrap">
        {filteredPdfs.map((pdf) => (
          <div
            key={pdf.id}
            className="m-1 flex flex-col items-center justify-center  "
          >
            <div
              className={` relative hover:shadow-sm border-2 border-${
                selectedFiles.includes(pdf.id) ? "green" : "gray"
              }-200 m-1  overflow-hidden rounded-md transform transition duration-100 hover:scale-105 hover:z-10 bg-gray-200 cursor-pointer flex flex-col align-middle `}
            >
              <img
                src={pdf.thumbs[0].url}
                onError={(e) => (e.target.src = FILE_PLACEHOLDER_THUMB)}
                alt={pdf.thumbs[0].name}
                size="small"
                onClick={toggleFileSelection(pdf.id)}
              />
              <span
                className="truncate absolute bottom-1 left-2/3 text-xs bg-gray-200 bg-opacity-60 py-1 px-3 rounded-md"
                onClick={handleClick}
                onContextMenu={handleClick}
                onDoubleClick={handleClick}
              >
                {0 + 1 + " / " + pdf.thumbs.length}
              </span>
              <span className="truncate absolute bottom-1 left-2 text-xs bg-gray-200 bg-opacity-80 py-1 px-3 rounded-md">
                <Link to={`/manage/settings/pdfs/${pdf.id}/${pdf.fileName}`}>
                  <MdEdit />
                </Link>
              </span>
              <span
                className="truncate absolute top-1 right-2 text-xs bg-gray-200 bg-opacity-80 py-1 px-3 rounded-md"
                onClick={() => console.log(pdf)}
              >
                <MdInfo />
              </span>
              <span
                className="w-12  overflow-hidden inline-block top-0 absolute "
                onClick={toggleFileSelection(pdf.id)}
              >
                <div
                  className={` h-20  bg-${
                    selectedFiles.includes(pdf.id) ? "green" : "gray"
                  }-200 rotate-45 transform origin-top-right`}
                ></div>
                {selectedFiles.includes(pdf.id) ? (
                  <MdCheckBox className="absolute top-1 left-1" />
                ) : (
                  <MdCheckBoxOutlineBlank className="absolute top-1 left-1" />
                )}
              </span>
            </div>
            <FileName name={pdf.fileName} />
          </div>
        ))}
      </div>
    </section>
  );
};

const FileName = ({ name }) => {
  let n = name;
  if (name && name.toLowerCase().endsWith(".pdf")) {
    n = name.slice(0, -4);
  }

  return (
    <div pinned position="bottom center" content={name} size="mini">
      {" "}
      <div className="w-36 text-center text-xs flex justify-center">
        <p className="truncate  ">{n.slice(0, -6)}</p>
        <p>{n.slice(-6)}</p>
      </div>
    </div>
  );
};

const PacketFields = ({ selectedFiles, pdfs }) => {
  //merge all pdf fields with pdf ids,

  function mergeFieldsFromPDFs(pdfs) {
    let packetFields = pdfs.reduce((a, v) => {
      return (a = [...a, ...v.formFields]);
    }, []);
    console.log(packetFields);
    /*
    pdf
      formFields
        fields
          pageIndex
          tabIndex
          x y w h
          type (number/alpha/box)
        name
        type

===========================

to
  formFields
    group
    as (alias)
    type
    fields
      ...fields
      pdfId
      pdfName
      name (to have different names on one input)


  */
  }

  const [active, setActive] = useState(0);

  return (
    <section>
      <h3>Packet Fields</h3>
      <button
        className="bg-red-500 p-3"
        onClick={() =>
          mergeFieldsFromPDFs(
            selectedFiles.map((id) => pdfs.find((p) => p.id === id))
          )
        }
      >
        get pdfs
      </button>
      <div styled>
        {selectedFiles.map((id, i) => {
          const pdf = pdfs.find((p) => p.id === id);
          return (
            <>
              <div
                key={i}
                active={active === i}
                index={i}
                onClick={() => setActive(i)}
              >
                {pdf.id} - {pdf.fileName} - {pdf.formFields.length}
              </div>
              <div active={active === i}>
                <div>
                  {pdf.formFields.map((f) => (
                    <div
                      key={f.name}
                      className="no-underline"
                      onClick={() => console.log(f)}
                    >
                      {f.name} {`(x${f?.fields?.length})`}
                    </div>
                  ))}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </section>
  );
};
