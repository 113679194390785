import React, { useState } from "react";
import { AboutTab } from "./AboutTab";
import { PhotosTab } from "./PhotosTab";

export const ProfileContent = ({ profile, isCurrentUser }) => {
  const [, setActiveTab] = useState(0);

  const panes = [
    {
      menuItem: "About",
      render: () => (
        <AboutTab profile={profile} isCurrentUser={isCurrentUser} />
      ),
    },
    {
      menuItem: "Photos",
      render: () => (
        <PhotosTab profile={profile} isCurrentUser={isCurrentUser} />
      ),
    },
  ];

  return (
    <div
      menuPosition="right"
      menu={{ fluid: true, vertical: true }}
      panes={panes}
      onTabChange={(e, data) => setActiveTab(data.activeIndex)}
    >
      {panes.map((tab, i) => tab.render())}
    </div>
  );
};
