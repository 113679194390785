import React from "react";
import { useSelector } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { signOutFirebase } from "../../firestore/firebaseService";

import { Popover, Transition } from "@headlessui/react";
import { MdOutlinePowerSettingsNew, MdOutlineSettingsApplications, MdPerson } from "react-icons/md";

export const SignedInMenu = () => {
  const { currentUserProfile } = useSelector((state) => state.profile);
  const history = useHistory();

  async function handleSignOut() {
    try {
      await signOutFirebase();
      history.push("/");
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div>
      <GroupLink
        label={currentUserProfile?.displayName}
        imageIconSrc={currentUserProfile?.photoURL || "/img/user.png"}
        links={[
          {
            to: `/profile/${currentUserProfile?.id}`,
            label: "Profile",
            Icon: MdPerson,
          },
          {
            to: "/account",
            label: "Account",
            Icon: MdOutlineSettingsApplications,
          },
          {
            onClick: handleSignOut,
            label: "Sign Out",

            Icon: MdOutlinePowerSettingsNew,
          },
        ]}
      />
    </div>
  );
};

function GroupLink({ label, Icon, links, imageIconSrc }) {
  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button className="flex rounded-full hover:shadow-md">
            {imageIconSrc ? <img src={imageIconSrc} className="aspect-square w-8 rounded-full" alt="icon img" /> : <Icon />}
            {/* <span>{label}</span> */}
          </Popover.Button>
          {open && (
            <Transition show={open}>
              <Popover.Panel static className="absolute z-30 right-0 border mt-2 rounded">
                <div className="bg-white text-gray-600 flex flex-col rounded ">
                  {links.map(({ to, label, Icon, onClick }, i) =>
                    !to ? (
                      <button type="button" key={i} className="px-4  py-2 flex items-center flex-nowrap hover:bg-blue-50" onClick={onClick}>
                        <Icon />
                        <span className="whitespace-nowrap ml-2"> {label}</span>
                      </button>
                    ) : (
                      <NavLink to={to} key={i} className="px-4 py-2 flex items-center flex-nowrap hover:bg-blue-50" onClick={close}>
                        <Icon />

                        <span className="whitespace-nowrap ml-2">{label}</span>
                      </NavLink>
                    )
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          )}
        </>
      )}
    </Popover>
  );
}
