import React from "react";
import { Input } from "../../app/common/form/Input";
import {
  MdHome,
  MdLocalShipping,
  MdAddLocation,
  MdRemove,
  MdAdd,
} from "react-icons/md";

import { useMove, useMoveDispatch } from "./MoveProvider";
import { SectionTitle } from "../../app/layout/SectionTitle";

export const Locations = () => {
  const client = useMove();
  const dispatch = useMoveDispatch();
  const {
    originAddress,
    destinationAddress,
    additionalStops,
    anyAdditionalStops,
  } = client;

  const onChange = (e) =>
    dispatch({ field: e.target.name, value: e.target.value });

  // TODO when address autocomplete is done, if address not found
  // (most likely case of newly built house) allow entry of coordinates

  // TODO autocomplete address

  // TODO calculate distance as the crow flies

  return (
    <>
      <SectionTitle
        title="Locations"
        onClick={() =>
          dispatch({
            field: "anyAdditionalStops",
            value: !anyAdditionalStops,
          })
        }
        Icon={anyAdditionalStops ? MdRemove : MdAdd}
      />
      <Input
        name="originAddress"
        value={originAddress}
        onChange={onChange}
        Icon={MdHome}
        placeholder="Starting Point (Origin)"
        label="Starting Point (Origin)"
      />
      {anyAdditionalStops && (
        <Input
          name="additionalStops"
          value={additionalStops}
          onChange={onChange}
          Icon={MdAddLocation}
          placeholder="Other Stops"
          label="Other Stops"
        />
      )}
      <Input
        name="destinationAddress"
        value={destinationAddress}
        onChange={onChange}
        Icon={MdLocalShipping}
        placeholder="End Point (Destination)"
        label="End Point (Destination)"
      />
    </>
  );
};
