import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../../app/common/components/Button";
import { Input } from "../../../app/common/form/Input";
import { RadioGroup } from "../../../app/common/form/RadioGroup";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { ModalWrapper } from "../../../app/common/modals/ModalWrapper";
import { renameShipment } from "../../../app/firestore/firestoreService";

export const RenameShipmentModal = ({ opId, shipment, shipmentId }) => {
  const [value, setValue] = useState(shipment?.refNumber?.trim() || "");
  const [manualShipmentType, setManualShipmentType] = useState(shipment?.refNumber?.startsWith("SE-"));

  const modalDispatch = useDispatch();

  const onSubmit = async (e) => {
    e.preventDefault();
    renameShipment({ shipmentName: value, opId, shipment, shipmentId });
    modalDispatch(closeModal());
  };

  const handleChange = (e) => {
    setValue(upper(e.target.value));
  };

  const toggleViewShipmentType = () => {
    setManualShipmentType((m) => !m);
  };
  const upper = value =>  value ? value.toUpperCase() : ''

  return (
    <ModalWrapper header="Rename Shipment">
      <div className="bg-white p-4 relative">
        <form onSubmit={onSubmit} className="flex flex-col">
          <Input
            onChange={handleChange}
            value={value}
            name="value"
            label="New Shipment Name"
            placeholder={`SE-000000-X1`}
            ActionButton={() => value.length === 12 ? (
              <button
                type="button"
                onClick={toggleViewShipmentType}
                className={`bg-${shipmentColor(parseRefNumber(value))}-400 px-2 rounded-md`}
              >
                {parseRefNumber(value)}
              </button>
            ) : null}
            // onBlur={parseRefNumber}
            Icon={() => <span className="font-bold mx-4">SE</span>}
            
          />
          {manualShipmentType && (
            <RadioGroup
              options={shipmentType}
              label="Shipment Type"
              onChange={(e) => {
                setValue((v) => setCharAt(v, v.length - 2, e.target.value[0]));
              }}
              value={parseRefNumber(value)}
            />
          )}
          <div className="mt-2 w-full">
            <Button type="submit" color="green" disabled={value === ("" || shipment?.refNumber)}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};

const shipmentColor = (shipmentType) => {
  switch (shipmentType) {
    case "SEA":
      return "blue";
    case "AIR":
      return "red";
    case "ROAD":
      return "green";
    case "PERM":
      return "yellow";
    case "DEST":
      return "red";

    default:
      return "gray";
  }
};

const parseRefNumber = (value = "") => {
  const v = value?.trim();
  const vLen = v.length;
  let type;

  if (vLen > 2) {
    type = v.charAt(vLen - 2).toLowerCase();
    switch (type) {
      case "a":
        return "AIR";
      case "p":
        return "PERM";
      case "r":
        return "ROAD";
      case "s":
        return "SEA";
      case "d":
        return "DEST";
      default:
        return console.log("not part of shipment");
    }
  }
};

const shipmentType = [
  { value: "SEA", name: "Sea" },
  { value: "AIR", name: "Air" },
  { value: "ROAD", name: "Road" },
  { value: "PERM", name: "Perm Storage" },
  // { value: "DEST", name: "Destination", Icon: MdDateRange },
];

function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + chr + str.substring(index + 1);
}
