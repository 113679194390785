import { Formik, Form, FieldArray } from "formik";
import React from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { MyTextInput } from "../../app/common/form/MyTextInput";
import { addPTSJobToFirestore } from "../../app/firestore/firestoreService";
import cuid from "cuid";
import { MdClear } from "react-icons/md";

function updateJobInFirestore(updatedJob) {
  console.log(updatedJob);
}

export const AddJob = ({
  hideId,
  callBack = () => {},
  match,
  history,
  location,
}) => {
  // const dispatch = useDispatch();
  //   const selectedJob = useSelector((state) => state.warehouse.Jobs?.find((u) => u.id === match.params.id));
  const selectedJob = null;

  const initialValues = selectedJob ?? {
    jobId: cuid(),
    fullName: "",
    refNumber: ["SE-"],
  };

  const validationSchema = Yup.object({
    jobId: Yup.string(),
    fullName: Yup.string(),
    // refNumber: Yup.array(),
  });

  async function onSubmit(values, { setSubmitting, resetForm }) {
    console.log(values);
    try {
      selectedJob
        ? await updateJobInFirestore(values)
        : await addPTSJobToFirestore(values);

      setSubmitting(false);
      callBack(values);
      resetForm({
        values: {
          jobId: cuid(),
          fullName: "",
          refNumber: ["SE-"],
        },
      });
      // history.push("/events");
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div className="pt-2">
      <section>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            dirty,
            isValid,
            values,
            handleSubmit,
            setValues,
            handleReset,
          }) => (
            <Form className="form ui mt-2">
              {/* <div>
                <pre className="text-xs">{JSON.stringify(values, null, 2)}</pre>
              </div> */}
              <h2>Job</h2>
              {!hideId && (
                <MyTextInput
                  name="jobId"
                  placeholder="Job Id"
                  label="Job Id"
                  value={values.jobId}
                />
              )}
              <MyTextInput
                name="fullName"
                placeholder="fullName"
                label="Customer Full Name"
                value={values.fullName}
              />

              <FieldArray
                name="refNumber"
                render={(arrayHelpers) => (
                  <div className="">
                    <label htmlFor="">Reference numbers</label>
                    {values.refNumber &&
                      values.refNumber.length > 0 &&
                      values.refNumber.map((ref, index) => (
                        <div key={index} className="  ">
                          <MyTextInput
                            placeholder="SE-XXXXXX-X1"
                            name={`refNumber.${index}`}
                            className=""
                            autoComplete="off"
                          />
                          {index !== 0 && (
                            <button
                              className="bg-red-600 text-white  hover:bg-red-500 px-3 py-1 rounded-lg"
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <MdClear />
                            </button>
                          )}
                        </div>
                      ))}
                    <button
                      className="bg-green-600 text-white px-2 rounded-md hover:bg-green-500 mt-2 mb-4 w-full py-2"
                      type="button"
                      onClick={() => arrayHelpers.push("")}
                    >
                      + Add Ref Number
                    </button>
                  </div>
                )}
              />
              <button
                // //loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                onClick={(e) => {
                  e.preventDefault();
                  handleReset();
                }}
              >
                <MdClear /> Clear
              </button>
              <button
                // //loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                onClick={(e) => {
                  handleSubmit();
                }}
                type="submit"
              >
                <MdClear /> {selectedJob ? "Edit" : "Add"}
              </button>

              {/* <div>
                    <pre>{JSON.stringify(values, null, 2)}</pre>
                  </div> */}
            </Form>
          )}
        </Formik>
      </section>
    </div>
  );
};
