import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Redirect } from "react-router-dom";
import { getUserProfile } from "../../../app/firestore/firestoreService";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";
import { LoadingComponent } from "../../../app/layout/LoadingComponent";
import { listenToSelectedUserProfile } from "../profileActions";
import { ProfileContent } from "./ProfileContent";
import { ProfileHeader } from "./ProfileHeader";

export const ProfilePage = ({ match }) => {
  const dispatch = useDispatch();
  const { selectedUserProfile, currentUserProfile } = useSelector(
    (state) => state.profile
  );
  const { currentUser } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.async);
  let profile;

  useFirestoreDoc({
    query: () => getUserProfile(match.params.id),
    data: (profile) => dispatch(listenToSelectedUserProfile(profile)),
    deps: [dispatch, match.params.id],
    shouldExecute: match.params.id !== currentUser.uid,
  });
  if (match.params.id === currentUser.uid) {
    profile = currentUserProfile;
  } else {
    profile = selectedUserProfile;
  }

  if ((loading && !profile) || (!profile && !error))
    return <LoadingComponent content="Loading profile..." />;

  // if (error) return <Redirect to="/error" />;

  return (
    <div>
      <ProfileHeader
        profile={profile}
        isCurrentUser={currentUser.uid === profile.id}
      />
      <ProfileContent
        profile={profile}
        isCurrentUser={currentUser.uid === profile.id}
      />
    </div>
  );
};
