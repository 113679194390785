import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../../app/common/components/Button";
import { Input } from "../../../app/common/form/Input";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { ModalWrapper } from "../../../app/common/modals/ModalWrapper";
import { addRefNumber } from "../../../app/firestore/firestoreService";

export const AddRefNumberModal = ({ opId, operation }) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);
  const modalDispatch = useDispatch();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (error) return;
    addRefNumber({ refNumber: value, opId, operation });
    modalDispatch(closeModal());
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  React.useEffect(() => {
    if (value.toString().length !== 6) return setError("Must be 6 symbols");
    setError(null);
  }, [value]);

  return (
    <ModalWrapper header="Add Operation Number">
      <div className="bg-white p-4 relative">
        <form onSubmit={onSubmit}>
          <Input onChange={handleChange} value={value} name="value" label="New Operation Number" placeholder={`400000`} />
          {error && <div className="text-xs px-2 text-red-500">{error}</div>}
          <div className="text-xs px-2 ">It's a 6 digit code, unique to the operation, usually starts with a 4. ie 435675.</div>
          <div className="my-10">
            <Button type="submit" color="green">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
