import {
  FETCH_GLOBAL_VARIABLES,
  FETCH_PDF,
  FETCH_PDFS,
  LISTEN_TO_PACKET,
  LISTEN_TO_PACKETS,
} from "./settingsConstants";

const initialSettingsState = {
  pdfs: [],
  pdf: {},
  globalVariables: [],
  packets: [],
  packet: {},
};

export function settingsReducer(state = initialSettingsState, { type, payload }) {
  switch (type) {
    case FETCH_PDFS:
      return {
        ...state,
        pdfs: payload,
      };
    case FETCH_PDF:
      return {
        ...state,
        pdf: payload,
      };
    case "PDF_ENTITY_ADD":
      return {
        ...state,
        pdf: {
          ...state.pdf,
          entities: state.pdf.entities ? [...state.pdf.entities, payload] : [payload],
        },
      };
    case "PDF_ENTITY_REMOVE":
      return {
        ...state,
        pdf: {
          ...state.pdf,
          entities: state.pdf.entities.filter((e) => e.id !== payload),
        },
      };
    case "PDF_ENTITY_UPDATE":
      const { id, ...rest } = payload;
      return {
        ...state,
        pdf: {
          ...state.pdf,
          entities: state.pdf.entities.map((e) => {
            if (e.id === id) {
              return { ...e, ...rest };
            }
            return e;
          }),
        },
      };
    case "UPDATE_FIELD_VISIBILITY":
      return {
        ...state,
        pdf: {
          ...state.pdf,
          formFields: state.pdf.formFields.map((f) => {
            let ff = f;
            if (f.name === payload) {
              ff["isVisible"] = !f["isVisible"];
            }

            return ff;
          }),
        },
      };
    case "UPDATE_FIELD_ENTITY":
      //payload = {field, entity}
      return {
        ...state,
        pdf: {
          ...state.pdf,
          formFields: state.pdf.formFields.map((f) => {
            let ff = f;
            if (f.name === payload.field.name) {
              ff["entity"] = payload.entity;
            }

            return ff;
          }),
        },
      };

    case FETCH_GLOBAL_VARIABLES:
      return {
        ...state,
        globalVariables: payload,
      };
    case LISTEN_TO_PACKETS:
      return {
        ...state,
        packets: payload,
      };
    case LISTEN_TO_PACKET:
      return {
        ...state,
        packet: payload,
      };
    default:
      return state;
  }
}
