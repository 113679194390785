import { useField } from "formik";
import React from "react";

export const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <label htmlFor="" className="flex flex-col px-2">
      {label && label}
      <input
        className="px-4 py-2 my-1 border rounded"
        autoComplete="off"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <span className="text-red-400 text-xs">{meta.error}</span>
      ) : null}
    </label>
  );
};
