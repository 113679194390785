import React, { useReducer, useEffect } from "react";
import {
  convertToHHMM,
  getFormattedDate,
  timeToDecimal,
} from "../../app/common/util/helperFunctions";
import { Input } from "../../app/common/form/Input";
import {
  MdAccessTime,
  MdAlarmOn,
  MdAttachMoney,
  MdClear,
  MdDirectionsRun,
  MdDownload,
  MdEmojiPeople,
  MdHome,
  MdHourglassEmpty,
  MdLocalOffer,
  MdLocalShipping,
  MdToday,
  MdUpdate,
  MdWeekend,
} from "react-icons/md";
import { SignatureBlock } from "../../app/common/form/SignatureBlock";
import { abhsFill } from "../../app/common/util/abhsFill";
import download from "downloadjs";
import { Button } from "../../app/common/components/Button";

const reducer = (state, { payload }) => {
  if (!payload) return state;
  if (payload?.clear) return {};
  return { ...state, ...payload };
};

const initialState = {
  reference: "Margeaux Everett",
  address: "2405 Maple Street, Everett, WA 98201",
  type: "Move in & Stage",
  crew: "Arnas, Kevan",
  breaks: "0",
  rate: 125,
  travelFee: 62.5,
  date: getFormattedDate(new Date()),
};

export const ABHS = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    reference = "",
    address = "",
    type = "",
    date = "",
    crew = "",
    start = "",
    breaks = "",
    end = "",
    totalTime = "",
    rate = "",
    travelFee = "",
    total = "",
  } = state;

  const sigDispatch = ({ field, value }) => {
    dispatch({ payload: { [field]: value } });
  };

  const onChange = (e) => {
    dispatch({ payload: { [e.target.name]: e.target.value } });
  };

  const clear = () => dispatch({ payload: { clear: true } });

  const getPDF = async (e) => {
    download(
      await abhsFill(state),
      `ABHS ${getFormattedDate(new Date())}.pdf`,
      "application/pdf"
    );
  };

  useEffect(() => {
    const begin = timeToDecimal(start);
    let finish = timeToDecimal(end);
    const offTime = timeToDecimal(breaks) || 0;

    if (begin > finish) finish += 12;
    const th = finish - begin - offTime;

    dispatch({
      payload: {
        totalTime: convertToHHMM(th >= 0 ? th : 0),
      },
    });
  }, [start, end, breaks, dispatch]);

  useEffect(() => {
    let value = 0;

    value = Number(timeToDecimal(totalTime)) * Number(rate);

    if (!!travelFee) value += travelFee;

    dispatch({ payload: { total: value } });
  }, [totalTime, rate, travelFee, dispatch]);

  // const jobs = [];

  return (
    <div className="flex flex-col items-center container bg-gray-50 p-2 rounded mt-1">
      <h1 className="my-2 text-teal-600 font-bold text-xl">ABHS Bill</h1>
      <div className=" flex flex-col md:flex-row justify-around relative ">
        <div className="flex flex-col mx-2 w-full">
          <Input
            name="reference"
            value={reference}
            onChange={onChange}
            Icon={MdEmojiPeople}
            label="Reference Client"
            placeholder="Firstname Lastname"
          />
          <Input
            name="address"
            value={address}
            onChange={onChange}
            Icon={MdHome}
            label="Staging Address"
            placeholder="123 Down Street, City, State, Zip"
          />
          <Input
            name="type"
            value={type}
            onChange={onChange}
            Icon={MdWeekend}
            label="Stage Type"
            placeholder="Move out"
          />
          <Input
            name="date"
            value={date}
            onChange={onChange}
            Icon={MdToday}
            label="Date"
            placeholder="MM/DD/YYY"
          />
        </div>
        <div className="flex flex-col min-w-fit mx-2  ">
          <Input
            name="crew"
            value={crew}
            onChange={onChange}
            Icon={MdDirectionsRun}
            label="Crew"
            placeholder="Name, Name, Name..."
          />
          <div className="flex space-x-2 max-w-xs">
            <Input
              name="start"
              value={start}
              onChange={onChange}
              Icon={MdUpdate}
              label="Start"
              placeholder="9 or 9:00, no AM/PM"
            />
            <Input
              name="end"
              value={end}
              onChange={onChange}
              Icon={MdAlarmOn}
              label="End"
              placeholder="13 or 13:00, no AM/PM"
            />
          </div>
          <Input
            name="breaks"
            value={breaks}
            onChange={onChange}
            Icon={MdHourglassEmpty}
            label="Break Time"
            placeholder="How many hours breaktime"
          />
        </div>
        <div className="flex flex-col w-full lg-w-64 mx-2">
          <Input
            name="totalTime"
            value={totalTime}
            onChange={onChange}
            Icon={MdAccessTime}
            label="Total Time"
            placeholder="Calculated Time in hrs"
          />
          <Input
            name="rate"
            value={rate}
            onChange={onChange}
            Icon={MdLocalOffer}
            label="Hourly Rate"
            placeholder="$/hr"
          />
          <Input
            name="travelFee"
            value={travelFee}
            onChange={onChange}
            Icon={MdLocalShipping}
            label="Travel Fee"
            placeholder="$"
          />
          <Input
            name="total"
            value={total}
            onChange={onChange}
            Icon={MdAttachMoney}
            label="Total Bill"
            placeholder="$"
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-around w-full  ">
        <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 max-w-lg mx-2">
          <label className="text-xs"> SFM Crew Signature</label>
          <SignatureBlock
            dispatch={sigDispatch}
            type="Signature"
            name="crewSignature"
          />
        </div>
        <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 max-w-lg mx-2">
          <label className="text-xs"> ABHS Signature</label>
          <SignatureBlock
            dispatch={sigDispatch}
            type="Signature"
            name="clientSignature"
            penColor="rgb(66, 133, 10)"
          />
        </div>
      </div>
      <div className="flex justify-around my-4 rounded-sm ">
        <Button onClick={clear} label="Clear" color="red" Icon={MdClear} />
        <Button
          onClick={getPDF}
          label="Download"
          color="teal"
          Icon={MdDownload}
        />
      </div>
    </div>
  );
};
