import { Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { MyTextInput } from "../../app/common/form/MyTextInput";
import { updateUserPassword } from "../../app/firestore/firebaseService";

export const AccountPage = () => {
  const { currentUser } = useSelector((state) => state.auth);

  return (
    <section>
      <h4 dividing size="large">
        Account
      </h4>
      {currentUser.providerId === "password" && (
        <>
          <h5>Change Password</h5>
          <p>use this form to change your password</p>
          <Formik
            initialValues={{ newPassword1: "", newPassword2: "" }}
            validationSchema={Yup.object({
              newPassword1: Yup.string().required("Password is required"),
              newPassword2: Yup.string().oneOf(
                [Yup.ref("newPassword1"), null],
                "Passwords do not match"
              ),
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                await updateUserPassword(values);
              } catch (error) {
                setErrors({ auth: error.message });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ errors, isSubmitting, isValid, dirty }) => (
              <Form className="ui form">
                <MyTextInput
                  name="newPassword1"
                  type="password"
                  placeholder="New Password"
                />
                <MyTextInput
                  name="newPassword2"
                  type="password"
                  placeholder="Confirm Password"
                />
                {errors.auth && (
                  <label style={{ marginBottom: 10 }}>{errors.auth}</label>
                )}
                <button
                  style={{ dispplay: "block" }}
                  type="submit"
                  //loading={isSubmitting}
                  disabled={!isValid || isSubmitting || !dirty}
                  size="large"
                >
                  Update Password
                </button>
              </Form>
            )}
          </Formik>
        </>
      )}
      {currentUser.providerId === "facebook.com" && (
        <>
          <h4>Facebook account</h4>
          <p>Please visit Facebook to update your account</p>
          <Link icon="facebook" color="facebook" to="https://facebook.com">
            Go to Facebook
          </Link>
        </>
      )}
      {currentUser.providerId === "google.com" && (
        <>
          <h4>Google Account</h4>
          <p>Please visit Google to update your account</p>
          <Link icon="google" color="google plus" to="https://google.com">
            Go to Google
          </Link>{" "}
        </>
      )}
    </section>
  );
};
