import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { listenToAdminUsers } from "../../../app/firestore/firestoreService";
import useFirestoreCollection from "../../../app/hooks/useFirestoreCollection";
import { PageLayout } from "../../../app/layout/PageLayout";
import download from "downloadjs";
import { MdClear, MdSearch, MdDownload } from "react-icons/md";

import { AddUser } from "./AddUser";
import { UserCard } from "./UserCard";

export const ManageUsers = () => {
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => state.async);
  const { users } = useSelector((state) => state.admin);
  const [search, setSearch] = React.useState("");
  const [editId, setEditId] = React.useState(null);

  useFirestoreCollection({
    query: () => listenToAdminUsers(),
    data: (users) => dispatch({ type: "LISTEN_TO_USERS", payload: users }),
    deps: [dispatch],
  });

  return (
    <PageLayout title="Users" settingsName="Add User" Settings={AddUser}>
      {loading ? (
        <div>Loading</div>
      ) : (
        <div className="flex justify-center flex-col">
          {error && (
            <div>
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </div>
          )}
          <label className="bg-white px-4 py-2 rounded-md flex border text-xl ">
            <MdSearch />
            <input
              type="text"
              value={search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
              className="outline-none w-full px-2 flex-grow"
              placeholder="Search by name, email..."
            />
            {search.length > 0 && <MdClear onClick={() => setSearch("")} />}
          </label>
          {users && users.length > 0 ? (
            <>
              <div name="toolbar">
                <button
                  type="button"
                  onClick={(e) => exportToCSV(e, users)}
                  className="px-2 mx-1 border-2  py-1 text-xs border-indigo-400 text-indigo-600 hover:bg-indigo-200 transition-all rounded-md my-1 flex"
                >
                  <MdDownload /> Download .csv
                </button>
              </div>
              {users
                .filter(
                  (u) =>
                    u?.displayName.toLowerCase().includes(search?.toLowerCase()) || u?.email?.toLowerCase().includes(search?.toLowerCase())
                )
                .map((user, i) => (
                  <UserCard
                    key={i}
                    user={user}
                    canEdit={true}
                    edit={editId === user.id}
                    setEdit={() => {
                      setEditId(user.id);
                    }}
                    close={() => setEditId(null)}
                  />
                ))}
            </>
          ) : (
            "No users found."
          )}
        </div>
      )}
    </PageLayout>
  );
};

const exportToCSV = (e, d) => {
  e.preventDefault();
  download(generateCSV(d), `SFM-team-${new Date().toISOString().split("T")[0]}.csv`, "text/csv");
};

function generateCSV(data) {
  const items = data;
  const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
  const header = ["displayName", "position", "email", "phone", "photoURL"];
  const csv = [
    header.join(","), // header row first
    ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(",")),
  ].join("\r\n");

  return csv;
}
