import { toast } from "react-toastify";
import { storage, functions, auth, database } from "../../app/config/firebase";
import {
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
  updatePassword,
} from "firebase/auth";
import {
  deleteObject,
  getDownloadURL,
  ref,
  listAll,
  // uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { ref as databaseRef, push, set, update } from "firebase/database";
import { setUserProfileData } from "./firestoreService";
import { httpsCallable } from "firebase/functions";
// import cuid from "cuid";

export function uploadStorageUnitIMGToFirebaseStorage(image, fileName, unitId) {
  const fileRef = ref(storage, `warehouse/storage/units/${unitId}/${fileName}`);
  const myCustomMetadata = { customMetadata: { unitId } };
  return uploadBytesResumable(fileRef, image, myCustomMetadata);
}

export async function checkIfFileExistsInFirebaseStorage(storagePath) {
  if (!storagePath) return null;
  const fileRef = ref(storage, storagePath);
  let exists = false;
  try {
    const url = await getDownloadURL(fileRef);
    exists = !!url;
  } catch (error) {
    if (error.code === "storage/object-not-found") exists = false;
  }
  return exists;
}

export function uploadUserPhotoToFirebaseStorage(file, fileId) {
  const user = auth.currentUser;
  return uploadFileToFirebaseStorage({
    storagePath: `${user.uid}/user_images`,
    file,
    fileId,
  });
}

export function uploadPDFToFirebaseStorage(file, fileId, docPath) {
  return uploadFileToFirebaseStorage({
    storagePath: `pdfs`,
    file,
    fileId,
    docPath,
  });
}

export function uploadFileToFirebaseStorage({ storagePath = "/temp/", file, fileId, sessionId }) {
  var metadata = {
    customMetadata: {
      fileId,
      sessionId,
    },
  };

  const storageRef = ref(storage, `${storagePath}/${fileId}/${file.name}`);
  return uploadBytesResumable(storageRef, file, metadata);
}

export function firebaseObjectToArray(snapshot) {
  if (snapshot) {
    return Object.entries(snapshot).map((e) => Object.assign({}, e[1], { id: e[0] }));
  }
}

export function signInWithEmail(creds) {
  return signInWithEmailAndPassword(auth, creds.email, creds.password);
}

export function signOutFirebase() {
  return signOut(auth);
}

export async function registerInFirebase(creds) {
  try {
    const result = await createUserWithEmailAndPassword(auth, creds.email, creds.password);
    await updateProfile(result.user, { displayName: creds.displayName });
    return await setUserProfileData(result.user);
  } catch (error) {
    throw error;
  }
}

export async function socialLogin(selectedProvider) {
  let provider;

  if (selectedProvider === "facebook") {
    provider = new FacebookAuthProvider();
  }
  if (selectedProvider === "google") {
    provider = new GoogleAuthProvider();
  }

  try {
    const result = await signInWithPopup(auth, provider);
    console.log(result);
    if (result.additionalUserInfo.isNewUser) {
      //TODO check if user/email is authorised - if not kick him out
      // await checkIfAutorisedSignUp()
      await setUserProfileData(result.user);
    }
  } catch (error) {
    toast.error(error.message);
  }
}

export function updateUserPassword(creds) {
  const user = auth.currentUser;
  return updatePassword(user, creds.newPassword);
}

export function deleteFromFirebaseStorage(filename) {
  const userUid = auth.currentUser.uid;
  //TODO need file id for user_images/${fileId}/${filename}
  const storageRef = ref(storage, `${userUid}/user_images/${filename}`);
  return deleteObject(storageRef);
}

export function deleteFileFromFirebaseStorage(storagePath) {
  const storageRef = ref(storage, storagePath);
  return deleteObject(storageRef);
}

export function addEventChatComment(eventId, values) {
  const user = auth.currentUser;

  const newComment = {
    displayName: user.displayName,
    photoURL: user.photoURL,
    uid: user.uid,
    text: values.comment,
    date: Date.now(),
    parentId: values.parentId,
  };

  return push(databaseRef(database, `chat/${eventId}`), newComment);
}

// export function getEventChatRef(eventId) {
//   return database.ref(`chat/${eventId}`).orderByKey();
// }

// export function getUserFeedRef() {
//   const user = auth.currentUser;
//   return database.ref(`posts/${user.uid}`).orderByKey().limitToLast(5);
// }

//https://stackoverflow.com/a/56402109
// export async function getListOfFilesInFolder(folder) {
//   const storageFolderRef = storage.ref(folder);
//   let files = {};

//   return storageFolderRef
//     .listAll()
//     .then(function (result) {
//       result.items.forEach(function (fileRef) {
//         let fileName = fileRef.name;
//         if (fileName.startsWith("thumb_")) {
//           fileName = fileName.slice("thumb_".length);
//           files = {
//             ...files,
//             [fileName]: {
//               ...files[fileName],
//               thumb: [
//                 {
//                   fullPath: fileRef.fullPath,
//                   fileName: fileRef.name,
//                 },
//               ],
//             },
//           };
//         } else {
//           files = {
//             ...files,
//             [fileName]: {
//               ...files[fileName],
//               fileName: fileRef.name,
//               fullPath: fileRef.fullPath,
//             },
//           };
//         }
//       });
//       return files;
//     })
//     .catch(function (error) {
//       console.log(error);
//       // Handle any errors
//     });
// }

export async function getFileDownloadUrl(refPath) {
  const pathReference = ref(storage, refPath);
  return await getDownloadURL(pathReference);
}

export async function makeThumbs(data) {
  // const mt = httpsCallable(functions, "makeThumbs");
  return await httpsCallable(functions, "makeThumbs")(data);
}
export async function registerInFirebaseByAdmin(data) {
  const fn = httpsCallable(functions, "registerInFirebaseByAdmin");
  return await fn(data);
}
export async function disableUser(data) {
  const fn = httpsCallable(functions, "disableUser");
  return await fn(data);
}
export async function enableUser(data) {
  const fn = httpsCallable(functions, "enableUser");
  return await fn(data);
}

export async function listDirFiles(dirPath) {
  const listRef = ref(storage, dirPath);
  // let files;

  // Find all the prefixes and items.
  const res = await listAll(listRef);
  res.prefixes.forEach(async (folderRef) => {
    console.log(folderRef);
    const folderRes = await listAll(folderRef);

    folderRes.items.forEach(async (itemRef) => {
      console.log(itemRef._location.path);
      // const url = await getFileDownloadUrl(itemRef);
      // console.log(url);
      // All the items under listRef.
    });
    // All the prefixes under listRef.
    // You may call listAll() recursively on them.
  });
  res.items.forEach((itemRef) => {
    console.log(itemRef._location.path);
    // All the items under listRef.
  });
  // console.log(files);
}

export async function newUploadSession(sessionId, data) {
  const user = auth.currentUser;
  console.log({ sessionId });
  return set(databaseRef(database, `uploads/${sessionId}`), {
    user: user.displayName,
    created: Date.now(),
    ...data,
  });
}
export async function updateUploadSession(sessionId, data) {
  console.log(data);
  return update(databaseRef(database, `uploads/${sessionId}`), {
    ...data,
    updated: Date.now(),
  });
}

export function getUploadsRef() {
  return databaseRef(database, `uploads`);
}
export function getEmailsRef() {
  return databaseRef(database, `email`);
}

export async function manualCompleteUpload(data) {
  return await httpsCallable(functions, "manualCompleteThumbs")(data);
}

export async function sendShipmentReceivedEmail(shipmentId, data) {
  return await set(databaseRef(database, `/emails/shipment/received/${shipmentId}`), {
    ...data,
  });
}
