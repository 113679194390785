import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../app/common/modals/modalReducer";

export const UnauthModal = ({ history, setModalOpen = () => {} }) => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const { prevLocation } = useSelector((state) => state.auth);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const isAdmin = currentUserProfile?.role?.admin;

  function handleClose() {
    if (!history) {
      setOpen(false);
      setModalOpen(false);
      return;
    }
    if (history && prevLocation) {
      history.push(prevLocation.pathname);
    } else {
      history.push("/events");
    }
    setOpen(false);
  }

  function handleOpenLoginModal(modalType) {
    dispatch(openModal({ modalType }));
    setOpen(false);
    setModalOpen(false);
  }

  return (
    <div open={open} onClose={handleClose} size="mini">
      <h4>You need to be signed in to do that" </h4>
      <div>
        <p>Please login {isAdmin && "or sign up "} to see this content.</p>

        <div widths={4}>
          <button onClick={() => handleOpenLoginModal("LoginForm")}>
            Login
          </button>
          {isAdmin && (
            <button onClick={() => handleOpenLoginModal("RegisterForm")}>
              Register
            </button>
          )}
        </div>
        {/* <Divider />
        <div style={{ textAlign: "center" }}>
          <p>Or click cancel to continue as a guest.</p>
          <Button onClick={handleClose} content="Cancel" />
        </div> */}
      </div>
    </div>
  );
};
