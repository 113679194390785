import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy } from "react-table";

import { listenToUnitsFromFirestore } from "../../../app/firestore/firestoreService";
import useFirestoreCollection, {
  useFirestoreCollectionPaginated,
} from "../../../app/hooks/useFirestoreCollection";
import { listenToUnits } from "../warehouseActions";
import { Link } from "react-router-dom";
import { PageLayout } from "../../../app/layout/PageLayout";

export const UnitsPage = () => {
  const dispatch = useDispatch();
  const { units } = useSelector((state) => state.warehouse);
  const { loading } = useSelector((state) => state.async);
  const [lastDoc, setLastDoc] = React.useState(null);
  const bottomElementRef = React.useRef(null);
  // useFirestoreCollection({
  //   query: () => listenToUnitsFromFirestore(),
  //   data: (newUnits) => dispatch(listenToUnits(newUnits)),
  //   deps: [dispatch, units],
  // });

  useFirestoreCollection({
    query: (lastDoc) => listenToUnitsFromFirestore(lastDoc),
    data: (newUnits) => dispatch(listenToUnits(newUnits)),
    deps: [dispatch],
  });

  // Intersection observer setup
  // React.useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       if (entries[0].isIntersecting && hasMore && !loading) {
  //         fetchMore();
  //       }
  //     },
  //     { threshold: 1.0 }
  //   );

  //   if (bottomElementRef.current) {
  //     observer.observe(bottomElementRef.current);
  //   }

  //   return () => {
  //     if (bottomElementRef.current) {
  //       observer.unobserve(bottomElementRef.current);
  //     }
  //   };
  // }, [fetchMore, hasMore, loading, bottomElementRef]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Unit Id",
        accessor: function (row) {
          return <Link to={`/w/unit/${row.id}`}>{row.id}</Link>;
        },
      },
      {
        Header: "Shipper",
        accessor: function (row) {
          return (
            row?.shippers &&
            Object.entries(row.shippers).map(([shipperId, { displayName }]) => (
              <Link key={shipperId} to={`/shipper/${shipperId}`}>
                {displayName}
              </Link>
            ))
          );
        },
      },
      {
        Header: "Shipment",
        accessor: function (row) {
          return (
            <Link
              to={`/warehouse/labels/${row.opId}`}
              className="px-2 font-bold"
            >
              {row?.refNumber}
            </Link>
          );
        },
      },
      {
        Header: "Operation Id",
        accessor: (row) => (
          <Link to={`/p/o/${row.opId}`} className="px-2">
            {row.opId}
          </Link>
        ),
      },
      {
        Header: "#",
        accessor: "number",
        sortType: "basic",
      },
    ],
    []
  );

  const data = React.useMemo(() => [...units], [units]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  if (loading && !units) return <div>Loading units</div>;

  return (
    <PageLayout
      IconTitle={(props) => (
        <img
          src="/img/pts-logo.png"
          className="object-contain h-10 px-2 "
          alt="warehouse Logo"
        />
      )}
      title="Storage Units"
    >
      {units && units.length > 0 ? (
        <table
          {...getTableProps()}
          className="mx-auto my-5  rounded-md overflow-hidden transition-all bg-white mb-10 text-xs"
        >
          <thead className=" bg-white bg-opacity-50  ">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={i}
                    className="select-none font-bold px-4 py-2 hover:bg-opacity-50 hover:bg-white bg-opacity-0 transition-all cursor-pointer "
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  {...row.getRowProps()}
                  className="bg-white even:bg-gray-200 hover:bg-blue-100 "
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="px-2 py-1">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No units found</div>
      )}
      {/* <div ref={bottomElementRef} /> */}
      {loading && <div>Spinning</div>}
    </PageLayout>
  );
};
