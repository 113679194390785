import cuid from "cuid";
import { formatDistance } from "date-fns";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  getFileDownloadUrl,
  uploadFileToFirebaseStorage,
} from "../../../app/firestore/firebaseService";
import { PDFDocument } from "pdf-lib";

import {
  addPDFToFirestore,
  listenToGlobalVariablesFromFirestore,
  listenToPDFsFromFirestore,
  updatePDFFieldsInFirestore,
} from "../../../app/firestore/firestoreService";
import useFirestoreCollection from "../../../app/hooks/useFirestoreCollection";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";
import { listenToGlobalVariables, listenToPDFs } from "../settingsActions";
import { FILE_PLACEHOLDER_THUMB } from "../../../config";
import { Link } from "react-router-dom";
import { MdClear, MdPictureAsPdf, MdUpload } from "react-icons/md";
import { FileIcon } from "../../../app/common/util/FileIcon";
// import { getPDFFieldCoordinates } from "./getPDFFieldCoordinates";

export const Pdfs = () => {
  const dispatch = useDispatch();
  const { pdfs, globalVariables } = useSelector((state) => state.settings);

  const [editFile, setEditFile] = useState(null);

  useFirestoreCollection({
    query: () => listenToPDFsFromFirestore(),
    data: (pdfs) => dispatch(listenToPDFs(pdfs)),
    deps: [dispatch],
  });
  useFirestoreDoc({
    query: () => listenToGlobalVariablesFromFirestore(),
    data: (gVars) => dispatch(listenToGlobalVariables(gVars)),
    deps: [dispatch],
  });

  const downloadState = (exportObj) => {
    var dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(exportObj.formFields));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "state.json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  return (
    <>
      <PdfUpload />
      <div className="container mx-auto mt-2">
        <section>
          <h2>PDF files</h2>
          <ul divided relaxed>
            {pdfs.map((pdf) => (
              <li key={pdf.id}>
                <MdPictureAsPdf />
                <div>
                  <h4 as="a" target="_blank" href={pdf.url}>
                    {pdf.fileName}
                  </h4>
                  <div>
                    {`v${pdf.version} `}
                    {Math.ceil(pdf.size / 1024) + "kb "}
                    {pdf.hasOwnProperty("updatedAt")
                      ? "Updated"
                      : "Uploaded"}{" "}
                    {formatDistance(new Date(pdf.createdAt), new Date())} ago
                  </div>
                </div>
                <div floated="left">
                  <div className="flex flex-wrap  ">
                    {pdf.thumbs &&
                      pdf.thumbs.length > 0 &&
                      pdf.thumbs.map((thumb, i) => (
                        <div
                          key={i}
                          className=" relative hover:shadow-lg border-2 border-gray-200 m-1  overflow-hidden rounded-md transform transition duration-100 hover:scale-105 hover:z-10 bg-gray-200 cursor-pointer flex align-middle"
                        >
                          <img
                            src={thumb.url}
                            onError={(e) =>
                              (e.target.src = FILE_PLACEHOLDER_THUMB)
                            }
                            alt={thumb.name}
                            size="small"
                          />
                          <span className="truncate absolute bottom-1 left-2/3 text-xs bg-gray-200 bg-opacity-60 py-1 px-3 rounded-md">
                            {i + 1 + " / " + pdf.thumbs.length}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
                <div floated="right">
                  {/* <Button
                    compact
                    icon="file"
                    content="Field Coordinates"
                    onClick={ (e) => {
                     console.log( getPDFFieldCoordinates(pdf.url))
                    }}
                  /> */}
                  <Link to={`/settings/pdfs/${pdf.id}/${pdf.fileName}`}>
                    Prepare Form
                  </Link>
                  <button
                    onClick={(e) => {
                      downloadState(pdf);
                    }}
                  >
                    Download PDF Sate
                  </button>
                  <button
                    onClick={(e) => {
                      setEditFile(pdf);
                    }}
                  >
                    Form Fields
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </section>
      </div>
      <div className="container mx-auto mt-2">
        <section>
          <h2>Global Fields</h2>
          <ul divided relaxed>
            {globalVariables &&
              Object.keys(globalVariables).map((item, i) => {
                if (item === "id") return null;
                return (
                  <li className="p-2 w-full flex justify-around" key={i}>
                    <label htmlFor={item}>{item}</label>
                    <input
                      name={item}
                      className="input-label"
                      defaultValue={globalVariables[item]}
                    />
                  </li>
                );
              })}
          </ul>
          <h4>Group Fields</h4>
          <GroupChips title="Vechicle Fields" options={vehicleFields} />
          <GroupChips
            title="Customer Name Fields"
            options={customerNameFields}
          />
        </section>
      </div>
      <div className="container mx-auto mt-2">
        <ManagePDFFields key={editFile?.id} file={editFile} />
      </div>
    </>
  );
};

const vehicleFields = [
  { value: "vin", text: "vin" },
  { value: "make", text: "make" },
  { value: "model", text: "model" },
  { value: "year", text: "year" },
  { value: "mileage", text: "mileage" },
  { value: "exteriorColor", text: "exteriorColor" },
  { value: "interiorColor", text: "interiorColor" },
  { value: "bodyStyle", text: "bodyStyle" },
  { value: "trim", text: "trim" },
  { value: "new", text: "new" },
  { value: "used", text: "used" },
  { value: "plateNumber", text: "plateNumber" },
  { value: "description", text: "description" },
  { value: "descriptionLine1", text: "descriptionLine1" },
  { value: "descriptionLine2", text: "descriptionLine2" },
  { value: "descriptionLine3", text: "descriptionLine3" },
];
const customerNameFields = [
  { value: "fullName", text: "fullName" },
  { value: "fullNameReversed", text: "fullNameReversed" },
  { value: "firstName", text: "firstName" },
  { value: "middleName", text: "middleName" },
  { value: "middleInitial", text: "middleInitial" },
  { value: "lastName", text: "lastName" },
  { value: "suffix", text: "suffix" },
  { value: "title", text: "title" },
  { value: "nickName", text: "nickName" },
];

const GroupChips = ({ title = "", options }) => {
  return (
    <section>
      <h6>{title}</h6>
      <select
        options={options}
        defaultValue={options.map((o) => o.value)}
        placeholder="Select Country"
      />
    </section>
  );
};

const ManagePDFFields = ({ file }) => {
  const [loading, setLoading] = useState(false);
  if (!file) return null;

  const getFormFields = async (e) => {
    setLoading(true);
    const formPdfBytes = await fetch(file.url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);

    const form = pdfDoc.getForm();
    const fields = form.getFields();
    const fieldsArray =
      fields.length > 0
        ? fields.map((field) => {
            const type = field.constructor.name;
            const name = field.getName();

            let f = { name, type };
            if (field.isReadOnly()) f["isReadOnly"] = true;
            if (field.isRequired()) f["isRequired"] = true;

            if (type === "PDFDropdown" || type === "PDFOptionList") {
              const dropdown = form.getDropdown(name);
              f["options"] = dropdown.getOptions() || [];
              f["selections"] = dropdown.getSelected() || [];
              if (dropdown.isMultiselect()) f["isMultiselect"] = true;
            }
            if (type === "PDFTextField") {
              const value = field.getText();
              const maxLength = field.getMaxLength();
              if (value) f["defaultValue"] = value;
              if (maxLength) f["maxLength"] = maxLength;
              if (field.isCombed()) f["isCombed"] = true;
              if (field.isMultiline()) f["isMultiline"] = true;
              if (field.isPassword()) f["isPassword"] = true;
              if (field.isRichFormatted()) f["isRichFormatted"] = true;
              if (field.isScrollable()) f["isScrollable"] = true;
            }
            return f;
          })
        : [];
    updatePDFFieldsInFirestore(file.id, fieldsArray);
    setLoading(false);
  };

  return (
    <section>
      <h2>PDF Fields for {file.fileName} </h2>
      {/* {file.formFields.length === 0 && ( */}
      <button loading={loading} onClick={getFormFields}>
        Get Form Fields
      </button>
      {/* )} */}
      {file.attemptedUpdateFields && file.formFields.length === 0 && (
        <h1>No fields</h1>
      )}
      {file.formFields.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Default Value</th>
            </tr>
          </thead>

          <tbody>
            {file.formFields.map((field, i) => (
              <tr key={i} onClick={() => console.log(field)}>
                <td>
                  <h4>
                    <FileIcon
                      fileType={file.type}
                      className="mr-4 text-gray-500"
                    />
                    <div>
                      {field.name}
                      <div>{field.type}</div>
                    </div>
                  </h4>
                </td>
                <td>{field?.defaultValue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
};

const PdfUpload = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  function handleUploadFile() {
    setLoading(true);

    if (files.length > 0) {
      for (let file of files) {
        const pdfId = cuid();

        const uploadTask = uploadFileToFirebaseStorage({
          file,
          fileId: pdfId,
          storagePath: `pdfs/${pdfId}/${file.name}`,
          documentPath: `pdfs/${pdfId}`,
          documentKey: "images",
        });

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress} % done`);
          },
          (error) => {
            toast.error(error.message);
            setLoading(false);
          },
          async () => {
            // const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            console.log(`${file.name} uploaded.`);
            const downloadURL = await getFileDownloadUrl(
              uploadTask.snapshot.ref
            );

            console.log(downloadURL);
            setLoading(false);

            const pdf = {
              url: downloadURL,
              fileName: file.name,
              // formFields: [],
              version: "1.0",
              size: file.size,
            };

            await addPDFToFirestore(pdfId, pdf);

            //createPDFdoc
          }
        );
      }
    }
  }

  function handleCancelCrop() {
    setFiles([]);
  }

  return (
    <div className="container bg-white mx-auto p-4 mt-2 rounded-md">
      <FileDropzone setFiles={setFiles} loading={loading} />
      {files.length > 0 && (
        <ul>
          {files.map((file) => (
            <li key={file.name}>
              <FileIcon fileType={file.type} />
              {file.name} / {Math.ceil(file.size / 1024)} kb
            </li>
          ))}
        </ul>
      )}
      <div>
        <button disabled={loading} onClick={handleCancelCrop}>
          <MdClear />
          Clear
        </button>
        <button
          loading={loading}
          onClick={handleUploadFile}
          disabled={files.length === 0}
        >
          {files.length > 0
            ? `Upload ${files.length === 1 ? "file" : files.length + " files"}`
            : "Please attach file"}
        </button>
      </div>
    </div>
  );
};

const FileDropzone = ({ setFiles, loading = false }) => {
  const dropzoneStyles = {
    border: "dashed 3px #eee",
    borderRadius: "5%",
    paddingTop: "30px",
    textAlign: "center",
  };

  const dropzoneActive = {
    border: "dashed 3px green",
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
    },
    [setFiles]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      style={
        isDragActive ? { ...dropzoneStyles, ...dropzoneActive } : dropzoneStyles
      }
    >
      <input {...getInputProps()} disabled={loading} />
      <MdUpload disabled={loading} /> <h2>Drop File Here</h2>
    </div>
  );
};

//getfields
//assign fields with form values

//define constant fields
