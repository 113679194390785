import cuid from "cuid";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../../app/common/components/Button";
import { Input } from "../../../app/common/form/Input";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { ModalWrapper } from "../../../app/common/modals/ModalWrapper";
import { addUnitsToFirestore } from "../../../app/firestore/firestoreService";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "INPUT_UPDATE":
      return [...state.map((unitId, i) => (i === payload.i ? payload.value : unitId))];
    default:
      return state;
  }
};

export const AddUnitsModal = ({ shipmentId, opId, shippers, refNumber, crew, startingNumber, totalUnitsToAdd = 1 }) => {
  const [unitsToAdd, /*setUnitsToAdd*/] = useState(totalUnitsToAdd);
  const [state, dispatch] = React.useReducer(
    reducer,
    [...Array(totalUnitsToAdd)].map((e) => cuid())
  );
  const modalDispatch = useDispatch();

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(state);
    addUnitsToFirestore({ shipmentId, opId, shippers, refNumber, crew, startingNumber, totalUnitsToAdd: unitsToAdd, unitIdArray: state });
    modalDispatch(closeModal());
  };

  const handleChange = (i) => {
    return function (e) {
      dispatch({ type: "INPUT_UPDATE", payload: { i, value: e.target.value } });
    };
  };

  return (
    <ModalWrapper header="Add Units By their Ids">
      <div className="bg-white p-4 relative">
        <form onSubmit={onSubmit}>
          {state?.map((unitId, i) => (
            <Input
              key={i}
              onChange={handleChange(i)}
              value={state[i]}
              name={`unit-${startingNumber + i}`}
              label={startingNumber + i}
              placeholder={`Unit id`}
            />
          ))}
          <div className="my-10">
            <Button type="submit" color="green">
              Submit
            </Button>
          </div>
          {/* <div>{JSON.stringify(state, null, 2)}</div> */}
        </form>
      </div>
    </ModalWrapper>
  );
};
