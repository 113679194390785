import React from "react";
import { Field } from "react-final-form";
import { MdClear } from "react-icons/md";

const pasteHandler = (onChange) => {
  return function (event) {
    event.preventDefault();
    let selectionStart = event.target.selectionStart;
    let selectionEnd = event.target.selectionEnd;
    let pasteText = event.clipboardData.getData("text").trim();
    let initialValue = event.target.value;
    event.target.value =
      event.target.value.substring(0, selectionStart) + pasteText + event.target.value.substring(selectionEnd, initialValue.length);
    onChange(event);
    event.target.selectionStart = selectionStart;
    event.target.selectionEnd = selectionStart + pasteText.length;
  };
};

export const Input = (props) => {
  const {
    name,
    placeholder,
    Icon = undefined,
    type = "text",
    inputMode,
    step,
    value,
    onChange,
    readOnly = false,
    units = "",
    align = "left",
    label = "",
    min = 0,
    onBlur = () => {},
    helperText = "",
    autoFocus = false,
    Helper = () => {
      return null;
    },
    ActionButton = () => {
      return null;
    },
    meta,
    labelAction = null,
    className = "",
    containerClassName = "",
    onKeyDown = null,
    pattern = null,
    ...rest
  } = props;

  const textAlign = {
    center: "text-center",
    left: "text-left",
    right: "text-right",
  };

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (autoFocus) inputRef.current.focus();
  }, [autoFocus]);

  return (
    <div className={`w-full    focus-within:text-indigo-600 max-w-lg box-border mb-2v transition-all ${containerClassName}`}>
      {!!label && type !== "hidden" && (
        <label htmlFor={name} className="text-xs font-sans   text-justify pl-2 flex justify-between">
          {label}{" "}
          {labelAction && (
            <button type="button" onClick={labelAction}>
              <MdClear />
            </button>
          )}
        </label>
      )}
      <div className="w-full flex justify-between items-center  bg-white hover:border-indigo-300 hover:focus-within:border-indigo-500  rounded-md  border focus-within:border-indigo-400 transition-all  ">
        {!!Icon && <Icon className="ml-2 min-w-fit" />}
        <input
          {...rest}
          onKeyDown={onKeyDown}
          ref={inputRef}
          type={type}
          name={name}
          placeholder={placeholder}
          autoComplete="off"
          value={value || ""}
          // onChange={onChange}
          step={step}
          readOnly={readOnly}
          inputMode={inputMode}
          min={min}
          onBlur={onBlur}
          pattern={pattern}
          onFocus={(e) => e.target.select()}
          {...props.input}
          onChange={onChange ? onChange : props?.input?.onChange}
          onPaste={pasteHandler(onChange ? onChange : props?.input?.onChange)}
          className={`w-full px-2 py-1 flex-grow text-sm focus:text-gray-900 ${textAlign[align]} bg-transparent outline-none ${className}`}
        />

        <span className="  flex items-center pr-2  cursor-pointer opacity-20 focus:opacity-100 hover:opacity-100">{units}</span>
        {ActionButton && (
          <span className="  flex items-center pr-2  cursor-pointer opacity-20 focus:opacity-100 hover:opacity-100">
            <ActionButton />
          </span>
        )}
      </div>
      {helperText && <div className="text-xs text-gray-600 mx-2">{helperText}</div>}
      {!!Helper && <Helper />}
      {meta?.touched && meta?.error && <span className="text-xs text-red-400 mx-2">{meta.error}</span>}
    </div>
  );
};

//FINAL FORM INPUT WRAPPER
export const FFInput = (props) => {
  return (
    <Field name={props.name} parse={props?.parse}>
      {({ input, meta }) => <Input {...props} input={input} meta={meta} />}
    </Field>
  );
};
