import {
  MdDeleteSweep,
  MdDirectionsBoat,
  MdDirectionsRun,
  MdHotTub,
  MdLocalParking,
  MdStraighten,
  MdTwoWheeler,
  MdWeekend,
  MdWidgets,
} from "react-icons/md";

const Piano = ({ setValue } = {}) => {
  const value = "33";

  return (
    <div className="flex flex-col  justify-between items-center  rounded-md border-b bg-white m-1 p-2">
      <span>Piano Guide</span>
      <span className="text-xs text-gray-400">
        This guide should show how to set price when item was unexpected and
        management is unavailable
      </span>
      <div>
        <button
          className="text-xs bg-blue-800 rounded-md text-white p-2 m-2"
          onClick={() => setValue && setValue(value)}
        >
          Set value to ${value}
        </button>
      </div>
    </div>
  );
};

export const defaultMiscFees = [
  {
    id: "IRmJsc",
    name: "Piano",
    defaultAmount: "125",
    Icon: MdStraighten,
    Guide: Piano,
  },
  {
    id: "zz7cX7",
    name: "Removal (Dump/Donate)",
    defaultAmount: "50",
    pre: "starts at",
    Icon: MdDeleteSweep,
  },
  {
    id: "KD83m2",
    name: "Hoist",
    defaultAmount: "350",
    Icon: MdWeekend,
  },
  {
    id: "RtoY57",
    name: "Tolls/Ferry",
    defaultAmount: "185",
    Icon: MdDirectionsBoat,
  },
  {
    id: "PobHV7",
    name: "Storage",
    defaultAmount: "100",
    Icon: MdWidgets,
  },
  {
    id: "odrUhV",
    name: "Storage Labor",
    defaultAmount: "2",
    Icon: MdDirectionsRun,
  },
  {
    id: "3qgVEX",
    name: "Bulky",
    defaultAmount: "1",
    Icon: MdTwoWheeler,
  },
  {
    id: "gs7eW8",
    name: "Hot tub",
    defaultAmount: "2",
    Icon: MdHotTub,
  },
  {
    id: "cZYuHW",
    name: "Permits",
    defaultAmount: "2",
    Icon: MdLocalParking,
  },
];
