export const defaultMaterials = [
  {
    id: "q3lYEM",
    name: "Small Box",
    volume: 1.5,
    w: "16 3/8",
    d: "12 5/8",
    h: "12 5/8",
    units: 0,
    img: "img/small.png",
    history: {},
    rate: 3,
  },
  {
    id: "KX1q8A",
    name: "Medium Box",
    volume: 3.0,
    w: "18",
    d: "18",
    h: "16",
    units: 0,
    img: "img/medium.png",
    history: {},
    rate: 4,
  },
  {
    id: "AT9F5y",
    name: "Large Box",
    volume: 4.5,
    w: "18",
    d: "18",
    h: "24",
    units: 0,
    img: "img/large.png",
    history: {},
    rate: 5,
  },
  {
    id: "L2NPOm",
    name: "Mattress Bag",
    description: "For use on mattresses & box springs",
    units: 0,
    img: "img/matbags.png",
    history: {},
    rate: 5,
  },
  {
    id: "Zb53TO",
    name: `Wardrobe (Rent)`,
    rent: true,
    volume: 13,
    w: "24",
    d: "24",
    h: "40",

    units: 0,
    img: "img/wardrobe.png",
    history: {},
    rate: 5,
  },
  {
    id: "jj4ghI",
    name: "TV Box S",
    units: 0,
    img: "img/tv.png",
    //     small 46 x 6 x30
    // large 56 x 6 x 36
    // xl 64 x 6 x40
    w: "46",
    d: "6",
    h: "30",
    history: {},
    rate: 25,
  },

  {
    id: "fjCPOT",
    name: "Small Mirror Pak",
    volume: 13,
    w: "37 5/16",
    d: "4 3/8",
    h: "26 15/16",

    units: 0,
    img: "img/smirror.png",
    history: {},
    rate: 5,
  },
  {
    id: "UmttCz",
    name: "Large Mirror Pak",
    volume: 13,
    w: "48",
    d: "4 1/2",
    h: "33",

    units: 0,
    img: "img/lmirror.png",
    history: {},
    rate: 10,
  },
  {
    id: "Aic8ie",
    name: "Flat Wardrobe",
    volume: 13,
    w: "35",
    d: "20",
    h: "9",

    units: 0,
    img: "img/laydown.png",
    history: {},
    rate: 5,
  },
  {
    id: "XjYPgM",
    name: "Wardrobe (Buy)",
    volume: 13,
    w: "24",
    d: "24",
    h: "40",

    units: 0,
    img: "img/wardrobe.png",
    history: {},
    rate: 15,
  },
  {
    id: "dW96Oj",
    name: "Paper Pads",
    units: 0,
    img: "img/pads.png",
    history: {},
    rate: 2,
  },
  {
    id: "K6HCr1",
    name: "Stretch Wrap",
    units: 0,
    img: "img/wrap.png",
    history: {},
    rate: 20,
  },
  {
    id: "s1s12u",
    name: "Carpet Protection",
    units: 0,
    img: "img/carpet.png",
    history: {},
    rate: 45,
  },
  {
    id: "INaIjO",
    name: "Ropes",
    units: 0,
    img: "img/rope.png",
    history: {},
    rate: 5,
  },
  {
    id: "s0mSbR",
    name: "Blankets",
    units: 0,
    img: "img/blanket.png",
    history: {},
    rate: 10,
  },
];
