import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../../app/common/components/Button";
import { Input } from "../../../app/common/form/Input";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { ModalWrapper } from "../../../app/common/modals/ModalWrapper";
import { addNewPTSShipment } from "../../../app/firestore/firestoreService";

export const AddCustomShipmentModal = ({
  shipmentId,
  opId,
  shippers,
  refNumber,
  crew,
  startingNumber,
  totalUnitsToAdd = 1,
}) => {
  const [shipmentName, setShipmentName] = useState("");
  const modalDispatch = useDispatch();

  const onSubmit = async (e) => {
    e.preventDefault();
    addNewPTSShipment({ refNumber: shipmentName, opId, shippers });
    modalDispatch(closeModal());
  };

  const handleChange = (e) => {
    setShipmentName(e.target.value);
  };

  return (
    <ModalWrapper header="Add Paramount Operation">
      <div className="bg-white p-4 relative">
        <form onSubmit={onSubmit}>
          <Input
            onChange={handleChange}
            value={shipmentName}
            name="shipmentName"
            label="New Shipment Name"
            placeholder={`SE-000000-X1`}
          />
          <div className="my-10">
            <Button type="submit" color="green">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
