import { Jobs } from "../../features/pts/Jobs";
import { Operation } from "./operations/Operation";
import { PtsJobDocSign } from "../../features/pts/jobDocSign/PtsJobDocSign";
import { PtsDocs } from "../../features/pts/PtsDocs";
import { FaFile } from "react-icons/fa";
import { Operations } from "./operations/Operations";
import { ShipperPage } from "./shippers/ShipperPage";
// import { Operations } from "./operations/Operations";

const TempComponent = () => {
  return <div>Temp Paramount Component</div>;
};

export const paramountRoutes = [
  {
    path: ["/paramount/jobs", "/pts/jobs", "/p/j"],
    component: Jobs,
    exact: true,
  },
  {
    path: ["/paramount/operations", "/pts/ops", "/p/o"],
    component: Operations,
    exact: true,
  },

  {
    path: ["/paramount/operation/:opId", "/pts/op/:opId", "/p/o/:opId"],
    component: Operation,
    exact: true,
  },
  {
    path: ["/paramount/shipper/:shipperId","/paramount/shipper/:shipperId/:displayName", "/pts/sh/:shipperId","/pts/sh/:shipperId/:displayName", "/p/s/:shipperId", "/p/s/:shipperId/:displayName"],
    component: ShipperPage,
    exact: true,
  },
  {
    path: [
      "/paramount/operation/:opId/docs",
      "/pts/op/:opId/docs",
      "/p/o/:opId/docs",
    ],
    component: PtsJobDocSign,
    exact: true,
  },
  {
    path: [
      "/paramount/operation/:opId/:property",
      "/pts/op/:opId/:property",
      "/p/o/:opId/:property",
    ],
    component: Operation,
    exact: true,
  },
  {
    path: ["/paramount/accounts", "/pts/acc", "/p/a"],
    component: TempComponent,
    exact: true,
  },
  {
    path: ["/paramount/docs", "/pts/docs", "/p/d"],
    component: PtsDocs,
    exact: true,
  },
  {
    path: ["/paramount/account/:accId", "/pts/acc/:accId", "/p/a/:addId"],
    component: TempComponent,
    exact: true,
  },
];

export const paramountLinks = {
  label: "Paramount",
  imageIconSrc: "/img/pts-logo-white.png",
  role: ["coordinator", "surveyor", "manager"],
  links: [
    {
      to: "/paramount/operations",
      label: "Operations",
      icon: "file",
      Icon: FaFile,
      role: ["manager", "coordinator", "surveyor"],
    },
    // {
    //   to: "/paramount/accounts",
    //   label: "Accounts",
    //   icon: "file",
    //   Icon: FaFile,
    //   role: ["manager"],
    // },
    {
      to: "/paramount/docs",
      label: "Docs",
      icon: "file",
      Icon: FaFile,
      role: ["manager"],
    },
    // {
    //   to: "/paramount/surveys",
    //   label: "Surveys",
    //   icon: "file",
    //   Icon: FaFile,
    //   role: ["manager", "coordinator", "surveyor"],
    // },
  ],
};
