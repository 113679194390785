import { Form, Formik } from "formik";
import React from "react";
import { ModalWrapper } from "../../app/common/modals/ModalWrapper";
import * as Yup from "yup";
import { MyTextInput } from "../../app/common/form/MyTextInput";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import { registerInFirebase } from "../../app/firestore/firebaseService";
import { SocialLogin } from "./SocialLogin";

export const RegisterForm = () => {
  const dispatch = useDispatch();

  return (
    <ModalWrapper size="mini" header="Register to Rev-vents">
      <Formik
        initialValues={{ displayName: "", email: "", password: "" }}
        validationSchema={Yup.object({
          displayName: Yup.string().required(),
          email: Yup.string().required().email(),
          password: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await registerInFirebase(values);
            setSubmitting(false);
            dispatch(closeModal());
          } catch (error) {
            setErrors({ auth: error.message });

            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <MyTextInput name="displayName" placeholder="Display Name" />
            <MyTextInput name="email" placeholder="Email address" />
            <MyTextInput
              name="password"
              placeholder="Password"
              type="password"
            />
            {errors.auth && (
              <label style={{ marginBottom: 10 }}>{errors.auth}</label>
            )}
            <button
              //loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
            >
              Register
            </button>
            <hr />

            <SocialLogin />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
};
