import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { listenToPacketsFromFirestore } from "../../../app/firestore/firestoreService";
import useFirestoreCollection from "../../../app/hooks/useFirestoreCollection";
import { listenToPackets } from "../settingsActions";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { MdAdd, MdStarHalf, MdStarOutline } from "react-icons/md";

export const Packets = () => {
  const dispatch = useDispatch();
  const { packets } = useSelector((state) => state.settings);

  useFirestoreCollection({
    query: () => listenToPacketsFromFirestore(),
    data: (packets) => dispatch(listenToPackets(packets)),
    deps: [dispatch],
  });

  return (
    <>
      <section>
        <h2 className="mb-2">
          Packets
          <button floated="right" color="green">
            <MdAdd />
            Create Packet
          </button>
        </h2>
        <hr />
        <PacketsTable packets={packets} />
      </section>
    </>
  );
};

const PacketsTable = ({ packets }) => {
  return (
    <div className="flex flex-col items-center align-middle justify-center ">
      <table singleLine>
        <th>
          <tr>
            <td>
              <input type="checkbox" />
            </td>
            <td>
              <MdStarOutline />
            </td>
            <td>Packet Name</td>
            <td>Files</td>
            <td>Tags</td>
            <td>Times used</td>
            <td>Frequency</td>
            <td>Created By</td>
            <td>Date</td>
            <td></td>
          </tr>
        </th>

        <tbody>
          {packets &&
            packets.map((packet) => (
              <PacketsRow key={packet.id} packet={packet} />
            ))}
        </tbody>
      </table>
    </div>
  );
};

const PacketsRow = ({ packet }) => {
  const dispatch = useDispatch();

  function toggleStarred() {
    dispatch({ type: "FAVOURITE_PACKET", payload: packet.id });
  }

  return (
    <tr>
      <td>
        <input type="checkbox" />
      </td>
      <td
        className="cursor-pointer hover:text-yellow-300"
        onClick={toggleStarred}
      >
        {packet?.isStarred ? <MdStarOutline /> : <MdStarHalf />}
      </td>
      <td>
        <Link to={`/manage/settings/packets/${packet?.id}`}>
          {packet?.name}
        </Link>
      </td>
      <td>{packet?.files?.length || 0}</td>
      <td>{packet?.tags}</td>
      <td>{packet?.usedCounter}</td>
      <td>{packet?.usedFrequency}</td>
      <td>{packet?.createdBy}</td>
      <td>{format(packet?.createdAt, "MM/dd/yy") || ""}</td>
      <td>
        <button>Edit</button>
        <button>Delete</button>
        <button>Copy</button>
      </td>
    </tr>
  );
};
