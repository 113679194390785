import React from "react";
import { Button } from "../../app/common/components/Button";
import { sendShipmentReceivedEmail } from "../../app/firestore/firebaseService";

export const Sandbox = () => {
  return (
    <div>
      <Button label="Add Email" onClick={() => sendShipmentReceivedEmail({ refNumber: "SE-475820-R1"})} />
    </div>
  );
};
