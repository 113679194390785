import { useField } from "formik";
import React from "react";

export const MyTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div error={meta.touched && !!meta.error}>
      <label htmlFor="">{label}</label>
      <textarea {...field} {...props} />
      {meta.touched && meta.error ? (
        <label basic color="red">
          {meta.error}
        </label>
      ) : null}
    </div>
  );
};
