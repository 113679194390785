import React from "react";

//THESE ARE TO MAKE SURE STYLES GET PROPERLY DISPLAYED IN TW3.0+
const text = {
  disabled: "text-gray-200",
  blue: "text-blue-700",
  gray: "text-gray-700",
  red: "text-red-500",
  green: "text-green-500",
  teal: "text-teal-500",
};
const border = {
  disabled: "border-gray-200",
  blue: "border-blue-700",
  gray: "border-gray-700",
  red: "border-red-500",
  green: "border-green-500",
  teal: "border-teal-500",
};
const hoverText = {
  disabled: "hover:text-gray-200",
  blue: "hover:text-white",
  gray: "hover:text-white",
  red: "hover:text-white",
  green: "hover:text-white",
  teal: "hover:text-white",
};
const hoverBg = {
  disabled: "hover:bg-gray-50",
  blue: "hover:bg-blue-800",
  gray: "hover:bg-gray-800",
  red: "hover:bg-red-600",
  green: "hover:bg-green-600",
  teal: "hover:bg-teal-600",
};
const focusRing = {
  disabled: "focus:ring-gray-200",
  blue: "focus:ring-blue-300",
  gray: "focus:ring-gray-300",
  red: "focus:ring-red-300",
  green: "focus:ring-green-300",
  teal: "focus:ring-teal-300",
};

export const Button = ({
  type = "button",
  onClick = () => {},
  className = "",
  label,
  Icon,
  IconRight,
  color = "blue",
  outlined,
  loading = false,
  preventDefault = false,
  notificationCount = null,
  disabled,
  children,
  ...rest
}) => {
  const handleClick = (e) => {
    if (preventDefault) e.preventDefault();
    onClick(e);
  };

  return (
    <button
      type={type}
      onClick={handleClick}
      className={
        `${disabled ? text.disabled : text[color]} ${disabled ? hoverText.disabled : hoverText[color]}  border ${
          disabled ? border.disabled : border[color]
        } ${disabled ? hoverBg.disabled : hoverBg[color]}  focus:ring-2 ${
          focusRing[color]
        } font-medium select-none rounded-lg text-xs px-3 py-1.5 text-center inline-flex items-center justify-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800 ` +
        className
      }
      {...rest}
      disabled={disabled || loading}
    >
      {loading ? (
        "Loading..." //TODO ADD better loading animantion
      ) : (
        <>
          {Icon && <Icon className="mr-2 -ml-1 w-4 h-4" />}
          {label && label}
          {IconRight && <IconRight className="ml-2 -mr-1 w-4 h-4" />}
          {notificationCount && (
            <span className="inline-flex justify-center  items-center ml-2 w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
              {notificationCount}
            </span>
          )}
          {children}
        </>
      )}
    </button>
  );
};
