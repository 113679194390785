import { onValue } from "firebase/database";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { asyncActionError, asyncActionFinish, asyncActionStart } from "../async/asyncReducer";
import { firebaseObjectToArray } from "../firestore/firebaseService";

export default function useFirebaseDatabase({ query, data, deps, limit }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncActionStart());
    const unsubscribe = onValue(
      query(),
      (snapshot) => {
        console.log(snapshot.val());
        data(firebaseObjectToArray(snapshot.val()) || []);
        dispatch(asyncActionFinish());
      },
      (error) => dispatch(asyncActionError(error))
    );
    return () => {
      unsubscribe();
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
