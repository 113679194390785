import React from "react";
import { MaterialsWProvider } from "../Moves/Materials";
import {
  useMaterialsDispatch,
  useMaterials,
  MaterialsProvider,
} from "../Moves/MaterialsProvider";

const Materials = () => {
  const materials = useMaterials();
  const materialsDispatch = useMaterialsDispatch();
  return <MaterialsWProvider state={materials} dispatch={materialsDispatch} />;
};

export const MaterialCalculator = () => {
  return (
    <MaterialsProvider>
      <Materials />
    </MaterialsProvider>
  );
};
