import { Menu } from "@headlessui/react";
import { useState } from "react";

import { usePopper } from "react-popper";

export function Select({
  label,
  className = "",
  suffix,
  options = [],
  selected,
  onSelect = (e) => alert(e),
  canCreateCustom = false,
  onCustomSelect = () => console.log("Custom Selection."),
}) {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement);

  const handleSelect = (option) => {
    return function (e) {
      if (e === "custom") return onCustomSelect();
      onSelect(option);
    };
  };

  return (
    // <div className={className + "relative  outline-none"}>
    <Menu
      as="div"
      // value={selected}
    >
      {({ open }) => (
        <>
          <Menu.Button ref={setReferenceElement}>
            <div className="flex items-center border px-2 py-1 text-xs rounded bg-white outline-none">
              <span className=" truncate ">{label ? label : selected?.label}</span>
              <span>{suffix}</span>
            </div>
          </Menu.Button>
          <Menu.Items
            as="ul"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="z-30 text-base bg-white rounded-md shadow-lg max-h-80 overflow-y-auto ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {({ close }) => (
              <>
                {options.length > 0 &&
                  options.map((option, idx) => (
                    <Menu.Item
                      as="li"
                      onClick={handleSelect(option)}
                      key={option?.label || idx}
                      value={option}
                      disabled={option?.disabled}
                      className={({ active, disabled }) =>
                        `text-xs whitespace-nowrap select-none relative py-1.5 px-4 ${
                          active ? "text-indigo-900 bg-indigo-100" : disabled ? "text-gray-300" : " text-gray-900"
                        }`
                      }
                    >
                      {option?.label}
                    </Menu.Item>
                  ))}
                {canCreateCustom && (
                  <>
                    <hr />
                    <Menu.Item
                      as="li"
                      value="custom"
                      onClick={onCustomSelect}
                      className={({ active, disabled }) =>
                        `text-xs whitespace-nowrap select-none relative py-1.5 px-4 ${
                          active ? "text-indigo-900 bg-indigo-100" : disabled ? "text-gray-300" : " text-gray-900"
                        }`
                      }
                    >
                      Custom
                    </Menu.Item>
                  </>
                )}
              </>
            )}
          </Menu.Items>
        </>
      )}
    </Menu>
    // </div>
  );
}
