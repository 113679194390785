import React, { useRef, useState } from "react";
// import units from "./units";
import "./map.css";
import {
  select,
  scaleLinear,
  /*max, min,*/ axisBottom,
  axisLeft,
  zoom,
} from "d3";
// import { zoom } from "d3-zoom";
import useResizeObserver from "../../../app/hooks/useResizeObserver";

const sections = [
  { id: "A", rows: 11, columns: 4, x: 0, y: 0, width: 17, height: 116 - 31 },
];

const liftvan = {
  width: 4,
  height: 7,
};
const units = [
  ...Array(sections[0].columns)
    .fill()
    .reduce(
      (a, x, i) => [
        ...a,
        ...Array(sections[0].rows) //row
          .fill()
          .map((y, j) => ({
            x: (i * sections[0].width) / sections[0].columns,
            y: (j * sections[0].height) / sections[0].rows,
            ...liftvan,
          })),
      ],
      []
    ),
  // { x: 0, y: 0, width: liftvan.width, height: liftvan.height },
  // { x: 4, y: 0, width: liftvan.width, height: liftvan.height },
  // { x: 8, y: 0, width: liftvan.width, height: liftvan.height },
  // { x: 0, y: 7, width: liftvan.width, height: liftvan.height },
  // { x: 4, y: 7, width: liftvan.width, height: liftvan.height },
  // { x: 8, y: 7, width: liftvan.width, height: liftvan.height },
];

const warehouseOutline = [
  { x: 0, y: 0 },
  { x: 147, y: 0 },
  { x: 147, y: 63 },
  { x: 128, y: 63 },
  { x: 128, y: 80 },
  { x: 97, y: 80 },
  { x: 97, y: 116 },
  { x: 0, y: 116 },
];

export const WarehouseMap = () => {
  const svgRef = useRef();
  const wrapperRef = useRef();

  const dimensions = useResizeObserver(wrapperRef);
  const [currentZoomState, setCurrentZoomState] = useState();
  const [data] = useState(units);
  // const [data, setData] = React.useState(Array.from({ length: 50 }, () => Math.round(Math.random() * 100)));

  React.useEffect(() => {
    const { width, height } =
      dimensions || wrapperRef.current.getBoundingClientRect();
    const svg = select(svgRef.current);
    const s = 0; //spacing
    const xScale = scaleLinear()
      .domain([0 - s, 147 + s])
      .range([0, width]);
    const yScale = scaleLinear()
      .domain([0 - s, 116 + s])
      .range([0, height]);

    if (currentZoomState) {
      const newXScale = currentZoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
      const newYScale = currentZoomState.rescaleY(yScale);
      yScale.domain(newYScale.domain());
    }

    svg
      .selectAll(".section")
      .data(sections)
      .join("rect")
      .attr("class", "section")
      .attr("x", (value) => xScale(value.x))
      .attr("y", (value) => yScale(value.y))
      .attr("width", (value) => 7.8 * value.width * (currentZoomState?.k || 1))
      .attr(
        "height",
        (value) => 7.8 * value.height * (currentZoomState?.k || 1)
      )
      .attr("fill", "white")
      .attr("stroke", "black")
      .attr("stroke-width", 2);

    svg
      .selectAll(".unit")
      .data(units)
      .join("rect")
      .attr("class", "unit")
      .attr("x", (value) => xScale(value.x))
      .attr("y", (value) => yScale(value.y))
      .attr("width", (value) => 7.8 * value.width * (currentZoomState?.k || 1))
      .attr(
        "height",
        (value) => 7.8 * value.height * (currentZoomState?.k || 1)
      )
      .attr("rx", 2)
      .attr("ry", 2);

    svg
      .selectAll("polygon")
      .data([warehouseOutline])
      .join("rect")
      .attr("points", (d) =>
        d.map((p) => [xScale(p.x), yScale(p.y)].join(",")).join(" ")
      )
      .attr("stroke", "black")
      .attr("stroke-width", 2);

    const xAxis = axisBottom(xScale);
    svg
      .select(".x-axis")
      .attr("transform", `translateY(${height})`)
      .call(xAxis);

    const yAxis = axisLeft(yScale);
    svg.select(".y-axis").attr("transform", `translateX(${width})`).call(yAxis);

    // zoom
    const zoomBehavior = zoom()
      .scaleExtent([0.8, 5])
      .translateExtent([
        [-0.2 * width, -0.2 * height],
        [1.2 * width, 1.2 * height],
      ])
      .on("zoom", (event) => {
        const zoomState = event.transform;
        setCurrentZoomState(zoomState);
      });
    svg.call(zoomBehavior);
  }, [currentZoomState, data, dimensions]);
  return (
    <>
      <div
        ref={wrapperRef}
        style={{ marginBottom: "2rem" }}
        className="text-xs bg-slate-400 w-full aspect-[147/116]"
      >
        <svg ref={svgRef} className=" border-2 w-full h-full ">
          <polygon
            id="warehouseOutline"
            points="0,0 147,0 147,63 128,63 128,80 97,80 97,116 0,116"
            fill="#ddd"
            stroke="black"
          />
        </svg>
      </div>
    </>
  );
};
