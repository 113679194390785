import React from "react";
import { PageLayout } from "../../../app/layout/PageLayout";
import { FaWarehouse } from "react-icons/fa";
// import { OpsSearch } from "../OpsSearch";
import { useDispatch, useSelector } from "react-redux";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";
import { listenToOperationInFirestore } from "../../../app/firestore/firestoreService";
import { listenToOperation } from "../../paramount/paramountActions";
import { MdBackspace, MdClear, MdSend, MdUndo } from "react-icons/md";
import cuid from "cuid";

export const LoadChart = ({ match }) => {
  const [selected] = React.useState({ objectID: match.params.opId } || null);
  const [uiOpen, setUiOpen] = React.useState(false);

  const { operation } = useSelector((state) => state.warehouse);
  const dispatch = useDispatch();
  useFirestoreDoc({
    query: () => listenToOperationInFirestore(selected?.objectID || operation?.id),
    data: (op) => dispatch(listenToOperation(op)),
    deps: [dispatch, selected],
    shouldExecute: !!selected?.objectID || !!operation?.id,
  });

  return (
    <>
      <PageLayout IconTitle={FaWarehouse} title="Storage Labels">
        <div className="md:p-2 border-b ">
          {/* <OpsSearch selected={selected} setSelected={setSelected} /> */}
          {operation && (
            <div>
              {operation?.shippers && Object.entries(operation.shippers).map(([shipperId, shipper]) => <div>{shipper?.displayName}</div>)}
            </div>
          )}
          <button onClick={() => setUiOpen((v) => !v)} className="bg-green-800 text-white px-10 py-2 rounded my-2">
            Start Load Charting
          </button>
          {uiOpen && <ChartUI operation={operation} close={() => setUiOpen(false)} />}
        </div>
      </PageLayout>
    </>
  );
};

const ChartUI = ({ operation, close }) => {
  const [number, setNumber] = React.useState("");
  const [activeUnit, setActiveUnit] = React.useState(null);
  const [history, setHistory] = React.useState([]);

  const [units, setUnits] = React.useState((operation?.shipments && Object.values(operation?.shipments)?.[0]?.units) || {});
  // const [shipment, setShipment] = React.useState(operation?.shipments && Object.keys(operation?.shipments)?.[0]);
  const handleBackspace = (e) => setNumber(number.slice(0, -1));
  const handleNumberPress = (e) => setNumber((v) => (v !== "N" ? v + e.target.name : e.target.name));
  const handleNPress = (e) => setNumber("N");
  const handleClearNumber = (e) => setNumber("");
  const handleSend = (e) => {
    setHistory((h) => [...h, `${number} > #${activeUnit}`]);
    setNumber("");
  };

  const handleAddUnit = (e) => {
    const unitId = cuid();
    setUnits((u) => {
      return { ...u, [unitId]: { number: Object.keys(u).length + 1 } };
    });
  };

  React.useEffect(() => {
    setActiveUnit(Object.keys(units).length);
  }, [units]);

  return (
    <div className="absolute w-screen h-screen bg-white top-0 left-0 z-30">
      <div className="w-full h-full bg-slate-100 relative flex flex-col md:flex-row">
        <div name="units" className="w-full md:w-2/5 bg-blue-100 h-2/5 md:h-full flex flex-col text-2xl">
          <div className="flex-grow h-full grid grid-cols-3 gap-4 overflow-y-scroll">
            {units &&
              Object.keys(units).length > 0 &&
              Object.entries(units)
                .sort((a, b) => a[1].number - b[1].number)
                .map(([unitId, unit]) => (
                  <NumberButton
                    className={activeUnit === unit.number ? "bg-pink-600 text-white" : ""}
                    number={`#${unit.number}`}
                    onClick={() => setActiveUnit(unit.number)}
                  />
                ))}
          </div>
          <div className="flex justify-end">
            <select>
              {operation?.shipments &&
                Object.entries(operation?.shipments)
                  .sort((a, b) => a[1].refNumber - b[1].refNumber)
                  .map(([shipmentId, shipment]) => <option>{shipment?.refNumber}</option>)}
            </select>
            <NumberButton label="+" onClick={handleAddUnit} />
          </div>
        </div>
        <div name="history" className="w-full md:w-1/5 h-1/5 md:h-full bg-pink-100 overflow-y-auto">
          {/* <div>
            {operation?.shipments &&
              Object.keys(operation?.shipments).length > 0 &&
              Object.entries(operation?.shipments).map(([shipmentId, shipment]) => <div>{shipment?.refNumber}</div>)}
          </div> */}
          <button type="button" onClick={close} className="mx-2 rounded-full cursor-pointer px-2 py-2 text-xl hover:bg-gray-200">
            <MdClear />
          </button>
          <div className="flex flex-col">
            {history.map((e) => (
              <div>{e}</div>
            ))}
          </div>
        </div>
        <div name="numbers" className="w-full md:w-2/5 h-2/5 md:h-full bg-green-100 flex flex-col text-2xl">
          <div name="number-display" className="w-full h-1/5 flex items-center justify-around">
            <div>
              <MdUndo />
            </div>
            <div>{number}</div>
            <div onClick={handleBackspace}>
              <MdBackspace />
            </div>
          </div>
          <div className="flex w-full h-1/5 items-center justify-around">
            <NumberButton number="1" onClick={handleNumberPress} />
            <NumberButton number="2" onClick={handleNumberPress} />
            <NumberButton number="3" onClick={handleNumberPress} />
          </div>
          <div className="flex w-full h-1/5 items-center justify-around">
            <NumberButton number="4" onClick={handleNumberPress} />
            <NumberButton number="5" onClick={handleNumberPress} />
            <NumberButton number="6" onClick={handleNumberPress} />
          </div>
          <div className="flex w-full h-1/5 items-center justify-around">
            <NumberButton number="7" onClick={handleNumberPress} />
            <NumberButton number="8" onClick={handleNumberPress} />
            <NumberButton number="9" onClick={handleNumberPress} />
          </div>
          <div className="flex w-full h-1/5 items-center justify-around">
            <NumberButton
              subText={number.length > 0 ? "" : "No number"}
              label={number.length > 0 ? "CE" : "N"}
              onClick={number.length > 0 ? handleClearNumber : handleNPress}
            />
            <NumberButton number="0" onClick={handleNumberPress} />
            <NumberButton Icon={MdSend} onClick={handleSend} disabled={Object.keys(units).length === 0} />
          </div>
        </div>
      </div>
    </div>
  );
};

const NumberButton = ({ number, onClick, Icon, label, className = "", subText, disabled = false }) => (
  <button
    type="button"
    className={"w-full flex flex-col justify-center items-center px-4 py-2 select-none " + className}
    name={number}
    onClick={onClick}
    disabled={disabled}
  >
    {number && number} {Icon && <Icon />} {label && label}
    <span className="block text-xs text-gray-400 min-h-[10px]">{subText || " "}</span>
  </button>
);
