import { BsFillCheckSquareFill, BsSquare } from "react-icons/bs";
import { MdCameraAlt, MdQrCode, MdSettings } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Select } from "../../../app/common/components/Select";
import { openModal } from "../../../app/common/modals/modalReducer";
import { setUnitTypeInFirestore } from "../../../app/firestore/firestoreService";
import React from "react";
import cuid from "cuid";
import { useDropzone } from "react-dropzone";
import {
  getFileDownloadUrl,
  newUploadSession,
  updateUploadSession,
  uploadFileToFirebaseStorage,
} from "../../../app/firestore/firebaseService";
import { money_round } from "../../../app/common/util/helperFunctions";
import { Link } from "react-router-dom";

export const UnitPreview = ({ unit, selected, toggleSelect, opId, shipmentId, created, shippers, refNumber, uploadImages, ...props }) => {
  const dispatch = useDispatch();
  const handleUnitTypeChange = (unit) => {
    return function (unitType) {
      const data = {
        weight: unitType.weight(),
        dimms: unitType.dimms,
        unitType: { label: unitType.label, id: unitType.id },
      };
      return setUnitTypeInFirestore({ unit, data, opId, shipmentId });
    };
  };
  return (
    <div
      className={` border group ${
        selected ? "bg-lime-200 border-lime-300" : ""
      }  rounded-lg w-40 h-48 mx-auto  relative bg-contain md:bg-cover bg-no-repeat bg-center`}
      onClick={toggleSelect}
      style={{
        backgroundImage: `url(${unit?.thumb || defaultUnitImages?.[unit?.unitType?.id || "liftvan"] || "/img/unitTypes/custom.png"})`,
      }}
    >
      <span className="absolute top-3 left-2 cursor-pointer   rounded">
        {selected ? (
          <BsFillCheckSquareFill className="text-lime-600 bg-white" />
        ) : (
          <BsSquare className="invisible group-hover:visible text-gray-600 bg-white bg-opacity-70 hover:bg-opacity-100 transition-all" />
        )}
      </span>
      <button
        className="absolute right-2 top-2 px-2 py-1 bg-slate-50 border rounded hover:bg-white outline-none  "
        onClick={(e) => {
          e.stopPropagation();
          dispatch(
            openModal({
              modalType: "EditUnitModal",
              modalProps: {
                opId,
                shipmentId,
                unit,
                created,
                shippers,
                refNumber,
              },
            })
          );
        }}
      >
        <MdSettings />
      </button>
      <Link
        to={`/w/unit/${unit.id}`}
        className="absolute right-2 bottom-10  py-1 px-2  bg-slate-50 border rounded hover:bg-white outline-none  "
      >
        <MdQrCode />
      </Link>
      {unit?.number && (
        <div className="absolute right-2 bottom-2 flex items-center  " onClick={(e) => e.stopPropagation()}>
          <Select
            selected={unit?.unitType}
            onSelect={handleUnitTypeChange(unit)}
            className="w-32"
            suffix={`#${unit.number}`}
            options={Object.values(defaultUnitTypes).sort()}
            canCreateCustom={true}
            onCustomSelect={() =>
              dispatch(
                openModal({
                  modalType: "AddCustomUnitTypeModal",
                  modalProps: {
                    opId,
                    shipmentId,
                    unit,
                  },
                })
              )
            }
          />
          {uploadImages && (
            <button className="ml-1  py-1 px-2  bg-slate-50 border rounded hover:bg-white outline-none  " onClick={uploadImages}>
              <MdCameraAlt />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export const defaultUnitImages = {
  liftvan: "/img/unitTypes/liftvan.png",
  cardboardLiftvan: "/img/unitTypes/corrugated-liftvan.png",
  sofaCarton: "/img/unitTypes/sofa.png",
  vault: "/img/unitTypes/vault.png",
  dContainer: "/img/unitTypes/d-container.png",
  ldn: "/img/unitTypes/ldn.png",
  pallet: "/img/unitTypes/pallet.png",
  crate: "/img/unitTypes/crate.png",
};

export const defaultUnitTypes = {
  liftvan: {
    id: "liftvan",
    label: "Lift Van",
    isNew: false,
    weight: function () {
      return { tare: getRandomInt(300, 320) };
    },
    dimms: { l: 87, w: 45, h: 87, units: "inches" },
  },
  cardboardLiftvan: {
    id: "cardboardLiftvan",
    label: "Cardboard Lift Van",
    isNew: false,
    weight: function () {
      return { tare: getRandomInt(140, 160) };
    },
    dimms: { l: 87, w: 45, h: 89, units: "inches" },
  },
  ldn: {
    id: "ldn",
    label: "LDN",
    isNew: false,
    weight: function () {
      return { tare: getRandomInt(80, 100) };
    },
    dimms: { l: 54, w: 54, h: 56, units: "inches" },
  },
  dContainer: {
    id: "dContainer",
    label: "D Container",
    isNew: false,
    weight: function () {
      return { tare: getRandomInt(55, 60) };
    },
    dimms: { l: 58, w: 42, h: 45, units: "inches" },
  },
  sofaCarton: {
    id: "sofaCarton",
    label: "Sofa Carton",
    isNew: false,
    weight: function () {
      return { tare: getRandomInt(90, 110) };
    },
    dimms: { l: 100, w: 40, h: 40, units: "inches" },
  },
  vault: {
    id: "vault",
    label: "Vault",
    isNew: false,
    weight: function () {
      return { tare: getRandomInt(400, 430) };
    },
    dimms: { l: 84, w: 60, h: 94, units: "inches" },
  },
  pallet: {
    id: "pallet",
    label: "Pallet",
    isNew: false,
    weight: function () {
      return { tare: getRandomInt(50, 100) };
    },
    dimms: { l: 0, w: 0, h: 0, units: "inches" },
  },
  crate: {
    id: "crate",
    label: "Crate",
    isNew: false,
    weight: function () {
      return { tare: getRandomInt(0, 100) };
    },
    dimms: { l: 0, w: 0, h: 0, units: "inches" },
  },
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export const UnitPreviewWUpload = (props) => {
  const {
    unit: { id: unitId, thumb },
    opId,
    shipmentId,
  } = props;
  const sessionId = unitId;
  const storagePath = `companies/paramount/units/${unitId}/`;
  const [loading, setLoading] = React.useState(false);
  const [bytesUploaded, setBytesUploaded] = React.useState();
  const [totalFilesSize, setTotalFilesSize] = React.useState(0);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleUploadFiles,
    noClick: true,
    noKeyboard: true,
  });

  async function handleUploadFiles(files /* ARRAY */) {
    setLoading(true);
    const f = files.map((file) => Object.assign(file, { id: cuid() }));
    setTotalFilesSize(getTotalFilesSize(files));
    function uploadFileAsPromise(file, index) {
      return new Promise(async function (resolve, reject) {
        const fileId = file.id || cuid();
        const data = { file, fileId, storagePath, sessionId };
        const uploadTask = uploadFileToFirebaseStorage(data);

        uploadTask.on(
          "state_changed",
          (snapshot) =>
            setBytesUploaded((b) => ({
              ...b,
              [fileId]: snapshot.bytesTransferred,
            })), // / snapshot.totalBytes) * 100,
          (error) => {
            console.log(error);
            updateUploadSession(sessionId, {
              [`files/${fileId}/error`]: error.message,
              [`files/${fileId}/status`]: "Failed to Upload",
            });
            reject(error);
          },
          async () => {
            const downloadURL = await getFileDownloadUrl(uploadTask.snapshot.ref);
            console.log(`${file.name} download url is ${downloadURL}.`);
            const uploadedFile = {
              id: file.id,
              fileName: file.name,
              size: file.size,
              type: file.type,
              url: downloadURL,
              storageRef: storagePath,
              thumbs: [],
            };
            let isMain = {};
            if (index === 0 && !thumb) {
              isMain = { [`files/${fileId}/main`]: true };
            }
            updateUploadSession(sessionId, {
              [`files/${fileId}/status`]: "File Uploaded",
              [`files/${fileId}/url`]: downloadURL,
              [`files/${fileId}/storageRef`]: storagePath,
              [`files/${fileId}/size`]: file.size,
              [`files/${fileId}/type`]: file.type,
              ...isMain,
            });
            return resolve(uploadedFile);
          }
        );
      });
    }
    try {
      await newUploadSession(unitId, {
        dbTargets: [
          {
            docPath: `companies/paramount/units/${unitId}`,
            docKey: "images",
            condition: true,
            additionalData: {
              ...(!thumb ? { mainImgId: f[0].id } : {}),
            },
          },
          {
            docPath: `companies/paramount/operations/${opId}`,
            docKey: `shipments.${shipmentId}.units.${unitId}.thumb`,
            mainThumb: true,
            condition: !thumb,
          },
          {
            docPath: `companies/paramount/shipments/${shipmentId}`,
            docKey: `units.${unitId}.thumb`,
            condition: !thumb,
            mainThumb: true,
          },
        ],
        files: Object.fromEntries(f.map((file) => [file.id, { fileName: file.name, status: "Uploading" }])),
      });
      await Promise.all(f.map(uploadFileAsPromise)); //uploadedFiles
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <div {...getRootProps()} className={`relative border border-transparent transition-all `}>
      {(loading || isDragActive) && (
        <div className="w-full h-full absolute top-0 left-0 bg-lime-400 bg-opacity-50 z-10 flex flex-col items-center justify-center border border-dashed">
          {loading ? "Uploading" : "Upload"}
          {loading && bytesUploaded && totalFilesSize && (
            <div>{money_round((100 * Object.values(bytesUploaded).reduce((a, b) => (a += b), 0)) / totalFilesSize) + " %"}</div>
          )}
        </div>
      )}
      <input {...getInputProps()} disabled={loading} />
      <UnitPreview {...props} uploadImages={open} />
    </div>
  );
};

const getTotalFilesSize = (files) => files.reduce((a, f) => (a += f.size), 0);
