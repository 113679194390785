export function listenToUploads(uploads) {
  return {
    type: "LISTEN_TO_UPLOADS",
    payload: uploads,
  };
}
export function listenToEmails(emails) {
  return {
    type: "LISTEN_TO_EMAILS",
    payload: emails,
  };
}
