import { format } from "date-fns";
// import download from "downloadjs";
import React, { useEffect, useReducer, useState } from "react";

import { Button } from "../../app/common/form/Button";
import { Checkbox } from "../../app/common/form/Checkbox";
import CountButton from "../../app/common/form/CountButton";
import { Input } from "../../app/common/form/Input";
// import { getFormattedDate } from "../../app/common/util/helperFunctions";
import { loadParamountJobsOptions } from "../../app/firestore/firestoreService";
import AsyncSelect from "react-select/async";
import { RadioGroup } from "../../app/common/form/RadioGroup";
import { InputGroup } from "../../app/common/form/InputGroup";
import { PageLayout } from "../../app/layout/PageLayout";
import {
  MdBookmark,
  MdCalendarToday,
  MdClear,
  MdHouse,
  MdPerson,
  MdPhone,
} from "react-icons/md";
import { PDFDocument, rgb, TextAlignment } from "pdf-lib";
import download from "downloadjs";

const reducer = (state, { payload, type }) => {
  if (!type && payload.field && payload.option) {
    return { ...state, [payload.field]: payload.option };
  }

  switch (type) {
    case "INPUT_CHANGE":
      return {
        ...state,
        ...payload,
      };
    case "CLEAR_FORM":
      return { ...initialState };
    case "UPDATE_COUNT":
      return { ...state, ...payload };
    case "TOGGLE_INCLUDE_INTERNATIONAL_DOCS":
      return { ...state, includeInternational: payload };
    case "SEARCH_UPDATE":
      return { ...initialState, ...payload };
    case "JOB_TYPE_UPDATE":
      return { ...state, jobType: payload };
    case "DOC_UPDATE":
      return {
        ...state,
        docs: state?.docs?.hasOwnProperty(Object.keys(payload)[0])
          ? Object.fromEntries(
              Object.entries(state.docs).filter(
                ([key]) => !key.includes(Object.keys(payload)[0])
              )
            )
          : { ...state.docs, ...payload },
      };
    //!!!!!!!!!!!!!!!!!!!! NEEDS WORK!!!!!!!!!!!!!!!!!!!!!!!!
    case "DOC_COUNT_UPDATE":
      return {
        ...state,
        docs: {
          ...state.docs,
          [payload.id]: {
            ...state.docs[payload.id],
            count: payload.count,
          },
        },
      };
    default:
      return state;
  }
};

const initialState = {
  fullName: "John Mayers",
  jobDate: format(new Date(), "MM/dd/yyyy"),
  refNumber: "SE-591914-A1",
  phoneNumber: "(253) 496-5521",
  address: "1599 128th Ave S, Apt 303, Kent WA 98031",
  inventorySheets: 5,
  includeInternational: false,
  jobType: "ORIGIN",
  docs: {},
};

const jobType = [
  { value: "ORIGIN", name: "Origin" },
  { value: "DESTINATION", name: "Destination" },
  { value: "DEBRIS", name: "Debri Removal" },
  { value: "INTRASTATE", name: "Intrastate" },
];

const originDocs = {
  srgy: {
    label: "Accessorials",
    url: "pdf/pts/Accessorials.pdf",
    fields: {
      "Full Name Reversed": "fullName",
      "Reference Number": "refNumber",
      "Origin Phone Number": "phoneNumber",
      whatIsJobTypeBlue: "jobType",
      originAddress1: "address",
      customerSignatureDate2: "jobDate",
    },
  },
  euet: { label: "Alcohol Inventory", url: "pdf/pts/Alcohol Inventory.pdf" },
  df765: {
    label: "CrateList And Property Damage Release",
    url: "pdf/pts/CrateList And Property Damage Release.pdf",
  },
  hdf6u7: {
    label: "Electronics Inventory",
    url: "pdf/pts/Electronics Inventory.pdf",
  },
  fhgdf6: { label: "Inventory Sheet", url: "pdf/pts/Inventory Sheet.pdf" },
  yttiw3w: { label: "Load Chart", url: "pdf/pts/Load Chart.pdf" },
  hggjkk: {
    label: "Post Origin Report",
    url: "pdf/pts/Post Origin Report.pdf",
  },
  w546575: { label: "Prohibited Items", url: "pdf/pts/Prohibited Items.pdf" },
  w54645t: {
    label: "Residence Inspection",
    url: "pdf/pts/Residence Inspection.pdf",
  },
};
const destinationDocs = {
  dygjfghj: {
    label: "Accessorials",
    url: "pdf/pts/Accessorials.pdf",
  },
  jfghjfgh: {
    label: "CrateList And Property Damage Release",
    url: "pdf/pts/CrateList And Property Damage Release.pdf",
  },
  jfgyy: { label: "Load Chart", url: "pdf/pts/Load Chart.pdf" },
  jgyiukj: {
    label: "Post Delivery Report",
    url: "pdf/pts/Post Delivery Report.pdf",
  },
  gfhjfg: {
    label: "Residence Inspection",
    url: "pdf/pts/Residence Inspection.pdf",
  },
};
const debrisDocs = {
  srgy: { label: "Accessorials", url: "pdf/pts/Accessorials.pdf" },
};
const intrastatenDocs = {
  jfgyuf: { label: "BOL", url: "pdf/pts/bol-sfm.pdf" },
  ytuuf: { label: "Accessorials", url: "pdf/pts/Accessorials.pdf" },
  jfghjgh: { label: "Accessorials", url: "pdf/pts/Accessorials.pdf" },
  fghjfd: {
    label: "CrateList And Property Damage Release",
    url: "pdf/pts/CrateList And Property Damage Release.pdf",
  },
  ghjfgh: { label: "Inventory Sheet", url: "pdf/pts/Inventory Sheet.pdf" },
  fghcd: {
    label: "Post Delivery Report",
    url: "pdf/pts/Post Delivery Report.pdf",
  },
  sdrt: { label: "Post Origin Report", url: "pdf/pts/Post Origin Report.pdf" },
  teryu: { label: "Prohibited Items", url: "pdf/pts/Prohibited Items.pdf" },
  mnbv: {
    label: "Residence Inspection",
    url: "pdf/pts/Residence Inspection.pdf",
  },
  vbvvv: {
    label: "Residence Inspection",
    url: "pdf/pts/Residence Inspection.pdf",
  },
};

// const getPDF = async (state) => {
//   download(
//     await getDocs(state),
//     `DOCS ${state.clientName} ${state.refNumber} ${getFormattedDate(new Date())}.pdf`,
//     "application/pdf"
//   );
// };

const getDocs = async (state) => {};

export const PtsDocs = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [docs, setDocs] = useState([]);

  const onChange = (e) => {
    dispatch({
      type: "INPUT_CHANGE",
      payload: { [e.target.name]: e.target.value },
    });
  };

  const clearForm = (e) => {
    e.preventDefault();
    dispatch({ type: "CLEAR_FORM" });
  };

  const changeCount = (field) => {
    dispatch({ type: "UPDATE_COUNT", payload: field });
  };

  const changeInventorySheets = (count) => {
    changeCount({ inventorySheets: count });
  };

  const toggleIncludeInternationalDocs = (e) => {
    dispatch({
      type: "TOGGLE_INCLUDE_INTERNATIONAL_DOCS",
      payload: e.target.checked,
    });
  };

  const handleOnChange = (newValue) => {
    dispatch({ type: "SEARCH_UPDATE", payload: newValue });
    // console.log(newValue);
  };

  useEffect(() => {
    if (!!state.jobType) {
      setDocs(() => {
        switch (state.jobType) {
          case "ORIGIN":
            return originDocs;
          case "DESTINATION":
            return destinationDocs;
          case "DEBRIS":
            return debrisDocs;
          case "INTRASTATE":
            return intrastatenDocs;

          default:
            return [];
        }
      });
    }
  }, [state.jobType]);

  return (
    <PageLayout title="Paramount Document Prep">
      <div className="flex flex-col lg:flex-row-reverse justify-around bg-gray-100 p-1 rounded-lg m-1">
        <form className="my-6 px-6  mx-auto">
          {/* <AsyncSelect
            cacheOptions
            loadOptions={loadParamountJobsOptions}
            onChange={handleOnChange}
            defaultOptions
            className="border-2 text-xl rounded-md max-w-md outline-none"
            isClearable
          /> */}
          <div className="flex space-x-2">
            <div className="w-96 border rounded px-4 py-2 bg-white">
              <Input
                value={state.fullName}
                name="fullName"
                label="Customers Name"
                onChange={onChange}
                Icon={MdPerson}
                placeholder="Steve Rogers..."
              />
              <Input
                value={state.refNumber}
                name="refNumber"
                label="Reference Number"
                onChange={onChange}
                Icon={MdBookmark}
                placeholder="SE-XXXXX-X1"
              />
              <Input
                value={state.jobDate}
                name="jobDate"
                label="Job Date"
                onChange={onChange}
                Icon={MdCalendarToday}
                placeholder="11/23/99"
              />
              <Input
                value={state.phoneNumber}
                name="phoneNumber"
                label="Phone Number"
                onChange={onChange}
                Icon={MdPhone}
                placeholder="11/23/99"
              />
              <Input
                value={state.address}
                name="address"
                label="Address"
                onChange={onChange}
                Icon={MdHouse}
                placeholder="123 Street Name, City, State, 99999"
              />
            </div>
            <div className="w-80 border rounded px-4 py-2 bg-white">
              <RadioGroup
                options={jobType}
                label="Job Type"
                onChange={(e) => {
                  dispatch({
                    type: "JOB_TYPE_UPDATE",
                    payload: e.target.value,
                  });
                }}
                value={state.jobType}
              />

              <InputGroup label="Docs">
                {Object.keys(docs).map((doc) => {
                  return (
                    <Checkbox
                      size="small"
                      key={doc}
                      name={doc}
                      label={docs[doc].label}
                      checked={state?.docs?.hasOwnProperty(doc)}
                      onChange={() => {
                        dispatch({
                          type: "DOC_UPDATE",
                          payload: { [doc]: docs[doc] },
                        });
                      }}
                      onCountButtonChange={(count) => {
                        dispatch({
                          type: "DOC_COUNT_UPDATE",
                          payload: { id: doc, count: count },
                        });
                      }}
                      count={docs[doc].count}
                    />
                  );
                })}
              </InputGroup>
              <Checkbox
                name="includeElectronicsAndInventory"
                label="Include International Docs"
                checked={state.includeInternational}
                onChange={toggleIncludeInternationalDocs}
              />
              <CountButton
                label="Inventory sheets"
                name="inventorySheets"
                count={state.inventorySheets}
                changeCount={changeInventorySheets}
                min={1}
              />
            </div>
            <div></div>
          </div>

          <div className="flex justify-around py-10">
            {state !== initialState && (
              <button
                tabIndex={-1}
                onClick={(e) => {
                  clearForm(e);
                }}
                className="transition-all rounded-md   border-2 border-gray-200  hover:text-white hover:bg-red-400 hover:border-3   flex space-x-2 items-center px-2 py-1
                "
              >
                <MdClear /> <span>Clear</span>
              </button>
            )}
            <button
              onClick={(e) => {
                e.preventDefault();
                fillPDF(state);
              }}
              className="bg-green-300 px-2 py-1 rounded border border-green-700 hover:bg-green-500 transition-all"
            >
              Get Documents!
            </button>
          </div>
          {/* <div>
            <pre>{JSON.stringify(state, null, 2)}</pre>
          </div> */}
        </form>
      </div>
    </PageLayout>
  );
};

async function fillPDF(data) {
  const baseUrl =
    window.location.origin.toString() + process.env.PUBLIC_URL + "/";

  const mergeDoc = await PDFDocument.create();

  for (const pdf of Object.values(data.docs)) {
    const formUrl = baseUrl + pdf.url;

    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const form = pdfDoc.getForm();
    Object.entries(pdf.fields).forEach(([fieldName, f]) => {
      //   console.log({ fieldName, value: data[f] });
      setField(fieldName, data[f], form);
    });

    form.flatten();
    await pdfDoc.save();

    const coppiedTempPages = await mergeDoc.copyPages(
      pdfDoc,
      pdfDoc.getPageIndices()
    );

    coppiedTempPages.forEach((page) => mergeDoc.addPage(page));
  }

  const now = new Date();

  return download(
    await mergeDoc.save(),
    `DOCs ${data.fullName} ${data.refNumber} ${data.jobType} ${getFormattedDate(
      now,
      " "
    )}.pdf`,
    "application/pdf"
  );
}

const setField = (pdfField, value, form, condition = false) => {
  if (!pdfField || !value || condition) return;
  if (Array.isArray(value)) value = value.join(", ");
  const f = form.getTextField(pdfField);
  if (!f) return;
  return f.setText(value.toString());
};

function getFormattedDate(date, separator = "/") {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return month + separator + day + separator + year;
}
