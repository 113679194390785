import React from "react";
import { MdClear, MdPhoto } from "react-icons/md";
import cuid from "cuid";
import { getFileDownloadUrl, uploadFileToFirebaseStorage } from "../../../app/firestore/firebaseService";
import { addProfilePhoto } from "../../../app/firestore/firestoreService";

export const UserAvatar = ({ user = {}, ...props }) => {
  const [uploading, setUploading] = React.useState(null);
  const [cancelUpload, setCancelUpload] = React.useState(null);

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = async (event, user) => {
    const fileUploaded = event.target.files[0];
    const f = await handleFile(fileUploaded, user, setUploading, setCancelUpload);
    console.log(f);
  };

  return (
    <>
      <div
        onClick={handleClick}
        style={{ backgroundImage: `url(${user?.photoURL})` }}
        className=" relative bg-cover group bg-center w-24 h-24 border-2 m-2 flex items-center justify-center bg-gray-500 hover:bg-black hover:bg-opacity-10 rounded-xl cursor-pointer text-gray-500"
      >
        {uploading && (
          <span onClick={() => cancelUpload?.fn()} className="text-xs text-white bg-black bg-opacity-60 absolute top-0 left-0 w-full">
            Cancel <MdClear />
          </span>
        )}
        {uploading && (
          <span className="text-xs text-white bg-black bg-opacity-60 absolute bottom-0 left-0 w-full">Uploading {uploading} %</span>
        )}
        <span
          className={`${
            user?.photoURL ? "invisible" : ""
          } group-hover:visible bg-white w-full h-full flex justify-center items-center bg-opacity-50 transition-all`}
        >
          <MdPhoto />
        </span>
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={(e) => handleChange(e, user)}
        style={{ display: "none" }}
        accept=".jpg,.jpeg,.png"
        disabled={!!uploading}
      />
    </>
  );
};

async function handleFile(file, user, setUploading, setCancelUpload) {
  console.log(user);
  return new Promise(async function (resolve, reject) {
    if (!user?.id) return reject("No user ID");
    const fileId = cuid();
    setUploading(1);
    const storagePath = `users/${user.id}/photos`;
    const docPath = `users/${user.id}/photos/${fileId}`;

    const uploadTask = uploadFileToFirebaseStorage({
      file,
      fileId,
      storagePath,
      docPath,
    });
    setCancelUpload({
      fn: () => {
        uploadTask.cancel();
        setUploading(null);
      },
    });

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (progress) setUploading(round(progress, 0));
      },
      (error) => {
        reject(error);
      },
      async () => {
        console.log(`${file.name} uploaded.`);
        const downloadURL = await getFileDownloadUrl(uploadTask.snapshot.ref);
        await addProfilePhoto(user.id, fileId, file.name, downloadURL);
        setUploading(null);
        return resolve({
          id: fileId,
          fileName: file.name,
          size: file.size,
          type: file.type,
        });
      }
    );
  });
}

const round = (value, decimals = 2) => {
  if (!value) throw new Error("Must include value to be rounded");
  if (!(typeof value === "string" || typeof value === "number")) throw new Error("Value must me a number or string");
  if (!(typeof decimals === "string" || typeof decimals === "number")) throw new Error("Decimals [2nd arg] must me a number or string");
  if (isNaN(value) || isNaN(decimals)) throw new Error("Args need to be numbers");
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};
