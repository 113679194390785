import download from "downloadjs";
import { PDFDocument } from "pdf-lib";
import React, { useReducer } from "react";
import { SignatureBlock } from "../../../app/common/form/SignatureBlock";
import { getFormattedDate } from "../../../app/common/util/helperFunctions";
import { signedBOL } from "../../../app/firestore/firestoreService";

import { arnasSignature } from "./arnasSignature";

const bol1 = "TNT - Dublish.pdf";
// const bol2 = "MovePros - Shyegyebai.pdf";

const reducer = (state, { payload }) => {
  if (!payload) return state;
  return { ...state, ...payload };
};

const initialState = {
  date: getFormattedDate(new Date()),
  clientSignature: arnasSignature,
  crewSignature: arnasSignature,
};

export const BOL = ({ match }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const pdfName = match.params.pdfName || bol1;

  const getPDF = async (e) => {
    signedBOL({ fileName: pdfName, date: getFormattedDate(new Date()), ...state });

    download(await bolFill(pdfName, state), `${pdfName} signed ${getFormattedDate(new Date())}.pdf`, "application/pdf");
  };

  const sigDispatch = ({ field, value }) => {
    dispatch({ payload: { [field]: value } });
  };

  return (
    <div className="bg-white rounded-md border p-5 text-xl">
      <div className="flex justify-center py-10 text-3xl">
        <a href={`/pdf/sailor/${pdfName}`} target="_blank" rel="noopener noreferrer">
          {pdfName}
        </a>
      </div>
      <div className="bg-pink-300 p-2 rounded-lg text-3xl">
        <span> Chris Adams Signature </span>
        <SignatureBlock data={state.crewSignature} dispatch={sigDispatch} type="signature" name="crewSignature" />
      </div>
      <div className="bg-green-300 p-2 rounded-lg text-3xl">
        <span> Shipper Signature </span>
        <SignatureBlock data={state.clientSignature} dispatch={sigDispatch} type="signature" name="clientSignature" />
      </div>
      <div
        className="flex justify-center my-4 bg-green-400 rounded-sm shadow-md py-4 transition-all hover:bg-green-300 cursor-pointer text-white"
        onClick={getPDF}
      >
        <span>Download </span>
      </div>
    </div>
  );
};

export async function bolFill(pdfName, data) {
  const pdf = `pdf/sailor/${pdfName}`;
  const baseUrl = window.location.origin.toString() + process.env.PUBLIC_URL + "/";
  const formUrl = baseUrl + pdf;
  const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(formPdfBytes);

  const pages = pdfDoc.getPages();
  const form = pdfDoc.getForm();

  const { date, crewSignature: carrierSignature, clientSignature: shipperSignature } = data;

  const setSignature = async (signature, x, y, width = 25, height = 10) => {
    if (!signature || !x || !y) return;
    const bytes = await fetch(signature).then((res) => res.arrayBuffer());
    const image = await pdfDoc.embedPng(bytes);
    return pages[0].drawImage(image, { x, y, height, width });
  };

  const setTextField = ({ text, x, y, size = 10, page = 0 }) => {
    if (!text || !x || !y) return;
    console.log("setting trext");
    return pages[page].drawText(text, { x, y, size });
  };

  await setSignature(shipperSignature, 72, 163, 180, 36);
  await setTextField({ text: date, x: 230, y: 180 });
  await setSignature(carrierSignature, 310, 163, 180, 36);
  await setTextField({ text: date, x: 460, y: 180 });

  form.flatten();
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
}
