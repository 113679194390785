import { Input } from "../../app/common/form/Input";
import { MdEmojiPeople, MdEmail, MdPhone } from "react-icons/md";

import { useMove, useMoveDispatch } from "./MoveProvider";
import { SectionTitle } from "../../app/layout/SectionTitle";

export const ContactInfo = (props) => {
  const move = useMove();
  const dispatch = useMoveDispatch();
  const { fullName, phoneNumber, email } = move;

  const onChange = (e) =>
    dispatch({ field: e.target.name, value: e.target.value });
  return (
    <>
      <SectionTitle title="Contact Info" hidePlus={true} />
      <Input
        name="fullName"
        value={fullName}
        onChange={onChange}
        Icon={MdEmojiPeople}
        placeholder="Full Name"
        label="Full Name"
      />
      <Input
        name="phoneNumber"
        value={phoneNumber}
        onChange={onChange}
        Icon={MdPhone}
        placeholder="Phone Number"
        inputMode="numeric"
        type="tel"
        label="Phone Number"
      />
      <Input
        name="email"
        value={email}
        onChange={onChange}
        Icon={MdEmail}
        placeholder="Email"
        type="email"
        label="Email"
      />
    </>
  );
};

// TODO name parse field
// TODO phone number field show
// TODO phone number mask
// TODO add 2nd contact
// TODO add autocomplete @ email
