import React, { useState, useRef, useCallback, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import cuid from "cuid";
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Menu, Transition } from "@headlessui/react";
// import { FieldsList } from "./FieldsList";
// import { PacketList } from "./PacketList";
// import { PreviewPDF } from "./PreviewPDF";
// import { fillPDF } from "../../utils/fillPDF";
// import { nanoid } from "nanoid";
// import { HTML5Backend } from "react-dnd-html5-backend";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";
import { DndProvider, useDrop, useDrag } from "react-dnd";
import update from "immutability-helper";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";
import { getPdfprep, updatePDF } from "../../../app/firestore/firestoreService";
import { listenToPDF } from "../settingsActions";

import debounce from "lodash.debounce";
import {
  MdAdd,
  MdBusiness,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdClear,
  MdDirectionsCar,
  MdDoNotTouch,
  MdFullscreen,
  MdInventory2,
  MdKeyboardArrowDown,
  MdPanTool,
  MdPerson,
  MdPictureAsPdf,
  MdRemoveCircleOutline,
  MdRoom,
  MdSave,
  MdSearch,
  MdTableView,
  MdVisibility,
  MdVisibilityOff,
  MdWarning,
  MdZoomIn,
  MdZoomOut,
} from "react-icons/md";
import { FieldIcon } from "../../../app/common/util/FieldIcon";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const PdfPrep = ({ match, ...props }) => {
  const dispatch = useDispatch();
  const { pdf } = useSelector((state) => state.settings);

  const [focusField, setFocusField] = useState({});

  useFirestoreDoc({
    query: () => getPdfprep(match.params.pdfId),
    data: (pdf) => dispatch(listenToPDF(pdf)),
    deps: [dispatch, match.params.pdfId],
  });

  // const [value, setValue] = useState("initialState");

  // const handleOnChange = (e) => {
  //   setValue(e.target.value);
  // };

  if (!pdf)
    return <div>Loading {match.params.fileName || match.params.pdfID}</div>;

  return (
    <>
      {/* <div className="bg-red-500 h-20 mt-10">
        <div>{value}</div>
        <DebouncedInput value={value} onChange={handleOnChange} />
      </div> */}
      <PreviewPDF
        pdf={pdf}
        fields={pdf.formFields && pdf.formFields.length > 0 && pdf.formFields}
        focusField={focusField}
        setFocusField={setFocusField}
      />
    </>
  );
};

const FieldValues = ({ field }) => {
  const styleClasses = "bg-gray-50 rounded-md px-2 py-1 hover:bg-blue-100";

  switch (field.type) {
    case "PDFButton":
      return (
        <button className={styleClasses} key={field.name}>
          {field.name}
        </button>
      );
    case "PDFCheckBox":
      return (
        <input
          className={styleClasses}
          type="checkbox"
          label={field.name}
          key={field.name}
        />
      );
    case "PDFDropdown":
    case "PDFOptionList":
      return (
        <select
          placeholder="Select Friend"
          className={styleClasses}
          // options={dropDownOptions}
          key={field.name}
        >
          {field.options.map((option, ii) => (
            <option key={ii}>{option}</option>
          ))}
          <option></option>
        </select>
      );
    case "PDFRadioGroup":
      return (
        <input
          type="radio"
          key={field.name}
          name={field.groupName}
          className={styleClasses}
        />
      );

    case "PDFSignature":
    default:
      return (
        <input
          className={styleClasses}
          key={field.name}
          // defaultValue={`${f.name}, ${f.x}, ${f.y}`}
          tabIndex={field.tabIndex || 0}
          defaultValue={field.defaultValue}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
        />
      );
  }
};

const styles = {
  width: "612px",
  height: "792px",
};

// const fieldExample = {
//   id: "234dss",
//   place: [
//     {
//       pdfId: "asdvxzcvzxcv",
//       pageNum: 1,
//       x: 0,
//       y: 0,
//     },
//   ],
//   name: "Full Name",
//   value: "Arnas Jelizarovas",
// };

// const urls = ({ path, url }) => ({
//   current: path,
//   redirect: `${url}/client`,

//   ...Object.fromEntries(
//     ["type", "contact", "prepdocs", "schedule", "assign", "overview"].map((u) => [u, `${path}/${u}`])
//   ),
// });

export const Paramount = () => {
  const [pdf] = useState(null);
  // const [fields, setFields] = useState([fieldExample]);
  const [boxes, setBoxes] = useState({
    a: { top: 20, left: 80, title: "Drag me around" },
    b: { top: 180, left: 20, title: "Drag me too" },
  });
  // const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  // const getCoordinates = (e) => {
  //   console.log("getcoordinates");
  //   var rect = e.target.getBoundingClientRect();
  //   var x = e.clientX - rect.left; //x position within the element.
  //   var y = e.clientY - rect.top; //y position within the element.
  //   setCoordinates({ x: x.toFixed(2), y: -(y - 792).toFixed(2) });
  //   // console.log("Left? : " + x + " ; Top? : " + -(y - 792) + ".");
  // };

  //   const pdfize = async (e) => {
  //     if (!!pdf) {
  //       const newPdf = await fillPDF({ url: pdf?.url });
  //       setPdf((file) => ({ ...file, data: newPdf }));
  //     }
  //   };

  return (
    <div className="flex  justify-around items-start ">
      {/* <TaskList /> */}
      <div>
        {/* <PacketList pdf={pdf} setPdf={setPdf} />
        <button onClick={pdfize} className="bg-purple-700 text-white p-2 rounded-md">
          PDFize
        </button> */}
        <button
          onClick={() => {
            console.log(pdf);
          }}
          className="bg-purple-700 text-white p-2 rounded-md"
        >
          clg
        </button>
      </div>
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        {/* <FieldsList /> */}
        {/* <div className="flex">{`x: ${coordinates.x}, y: ${coordinates.y}`}</div> */}
        {/* <Dropzone style={styles} className="bg-red-500" allowedDropEffect="move" onClick={getCoordinates}>
          DROPZONE */}
        {/* </Dropzone> */}
        {/* <GlobalBox /> */}
        <LocalBox pdf={pdf} boxes={boxes} setBoxes={setBoxes} />
      </DndProvider>
      {/* <PreviewPDF /> */}
    </div>
  );
};

// const style = {
//   minWidth: "612px",
//   minHeight: "792px",
// };

// function selectBackgroundColor(isActive, canDrop) {
//   if (isActive) {
//     return "darkgreen";
//   } else if (canDrop) {
//     return "darkkhaki";
//   } else {
//     return "#222";
//   }
// }
export const Dropzone = (props) => {
  // const ref = useLocalDrop(console.log);
  // const ref = useRef();
  // const { allowedDropEffect } = props;
  // const [{ canDrop, isOver }, drop] = useDrop(
  //   () => ({
  //     accept: "box",
  //     drop(item, monitor) {
  //       console.log("drop");
  //       const offset = monitor.getSourceClientOffset();
  //       if (offset && ref.current) {
  //         const dropTargetXy = ref.current.getBoundingClientRect();
  //         console.log("local", {
  //           x: offset.x - dropTargetXy.left,
  //           y: offset.y - dropTargetXy.top,
  //         });
  //       }
  //     },
  //     collect: (monitor) => ({
  //       isOver: monitor.isOver(),
  //       canDrop: monitor.canDrop(),
  //     }),
  //   }),
  //   [allowedDropEffect]
  // );
  // const isActive = canDrop && isOver;
  // const backgroundColor = selectBackgroundColor(isActive, canDrop);
  return <div {...props}>Dropzone</div>;
};

// function GlobalBox() {
//   const ref = useGlobalDrop(console.log);
//   return <div ref={ref} className="GlobalBox" />;
// }

function LocalBox({ pdf, boxes, setBoxes }) {
  const moveBox = useCallback(
    (id, left, top) => {
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left, top },
          },
        })
      );
    },
    [boxes, setBoxes]
  );

  const handleDelete = (id) => {
    return (e) => {
      setBoxes((boxes) => {
        console.log(boxes[id]);
        return boxes;
      });
    };
  };

  const ref = useLocalDrop(moveBox, setBoxes);
  return (
    <div ref={ref} className="LocalBox bg-purple-900 relative" style={styles}>
      <PreviewPDF pdf={pdf} />
      {Object.keys(boxes).map((key) => {
        const { left, top, title } = boxes[key];
        return (
          <Box
            key={key}
            id={key}
            left={left}
            top={top}
            hideSourceOnDrag={true}
            handleDelete={handleDelete}
          >
            {title}
          </Box>
        );
      })}
    </div>
  );
}

const Box = ({
  id,
  left,
  top,
  hideSourceOnDrag = true,
  children,
  handleDelete,
}) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "box",
      item: { id, left, top },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top]
  );

  if (isDragging && hideSourceOnDrag) {
    return <div ref={drag}>t</div>;
  }

  return (
    <button
      ref={drag}
      className="absolute bg-green-500 text-white text-xs px-1 rounded-sm cursor-pointer"
      style={{ left, top }}
      // role="Box"
    >
      <span>
        {children} [{left.toFixed(1)}, {(792 - Number(top)).toFixed(1)}]
      </span>
      <span className="m-1 hover:text-gray-300" onClick={handleDelete(id)}>
        <MdClear className="p-1" />
      </span>
    </button>
  );
};

function useLocalDrop(moveBox, setBoxes) {
  const ref = useRef();

  const [, dropTarget] = useDrop({
    accept: ["box", "field"],
    drop(item, monitor) {
      console.log();
      if (monitor.getItemType() === "field") {
        const offset = monitor.getSourceClientOffset();
        if (offset && ref.current) {
          const dropTargetXy = ref.current.getBoundingClientRect();
          const x = offset.x - dropTargetXy.left;
          const y = offset.y - dropTargetXy.top;

          setBoxes((b) => ({
            ...b,
            [cuid(6)]: { title: item.name, fieldId: item.id, left: x, top: y },
          }));
          return undefined;
        }
      }
      if (monitor.getItemType() === "box") {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.id, left, top);
        return undefined;
      }
    },
  });

  return (elem) => {
    ref.current = elem;
    dropTarget(ref);
  };
}

// function useGlobalDrop(onDrop) {
//   const [, dropTarget] = useDrop({
//     accept: "box",
//     drop(item, monitor) {
//       const offset = monitor.getClientOffset();
//       if (offset) {
//         onDrop("global", offset);
//       }
//     },
//   });

//   return dropTarget;
// }

// const TaskList = () => {
//   // const [links] = useState(urls(useRouteMatch()));

//   return (
//     <div>
//       <h1>Task List</h1>
//       <ul>
//         <TaskLink to="type" text="Job Type" />

//         <TaskLink to="contact" text="Personal Info" />

//         <TaskLink to="prep" text="Prepare Paperwork" />

//         <TaskLink to="schedule" text="Schedule" />

//         <TaskLink to="assign" text="Assign Crew" />
//       </ul>
//     </div>
//   );
// };

export const PreviewPDF = memo(({ pdf, fields } = {}) => {
  const [panDisabled, setPanDisabled] = useState(false);
  const [filterFields, setFilterFields] = useState("");
  const [pageDimensions, setPageDimensions] = useState({});
  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [focusField, setFocusField] = useState("");
  const getCoordinates = (e) => {
    var rect = e.target.getBoundingClientRect();
    var x = ((e.clientX - rect.left) / rect.width) * pageDimensions.pw; //x position within the element.
    var y = ((rect.bottom - e.clientY) / rect.height) * pageDimensions.ph; //y position within the element.
    setCoordinates({ x: x.toFixed(2), y: y.toFixed(2) });
  };

  function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf.numPages);
  }
  function onRenderSuccess(page) {
    updatePageDimmensions(page);
  }

  function updatePageDimmensions(page) {
    setPageDimensions({
      w: page.width,
      h: page.height,
      ph: page.originalHeight,
      pw: page.originalWidth,
    });
  }

  function savePDF() {
    updatePDF(pdf);
  }

  return (
    <>
      {/* <TransformWrapper
        limitToBounds={true}
        minScale={0.5}
        initialScale={0.9}
        panning={{ excluded: ["iii"] }}
        doubleClick={{ excluded: ["iii"] }}
        pinch={{ excluded: ["iii"] }}
        centerOnInit={true}
        centerZoomedOut={true}
        disabled={panDisabled}
      >
        {({ zoomIn, zoomOut, resetTransform, zoomToElement, ...rest }) => (
          <> */}
      <div className="flex  justify-around items-start h-screen">
        <div className="bg-white  overflow-y-scroll overscroll-x-none p-4 h-full relative w-1/2">
          <span
            onClick={savePDF}
            className="absolute right-2 hover:text-yellow-500 cursor-pointer"
          >
            <MdSave />
          </span>
          <h2>
            {pdf.thumbs && pdf.thumbs[0] ? (
              <img alt="thumb" src={pdf.thumbs[0].url} />
            ) : (
              <MdPictureAsPdf />
            )}
            <div>
              {pdf.fileName}
              <div>
                <div>
                  <div link as={Link} to="/settings/pdfs/">
                    PDFs
                  </div>
                  <hr />
                  <div active>{pdf.id || " file"} </div>
                </div>
              </div>
            </div>
          </h2>
          <Entities entities={pdf.entities} />
          <FieldsTable
            entities={pdf.entities}
            formFields={pdf.formFields}
            filterFields={filterFields}
            setFilterFields={setFilterFields}
            setPageNumber={setPageNumber}
            setFocusField={setFocusField}
            pageNumber={pageNumber}
            // zoomToElement={zoomToElement}
            // resetTransform={resetTransform}
          />
        </div>
        <div className="bg-gray-300  w-1/2" ref={componentRef}>
          <PDFtoolbar
            // resetTransform={resetTransform}
            // zoomOut={zoomOut}
            // zoomIn={zoomIn}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            numPages={numPages}
            setPanDisabled={setPanDisabled}
            panDisabled={panDisabled}
          />
          {/* <TransformComponent className="relative"> */}
          <Document
            file={
              !!pdf?.data
                ? { data: pdf.data }
                : pdf?.clearFormFile?.url || pdf?.url
            }
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode="canvas"
            onClick={getCoordinates}
            onLoadError={console.error}
            className="w-full relative"
            error={
              "Unable to load the library article. Please reach out to the support for further assistance."
            }
          >
            <div className="relative ">
              <FormFields
                coordinates={coordinates}
                pageNumber={pageNumber}
                fields={fields}
                pageDimensions={pageDimensions}
                focusField={focusField}
              />
              <Page
                onLoadSuccess={updatePageDimmensions}
                scale={1.0}
                width={width}
                pageNumber={pageNumber}
                onRenderSuccess={onRenderSuccess}
              />
            </div>
          </Document>
          {/* </TransformComponent> */}
        </div>
      </div>
      {/* </>
        )}
      </TransformWrapper> */}
    </>
  );
});

const PDFtoolbar = ({
  resetTransform,
  zoomOut,
  zoomIn,
  pageNumber,
  setPageNumber,
  numPages,
  coordinates,
  setPanDisabled,
  panDisabled,
}) => {
  return (
    <div className="toolbar flex justify-between items-center bg-gray-700 p-2 text-white">
      <div>
        <button
          className="px-4 py-2 hover:bg-gray-200 hover:bg-opacity-20 rounded-md"
          onClick={() => resetTransform()}
        >
          <MdFullscreen />
        </button>
        <button
          className="px-4 py-2 hover:bg-gray-200 hover:bg-opacity-20 rounded-md"
          onClick={() => zoomOut()}
        >
          <MdZoomOut />
        </button>
        <button
          className="px-4 py-2 hover:bg-gray-200 hover:bg-opacity-20 rounded-md"
          onClick={() => zoomIn()}
        >
          <MdZoomIn />
        </button>
        <button
          className="px-4 py-2 hover:bg-gray-200 hover:bg-opacity-20 rounded-md"
          onClick={() => setPanDisabled(!panDisabled)}
        >
          {!panDisabled ? <MdPanTool /> : <MdDoNotTouch />}
        </button>
      </div>
      <div className="flex justify-center items-center text-md">
        <button
          className="m-0 p-0"
          disabled={pageNumber === 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          <MdRemoveCircleOutline />
        </button>
        <div className=" p-0 m-0">
          <input
            className="w-6 text-right bg-transparent align-end"
            value={pageNumber.toString()}
            type="text"
            onChange={(e) =>
              e.target.value >= 1 &&
              e.target.value <= numPages &&
              setPageNumber(Number(e.target.value))
            }
          />
          <span className="pr-4">{`/ ${numPages}`}</span>
        </div>
        <button
          disabled={pageNumber === numPages}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          <MdAdd />
        </button>
      </div>
      <div></div>
    </div>
  );
};

const getDimensions = (ref = {}) => ({
  width: ref.current.offsetWidth,
  height: ref.current.offsetHeight,
});

export const useContainerDimensions = (ref) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    function handleResize() {
      setDimensions(getDimensions(ref));
    }

    if (ref.current) {
      setDimensions(getDimensions(ref));
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref]);

  return dimensions;
};

const FormFields = ({
  fields = [],
  pageNumber = 1,
  pageDimensions,
  coordinates,
  focusField,
  ...props
}) => {
  const [pageFields, setpageFields] = useState([]);

  useEffect(() => {
    if (fields.length > 0) {
      console.log("formFields recalculated");
      setpageFields(
        fields.reduce((a, f) => {
          if (!f.fields) return a;
          for (let i of f.fields) {
            if (i.pageIndex === pageNumber - 1) {
              const { fields, ...rest } = f;
              a.push({ ...i, ...rest });
            }
          }

          return a;
        }, [])
      );
    }
    return () => {
      setpageFields([]);
    };
  }, [pageNumber, fields]);
  // console.log(pageFields);
  const { pw, ph, w, h } = pageDimensions;

  return (
    <div
      {...props}
      className="absolute w-full h-full    top-0 lef-0 z-10"
      style={{ height: h }}
    >
      <div className="relative w-full h-full  ">
        <h1 className="absolute left-1/3 bg-gray-700 text-white p-2 rounded-md top-2 bg-opacity-50">{`coordinate: x: ${coordinates.x}, y: ${coordinates.y}.`}</h1>
        {pageFields.length > 0 &&
          pageFields.map((f, i) => {
            const isFocused = focusField === f.name;

            const position = {
              bottom: (w / pw) * (Number(f.y) - Number(f.h)) || 0,
              left: (h / ph) * Number(f.x) || 0,
              width: (w / pw) * Number(f.w) || 0,
              height: (h / ph) * Number(f.h) || 0,
            };

            const classes = `iii absolute bg-${
              isFocused ? "pink" : "blue"
            }-200 bg-opacity-90  border-1 border-${
              isFocused ? "pink-500" : "blue-500"
            } overflow-hidden truncate w-64 hover:z-50 hover:bg-blue-300 text-xs`;

            // if (f.type === "PDFTextField") {
            switch (f.type) {
              case "PDFButton":
                return (
                  <button
                    id={`${f.name} p${f.pageIndex + 1}`}
                    key={f.name + i}
                    className={classes}
                    style={position}
                  >
                    {f.name}
                  </button>
                );
              case "PDFCheckBox":
                return (
                  <input
                    id={`${f.name} p${f.pageIndex + 1}`}
                    type="checkbox"
                    label={f.name}
                    key={f.name + i}
                    className={classes}
                    style={position}
                  />
                );
              case "PDFDropdown":
              case "PDFOptionList":
                return (
                  <select
                    id={`${f.name} p${f.pageIndex + 1}`}
                    placeholder="Select Friend"
                    // options={dropDownOptions}
                    key={f.name + i}
                    className={classes}
                    style={position}
                  >
                    {f.options.map((option, ii) => (
                      <option key={ii}>{option}</option>
                    ))}
                    <option></option>
                  </select>
                );
              case "PDFRadioGroup":
                return (
                  <input
                    id={`${f.name} p${f.pageIndex + 1}`}
                    type="radio"
                    key={f.name + i}
                    className={classes}
                    style={position}
                    name={f.groupName}
                  />
                );

              case "PDFSignature":
              default:
                return (
                  <input
                    id={`${f.name} p${f.pageIndex + 1}`}
                    key={f.name + i}
                    className={classes}
                    style={position}
                    // defaultValue={`${f.name}, ${f.x}, ${f.y}`}
                    tabIndex={f.tabIndex || 0}
                    defaultValue={f.defaultValue}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.target.blur();
                      }
                    }}
                  />
                );
            }
          })}
      </div>
    </div>
  );
};

const FieldsTable = ({
  formFields,
  filterFields,
  setFilterFields,
  setPageNumber,
  setFocusField,
  pageNumber,
  zoomToElement,
  resetTransform,
  entities,
}) => {
  const dispatch = useDispatch();
  const [selectedFields, setSelectedFields] = useState([]);
  const [showOnlySelectedFields, setShowOnlySelectedFields] = useState(false);
  function toggleFieldSelection(fieldName) {
    return function (event) {
      if (selectedFields.includes(fieldName)) {
        setSelectedFields(selectedFields.filter((f) => f !== fieldName));
      } else {
        setSelectedFields([...selectedFields, fieldName]);
      }
    };
  }

  function toggleShowSelected() {
    setShowOnlySelectedFields(!showOnlySelectedFields);
  }

  function toggleFieldVisibility(fieldName) {
    return function (event) {
      event.stopPropagation();
      dispatch({ type: "UPDATE_FIELD_VISIBILITY", payload: fieldName });
    };
  }

  function selectEntity(field) {
    return function (entity) {
      dispatch({ type: "UPDATE_FIELD_ENTITY", payload: { field, entity } });
    };
  }

  if (!formFields) return <div>No form Fields</div>;

  return (
    <>
      <div className="toolbar w-full bg-gray-500 text-white rounded-t-md px-4 py-0 flex items-center">
        <div className="px-4 py-2 border border-gray-700 rounded-md text-black bg-white bg-opacity-50 hover:bg-opacity-70">
          <input
            placeholder="Filter Fields..."
            type="text"
            className="bg-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            value={filterFields}
            onChange={(e) => setFilterFields(e.target.value)}
          />
          {filterFields.length > 0 ? (
            <MdClear
              onClick={() => setFilterFields("")}
              className="cursor-pointer"
            />
          ) : (
            <MdSearch
              onClick={() => setFilterFields("")}
              className="cursor-pointer"
            />
          )}
        </div>

        <button
          className="px-4 py-2 rounded-md  m-2 "
          onClick={toggleShowSelected}
        >
          Show only selected
          {showOnlySelectedFields ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
        </button>
        <DropDown
          className="px-4 py-2 rounded-md  m-2 "
          label="Mark Selected As..."
          options={[
            {
              name: "visible",
              label: "Visible",
              icon: "eye",
            },
            {
              name: "hidden",
              label: "Hidden",
              icon: "eye slash",
            },
            {
              name: "setFormComponent",
              label: "Set Form Component",
              icon: "file alternate outline",
              action: function () {
                alert("Setting form components");
              },
            },
          ]}
        />
      </div>

      <table>
        <thead>
          <tr>
            <th>
              <MdCheckBoxOutlineBlank />
              <MdKeyboardArrowDown />
            </th>
            <th>Field</th>
            {entities && entities.length > 0 && <th>Entity</th>}
            <th>Shared Name</th>
            <th>Form Component</th>
            <th>Default Value</th>
            <th>Visibility</th>
            <th>Coordinates</th>
          </tr>
        </thead>

        <tbody className="select-none">
          {formFields
            .filter(
              (ff) =>
                ff.name.includes(filterFields) || ff.type.includes(filterFields)
            )
            .filter((ff) =>
              showOnlySelectedFields ? selectedFields.includes(ff.name) : true
            )
            .map((field, i) => (
              <tr
                key={i}
                className={`hover:bg-red-50 ${
                  selectedFields.includes(field.name) &&
                  "bg-indigo-50 hover:bg-indigo-200"
                } `}
              >
                <td onClick={toggleFieldSelection(field.name)}>
                  {selectedFields.includes(field.name) ? (
                    <MdCheckBox />
                  ) : (
                    <MdCheckBoxOutlineBlank />
                  )}
                </td>

                <td>
                  <h4>
                    <FieldIcon fieldType={field.type} />
                    <div>
                      {field.name}
                      <div className="text-xs">{field.type}</div>
                    </div>
                  </h4>
                </td>

                {entities && entities.length > 0 && (
                  <td>
                    <EntitySelect
                      entities={entities}
                      selected={field.entity}
                      selectEntity={selectEntity(field)}
                    />
                  </td>
                )}
                <td>
                  <input
                    className="bg-gray-50 rounded-md px-2"
                    defaultValue={
                      isCamelCase(field.name)
                        ? camelToSnakeCase(field.name)
                        : toSnakeCase(field.name)
                    }
                  />
                </td>

                <td>
                  <span className="px-2 py-1 bg-gray-50 rounded-md text-xs">
                    Default Component <MdKeyboardArrowDown />
                  </span>
                </td>
                <td>
                  <FieldValues field={field} />
                </td>
                <td onClick={toggleFieldVisibility(field.name)}>
                  {field.isVisible ? <MdVisibilityOff /> : <MdVisibility />}
                </td>
                <td>
                  <div className="flex flex-col ">
                    {field.fields &&
                      field.fields.length > 0 &&
                      field.fields.map((f, i) => (
                        <span
                          className="border-1 border-black text-xs px-2 mb-1 whitespace-nowrap hover:bg-red-200 cursor-pointer"
                          key={i}
                          onClick={(e) => {
                            e.stopPropagation();
                            setPageNumber(f.pageIndex + 1);
                            setFocusField(field.name);
                            if (pageNumber === f.pageIndex + 1) {
                              // {f.name + "+i" + i + "+p" + (f.pageIndex + 1)}
                              zoomToElement(
                                `${field.name} p${f.pageIndex + 1}`,
                                1.8
                              );
                            } else {
                              resetTransform();
                              setTimeout(() => {
                                zoomToElement(
                                  `${field.name} p${f.pageIndex + 1}`,
                                  1.8
                                );
                              }, 1000);
                            }
                          }}
                        >
                          {f.x} x {f.y} [{f.pageIndex + 1}]
                        </span>
                      ))}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

const Entities = ({ entities = [] }) => {
  const dispatch = useDispatch();
  const [showEntities, setShowEntities] = useState(true);

  function addEntity(e) {
    return function (event) {
      dispatch({ type: "PDF_ENTITY_ADD", payload: { ...e, id: cuid() } });
    };
  }

  function removeEntity(id) {
    return function (event) {
      dispatch({ type: "PDF_ENTITY_REMOVE", payload: id });
    };
  }

  const entityTypes = [
    { label: "Company", icon: "building", Icon: MdBusiness },
    { label: "Client", icon: "user", Icon: MdPerson },
    { label: "Vehicle", icon: "car", Icon: MdDirectionsCar },
    { label: "Table", icon: "table", Icon: MdTableView },
    { label: "Project", icon: "archive", Icon: MdInventory2 },
  ];

  return (
    <div>
      <button
        className="px-4 py-2 rounded-md bg-indigo-600 text-white"
        onClick={() => setShowEntities(!showEntities)}
      >
        Entities {showEntities ? <MdRoom /> : <MdRoom />}
        <MdKeyboardArrowDown />
      </button>

      {showEntities && (
        <div className="">
          Entities List
          <div className="flex flex-col">
            <ul className="mb-4">
              {entityTypes.map((t) => (
                <li
                  key={t.label}
                  className="px-2 py-1 hover:bg-indigo-200 rounded-md cursor-pointer"
                  onClick={addEntity(t)}
                >
                  <MdAdd /> <t.Icon />
                  {t.label}
                </li>
              ))}
              <li className="px-2 py-1 text-center text-xs " disabled>
                Load More
              </li>
            </ul>
            <div className="px-2 py-1 text-lg">
              This document contains the following entities
            </div>
            <div className="flex flex-wrap flex-col w-full">
              {entities.map((e, i) => (
                <Entity key={i} entity={e} removeEntity={removeEntity} i={i} />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Entity = ({ entity, removeEntity }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const [searchGroups, setSearchGroups] = useState("");
  const [fieldGroup, setFieldGroup] = useState([]);

  const [, setIsMax] = useState(false);

  const handleOnChange = (e) => {
    dispatch({
      type: "PDF_ENTITY_UPDATE",
      payload: { id: entity.id, [e.target.name]: e.target.value },
    });
  };

  return (
    <div className="w-full pl-4 pr-8 py-1 mb-1 rounded-sm bg-gray-300 hover:bg-indigo-200 relative">
      <MdClear
        className="ml-2 cursor-pointer absolute right-0"
        onClick={removeEntity(entity.id)}
      />
      {show ? (
        <>
          <MdKeyboardArrowDown
            className="cursor-pointer"
            onClick={() => setShow(!show)}
          />
          <entity.Icon /> {entity.label}
          <div className="flex flex-col mb-2">
            <label htmlFor="alias">Entity Name</label>
            <input
              name="name"
              value={entity.name || ""}
              onChange={handleOnChange}
              className="px-4 py-2 rounded-md"
              type="text"
            />{" "}
          </div>
          <div className="flex">
            <div className="flex flex-col mb-2 w-1/2">
              <label htmlFor="alias">Initial Instances</label>
              <input
                name="initial"
                value={entity.initial || 1}
                onChange={handleOnChange}
                className="px-4 py-2 rounded-md"
                type="number"
              />{" "}
            </div>
            <div className="flex flex-col mb-2  w-1/2 ml-2 items-center justify-center relative">
              {entity.isMax ? (
                <>
                  <MdClear
                    onClick={() => setIsMax(false)}
                    className="absolute top-0 right-0"
                  />
                  <label htmlFor="alias">Max Instances</label>
                  <input
                    name="max"
                    value={entity.max || 1}
                    onChange={handleOnChange}
                    className="px-4 py-2 rounded-md w-full"
                    type="number"
                  />
                </>
              ) : (
                <button
                  onClick={() => setIsMax(true)}
                  className="px-4 py-2 bg-indigo-800 text-white rounded-md"
                >
                  Set Max Instances
                </button>
              )}
            </div>
          </div>
          <div>
            <ul>
              {fieldGroup.map((fg) => (
                <li>{fg}</li>
              ))}
            </ul>
          </div>
          <input
            className="px-4 py-2 rounded-md"
            type="text"
            value={searchGroups}
            onChange={(e) => setSearchGroups(e.target.value)}
          />{" "}
          <button
            onClick={() => {
              setFieldGroup([...fieldGroup, searchGroups]);
              setSearchGroups("");
            }}
            className="px-4 py-2 bg-yellow-200 rounded-md"
          >
            Add fields
          </button>
        </>
      ) : (
        <>
          <MdKeyboardArrowDown
            className="cursor-pointer"
            onClick={() => setShow(!show)}
          />
          <entity.Icon /> {entity.name || entity.label}
        </>
      )}
    </div>
  );
};

function EntitySelect({ entities = [], selected = null, selectEntity }) {
  // const [selected, setSelected] = useState(null);

  function MenuItem({ entity }) {
    return (
      <Menu.Item>
        {({ active }) => (
          <button
            onClick={() => {
              selectEntity(entity);
              // setSelected(entity);
            }}
            className={`${
              active ? "bg-indigo-300-500 text-indigo-700" : "text-gray-900"
            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
          >
            <entity.Icon className="w-5 h-5 mr-2" aria-hidden="true" />
            <span>{entity.name || entity.label}</span>
          </button>
        )}
      </Menu.Item>
    );
  }

  return (
    <div className=" ">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className=" inline-flex justify-center w-full  font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            {selected?.Icon && (
              <selected.Icon
                className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                aria-hidden="true"
              />
            )}
            <span className="truncate">
              {(selected && (selected.name || selected.label)) || "Select"}
            </span>
            <MdKeyboardArrowDown
              className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
            {entities.map((e, i) => (
              <MenuItem key={e.id || i} entity={e} />
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

const toSnakeCase = (str = "") => {
  const strArr = str.split(" ");
  const snakeArr = strArr.reduce((acc, val) => {
    return acc.concat(val.toLowerCase());
  }, []);
  return snakeArr.join("_");
};

const isCamelCase = (str = "") => /^([a-z]+)(([A-Z]([a-z]+))+)$/.test(str);
//fail on consequtive uppercase - MC ID etc... or with number (ownertype2)

const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const DropDown = ({ options = [], name, label }) => {
  const [selected, setSelected] = useState(null);

  if (options.length === 0)
    return (
      <span className="bg-yellow-100 px-2 py-1 rounded-md">
        <MdWarning />
        No options to select
      </span>
    );
  function MenuItem({ option }) {
    return (
      <Menu.Item>
        {({ active }) => (
          <button
            onClick={() => {
              if (typeof option.action === "function") {
                option.action(option);
              } else {
                setSelected(option);
              }
            }}
            className={`${
              active ? "bg-indigo-300-500 text-indigo-700" : "text-gray-900"
            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
          >
            {option?.Icon && (
              <option.Icon className="w-5 h-5 mr-2" aria-hidden="true" />
            )}

            {option.label || option.name}
          </button>
        )}
      </Menu.Item>
    );
  }

  return (
    <div className=" ">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="px-2 py-1 inline-flex justify-center w-full hover:bg-indigo-100  font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            {selected?.Icon && <selected.Icon aria-hidden="true" />}
            {(selected && (selected.label || selected.name)) ||
              label ||
              "Select"}

            <MdWarning aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
            {options.map((o, i) => (
              <MenuItem key={o.id || i} option={o} />
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export const DebouncedInput = ({
  onChange,
  value,
  timeout = 2000,
  ...props
}) => {
  const [debouncedValue, setValue] = useState(value);
  const [loading, setLoading] = useState(false);
  const handleTextChange = (e) => {
    setLoading(true);
    setValue(e.target.value);
    sendTextChange(e);
  };
  const sendTextChange = debounce((newValue) => {
    onChange(newValue);
    setLoading(false);
  }, timeout);

  return (
    <div className="w-64 relative px-2 py-1  rounded-md text-black bg-gray-50 bg-opacity-50 hover:bg-opacity-70">
      <input
        className="bg-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        {...props}
        onChange={handleTextChange}
        value={debouncedValue}
      />

      <div active={loading} size="tiny" inline className="">
        Loading
      </div>
    </div>
  );
};
