import React from "react";

import { MdAdd, MdBusiness, MdClear, MdEdit, MdEmail, MdEmojiPeople, MdPassword, MdPhone } from "react-icons/md";
import { FFInput } from "../../../app/common/form/Input";
import { addAuthToUser, addUserToFirestore, createLogin } from "../../../app/firestore/firestoreService";
import { FFCheckbox } from "../../../app/common/form/Checkbox";
import { Button } from "../../../app/common/components/Button";
import { InputGroup } from "../../../app/common/form/InputGroup";
import { UserAvatar } from "./UserAvatar";
import { Form } from "react-final-form";
import { disableUser, enableUser } from "../../../app/firestore/firebaseService";
import { toast } from "react-toastify";

//TODO replace with Md Icons
function getRoleIcon(role) {
  switch (role) {
    case "admin":
      return "king chess";
    case "manager":
      return "chess rook";
    case "warehouse":
      return "warehouse";
    case "lead":
      return "clipboard outline";
    case "mover":
      return "male";
    case "driver":
      return "truck";
    case "coordinator":
      return "user secret";
    case "surveyor":
      return "eye";
    case "freight":
      return "truck";
    default:
      return "question circle";
  }
}


const initialState = {
  displayName: "",
  email: "",
  phone: "",
  isEmployed: true,
  photoURL: "",
  position: "",
  role: {},
};

const availableRoles = [
  { value: "admin", label: "Administrator" },
  { value: "mover", label: "Mover" },
  { value: "warehouse", label: "Warehouse" },
  { value: "lead", label: "Crew Lead" },
  { value: "manager", label: "Manager" },
  { value: "freight", label: "Freight" },
  { value: "ptsManager", label: "PTS Manager" },
  { value: "coordinator", label: "Coordinator" },
  { value: "surveyor", label: "Surveyor" },
];

export const AddUser = ({ user = {}, close, ...props }) => {
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (values) => {
    console.log(JSON.stringify(values, null, 2));
    try {
      setLoading(true);
      await addUserToFirestore(values);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    close();
  };
  const validate = (values) => {
    const errors = {};
    if (!values.displayName) {
      errors.displayName = "Name is Required.";
    }

    if (values?.createAuthAccount && !values.email && !values.phone) {
      errors.phone = "Phone or Email is Required to create login account.";
      errors.email = "Phone or Email is Required to create login account.";
    }

    if (values?.createAuthAccount && values?.password?.length > 0 && values?.password?.length < 6) {
      errors.password = "Must be longer (6 characters min)";
    }
    return errors;
  };

  return (
    <Form
      initialValues={{ ...initialState, ...user }}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          {/* <pre className="text-xs">{JSON.stringify(values, null, 2)}</pre> */}
          <div className="px-4 py-2 flex  relative bg-white rounded-lg m-2 border-2" {...props}>
            {user?.id && <UserAvatar user={user} /> /*//TODO ADD PHOTO WHEN CREATING USER */}
            <div className="flex flex-col h-min transition-all ">
              <FFInput name="displayName" label="Full Name" Icon={MdEmojiPeople} placeholder="Steve Rogers..." />
              <FFInput name="email" placeholder="email@internet.com..." label="Email" Icon={MdEmail} />
              <FFInput name="phone" placeholder="(123) 456-7890" label="Phone Number" Icon={MdPhone} />
              <FFInput name="position" placeholder="Friend" label="Position" Icon={MdBusiness} />
              <FFCheckbox name="isEmployed" label="Employed?" />
              <InputGroup label="Roles">
                {availableRoles.map((role) => (
                  <FFCheckbox key={role.value} name={`role.${role.value}`} label={`${role.label}`} displayIcon={getRoleIcon(role.value)} />
                ))}
              </InputGroup>
              {!user?.id ? (
                <div>
                  <FFCheckbox name="createAuthAccount" label="Create Log In Account" />
                  {values?.createAuthAccount && <FFInput name="password" placeholder="Password" label="Password" Icon={MdPassword} />}
                </div>
              ) : (
                <div>
                  {!values?.authAccount ? (
                    <Button
                      color="blue"
                      loading={loading}
                      label="Create Login"
                      onClick={async () => {
                        setLoading(true);
                        try {
                          const data = await createLogin(values);
                          if (data.uid) await addAuthToUser(data.uid);
                          setLoading(false);
                        } catch (error) {
                          console.log(error);
                          toast(error);
                          setLoading(false);
                        }
                      }}
                    />
                  ) : (
                    <Button
                      loading={loading}
                      onClick={async () => {
                        setLoading(true);
                        if (values?.authDisabled) {
                          await enableUser({ userId: user.id });
                        } else {
                          await disableUser({ userId: user.id });
                        }
                        setLoading(false);
                      }}
                      color={values?.authDisabled ? "green" : "red"}
                      label={values?.authDisabled ? "Enable Login" : "Disable Login"}
                    />
                  )}
                </div>
              )}
              <div className="flex justify-between mt-4">
                <Button color="gray" Icon={MdClear} label="Close" onClick={() => close()} />
                <Button
                  loading={loading}
                  color="green"
                  type="submit"
                  disabled={submitting || pristine}
                  Icon={Object.keys(user).length > 0 ? MdEdit : MdAdd}
                  label={Object.keys(user).length > 0 ? "Edit User" : "Add User"}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};
