import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { paramountLinks } from "../../features/paramount/paramountRoutes";
import { useBreakpoint } from "../../app/hooks/useBreakpoint";
import useLocalStorageState from "use-local-storage-state";

import {
  MdArrowRight,
  MdCalendarToday,
  MdFolder,
  MdKeyboardArrowDown,
} from "react-icons/md";
import { RiVipDiamondFill } from "react-icons/ri";
import {
  FaBox,
  FaBoxOpen,
  FaChessKing,
  FaClipboardList,
  FaDolly,
  FaFile,
  FaFileAlt,
  FaFolder,
  FaMoneyCheck,
  FaSms,
  FaTools,
  FaTruck,
  FaTruckMoving,
  FaUsers,
  FaWarehouse,
  FaWrench,
} from "react-icons/fa";
// import { useLocalStorage } from "../hooks/useLocalStorage";

export const SideBar = ({ sidebarOpen, setSidebarOpen, ...props }) => {
  const [open, setOpen] = React.useState(sidebarOpen);

  React.useEffect(() => {
    setOpen(sidebarOpen);
  }, [sidebarOpen]);

  const { currentUserProfile } = useSelector((state) => state.profile);
  const userRole = currentUserProfile?.role || {};

  return (
    <ul
      className={`${
        open ? "block w-full md:w-64 min-w-[64]" : "hidden w-12"
      } bg-white md:flex md:flex-col list-none transition-all overflow-y-auto md:max-h-[calc(100vh_-_2.5rem)] border-r-2 border-b min-h-[256px] `}
      onMouseEnter={() => !sidebarOpen && setOpen(true)}
      onMouseLeave={() => !sidebarOpen && setOpen(false)}
    >
      {sidebarLinks.map((link, i) => (
        <GroupLink
          key={i}
          {...link}
          userRole={userRole}
          setOpen={setOpen}
          open={open}
          setSidebarOpen={setSidebarOpen}
        />
      ))}
    </ul>
  );
};

function GroupLink({
  to,
  label,
  Icon = MdFolder,
  links,
  imageIconSrc,
  role = [],
  userRole = {},
  setOpen,
  open,
  setSidebarOpen,
}) {
  if (!rolesInclude(userRole, role)) return null;

  return !!to ? (
    <li className="h-8 flex ">
      <NavLink
        to={to}
        className={`${
          open ? "px-4" : "justify-center  "
        } py-2 w-full flex items-center select-none  hover:bg-blue-500 hover:bg-opacity-10   `}
      >
        {imageIconSrc ? (
          <img src={imageIconSrc} className="w-6 mr-2" alt="icon img" />
        ) : (
          <Icon className=" mr-2 min-w-fit" />
        )}
        {open && <span>{label}</span>}
      </NavLink>
    </li>
  ) : (
    <SideNavGroup
      label={label}
      Icon={Icon}
      links={links}
      imageIconSrc={imageIconSrc}
      userRole={userRole}
      setOpen={setOpen}
      open={open}
      setSidebarOpen={setSidebarOpen}
    />
  );
}

const SideNavGroup = ({
  label,
  Icon = MdFolder,
  links,
  imageIconSrc,
  userRole = {},
  setOpen,
  setSidebarOpen,
  open,
}) => {
  const [thisOpen, setThisOpen] = useLocalStorageState(
    `sb-${label.toLowerCase()}`,
    {
      defaultValue: false,
    }
  );
  const toggleThisOpen = () => setThisOpen((o) => !o);
  const isDesktop = useBreakpoint("md");

  return (
    <li
      onClick={toggleThisOpen}
      className={
        "select-none flex flex-col  hover:bg-blue-500 hover:bg-opacity-10  transition-all"
      }
    >
      <span
        className={`flex transition-all items-center h-8 ${
          open ? "px-4" : "justify-center  "
        }`}
      >
        {imageIconSrc ? (
          <img
            src={imageIconSrc}
            className="w-4 mr-2 bg-black rounded-sm"
            alt="icon img"
          />
        ) : (
          <Icon className=" mr-2 min-w-fit" />
        )}
        {open && (
          <>
            <span>{label}</span>
            {thisOpen ? (
              <MdKeyboardArrowDown className="float-right" />
            ) : (
              <MdArrowRight className="float-right" />
            )}
          </>
        )}
      </span>

      {thisOpen && (
        <ul className="bg-white text-gray-600 flex flex-col ">
          {links.map(({ to, label, Icon, role = [] }, i) => {
            if (!rolesInclude(userRole, role)) return null;

            return (
              <li key={i}>
                <NavLink
                  to={to}
                  className={`${
                    open ? "px-6" : "justify-center "
                  } py-1 text-gray-700 transition-all  flex items-center flex-nowrap hover:bg-blue-100 hover:text-gray-900  h-8 `}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isDesktop) setSidebarOpen(false);
                    // TODO !sidebarOpen && setOpen(false)
                    //   close();
                    // setOpen(false);
                  }}
                >
                  {Icon && <Icon className=" mr-2 min-w-fit" />}

                  <span className={open ? "whitespace-nowrap" : "hidden"}>
                    {label}
                  </span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
};

function rolesInclude(userRoles, linkRoles) {
  if (
    linkRoles &&
    linkRoles.length > 0 &&
    userRoles &&
    !Object.keys(userRoles).some(
      (r) => userRoles[r] && ["admin", ...linkRoles].includes(r)
    )
  ) {
    return false;
  }
  return true;
}

const sidebarLinks = [
  {
    role: [
      "mover",
      "freight",
      "surveyor",
      "warehouse",
      "lead",
      "manager",
      "pts",
      "ptsManager",
    ],
    label: "Team",
    Icon: FaUsers,
    to: "/team",
  },
  // {
  //   role: ["admin"],
  //   label: "Calendar",
  //   Icon: MdCalendarToday,
  //   to: "/calendar",
  // },
  // {
  //   label: "Freight",
  //   icon: "truck",
  //   Icon: FaTruck,
  //   role: ["admin", "freight"],
  //   links: [
  //     {
  //       to: "/freight/bols",
  //       label: "BOLs",
  //       icon: "clipboard list",
  //       Icon: FaClipboardList,
  //       role: ["freight"],
  //     },
  //   ],
  // },
  // {
  //   label: "SFM",
  //   icon: "home",
  //   Icon: RiVipDiamondFill,
  //   role: ["admin", "lead", "surveyor"],
  //   links: [
  //     {
  //       to: "/sfm/moves",
  //       label: "Moves",
  //       icon: "dolly",
  //       Icon: FaDolly,
  //       role: ["manager", "lead"],
  //     },
  //     {
  //       to: "/estimates",
  //       label: "Estimates",
  //       icon: "clipboard list",
  //       Icon: FaClipboardList,
  //       role: ["surveyor"],
  //     },
  //     {
  //       to: "/sfm/bol",
  //       label: "Bol",
  //       icon: "clipboard list",
  //       Icon: FaClipboardList,
  //       role: ["surveyor"],
  //     },
  //     {
  //       to: "/sfm/survey",
  //       label: "Survey",
  //       icon: "clipboard list",
  //       Icon: FaClipboardList,
  //       role: ["surveyor"],
  //     },
  //   ],
  // },

  paramountLinks,
  {
    label: "Warehouse",
    icon: "warehouse",
    Icon: FaWarehouse,
    role: ["warehouse"],

    links: [
      {
        to: "/warehouse/labels",
        label: "Labels",
        icon: "file alternate outline",
        Icon: FaFileAlt,
        role: ["warehouse", "mover"],
      },
      // {
      //   to: "/warehouse/loadChart",
      //   label: "Load Chart",
      //   icon: "file alternate outline",
      //   Icon: FaFileAlt,
      //   role: ["admin"],
      // },
      // {
      //   to: "/warehouse/map",
      //   label: "Map",
      //   icon: "file alternate outline",
      //   Icon: FaFileAlt,
      //   role: ["admin"],
      // },
      {
        to: "/warehouse/units",
        label: "Units",
        icon: "box",
        Icon: FaBox,
        role: ["warehouse"],
      },
      // {
      //   to: "/warehouse/trucks",
      //   label: "Trucks",
      //   icon: "truck",
      //   Icon: FaTruckMoving,
      //   role: ["manager"],
      // },
    ],
  },
  // {
  //   label: "Settings",
  //   icon: "wrench",
  //   Icon: FaWrench,
  //   role: ["admin"],

  //   links: [
  //     {
  //       to: "/settings/packets",
  //       label: "Packets",
  //       icon: "folder",
  //       Icon: FaFolder,
  //     },
  //     { to: "/settings/pdfs", label: "PDFs", icon: "file", Icon: FaFile },
  //   ],
  // },
  {
    label: "Tools",
    Icon: FaTools,
    role: ["admin"],

    links: [
      // { to: "/tools/sms", label: "SMS", Icon: FaSms },
      { to: "/tools/abhs", label: "ABHS", Icon: FaFile },
      // { to: "/tools/tips", label: "Tip Split", Icon: FaMoneyCheck },
      { to: "/tools/materials", label: "Material Calculator", Icon: FaBoxOpen },
      // { to: "/tools/access", label: "Access", Icon: FaBoxOpen },
    ],
  },
  {
    label: "Admin",
    icon: "chess king",
    Icon: FaChessKing,
    role: ["admin"],
    links: [
      { to: "/admin/users", label: "Users", Icon: FaUsers, icon: "users" },
    ],
  },
];
