import React from "react";
import files from "./files.json";
import urls from "./urls.json";
import { PageLayout } from "../layout/PageLayout";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "DELETE_IMAGE":
      return Object.fromEntries(
        Object.entries(state).filter(([imageId]) => imageId === payload)
      );

    default:
      return state;
  }
};

export const ManageImages = () => {
  const [images, dispatch] = React.useReducer(reducer, files);
  return (
    <PageLayout title="Manage Images">
      <div className="flex flex-wrap">
        {Object.entries(files).map(([unitId, unitFiles]) => {
          return (
            <>
              {Object.entries(unitFiles).map(
                ([fileId, { fileName, storagePath }]) => {
                  return (
                    <Row
                      key={storagePath}
                      fileName={fileName}
                      storagePath={storagePath}
                      delete={() =>
                        dispatch({ type: "DELETE_IMAGE", payload: fileId })
                      }
                    />
                  );
                }
              )}
            </>
          );
        })}
      </div>
      <div>
        <pre className="text-xs">{JSON.stringify(images, null, 2)}</pre>
      </div>
      {/* <div>
        <pre className="text-xs">{JSON.stringify(images, null, 2)}</pre>
      </div> */}
    </PageLayout>
  );
};

const Row = ({ fileName, storagePath }) => {
  const thumbPath = storagePath.replace(fileName, "") + "thumb_001_" + fileName;

  // const [src, setSrc] = React.useState(urls[storagePath]);
  const [thumbSrc] = React.useState(urls[thumbPath]);

  const handleDelete = async () => {
    try {
      // await deleteFileFromFirebaseStorage(storagePath);
      // await deleteFileFromFirebaseStorage(thumbPath);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      onClick={handleDelete}
      className="flex   w-40 h-48 mx-auto  relative bg-contain md:bg-cover bg-no-repeat bg-center overflow-hidden"
      style={{
        backgroundImage: `url(${thumbSrc})`,
      }}
    >
      <span className="absolute bg-white bottom-0 left-0 text-xs whitespace-nowrap">
        {fileName}
      </span>
      {/* <IconButton onClick={handleDelete} /> */}
    </div>
  );
};
