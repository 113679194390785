import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { MdCopyAll, MdEdit, MdDirectionsRun, MdBusinessCenter, MdRemoveRedEye, MdMail, MdSms, MdPhone } from "react-icons/md";
import { FaCrown, FaChessRook, FaWarehouse, FaClipboard, FaTruckMoving, FaQuestion } from "react-icons/fa";
import { GiSteeringWheel } from "react-icons/gi";
import { AddUser } from "./AddUser";

export const UserCard = ({ user, canEdit = false, edit, setEdit, close, ...props }) => {
  if (!user) return null;

  if (edit) {
    return <AddUser user={user} close={close} />;
  }

  return (
    <div className=" flex items-stretch  hover:bg-black hover:bg-opacity-10 rounded-md relative mx-2 my-0" {...props}>
      <div
        className="bg-cover bg-center w-32  my-1  rounded"
        style={{ backgroundImage: `url(${user?.photoURL || "/img/user.png"})` }}
      ></div>

      <div className="flex flex-col w-full ml-4">
        <div className="flex flex-col justify-between">
          <div>
            <Link to={`/profile/${user.id}`} className="text-xl px-2 " onClick={() => console.log(user)}>
              {user?.displayName}
            </Link>
            {user?.position && (
              <div className="flex flex-wrap">
                <span className="text-xs text-white bg-gray-500 bg-opacity-60 hover:bg-opacity-100 transition-all mb-1 select-none rounded-md px-4 py-0.5 my-0.5">
                  {user?.position}
                </span>
                <div className="hidden md:flex justify-end">
                  {user.role &&
                    Object.keys(user.role).length > 0 &&
                    Object.keys(user.role).map((r, i) => {
                      return (
                        <span key={i} className={user.role[r] ? "text-gray-700 hover:text-black" : "opacity-20"} title={r}>
                          <UserRoleIcon role={r} className="m-1" />
                        </span>
                      );
                    })}
                </div>
              </div>
            )}
            <div className="flex flex-col">
              {user?.email && (
                <div>
                  <button
                    type="button"
                    className=" text-xs group flex items-center rounded  hover:bg-black hover:bg-opacity-10 transition-all px-2 py-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(user.email);
                      toast("Copied - " + user.email);
                    }}
                  >
                    <span className="text-xs  rounded-md pr-4">{user.email}</span>
                    <span className="invisible group-hover:visible transition-all text-gray-500">
                      <MdCopyAll />
                    </span>
                  </button>
                </div>
              )}
              {user?.phone && (
                <div>
                  <button
                    type="button"
                    className=" text-xs group flex items-center rounded  hover:bg-black hover:bg-opacity-10 transition-all px-2 py-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(user.phone);
                      toast("Copied - " + user.phone);
                    }}
                  >
                    <span className="text-xs  rounded-md pr-4">{user.phone}</span>
                    <span className="invisible group-hover:visible transition-all text-gray-500">
                      <MdCopyAll />
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex">
          {canEdit && (
            <button type="button" onClick={() => setEdit(true)} className={actionLinkButtonClassName("green")}>
              <MdEdit className="md: ml-2 w-4 h-4" /> <span className="md:mx-2 hidden md:block">Edit</span>
            </button>
          )}
          <ActionLinkButton prop={user?.phone} color="purple" text="Call" Icon={MdPhone} href={`tel:${user?.phone}`} />
          <ActionLinkButton prop={user?.phone} color="yellow" text="Text" Icon={MdSms} href={`sms:${user?.phone}`} />
          <ActionLinkButton
            prop={user?.email}
            color="indigo"
            text="Email"
            href={`mailto:${user?.email}`}
            target="_blank"
            rel="noreferrer"
            Icon={MdMail}
          />
        </div>
      </div>
    </div>
  );
};

const borderColor = {
  gray: "border-gray-400",
  yellow: "border-yellow-400",
  purple: "border-purple-400",
  indigo: "border-indigo-400",
  green: "border-green-400",
};
const textColor = {
  gray: "text-gray-600",
  yellow: "text-yellow-600",
  purple: "text-purple-600",
  indigo: "text-indigo-600",
  green: "text-green-600",
};
const hoverbgColor = {
  gray: "hover:bg-gray-200",
  yellow: "hover:bg-yellow-200",
  purple: "hover:bg-purple-200",
  indigo: "hover:bg-indigo-200",
  green: "hover:bg-green-200",
};

const actionLinkButtonClassName = (color = "gray") =>
  `px-1 border-2 w-16 py-1 select-none  text-xs ${borderColor[color]} ${textColor[color]} ${hoverbgColor[color]} transition-all rounded-md my-1 mx-1 flex justify-center items-center min-w-min`;
const ActionLinkButton = ({ prop, text, Icon = null, href, color, ...props }) => {
  return !!prop ? (
    <a {...props} href={href} className={actionLinkButtonClassName(color)}>
      {Icon && <Icon className="ml-2 w-4 h-4" />} <span className="md:mx-2 hidden md:block">{text}</span>
    </a>
  ) : (
    <span {...props} className={actionLinkButtonClassName(color) + " opacity-20"}>
      {Icon && <Icon className="mx-2 w-4 h-4" />} <span className="md:mx-2 hidden md:block">{text}</span>
    </span>
  );
};

const UserRoleIcon = ({ role, ...props }) => {
  switch (role) {
    case "admin":
      return <FaCrown {...props} />;
    case "manager":
      return <FaChessRook {...props} />;
    case "warehouse":
      return <FaWarehouse {...props} />;
    case "lead":
      return <FaClipboard {...props} />;
    case "mover":
      return <MdDirectionsRun {...props} />;
    case "driver":
      return <GiSteeringWheel {...props} />;
    case "coordinator":
      return <MdBusinessCenter {...props} />;
    case "surveyor":
      return <MdRemoveRedEye {...props} />;
    case "freight":
      return <FaTruckMoving {...props} />;
    default:
      return <FaQuestion {...props} />;
  }
};
