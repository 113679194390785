import cuid from "cuid";
// import cuidPlus from "cuid-plus";
import { db, auth } from "../config/firebase";
import {
  doc,
  setDoc,
  Timestamp,
  serverTimestamp,
  // updateDoc,
  collection,
  arrayUnion,
  // arrayRemove,
  deleteField,
  increment,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  // startAt,
  // endAt,
  // onSnapshot,
  writeBatch,
  updateDoc,
  addDoc,
  getDoc,
  deleteDoc,
  startAfter,
} from "firebase/firestore";
import { updateProfile } from "firebase/auth";
import {
  registerInFirebaseByAdmin,
  sendShipmentReceivedEmail,
} from "./firebaseService";
const unitsRef = collection(db, "companies/paramount/units/");
const ptsShipmentsRef = collection(db, "companies/paramount/shipments/");
const ptsOpsRef = collection(db, "companies/paramount/operations/");
const ptsShippersRef = collection(db, "companies/paramount/shippers/");
const trucksRef = collection(db, "trucks");

export function dataFromSnapshot(snapshot) {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data();

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof Timestamp) {
        //Change Firestore dates into Date object
        data[prop] = data[prop].toDate();
      }
    }
  }

  return {
    ...data,
    id: snapshot.id,
  };
}

export async function addUserToFirestore(user) {
  const currentUser = auth.currentUser;
  const { createAuthAccount, password, ...rest } = user;
  const userTimestamp = {
    at: serverTimestamp(),
    userId: currentUser.uid,
    displayName: currentUser.displayName,
  };
  let status = {},
    newUserId = cuid();
  if (!user?.id) {
    status = { created: userTimestamp };
    if (createAuthAccount && (user?.email || user?.phone) && user?.password) {
      const isLoginCreated = await createLogin({ id: newUserId, ...user });
      if (isLoginCreated) status.authAccount = true;
    }
  } else {
    status = { updated: { [cuid.slug()]: userTimestamp } };
  }
  const jobRef = doc(db, "users", user?.id || newUserId);
  return await setDoc(jobRef, { ...rest, ...status }, { merge: true });
}

export async function createLogin(user) {
  return new Promise(async (resolve, reject) => {
    if (!user?.id) return reject("No user ID");
    let data = { id: user.id };
    if (user?.email) data.email = user.email;
    if (user?.password) data.password = user.password;
    if (user?.phone) data.phone = user.phone;
    if (user?.displayName) data.displayName = user.displayName;
    if (user?.photoURL) data.photoURL = user.photoURL;
    try {
      const res = await registerInFirebaseByAdmin(data);
      return resolve(res?.data);
    } catch (error) {
      console.log(error);
      return reject(error);
    }
  });
}

export async function addAuthToUser(userId) {
  return await updateDoc(doc(db, `users/${userId}`), { authAccount: true });
}

export function listenToAdminUsers() {
  return query(collection(db, "users"), orderBy("displayName"));
}
export function listenToTeamUsers() {
  return query(collection(db, "users"), where("isEmployed", "==", true));
}

/********************* PDF CRUD START ********************************/
export function listenToPDFsFromFirestore() {
  return query(collection(db, "pdfs"), orderBy("createdAt"));
}

export function updatePDFFieldsInFirestore(pdfId, formFields) {
  return updateDoc(doc(db, `pdfs/${pdfId}`), {
    formFields,
    attemptedUpdateFields: true,
  });
}

export function getPdfprep(pdfId) {
  return doc(db, `pdfs/${pdfId}`);
}

export function updatePDF(pdf) {
  return updateDoc(doc(db, `pdfs/${pdf.id}`), pdf);
}
export function addPDFToFirestore(pdfId, pdf) {
  return setDoc(
    doc(db, `pdfs/${pdfId}`),
    { ...pdf, createdAt: serverTimestamp() },
    { merge: true }
  );
}
/********************* PDF CRUD END ********************************/
/********************* PACKETS CRUD START ********************************/
export function listenToGlobalVariablesFromFirestore() {
  return doc(db, "settings", "globalVariables");
}
export function listenToPacketsFromFirestore() {
  return collection(db, "settings", "packets", "packetList");
}
export function listenToPacketFromFirestore(packetId) {
  return doc(db, `settings"/packets/packetList/${packetId}`);
}

export function updatePacketSelectedFilesInFirestore(packetId, selectedFiles) {
  if (!packetId) return;
  return updateDoc(doc(db, `settings/packets/packetList/${packetId}`), {
    files: selectedFiles,
  });
}
export function addPacketToFirestore(packet) {
  return addDoc(collection(db, "settings/packets/packetList"), {
    ...packet,
    createdAt: serverTimestamp(),
  });
}

/********************* PACKETS CRUD END ********************************/
/********************* PTS OPS CRUD START ********************************/
export function listenToOperationsInFirestore() {
  return query(ptsOpsRef, orderBy("updatedAt"));
}
export function listenToOperationInFirestore(operationId) {
  return doc(ptsOpsRef, operationId);
}
export function listenToPTSJobsFromFirestore() {
  return query(ptsOpsRef, limit(10));
}

export async function addPTSJobToFirestore({ jobId, ...job }) {
  const jobRef = doc(ptsOpsRef, jobId);
  return await setDoc(jobRef, job);
}

export async function addNewPTSOperation(values) {
  // const { opId, displayName, refNumber, codeName } = values;
  const opId = cuid();
  const shipmentId = cuid();
  const shipperId = cuid();
  const batch = writeBatch(db);
  const opData = { ...created() };
  const shipmentData = { opId, ...created() };
  const shipperData = { ...created() };

  if (values?.displayName) {
    //TODO check if name exists
    const shippers = { [shipperId]: { displayName: values.displayName } };
    opData.shippers = shippers;
    shipmentData.shippers = shippers;
    shipperData.shippers = shippers;
    const shipperRef = doc(ptsShippersRef, shipperId);
    batch.set(shipperRef, shipperData, { merge: true });
  }
  if (values?.refNumber) {
    //TODO check if shipment exists
    if (values.refNumber.trim().startsWith("SE-")) {
      opData.refNumber = parseRefNumber(values.refNumber);
    } else {
      opData.foreignRef = values.refNumber.trim();
    }
    opData.shipments = { [shipmentId]: { refNumber: values.refNumber } };
    shipmentData.refNumber = values.refNumber;
    const shipmentRef = doc(ptsShipmentsRef, shipmentId);
    batch.set(shipmentRef, shipmentData, { merge: true });
  }

  if (values?.codeName) {
    //TODO check if codename exists
    opData.codeName = values.codeName;
  }

  const opRef = doc(ptsOpsRef, opId);
  batch.set(opRef, opData, { merge: true });

  await batch.commit();
  return opId;
}

export async function addRefNumber({ refNumber, opId }) {
  const user = auth.currentUser;
  const updated = {
    [`${cuid.slug()}`]: {
      displayName: user.displayName,
      userId: user.uid,
      at: serverTimestamp(),
      description: `added operation number ${refNumber}`,
    },
  };

  const opData = { refNumber, updated };
  const batch = writeBatch(db);
  const opRef = doc(ptsOpsRef, opId);
  batch.set(opRef, opData, { merge: true });
  return await batch.commit();
}

export async function addNewPTSShipment({ refNumber, opId, shippers = {} }) {
  const user = auth.currentUser;
  const updated = {
    [`${cuid.slug()}`]: {
      displayName: user.displayName,
      userId: user.uid,
      at: serverTimestamp(),
      description: `added new shipment ${refNumber}`,
    },
  };

  const shipmentId = cuid();
  const batch = writeBatch(db);
  const opData = { shipments: { [shipmentId]: { refNumber } }, updated };
  const shipmentData = { opId, refNumber, shippers, ...created() };
  const shipmentRef = doc(ptsShipmentsRef, shipmentId);
  batch.set(shipmentRef, shipmentData, { merge: true });
  const opRef = doc(ptsOpsRef, opId);
  batch.set(opRef, opData, { merge: true });
  await batch.commit();
  return shipmentId;
}

export async function loadParamountJobsOptions(inputValue) {
  return new Promise(async (resolve, reject) => {
    const q = query(
      ptsOpsRef,
      where("fullName", ">=", inputValue),
      where("fullName", "<=", inputValue + "~"),
      limit(5)
    );

    try {
      const docs = await getDocs(q);
      if (!docs.empty) {
        let jobs = [];
        docs.forEach(function (doc) {
          const job = {
            value: doc.id,
            label: doc.data().fullName,
            id: doc.id,
            fullName: doc.data().fullName,
            clientName: doc.data().fullName,
            refNumber: doc.data().refNumber[0],
            existingUnits: doc.data().units,
          };
          jobs.push(job);
        });
        return resolve(jobs);
      } else {
        return resolve([]);
      }
    } catch (error) {
      return resolve([]);
    }
  });
}

export async function requestShipmentsToBeDeleted({
  opId,
  shipmentsIdArray,
  reason,
}) {
  const batch = writeBatch(db);
  const opRef = doc(ptsOpsRef, opId);
  batch.set(doc(db, `deleteRequests`, cuid()), {
    opId,
    shipmentsIdArray,
    reason,
  });

  const user = auth.currentUser;
  const updated = {
    [`${cuid.slug()}`]: {
      displayName: user.displayName,
      userId: user.uid,
      at: serverTimestamp(),
      description: `requested to delete ${shipmentsIdArray.length} shipment${
        shipmentsIdArray.length > 1 ? "s" : ""
      } - ${reason}`,
    },
  };
  batch.set(opRef, { updated }, { merge: true });

  return await batch.commit();
}
export async function requestUnitsToBeDeleted({
  opId,
  shipmentId,
  unitsIdArray,
  reason,
}) {
  const batch = writeBatch(db);
  const opRef = doc(ptsOpsRef, opId);
  batch.set(doc(db, `deleteRequests`, cuid()), {
    opId,
    shipmentId,
    unitsIdArray,
    reason,
  });

  const user = auth.currentUser;
  const updated = {
    [`${cuid.slug()}`]: {
      displayName: user.displayName,
      userId: user.uid,
      at: serverTimestamp(),
      description: `requested to delete ${unitsIdArray.length} unit${
        unitsIdArray.length > 1 ? "s" : ""
      } - ${reason}`,
    },
  };
  batch.set(opRef, { updated }, { merge: true });

  return await batch.commit();
}
/********************* PTS OPS CRUD END ********************************/
/********************* TRUCKS CRUD START ********************************/
export function addFileToTruckInFirestore(truckId, file) {
  return updateDoc(doc(db, `trucks/${truckId}`), {
    [`files.${file.fileId}`]: file,
    updatedAt: serverTimestamp(),
  });
}

export function listenToTrucksFromFirestore() {
  return query(trucksRef, orderBy("name"));
}
export function listenToTruckFromFirestore(truckId) {
  return doc(trucksRef, truckId);
}

/********************* TRUCKS CRUD END ********************************/
/********************* USER PROFILE CRUD START ********************************/
export function setUserProfileData(user) {
  return setDoc(doc(db, `users/${user.uid}`), {
    displayName: user.displayName,
    email: user.email,
    photoURL: user.photoURL || null,
    role: { noRole: true },
    createdAt: serverTimestamp(),
  });
}

export function getUserProfile(userId) {
  return doc(db, `users/${userId}`);
}

export async function updateUserProfile(profile) {
  const user = auth.currentUser;
  try {
    if (user.displayName !== profile.displayName) {
      await updateProfile(user, {
        displayName: profile.displayName,
        // description: profile.description
      });
    }
    return await updateDoc(doc(db, `users/${user.uid}`), profile);
  } catch (error) {
    throw error;
  }
}
/********************* USER PROFILE CRUD END ********************************/
/********************* USER AVATAR CRUD START ********************************/
/**
 *
 * @param {*} userId
 * @param {*} fileId
 * @param {*} fileName
 * @param {string} url
 * @returns
 */
export async function addProfilePhoto(userId, fileId, fileName, url) {
  // console.log({path: `users/${userId}/photos/${fileId}`, url, fileName})
  const fileRef = doc(db, `users/${userId}/photos/${fileId}`);
  return await setDoc(fileRef, { url, fileName }, { merge: true });
}

/**
 * Ref to user photos
 * @param {string} userUid
 * @returns
 */
export function getUserPhotos(userUid) {
  return collection(db, `users/${userUid}/photos`);
}

/**
 *
 * @param {string} photoURL
 * @param {string} filename
 * @returns
 */
export async function updateUserProfilePhoto(photoURL, filename) {
  const user = auth.currentUser;
  const userDocRef = doc(db, `users/${user.uid}`);
  try {
    const userDoc = await getDoc(userDocRef);
    if (!userDoc.data().photoURL) await updateDoc(userDocRef, { photoURL });
    return await addDoc(collection(db, `users/${user.uid}/photos"`), {
      filename,
      photoURL,
    });
  } catch (error) {
    throw error;
  }
}
/**
 * Only can delete own photos
 * TODO updateProfile if last photo was deleted
 * @param {*} photoId
 * @returns
 */
export async function deletePhotoFromCollection(photoId) {
  const userUid = auth.currentUser.uid;
  return await deleteDoc(doc(db, `users/${userUid}/photos/${photoId}`));
}

/**
 * Sets current photo to Main avatar
 * @param {object} photo with url key
 * @returns Promise of updateDoc
 */
export async function setMainPhoto(photo, user = auth.currentUser) {
  try {
    await updateDoc(doc(db, "users", user.uid), { photoURL: photo.url });
    return await updateProfile(user, { photoURL: photo.url });
  } catch (error) {
    throw error;
  }
}

/********************* USER AVATAR CRUD END ********************************/

/********************* SHIPPERS START ********************************/
export async function addShipper({ displayName, opId, operation = {} }) {
  if (!displayName) return console.log("ERROR: NO NAME");
  console.log({ displayName, opId, operation });
  const user = auth.currentUser;
  const updated = {
    [`updated.${cuid.slug()}`]: {
      displayName: user.displayName,
      userId: user.uid,
      at: serverTimestamp(),
      description: `added shipper ${displayName}`,
    },
  };
  const batch = writeBatch(db);
  //create shipper
  const shipperId = cuid();

  const shipperData = { displayName, ...created() };

  const shipperRef = doc(ptsShippersRef, shipperId);
  batch.set(shipperRef, shipperData, { merge: true });

  // const shippers = { [shipperId]: { displayName } };
  //add to operation
  const opRef = doc(ptsOpsRef, opId);
  batch.update(opRef, {
    [`shippers.${shipperId}`]: { displayName },
    ...updated,
  });
  //add to shipments & their units
  if (operation?.shipments && Object.keys(operation.shipments).length > 0) {
    Object.keys(operation.shipments).forEach((shipmentId) => {
      const shipmentRef = doc(ptsShipmentsRef, shipmentId);
      batch.update(shipmentRef, {
        [`shippers.${shipperId}`]: { displayName },
        ...updated,
      });
      if (
        operation.shipments[shipmentId]?.units &&
        Object.keys(operation.shipments[shipmentId].units).length > 0
      ) {
        Object.keys(operation.shipments[shipmentId].units).forEach((unitId) => {
          const unitRef = doc(unitsRef, unitId);
          batch.update(unitRef, {
            [`shippers.${shipperId}`]: { displayName },
            ...updated,
          });
        });
      }
    });
  }
  return await batch.commit();
}

/********************* SHIPPERS END ********************************/
/********************* SHIPMENTS START ********************************/
export function listenToShipmentInFirestore(shipmentId) {
  return query(doc(ptsShipmentsRef, shipmentId));
}

export async function renameShipment({
  shipmentName,
  opId,
  shipment,
  shipmentId,
}) {
  console.log({ shipmentName, opId, shipment, shipmentId });
  const user = auth.currentUser;
  const updated = {
    [`updated.${cuid.slug()}`]: {
      displayName: user.displayName,
      userId: user.uid,
      at: serverTimestamp(),
      description: `renamed shipment ${
        shipment?.refNumber || shipmentId
      } to ${shipmentName}`,
    },
  };
  const batch = writeBatch(db);

  //update shipment/{shipmentId}.refNumber
  const shipmentRef = doc(ptsShipmentsRef, shipmentId);
  batch.update(shipmentRef, { refNumber: shipmentName, ...updated });

  //update units/{unitId}.refNumber
  if (shipment?.units && Object.keys(shipment.units).length > 0) {
    Object.keys(shipment.units).forEach((unitId) => {
      const unitRef = doc(unitsRef, unitId);
      batch.update(unitRef, { refNumber: shipmentName, ...updated });
    });
  }

  //update operation/{opId}.shipments/{shipmentId}/refNumber
  const opRef = doc(ptsOpsRef, opId);
  batch.update(opRef, {
    [`shipments.${shipmentId}.refNumber`]: shipmentName,
    ...updated,
  });

  return await batch.commit();
}

export async function deleteshipmentsInFirestore({ opId, shipmentsIdArray }) {
  const user = auth.currentUser;
  const batch = writeBatch(db);

  const updated = {
    [`updated.${cuid.slug()}`]: {
      displayName: user.displayName,
      userId: user.uid,
      at: serverTimestamp(),
      description: `deleted ${shipmentsIdArray.join(", ")} shipment${
        shipmentsIdArray.length > 1 ? "s" : ""
      }`,
    },
  };
  console.log({ action: "DELETING", shipmentsIdArray, opId });

  try {
    shipmentsIdArray.forEach((shipmentId) => {
      batch.delete(doc(ptsShipmentsRef, shipmentId));
    });
    batch.update(doc(ptsOpsRef, opId), {
      ...shipmentsIdArray.reduce(
        (a, shipmentId) => ({
          ...a,
          [`shipments.${shipmentId}`]: deleteField(),
        }),
        {}
      ),
      ...updated,
    });
    await batch.commit();
  } catch (error) {
    console.log(error);
  }
}
/********************* SHIPMENTS END ********************************/
/********************* UNITS CRUD START ********************************/

/**
 *
 * @param {object} data containing shipmentId, opId, shippers, refNumber, crew, startingNumber, totalUnitsToAdd
 * sfmt.v.0.4+
 */
export async function addUnitsToFirestore({
  shipmentId,
  opId,
  shippers = {},
  refNumber,
  crew = {},
  startingNumber = 1,
  totalUnitsToAdd = 1,
  unitIdArray = null,
}) {
  // console.log({ shipmentId, opId, shippers, refNumber, crew, startingNumber, totalUnitsToAdd });
  const user = auth.currentUser;
  const batch = writeBatch(db);
  const units =
    unitIdArray ||
    Array.apply(null, Array(totalUnitsToAdd)).map(function () {
      return cuid();
    });

  const defaultUnit = defaultLiftVan;

  const updated = {
    [cuid.slug()]: {
      displayName: user.displayName,
      userId: user.uid,
      at: serverTimestamp(),
      description: `added ${totalUnitsToAdd} unit${
        totalUnitsToAdd > 1 ? "s" : ""
      } to ${refNumber ? refNumber : "sId:" + shipmentId}`,
    },
  };
  try {
    //add to OP
    batch.set(
      doc(ptsOpsRef, opId),
      {
        shipments: {
          [shipmentId]: {
            units: units.reduce((a, unitId, index) => {
              return {
                ...a,
                [unitId]: { number: startingNumber + index, ...defaultUnit },
              };
            }, {}),
          },
        },
        updated,
      },
      { merge: true }
    );
    //add to shipment
    batch.set(
      doc(ptsShipmentsRef, shipmentId),
      {
        units: units.reduce((a, unitId, index) => {
          return {
            ...a,
            [unitId]: {
              number: startingNumber + index,
              ...defaultUnit,
            },
          };
        }, {}),
        unitCount: increment(totalUnitsToAdd),
        updated,
      },
      { merge: true }
    );
    //add to units
    units.forEach((unitId, index) => {
      batch.set(doc(unitsRef, unitId), {
        shipmentId,
        opId,
        shippers,
        refNumber,
        crew,
        number: startingNumber + index,
        ...defaultUnit,
        ...created(),
      });
    });
    await batch.commit();
    return units;
  } catch (error) {
    console.log(error);
  }
}

const defaultLiftVan = {
  unitType: { id: "liftvan", label: "Lift Van" },
  isNew: false,
  weight: { tare: getRandomInt(300, 320) },
  dimms: { l: 87, w: 45, h: 87, units: "inches" },
};
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export async function setUnitTypeInFirestore({ unit, data, opId, shipmentId }) {
  const user = auth.currentUser;
  const batch = writeBatch(db);

  const updated = {
    [cuid.slug()]: {
      displayName: user.displayName,
      userId: user.uid,
      at: serverTimestamp(),
      description:
        unit?.unitType?.label === data?.unitType?.label
          ? `updated unit #${unit?.number} type details`
          : `changed unit #${unit?.number} ${unit?.unitType?.label || ""} to ${
              data?.unitType?.label
            }`,
    },
  };
  try {
    //add to OP
    batch.set(
      doc(ptsOpsRef, opId),
      {
        shipments: {
          [shipmentId]: {
            units: {
              [unit.id]: { ...data },
            },
          },
        },
        updated,
      },
      { merge: true }
    );
    //add to shipment
    batch.set(
      doc(ptsShipmentsRef, shipmentId),
      {
        units: {
          [unit.id]: {
            ...data,
          },
        },
        updated,
      },
      { merge: true }
    );
    //add to units

    batch.set(
      doc(unitsRef, unit.id),
      {
        ...data,
      },
      { merge: true }
    );

    return await batch.commit();
  } catch (error) {
    console.log(error);
  }
  //update unit

  //update shipment

  //update operation
}

export async function getUnitCount(shipmentId) {
  const docRef = doc(db, `/companies/paramount/shipments/${shipmentId}`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    if (data?.units) {
      const unitCount = Object.keys(docSnap.data().units).length;
      console.log(unitCount);
      return unitCount;
    }
    return 0;
    // console.log("Document data:", docSnap.data());
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}

export async function addUnitToFirestore({ jobId, unitId, ...unit }) {
  const batch = writeBatch(db);
  try {
    batch.set(doc(unitsRef, unitId), {
      ...unit,
      jobRef: doc(db, `/paramount/${jobId}`),
      jobId,
      createdAt: serverTimestamp(),
    });
    batch.update(doc(ptsOpsRef, jobId), {
      [`units.${unitId}`]: {
        id: unitId,
        number: unit.number,
        crew: unit.crew,
        unitRef: doc(db, `/companies/paramount/units/${unitId}`),
      },
      // units: arrayUnion({ id: unitId, number: unit.number, crew: unit.crew }),
      updatedAt: serverTimestamp(),
    });
    return await batch.commit();
  } catch (error) {
    console.log(error);
  }
}

export async function addWarehouseUnitsoFirestore(data) {
  const { clientName, refNumber, crew, shipmentType, id, newUnits } = data;
  const batch = writeBatch(db);

  try {
    newUnits.length > 0 &&
      newUnits.forEach((unit) => {
        batch.set(doc(unitsRef, unit.id), {
          number: unit.number,
          fullName: clientName,
          refNumber,
          crew,
          shipmentType,
          jobRef: doc(db, `/paramount/${id}`),
          jobId: id,
          createdAt: serverTimestamp(),
        });
      });
    batch.update(doc(ptsOpsRef, id), {
      ...newUnits.reduce((ob, unit) => {
        ob[`units.${unit.id}`] = {
          id: unit.id,
          number: unit.number,
          // crew: unit.crew,
          unitRef: doc(db, `/companies/paramount/units/${unit.id}`),
        };
        return ob;
      }, {}),
      // units: arrayUnion({ id: unitId, number: unit.number, crew: unit.crew }),
      updatedAt: serverTimestamp(),
    });
    // batch.update(doc(ptsJobsRef, id), {
    //   unitCount: increment(newUnits.length),
    //   units: arrayUnion(
    //     ...newUnits.map((unit) => {
    //       return { unitRef: doc(db, `/companies/paramount/units/${unit.id}`), crew, shipmentType, ...unit };
    //     })
    //   ),
    //   updatedAt: serverTimestamp(),
    // });
    return await batch.commit();
  } catch (error) {
    console.log(error);
  }
}

export function listenToUnitFromFirestore(unitId) {
  return query(doc(unitsRef, unitId));
}
export function listenToUnitsFromFirestore(lastDoc) {
  let queryConstraints = [orderBy("created.at", "desc"), limit(10)];

  if (lastDoc) {
    queryConstraints.push(startAfter(lastDoc));
  }

  return query(unitsRef, ...queryConstraints);
}

export async function updateUnitImageUrlInFirestore({
  unitId,
  imageId,
  imageUrl,
}) {
  const t = await updateDoc(doc(unitsRef, unitId), {
    [`images.${imageId}.url`]: imageUrl,
  });
  console.log(t);
}

export function addUnitImageToFirestore(unitId, img) {
  return updateDoc(doc(unitsRef, unitId), {
    images: arrayUnion({ ...img }), //TODO might need to be changed to images[imgId]
    updatedAt: serverTimestamp(), // do not want introduce breaking changes
  });
}

export async function deleteUnitsInFirestore({
  unitIdArray,
  shipmentId,
  opId,
}) {
  const user = auth.currentUser;
  const batch = writeBatch(db);

  const updated = {
    [`updated.${cuid.slug()}`]: {
      displayName: user.displayName,
      userId: user.uid,
      at: serverTimestamp(),
      description: `deleted ${unitIdArray.length} unit${
        unitIdArray.length > 1 ? "s" : ""
      }`,
    },
  };
  console.log({ action: "DELETING", unitIdArray, shipmentId, opId });

  try {
    unitIdArray.forEach((unitId) => {
      batch.delete(doc(unitsRef, unitId));
    });
    batch.update(doc(ptsShipmentsRef, shipmentId), {
      ...unitIdArray.reduce(
        (a, unitId) => ({ ...a, [`units.${unitId}`]: deleteField() }),
        {}
      ),
      ...updated,
    });
    batch.update(doc(ptsOpsRef, opId), {
      ...unitIdArray.reduce(
        (a, unitId) => ({
          ...a,
          [`shipments.${shipmentId}.units.${unitId}`]: deleteField(),
        }),
        {}
      ),
      ...updated,
    });
    await batch.commit();
  } catch (error) {
    console.log(error);
  }

  //remove ref from job
  // return doc(unitsRef, unitId).delete();
}
export async function deleteUnitFromFirestore({ unitId, jobId }) {
  const batch = writeBatch(db);

  try {
    batch.delete(doc(unitsRef, unitId));
    batch.update(doc(ptsOpsRef, jobId), {
      [`units.${unitId}`]: deleteField(),
    });
  } catch (error) {
    console.log(error);
  }
  return await batch.commit();
  //remove ref from job
  // return doc(unitsRef, unitId).delete();
}

export async function updateUnitFromOp({
  shipmentId,
  opId,
  unit,
  created,
  shippers,
  refNumber,
}) {
  console.log(`updateUnitFromOp for unit ${unit.id}`);
  console.log({
    shipmentId,
    opId,
    shippers,
    number: unit.number,
    created,
    refNumber,
  });

  if (!created) {
    created = created();
  }

  try {
    setDoc(
      doc(unitsRef, unit.id),
      {
        shipmentId,
        opId,
        shippers,
        number: unit.number,
        created,
        refNumber,
      },
      { merge: true }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function setMainUnitImageInFirebase({
  opId,
  shipmentId,
  unitId,
  imageId,
  url,
}) {
  const batch = writeBatch(db);
  try {
    batch.update(doc(unitsRef, unitId), { mainImgId: imageId });
    batch.update(doc(ptsShipmentsRef, shipmentId), {
      [`units.${unitId}.thumb`]: url,
    });
    batch.update(doc(ptsOpsRef, opId), {
      [`shipments.${shipmentId}.units.${unitId}.thumb`]: url,
    });
    return await batch.commit();
  } catch (error) {
    console.log(error);
  }
}

export async function addUnitImagesDataToFirestore({
  shipmentId,
  opId,
  unitId,
  uploadedFiles,
}) {
  const batch = writeBatch(db);

  //TODO add update "somebody upload couple of images to unit"
  try {
    batch.set(
      doc(unitsRef, unitId),
      {
        images: {
          ...uploadedFiles.reduce((a, f) => ({ ...a, [`${f.id}`]: f }), {}),
        },
      },
      { merge: true }
    );
    const mainPhotoFile = uploadedFiles?.find((p) => p.main);
    if (mainPhotoFile) {
      const url = mainPhotoFile?.thumbs?.[0]?.url;
      console.log(mainPhotoFile, url);
      batch.update(doc(ptsShipmentsRef, shipmentId), {
        [`units.${unitId}.thumb`]: url,
      });
      batch.update(doc(ptsOpsRef, opId), {
        [`shipments.${shipmentId}.units.${unitId}.thumb`]: url,
      });
    }
    return await batch.commit();
  } catch (error) {
    console.log(error);
  }
}

/********************* UNITS CRUD END ********************************/
/********************* FILE DATA WITHIN DOC CRUD START ********************************/
export function addFileDataToFirestore({ docPath, docKey, uploadedFiles }) {
  const docRef = doc(db, docPath);
  return setDoc(
    docRef,
    uploadedFiles.reduce(
      (a, f) => ({
        ...a,
        [`${docKey}`]: { ...a[`${docKey}`], [`${f.id}`]: f },
      }),
      {}
    ),
    { merge: true }
  );
}

export async function updateFileData(docPath, docKey = "files", data) {
  const docRef = doc(db, docPath);
  return docRef.update({ [docKey]: data });
}

export async function deleteFileData(docPath, docKey = "keywords") {
  const docRef = doc(db, docPath);
  return updateDoc(docRef, { [docKey]: deleteField() });
}

export async function deleteImageFromDocInFirestore({ unitId, imageId }) {
  if (!unitId || !imageId) return null;
  return updateDoc(doc(unitsRef, unitId), {
    [`images.${imageId}`]: deleteField(),
  });
}
/********************* FILE DATA WITHIN DOC CRUD END ********************************/

export function signedBOL(data) {
  return addDoc(doc(db, "BOLS"), data);
}

function created() {
  const user = auth.currentUser;
  return {
    created: {
      displayName: user.displayName,
      userId: user.uid,
      at: serverTimestamp(),
    },
  };
}

const parseRefNumber = (string) => {
  if (!string || !isString(string)) return null;
  //TODO test regEXP
  if (!string.trim().startsWith("SE-")) console.log(string);
  const refNumber = string.trim().substring(3, 9);
  return refNumber;
};

function isString(x) {
  return Object.prototype.toString.call(x) === "[object String]";
}

export const completeReceiveShipment = async (
  shipmentId,
  opId,
  data,
  skipIsComplete = false
) => {
  console.log(shipmentId, opId, data, skipIsComplete);
  try {
    await sendShipmentReceivedEmail(shipmentId, data);
    if (skipIsComplete) return;
    const batch = writeBatch(db);
    batch.update(doc(ptsShipmentsRef, shipmentId), {
      isCompleted: true,
    });
    batch.update(doc(ptsOpsRef, opId), {
      [`shipments.${shipmentId}.isCompleted`]: true,
    });
    return await batch.commit();
  } catch (error) {
    console.log(error);
  }
};
