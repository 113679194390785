import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../../app/common/components/Button";
import { Input } from "../../../app/common/form/Input";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { ModalWrapper } from "../../../app/common/modals/ModalWrapper";
import { addShipper } from "../../../app/firestore/firestoreService";

export const AddShipperModal = ({ opId, operation }) => {
  const [value, setValue] = useState("");
  const modalDispatch = useDispatch();

  const onSubmit = async (e) => {
    e.preventDefault();
    addShipper({ displayName: value, opId, operation });
    modalDispatch(closeModal());
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <ModalWrapper header="Add Shipper">
      <div className="bg-white p-4 relative">
        <form onSubmit={onSubmit} className="flex flex-col">
          <Input onChange={handleChange} value={value} name="value" label="New Shipper DisplayName" placeholder={`Shipper Shipperson`} />
          <div className="mt-2 w-full">
            <Button type="submit" color="green" disabled={value === ""}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
