import React from "react";
import { useSelector } from "react-redux";
import { LoginForm } from "../../../features/auth/LoginForm";
import { AddOperationModal } from "../../../features/paramount/operations/AddOperationModal";
import { AddRefNumberModal } from "../../../features/paramount/operations/AddRefNumberModal";
import { AddUnitsModal } from "../../../features/paramount/units/AddUnitsModal";
import { EditUnitModal } from "../../../features/paramount/units/EditUnitModal";
import { AddCustomUnitTypeModal } from "../../../features/paramount/units/AddCustomUnitTypeModal";
import { ManageLabelSettingsModal } from "../../../features/paramount/units/ManageLabelSettingsModal";
import { AddCustomShipmentModal } from "../../../features/paramount/shipments/AddCustomShipmentModal";
import { ManageShipmentsModal } from "../../../features/paramount/shipments/ManageShipmentsModal";
import { ManageShipmentUnitsModal } from "../../../features/paramount/shipments/ManageShipmentUnitsModal";
import { RenameShipmentModal } from "../../../features/paramount/shipments/RenameShipmentModal";
import { AddShipperModal } from "../../../features/paramount/shippers/AddShipperModal";
import { RegisterForm } from "../../../features/auth/RegisterForm";
import { QRModal } from "../qr/QRModal";
import { ErrorModal } from "./ErrorModal";
import { CompleteReceivedShipmentModal } from "../../../features/paramount/shipments/CompleteReceivedShipmentModal";

export const ModalManager = () => {
  const modalLookup = {
    LoginForm,
    RegisterForm,
    QRModal,
    AddOperationModal,
    AddUnitsModal,
    AddCustomShipmentModal,
    AddRefNumberModal,
    ManageShipmentsModal,
    AddShipperModal,
    RenameShipmentModal,
    ManageShipmentUnitsModal,
    EditUnitModal,
    AddCustomUnitTypeModal,
    ManageLabelSettingsModal,
    CompleteReceivedShipmentModal,
  };
  const currentModal = useSelector((state) => state.modals);

  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    let ModalComponent = modalLookup?.[modalType];
    if (!ModalComponent) ModalComponent = ErrorModal;
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <>{renderedModal}</>;
};
