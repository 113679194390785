import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { Button } from "../../../app/common/components/Button";
import { FFCheckbox } from "../../../app/common/form/Checkbox";
import { FFInput } from "../../../app/common/form/Input";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { ModalWrapper } from "../../../app/common/modals/ModalWrapper";

export const ManageLabelSettingsModal = ({ labelSettings, setLabelSettings }) => {
  const modalDispatch = useDispatch();
  const onSubmit = (values) => {
    setLabelSettings(values);
    modalDispatch(closeModal());
  };



  return (
    <ModalWrapper header="Storage Label Settings">
      <Form
        initialValues={labelSettings}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} className="p-4 flex flex-col space-y-2">
            {Object.entries(labelSettings).map((field, i) =>
              typeof field[1] === "boolean" ? (
                <FFCheckbox key={i} name={field[0]} label={field[0]} />
              ) : (
                <FFInput key={i} name={field[0]} label={field[0]} />
              )
            )}

            <div className="mt-10 flex justify-center">
              <Button type="submit" color="green" disabled={submitting}>
                Submit
              </Button>
            </div>
            {/* <div className="text-[8px]">
                <pre>{JSON.stringify(values, null, 2)}</pre>
              </div> */}
          </form>
        )}
      />

      {/* <div>
        <pre>{JSON.stringify(labelSettings, null, 2)}</pre>
      </div> */}
    </ModalWrapper>
  );
};
