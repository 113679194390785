export function listenToUnit(unit) {
  return {
    type: "LISTEN_TO_UNIT",
    payload: unit,
  };
}
export function deleteUnit(unitId) {
  return {
    type: "DELETE_UNIT",
    payload: unitId,
  };
}
export function listenToUnits(units) {
  return {
    type: "LISTEN_TO_UNITS",
    payload: units,
  };
}
export function listenToTruck(truck) {
  return {
    type: "LISTEN_TO_TRUCK",
    payload: truck,
  };
}
export function listenToTrucks(trucks) {
  return {
    type: "LISTEN_TO_TRUCKS",
    payload: trucks,
  };
}

export function listenToOperation(operation) {
  return {
    type: "LISTEN_TO_OPERATION",
    payload: operation,
  };
}
