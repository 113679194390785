import React from "react";
// import { Button } from "../../app/common/components/Button";cd

function positionError(error) {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      console.error("User denied the request for Geolocation.");
      break;

    case error.POSITION_UNAVAILABLE:
      console.error("Location information is unavailable.");
      break;

    case error.TIMEOUT:
      console.error("The request to get user location timed out.");
      break;

    case error.UNKNOWN_ERROR:
      console.error("An unknown error occurred.");
      break;
    default:
      console.error("who knows");
  }
}

export const Access = () => {
  const [position, setPosition] = React.useState(null);

  //   const getL = () => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (p) => setPosition(p),
  //         positionError,
  //         {
  //           enableHighAccuracy: false,
  //           timeout: 15000,
  //           maximumAge: 0,
  //         }
  //       );
  //     }
  //   };

  React.useEffect(() => {
    var geoWatch;

    if (
      "geolocation" in navigator &&
      "watchPosition" in navigator.geolocation
    ) {
      geoWatch = navigator.geolocation.watchPosition(
        setPosition,
        positionError,
        {
          enableHighAccuracy: false,
          timeout: 15000,
          maximumAge: 0,
        }
      );
    }

    return () => {
      navigator.geolocation.clearWatch(geoWatch);
      geoWatch = undefined;
    };
  }, []);

  return (
    <div className="flex flex-col">
      <span>Speed: {position?.coords?.speed}</span>
      <span>altitude: {position?.coords?.altitude}</span>
      <span>altitudeAccuracy: {position?.coords?.altitudeAccuracy}</span>
      <span>heading: {position?.coords?.heading}</span>
      <span>accuracy: {position?.coords?.accuracy}</span>
      <span>latitude: {position?.coords?.latitude}</span>
      <span>longitude: {position?.coords?.longitude}</span>
      <span>{convertTimestamp(position?.timestamp)}</span>
      {/* <Button type="button">GET LOCATION</Button> */}
    </div>
  );
};

function convertTimestamp(timestamp) {
  if (!timestamp) return "No date.";
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  var date = new Date(timestamp);
  // Hours part from the timestamp
  var hours = date.getHours();
  // Minutes part from the timestamp
  var minutes = "" + date.getMinutes();
  // Seconds part from the timestamp
  var seconds = "" + date.getSeconds();

  // Will display time in 10:30:23 format
  return hours + ":" + minutes.substring(-2) + ":" + seconds.substring(-2);
}
