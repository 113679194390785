import {
  MdArrowDropDownCircle,
  MdCheckBox,
  MdCreate,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdSmartButton,
  MdSort,
  MdTextFormat,
} from "react-icons/md";

export const FieldIcon = ({ fieldType, ...rest }) => {
  switch (fieldType) {
    case "PDFButton":
      return <MdSmartButton />;
    case "PDFCheckBox":
      return <MdCheckBox />;
    case "PDFDropdown":
      return <MdArrowDropDownCircle />;
    case "PDFOptionList":
      return <MdSort />;
    case "PDFSignature":
      return <MdCreate />;
    case "PDFTextField":
      return <MdTextFormat />;
    case "PDFRadioGroup":
      return <MdRadioButtonChecked />;

    default:
      return <MdRadioButtonUnchecked />;
  }
};
