import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const ErrorComponent = () => {
  const { error } = useSelector((state) => state.async);

  return (
    <section placeholder>
      <h2 textAlign="center">{error?.message || "Oopes - error"}</h2>
      <Link to="/">
        <button style={{ marginTop: 20 }}>Return to page</button>
      </Link>
    </section>
  );
};
