import React from "react";
import { MdKeyboardArrowDown, MdOutlineSettings } from "react-icons/md";
import { ErrorBoundary } from "../common/errors/ErrorBoundary";

export const PageLayout = ({
  title = "Untitled Page",
  titleIconName = "folder open",
  IconTitle = null,
  Settings,
  settingsName = "Settings",
  IconSettings = MdOutlineSettings,
  settingsIcon = "setting",
  ...props
}) => {
  const [settings, setsettings] = React.useState(false);
  const toggleSettings = (e) => setsettings((v) => !v);

  return (
    <div className="flex flex-col h-full bg-gray-100  shadow-lg  bg-gradient-to-r from-gray-600 to-indigo-600 pb-10 pt-0.5 ">
      <div className="  text-white text-base lg:text-2xl shadow-inner pt-2 lg:py-8 transition-all bg-white bg-opacity-10  md:m-1 md:rounded-lg  ">
        <div className="flex  px-6 justify-between items-center  ">
          <div className="left">
            <h1 className="mb-2 flex items-center">
              {IconTitle && (
                <span className="flex items-center mr-2">
                  <IconTitle className="mr-1" /> /
                </span>
              )}
              {title}
            </h1>
          </div>

          <div className="right flex-nowrap text-base lg:text-xl">
            {Settings && (
              <button
                className={`px-3 py-2 lg:px-6 lg:py-4 mr-2 text-white rounded-md hover:bg-gray-800 transition-all duration-200 flex items-center ${
                  settings ? "bg-gray-600 shadow-lg border-white border-2" : ""
                }`}
                onClick={toggleSettings}
              >
                {settings ? (
                  <MdKeyboardArrowDown className="mr-2" />
                ) : (
                  <IconSettings className="mr-2" />
                )}
                {settingsName}
              </button>
            )}
          </div>
        </div>
      </div>
      {settings && Settings && (
        <div className="bg-gray-100 md:m-1 flex flex-col items-center justify-around md:flex-row   md:rounded-md transition-all duration-200 py-6">
          <Settings close={() => setsettings(false)} />
        </div>
      )}
      <div className="flex-grow bg-gray-100 md:rounded-lg md:m-1  ">
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </div>
    </div>
  );
};
