import React from "react";

export const IconButton = ({ Icon, onClick, ...rest }) => {
  return (
    <button
      onClick={onClick}
      className="  hover:bg-black hover:bg-opacity-10 rounded cursor-pointer transition-all"
      type="button"
    >
      <Icon className="w-8 h-8 p-1" />
    </button>
  );
};
