import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { listenToTeamUsers } from "../../app/firestore/firestoreService";
import useFirestoreCollection from "../../app/hooks/useFirestoreCollection";
import { PageLayout } from "../../app/layout/PageLayout";
import { MdClear, MdSearch } from "react-icons/md";
import { UserCard } from "../admin/users/UserCard";

export const TeamPage = () => {
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => state.async);
  const { users } = useSelector((state) => state.admin);
  const [search, setSearch] = React.useState("");

  useFirestoreCollection({
    query: () => listenToTeamUsers(),
    data: (jobs) => dispatch({ type: "LISTEN_TO_USERS", payload: jobs }),
    deps: [dispatch],
  });

  return (
    <PageLayout title="Team" titleIconName="users">
      {loading ? (
        <div>Loading</div>
      ) : (
        <div className="flex justify-center flex-col">
          {error && (
            <div>
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </div>
          )}
          <label className="bg-white px-4 py-2 rounded-md flex border text-xl ">
            <MdSearch />
            <input
              type="text"
              value={search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
              className="outline-none w-full px-2 flex-grow"
              placeholder="Search by name, email..."
            />
            {search.length > 0 && <MdClear onClick={() => setSearch("")} />}
          </label>
          {users && users.length > 0 ? (
            <>
              {users
                .sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
                .filter(
                  (u) =>
                    u?.displayName.toLowerCase().includes(search.toLowerCase()) || u?.email.toLowerCase().includes(search.toLowerCase())
                )
                .map((user, i) => (
                  <UserCard key={i} user={user} />
                ))}
            </>
          ) : (
            "No team members found."
          )}
        </div>
      )}
    </PageLayout>
  );
};
