import {} from "./adminConstants";

const initialState = {
  users: [],
  uploads: [],
  emails: [],
};

export default function adminReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "LISTEN_TO_USERS":
      return {
        ...state,
        users: payload,
      };
    case "LISTEN_TO_UPLOADS":
      return {
        ...state,
        uploads: payload,
      };
    case "LISTEN_TO_EMAILS":
      return {
        ...state,
        emails: payload,
      };
    default:
      return state;
  }
}
