import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUploadsRef, manualCompleteUpload } from "../../../app/firestore/firebaseService";
import useFirebaseDatabase from "../../../app/hooks/useFirebaseDatabase";
import { listenToUploads } from "../adminActions";

export const ManageUploads = () => {
  const dispatch = useDispatch();
  const { uploads } = useSelector((state) => state.admin);

  useFirebaseDatabase({
    query: () => getUploadsRef(),
    data: (uploads) => dispatch(listenToUploads(uploads)),
    deps: [dispatch],
  });

  // React.useEffect(() => {
  //   getUploadsRef().on("value", (snapshot) => {
  //     if (!snapshot.exists()) {
  //       return;
  //     }
  //     const uploads = firebaseObjectToArray(snapshot.val()).reverse();
  //     dispatch(listenToUploads(uploads));
  //   });
  //   return () => {
  //     getUploadsRef().off();
  //   };
  // }, [dispatch]);

  return (
    <div>
      Manage Uploads
      {uploads && uploads.length > 0 && (
        <div>
          {uploads.map((session) => (
            <button
              key={session.id}
              className="bg-indigo-700 text-white px-4 py-2 m-1"
              onClick={() => manualCompleteUpload({ sessionId: session.id })}
            >
              {session.id}
            </button>
          ))}
        </div>
      )}
      <div className="text-xs">
        <pre>{JSON.stringify(uploads, null, 2)}</pre>
      </div>
    </div>
  );
};
