import {
  FETCH_GLOBAL_VARIABLES,
  FETCH_INVENTORY,
  FETCH_PDFS,
  FETCH_PDF,
  LISTEN_TO_PACKETS,
  LISTEN_TO_PACKET,
} from "./settingsConstants";

export function listenToInventory(vehicles) {
  return {
    type: FETCH_INVENTORY,
    payload: vehicles,
  };
}
export function listenToPDFs(pdfs) {
  return {
    type: FETCH_PDFS,
    payload: pdfs,
  };
}
export function listenToPDF(pdf) {
  return {
    type: FETCH_PDF,
    payload: pdf,
  };
}
export function listenToPackets(packets) {
  return {
    type: LISTEN_TO_PACKETS,
    payload: packets,
  };
}
export function listenToPacket(packet) {
  return {
    type: LISTEN_TO_PACKET,
    payload: packet,
  };
}
export function listenToGlobalVariables(globalVariables) {
  return {
    type: FETCH_GLOBAL_VARIABLES,
    payload: globalVariables,
  };
}
