import { Route, /*Redirect,*/ Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ModalManager } from "../common/modals/ModalManager";
import { ErrorComponent } from "../common/errors/ErrorComponent";
import { useSelector } from "react-redux";
import { LoadingComponent } from "./LoadingComponent";

import { HomePage } from "../../features/home/HomePage";
import { AccountPage } from "../../features/auth/AccountPage";
import { ProfilePage } from "../../features/profiles/profilePage/ProfilePage";
import { PrivateRoute } from "./PrivateRoute";
import { StorageUnit } from "../../features/warehouse/units/StorageUnit";
import { PdfPrep } from "../../features/settings/pdfs/PdfPrep";
import { Pdfs } from "../../features/settings/pdfs/Pdfs";
import { Labels } from "../../features/warehouse/Labels";
import { Packets } from "../../features/settings/pdfs/Packets";
import { Packet } from "../../features/settings/pdfs/Packet";

import { UnitsPage } from "../../features/warehouse/units/UnitsPage";
import { TruckDetailsPage } from "../../features/warehouse/trucks/TruckDetailsPage";
import { TrucksPage } from "../../features/warehouse/trucks/TrucksPage";
import { BOL as SailorBOL } from "../../features/freight/bols/BOL";
// import { BOL } from "../../features/sfm/bol/BOL";
import { paramountRoutes } from "../../features/paramount/paramountRoutes";
import { Calendar } from "../../features/calendar/Calendar";
import { adminRoutes } from "../../features/admin/adminRoutes";
import { CreateSurvey } from "../../features/sfm/surveys/CreateSurvey";
import React from "react";
import { TeamPage } from "../../features/team/TeamPage";
import { SideBar } from "./SideBar";
import { MoveInOne } from "../../features/Moves/MoveInOne";
import { MakeText } from "../../features/Tools/MakeText";
import { ABHS } from "../../features/Tools/ABHS";
import { TipSplit } from "../../features/Tools/TipSplit";
import { MaterialCalculator } from "../../features/Tools/MaterialCalculator";
import { Appbar } from "./nav/Appbar";
import { Access } from "../../features/Tools/Access";
import { ShipmentReleaseFormstack } from "../../features/warehouse/shipment_release/ShipmentReleaseFormstack";
import { LoadChart } from "../../features/warehouse/loadChart/LoadChart";
import { WarehouseMap } from "../../features/warehouse/map/WarehouseMap";

// http://localhost:3000/sailor/bol/pdf/sailor/MovePros%20-%20Shyegyebai.pdf
const routes = [
  { path: "/", component: HomePage, exact: true, public: true },
  { path: "/calendar", component: Calendar, exact: true },
  { path: "/team", component: TeamPage, exact: true },
  { path: "/dashboard", component: HomePage, exact: true },

  { path: "/sfm/bol", component: MoveInOne, exact: true },
  { path: "/sfm/survey", component: CreateSurvey, exact: true },
  { path: "/freight/bol/:pdfName/", component: SailorBOL, exact: true },
  { path: ["/warehouse/units", "/w/units"], component: UnitsPage },
  { path: "/warehouse/labels", component: Labels, exact: true },
  {
    path: ["/warehouse/loadChart/:opId", "/warehouse/loadChart"],
    component: LoadChart,
    exact: true,
  },
  { path: "/warehouse/labels/:opId", component: Labels, exact: true },
  { path: "/warehouse/trucks", component: TrucksPage, exact: true },
  { path: "/warehouse/map", component: WarehouseMap, exact: true },
  { path: "/w/truck/:truckId", component: TruckDetailsPage },
  { path: "/w/unit/:unitId", component: StorageUnit, exact: true },
  {
    path: "/w/unit/:unitId/release",
    exact: true,
    component: ShipmentReleaseFormstack,
  },
  { path: "/account", component: AccountPage },
  { path: "/error", component: ErrorComponent },
  { path: "/profile/:id", component: ProfilePage },
  { path: "/settings/pdfs", component: Pdfs, exact: true },
  { path: "/tools/sms", component: MakeText, exact: true },
  { path: "/tools/abhs", component: ABHS, exact: true },
  { path: "/tools/tips", component: TipSplit, exact: true },
  { path: "/tools/materials", component: MaterialCalculator, exact: true },
  { path: "/tools/access", component: Access, exact: true },

  {
    path: ["/settings/pdfs/:pdfId/:fileName", "/settings/pdfs/:pdfId"],
    component: PdfPrep,
  },
  { path: "/settings/packets", component: Packets, exact: true },
  {
    path: [
      "/settings/packets/:packetId/:packetName",
      "/settings/packets/:packetId",
    ],
    component: Packet,
  },
  ...paramountRoutes,
  ...adminRoutes,
];

// const redirects = [
//   { from: "bol", to: "/m/new", exact: true },
//   { from: "bingo", to: "/t/loadchart", exact: true },
//   { from: "materials", to: "/t/materials", exact: true },
// ];

export function App() {
  const { initialized } = useSelector((state) => state.async);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const { authenticated } = useSelector((state) => state.auth);

  if (!initialized) return <LoadingComponent content="loading app..." />;
  return (
    <>
      <ModalManager />
      <ToastContainer position="bottom-right" hideProgressBar />
      <div className="flex flex-col items-stretch w-full h-full max-h-[calc(100vh_-_2.5rem)] ">
        {authenticated && (
          <Appbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        )}
        <div className="flex flex-col md:flex-row  flex-grow justify-start min-h-full h-full  ">
          {authenticated && (
            <SideBar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
          )}
          <div className="w-full h-full   ">
            {/* {redirects.map((r, i) => (
            <Redirect key={i} {...r} />
          ))} */}
            <Switch>
              {routes.map((r, i) =>
                r.public ? (
                  <Route key={i} {...r} />
                ) : (
                  <PrivateRoute key={i} {...r} />
                )
              )}
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
}
