import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteImageFromDocInFirestore,
  listenToUnitFromFirestore /*, updateFileData */,
  setMainUnitImageInFirebase,
  updateUnitImageUrlInFirestore,
} from "../../../app/firestore/firestoreService";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";
import { listenToUnit } from "../warehouseActions";
import { FileUpload } from "../../../app/common/form/FileUpload";
import { FaBoxes, FaPallet } from "react-icons/fa";
import { Image } from "../../../app/common/components/Image";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { MdExitToApp } from "react-icons/md";
// import { getFileDownloadUrl } from "../../../app/firestore/firebaseService";

// function getThumbUrl(unit, imgId, img) {
//   return async function (e) {
//     if (!img.thumb) return;
//     if (img.thumb && img.thumb[0] && img.thumb[0].url) return;
//     console.log(img.thumb[0].fullPath);

//     const url = await getFileDownloadUrl(img.thumb[0].fullPath);
//     console.log(url);
//     updateFileData(`warehouse/storage/units/${unit.id}`, `images.${imgId}.thumb`, [
//       { fullPath: img.thumb[0].fullPath, url },
//     ]);
//   };
// }

export const StorageUnit = ({ match, location }) => {
  const dispatch = useDispatch();
  const { unit } = useSelector((state) => state.warehouse);
  const { loading, error } = useSelector((state) => state.async);

  useFirestoreDoc({
    query: () => listenToUnitFromFirestore(match.params.unitId),
    data: (unit) => dispatch(listenToUnit(unit)),
    deps: [dispatch, match.params.unitId],
  });
  if (loading && !unit) return <div className="bg-white py-10 text-center">loading unit {match.params.unitId} ...</div>;

  if (!unit || error)
    return (
      <div className="bg-white py-10 flex justify-center items-center">
        {error && error?.code === "not-found" && (
          <>
            <FaPallet className="mr-2" />
            No unit found.
          </>
        )}{" "}
      </div>
    );

  // if (!unit || location.search === "?t=qr") {
  //   return <div>Not found. Maybe retry qr scan</div>;
  // }
  // if (!!unit.images) {
  //   Object.keys(unit.images).forEach(imgId => {
  //     const file = unit.images[imgId]
  // if (!file?.thumb[0]?.url) {
  //   const thumbUrl = getFileDownloadUrl(file.thumb[0].fullPath);
  // }

  //   })
  // }

  const unitId = unit.id;
  const opId = unit.opId;
  const shipmentId = unit.shipmentId;

  const updateImageUrl = async (imageUrl, imageId) => {
    if (unit && imageId && imageUrl) {
      console.log({ unitId: unit.id, imageId, imageUrl });
      await updateUnitImageUrlInFirestore({
        unitId: unit.id,
        imageId,
        imageUrl,
      });
    }
  };
  const deleteImageFromDoc = async (imageId) => {
    if (unit && imageId) {
      console.log({ unitId: unit.id, imageId });
      await deleteImageFromDocInFirestore({
        unitId: unit.id,
        imageId,
      });
    }
  };

  const setMainUnitImage = ({ imageId, url }) => {
    setMainUnitImageInFirebase({ opId, shipmentId, unitId, imageId, url });
  };

  // const onUploadCallBack = async (uploadedFiles) => {
  //   console.log({ shipmentId: unit.shipmentId, opId: unit.opId, unitId: unit.id, uploadedFiles });
  //   addUnitImagesDataToFirestore({ shipmentId: unit.shipmentId, opId: unit.opId, unitId: unit.id, uploadedFiles });
  // };
  const hasMainPhoto = unit?.images && unit?.mainImgId;

  if (match.params.unitId !== unitId) return <div className="bg-gray-100 w-full h-full flex items-center justify-center">Loading</div>;

  return (
    <div className="container mx-auto px-2">
      {/* <Button label="list files" onClick={() => listDirFiles(`companies/paramount/units/${unit.id}`)} /> */}
      <div className="flex  justify-between items-center bg-white rounded-lg py-10 px-12 my-2 transition-all border border-blue-800">
        <div className="flex flex-col justify-center items-center">
          {unit?.shippers && (
            <span className="text-xl md:text-4xl">{`${Object.values(unit.shippers)
              .map((v) => v.displayName)
              .join(",")}`}</span>
          )}
          <span className="text-xs md:text-xl">{`${unit.refNumber}`}</span>
        </div>
        <div className="flex flex-col justify-center items-center">
          <span className="text-xl md:text-4xl">{`Unit #${unit.number}`}</span>
          <span className="text-gray-300 text-xs md:text-xs">{`${unit.id}`}</span>
        </div>
      </div>
      <div className=" my-2 text-center w-full  flex justify-around">
        {/* link to /shipment/:shipmentId/release so no need
        to send state
        */}
        <Link
          to={{
            pathname: `${match.params.unitId}/release`,
            state: {
              shippers: unit?.shippers
                ? Object.values(unit?.shippers)
                    .map((v) => v?.displayName)
                    .join(",")
                : "",
              refNumber: unit?.refNumber,
              shipmentDate: unit?.created?.at ? format(unit.created.at.toDate(), "MM/dd/yyyy") : format(new Date(), "MM/dd/yyyy"),
              shipmentId: unit?.shipmentId, //will allow to get unit count
            },
          }}
          className=" flex items-center px-10 space-x-2 bg-gray-100 hover:bg-indigo-200 transition-all py-2  rounded-lg  "
        >
          <MdExitToApp />
          <span>Release shipment</span>
        </Link>
        {/* <button type="butto" className="bg-blue-700 text-white py-2">
          Change Location
        </button>
        <button type="butto" className="bg-pink-700 text-white py-2">
          Type
        </button>
        <button type="butto" className="bg-lime-700 text-white py-2">
          Weigh
        </button>
        <button type="butto" className="bg-slate-700 text-white py-2">
          Load Chart
        </button> */}
        <Link
          to={`/warehouse/labels/${opId}`}
          className="bg-gray-100 hover:bg-indigo-200 transition-all py-2 rounded-lg flex items-center px-10 space-x-2  "
        >
          <FaBoxes />
          <span>View all units</span>
        </Link>
      </div>
      <FileUpload
        title="Images"
        dbTargets={[
          {
            docPath: `companies/paramount/units/${unitId}`,
            docKey: "images",
            condition: true,
          },
          {
            docPath: `companies/paramount/operations/${opId}`,
            docKey: `shipments.${shipmentId}.units.${unitId}.thumb`,
            mainThumb: true,
            condition: !hasMainPhoto,
          },
          {
            docPath: `companies/paramount/shipments/${shipmentId}`,
            docKey: `units.${unitId}.thumb`,
            condition: !hasMainPhoto,
            mainThumb: true,
          },
        ]}
        storagePath="companies/paramount/units/"
        docPath="companies/paramount/units/"
        docKey="images"
        docId={unit.id}
        hasMainPhoto={hasMainPhoto}
        // onUploadCallBack={onUploadCallBack}
      >
        {/* <ImgUpload unit={unit} /> */}
        {unit?.images ? (
          <div className="auto-grid-s ">
            {Object.keys(unit.images).length > 0 &&
              Object.keys(unit.images)
                .sort()
                .map((imgId) => (
                  <div
                    className="w-64 m-2   flex items-center "
                    key={imgId}
                    // onClick={getThumbUrl(unit, imgId, unit.images[imgId])}
                  >
                    {unit.images[imgId] && (
                      <Image
                        image={unit.images[imgId]}
                        unitId={unit.id}
                        imageId={imgId}
                        updateImageUrl={updateImageUrl}
                        deleteImageFromDoc={deleteImageFromDoc}
                        setMain={setMainUnitImage}
                        isMain={imgId === unit?.mainImgId}
                      />
                    )}
                  </div>
                ))}
          </div>
        ) : (
          <div className="w-full my-10 text-center text-slate-300">No photos of the unit.</div>
        )}
      </FileUpload>
      {/* <div className="text-xs">
        <pre>{JSON.stringify(unit, null, 2)}</pre>
      </div> */}
    </div>
  );
};

//https://github.com/salimd83/bookslib/blob/f6c39de6a2879ef214df2268c532459f7cafc135/src/functions/imageFn.js
