const initialState = {
  unit: null,
  units: [],
  label: null,
  labels: [],
  trucks: [],
  truck: null,
  operation: null,
};

export function warehouseReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "LISTEN_TO_UNIT":
      return {
        ...state,
        unit: payload,
      };
    case "LISTEN_TO_UNITS":
      return {
        ...state,
        units: payload,
      };
    case "LISTEN_TO_TRUCKS":
      return {
        ...state,
        trucks: payload,
      };
    case "LISTEN_TO_TRUCK":
      return {
        ...state,
        truck: payload,
      };
    case "LISTEN_TO_OPERATION":
      return {
        ...state,
        operation: payload,
      };
    default:
      return state;
  }
}
