import React from "react";
import { useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { closeModal } from "./modalReducer";
import { MdClear } from "react-icons/md";

export const ModalWrapper = ({
  children,
  size,
  header,
  HeaderIcon = null,
  initialFocusRef = null,
}) => {
  const ref = React.useRef();
  const dispatch = useDispatch();
  return (
    <Transition.Root show={true} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={ref}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="">
                <div className=" text-center   sm:text-left">
                  <div className="flex  items-center space-x-4 ml-2 mt-2 justify-between px-2">
                    <div>

                    {HeaderIcon && (
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 mr-4 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                        <HeaderIcon
                          className="h-6 w-6 text-blue-500"
                          aria-hidden="true"
                          />
                      </div>
                    )}

                    {header && (
                      <Dialog.Title
                      as="h3"
                      className="text-lg  leading-6 font-medium text-gray-900"
                      >
                        {header}
                      </Dialog.Title>
                    )}
                    </div>
                    <button type='button' onClick={() => dispatch(closeModal())} className="mx-2 rounded-full cursor-pointer px-2 py-2 text-xl hover:bg-gray-200"><MdClear /></button>
                  </div>
                  <div className="mt-2 mb-2 w-full " ref={ref}>
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
