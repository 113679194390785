import {
  MdArticle,
  MdCompress,
  MdDvr,
  MdImage,
  MdOutlineFeed,
  MdPictureAsPdf,
  MdVideoLibrary,
} from "react-icons/md";

export const FileIcon = ({ fileType = "", ...rest }) => {
  switch (fileType) {
    case "application/pdf":
      return <MdPictureAsPdf {...rest} />;
    case "application/x-msdownload":
      return <MdDvr {...rest} />;
    case "image/png":
    case "image/jpg":
    case "image/jpeg":
      return <MdImage {...rest} />;
    case "video/mp4":
    case "video/x-matroska":
    case "video/quicktime":
      return <MdVideoLibrary {...rest} />;
    case "text/plain":
      return <MdArticle {...rest} />;
    case "application/x-zip-compressed":
      return <MdCompress {...rest} />;
    default:
      return <MdOutlineFeed {...rest} />;
  }
};
