import cuid from "cuid";
// import download from "downloadjs";
import { PDFDocument } from "pdf-lib";
import React, { useReducer } from "react";
import { MdAdd, MdClear, MdPhoto } from "react-icons/md";
import { Checkbox } from "../../../app/common/form/Checkbox";
import { FileUpload } from "../../../app/common/form/FileUpload";
import { getFormattedDate } from "../../../app/common/util/helperFunctions";
// import { signedBOL } from "../../../app/firestore/firestoreService";
// import { arnasSignature } from "../../sailor/arnasSignature";

// import { SignatureBlock } from "../../app/common/form/SignatureBlock";
// import { getFormattedDate } from "../../app/common/util/helperFunctions";
// import { signedBOL } from "../../app/firestore/firestoreService";

// import { arnasSignature } from "./arnasSignature";

const bol1 = "TNT - Dublish.pdf";
// const bol2 = "MovePros - Shyegyebai.pdf";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "ADD_ROOM":
      const roomId = cuid();
      return {
        ...state,
        inspection: {
          ...state.inspection,
          [roomId]: { id: roomId, room: payload },
        },
      };
    case "UPDATE_ROOM_INSPECTION":
      //payload: {ckwpges6y0004386bnpypdasf: 'df'}
      return {
        ...state,
        inspection: {
          ...state.inspection,
          [payload.roomId]: {
            ...state.inspection[payload.roomId],
            [payload.inspectionId]: payload.value,
          },
        },
      };
    case "REMOVE_ROOM":
      const temp = state.inspection;
      delete temp[payload];
      return {
        ...state,
        inspection: temp,
      };
    case "ACCESSORIALS_TOGGLE":
      return {
        ...state,
        accessorials: {
          ...state.accessorials,
          [payload.id]: {
            ...state.accessorials[payload.id],
            checked: payload.checked,
          },
        },
      };
    case "ACCESSORIALS_CONTENTS_UPDATE":
      // accessorials[acc].contents[prop].value
      return {
        ...state,
        accessorials: {
          ...state.accessorials,
          [payload.id]: {
            ...state.accessorials[payload.id],
            contents: {
              ...state.accessorials[payload.id].contents,
              [payload.prop]: {
                ...state.accessorials[payload.id].contents[payload.prop],
                value: payload.value,
              },
            },
          },
        },
      };
    case "POST_MOVE_DAMAGE":
      return {
        ...state,
        postMoveDamage: payload,
      };
    case "ADD_CRATE":
      return {
        ...state,
        crates: [
          ...state.crates,
          {
            id: cuid(),
            number: state.crates.length > 0 ? state.crates.length : 1,
          },
        ],
      };
    case "DELETE_CRATE":
      return {
        ...state,
        crates: state.crates.filter(({ id }) => id !== payload.id),
      };
    case "UPDATE_CRATE":
      return {
        ...state,
        crates: state.crates.map((crate, i) =>
          crate.id === payload.id
            ? { ...crate, [payload.name]: payload.value }
            : crate
        ),
      };
    case "QUESTION_ANSWER":
      return {
        ...state,
        questionaire: {
          ...state.questionaire,
          [payload.id]: {
            ...state.questionaire[payload.id],
            value: payload.value,
          },
        },
      };
    case "QUESTION_ANSWER_FOLLOW_UP":
      return {
        ...state,
        questionaire: {
          ...state.questionaire,
          [payload.qId]: {
            ...state.questionaire[payload.qId],
            [payload.fuId]: {
              ...state.questionaire[payload.qId][payload.fuId],
              value: payload.value,
            },
          },
        },
      };

    // type: "UPDATE_CRATE",
    // payload: { id: crate.id, name: e.target.name, value: e.target.value },

    default:
      return state;
  }
};

const accessorials = {
  1: { name: "Containers Pkg/Unpkg" },
  2: { name: "Appliance Servicing" },
  3: { name: "Extra Pick up/Delivery" },
  4: { name: "Auxiliary/Shuttle Service" },
  5: {
    name: "Elevator/Stair/Long Carry",
    contents: {
      elevator: { value: false, label: "Elevator" },
      stairs: { value: "", label: "What is the stair count?" },
      longCarry: { value: "", label: "What is the long carry distance?" },
    },
  },
  6: { name: "Piano/Organ" },
  7: { name: "Bulky Article" },
  8: { name: "Reweigh" },
  9: { name: "Waiting Time" },
  10: { name: "Storage" },
  11: { name: "Overtime packing/unpacking" },
  12: { name: "Overtime loading/unloading" },
  13: { name: "Other" },
};

const inventory = {
  1: { name: "5.0 ctn - Glassware" },
  2: { name: "5.0 ctn -  pots" },
  3: { name: "ironing board" },
  4: { name: "4.5 ctn -  basket" },
  5: { name: "4.5 - blue bins" },
  6: { name: "5.0 ctn -tv mcu" },
  7: { name: "wd - clothes" },
  8: { name: "wd - clothes/basket" },
  9: { name: "3.0 ctn - cat food" },
};

const questions = {
  1: {
    question: "Have all items listed on the packing list been delivered?",
    falseFollowup: {
      question: "Please state which item numbers are missing",
      type: "string",
    },
  },
  2: {
    question:
      "Has the Packing List been reviewed and signed by both the Crew Leader and customer?",
    falseFollowup: { question: "Why Not?", type: "string" },
  },
  3: {
    question: "Are any items to be placed into Permanent storage?",
    trueFollowup: {
      question:
        "Has a separate Packing List been completed and reviewed by both the Crew Leader and Customer?",
      type: "check",
      falseFollowup: { question: "Why Not?", type: "string" },
    },
  },
  4: {
    question: "Are any items damaged?",
    trueFollowup: {
      question: "Have photos of the damages been taken by the crew leader?",
      type: "check",
      trueFollowup: {
        question: "Note damaged item numbers here",
        type: "string",
      },
      falseFollowup: { question: "Why Not?", type: "string" },
    },
  },
  5: {
    question:
      "Have all items been placed in the preferred location in the residence?",
    falseFollowup: { question: "Why Not?", type: "string" },
  },
  6: {
    question:
      "Have all items requested to be unpacked and debris removed from the residence to the customers satisfaction?",
    falseFollowup: { question: "Why Not?", type: "string" },
  },
  7: {
    question:
      "Was there any damage caused to the residence during the delivery and move in process?",
    trueFollowup: {
      question: "Please describe the damage here and take photos",
      type: "string",
    },
  },
};

// const feedbackOptions = ["Excellent", "Very Good", "Average", "Poor", "Very Poor"];

const rooms = [
  "Bedroom",
  "Dining Room",
  "Entrance",
  "Family Room",
  "Garage",
  "Kitchen",
  "Landing",
  "Living Room",
  "Master Bedroom",
  "Office",
  "Staircase",
  "Other",
];

const initialState = {
  date: getFormattedDate(new Date()),
  // shipperSignature: arnasSignature,
  // shipperInitials: arnasSignature,
  // shipperPrintName: arnasSignature,
  // crewLeadSignature: arnasSignature,
  // crewLeadPrintName: arnasSignature,
  //   preMoveInspection: "",
  //   postMoveInspection: "",
  inspection: {},
  postMoveDamage: null,
  accessorials,
  crates: [],
  inventory: inventory,
  questionaire: questions,
  time: {
    arrival: "",
    departure: "",
  },
  feedback: {
    crewNotes: "",
    shipperNotes: "",
    shipperRating: "",
  },
};

export const PtsJobDocSign = ({ match }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // const pdfName = match.params.jobId || bol1;
  const jobId = match.params.jobId || bol1;

  // const getPDF = async (e) => {
  //   signedBOL({ fileName: pdfName, date: getFormattedDate(new Date()), ...state });
  //   download(await bolFill(pdfName, state), `${pdfName} signed ${getFormattedDate(new Date())}.pdf`, "application/pdf");
  // };

  // const sigDispatch = ({ field, value }) => {
  //   dispatch({ payload: { [field]: value } });
  // };

  return (
    <div className="bg-white rounded-md border p-5 text-xl">
      <div>{jobId}</div>
      {/* <div className="flex justify-center py-10 text-3xl">
        <a href={`/pdf/sailor/${pdfName}`} target="_blank" rel="noopener noreferrer">
          {pdfName}
        </a>
      </div>
      <div className="bg-pink-300 p-2 rounded-lg text-3xl">
        <span> Crew Lead Signature </span>
        <SignatureBlock
          data={state.crewLeadSignature}
          dispatch={sigDispatch}
          type="signature"
          name="crewLeadSignature"
        />
      </div>
      <div className="bg-green-300 p-2 rounded-lg text-3xl">
        <span> Shipper Signature </span>
        <SignatureBlock data={state.shipperSignature} dispatch={sigDispatch} type="signature" name="shipperSignature" />
      </div>
      <div
        className="flex justify-center my-4 bg-green-400 rounded-sm shadow-md py-4 transition-all hover:bg-green-300 cursor-pointer text-white"
        onClick={getPDF}
      >
        <span>Download </span>
      </div> */}
      <Inspection
        inspection={state.inspection}
        inspectionId="preMoveInspection"
        name="Pre-Move Inspection"
        dispatch={dispatch}
      />

      <Accessorials
        jobId={jobId}
        dispatch={dispatch}
        accessorials={state.accessorials}
      />
      <Crates dispatch={dispatch} crates={state.crates} jobId={jobId} />
      <Inventory
        name="Destination Inventory"
        dispatch={dispatch}
        jobId={jobId}
      />
      <LoadChart name="Unload Chart" dispatch={dispatch} jobId={jobId} />
      <Questionaire
        name="Post Delivery Report"
        questionaire={state.questionaire}
        dispatch={dispatch}
        jobId={jobId}
      />
      {state.postMoveDamage === null && (
        <div className="flex flex-col justify-center py-10 m-4 bg-red-50 rounded-lg text-3xl">
          <span className="text-center py-2 pb-10">
            Any property damage during move?
          </span>
          <div className="flex justify-evenly">
            <button
              type="button"
              className="px-4 w-1/3 py-4 rounded-lg bg-gray-500 bg-opacity-10"
              onClick={() =>
                dispatch({ type: "POST_MOVE_DAMAGE", payload: false })
              }
            >
              No
            </button>
            <button
              type="button"
              className="px-4 w-1/3 py-4 rounded-lg bg-gray-500 bg-opacity-10"
              onClick={() =>
                dispatch({ type: "POST_MOVE_DAMAGE", payload: true })
              }
            >
              Yes
            </button>
          </div>
        </div>
      )}
      {state.postMoveDamage && (
        <Inspection
          inspection={state.inspection}
          inspectionId="postMoveInspection"
          name="Post-Move Inspection"
          dispatch={dispatch}
        />
      )}
      <div className="text-xs">
        {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
      </div>
    </div>
  );
};

const Inspection = ({
  jobId,
  inspectionId,
  name,
  dispatch,
  inspection,
  ...props
}) => {
  function updateInput(e) {
    dispatch({
      type: "UPDATE_ROOM_INSPECTION",
      payload: {
        value: e.target.value,
        inspectionId,
        roomId: e.target.name,
      },
    });
  }

  const inputRef = React.useRef();

  return (
    <div>
      <FileUpload
        title={name || "Initial Residence Inspection"}
        storagePath="paramount/inspection/"
        documentPath="paramount/"
        documentKey="preInspectionImages"
        docId={jobId}
      >
        <div className="flex flex-col">
          {inspection &&
            Object.keys(inspection).map((room, i) => {
              return (
                <div key={i} className="flex items-center">
                  <label className="flex-grow relative">
                    <span className="text-xs absolute top-2 left-6">
                      {inspection[room].room}
                    </span>
                    <input
                      ref={inputRef}
                      className="border-2 px-6 pt-4 pb-2  w-full rounded-md m-1 focus:outline-none"
                      value={inspection[room][inspectionId] || ""}
                      onChange={updateInput}
                      name={inspection[room].id}
                    />
                  </label>
                  <div>
                    <button
                      className="mx-4 px-2 py-1 bg-green-50 hover:bg-green-300 rounded-md"
                      type="button"
                      onClick={() =>
                        dispatch({ type: "ADD_PHOTO", payload: room })
                      }
                    >
                      <MdPhoto />
                    </button>
                    <button
                      className="mx-4 px-2 py-1 bg-red-50 hover:bg-red-400 rounded-md"
                      type="button"
                      onClick={() =>
                        dispatch({ type: "REMOVE_ROOM", payload: room })
                      }
                    >
                      <MdClear />
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        <div>
          <div className="flex">
            {rooms.map((room, i) => (
              <button
                className="m-1 px-2 py-1 bg-blue-600 text-white rounded-md text-xs"
                key={i}
                type="buton"
                onClick={() => {
                  dispatch({ type: "ADD_ROOM", payload: room });
                }}
              >
                + {room}
              </button>
            ))}
          </div>
        </div>
      </FileUpload>
    </div>
  );
};
const Accessorials = ({ jobId, name, dispatch, accessorials, ...props }) => {
  return (
    <div className="my-2">
      <FileUpload
        title="Accessorials"
        storagePath="paramount/accessorials/"
        documentPath="paramount/"
        documentKey="asccessorialImages"
        docId={jobId}
      >
        <div>
          {Object.keys(accessorials).map((acc, i) => {
            return (
              <div key={i}>
                <Checkbox
                  name="disableNumbers"
                  label={`${acc} - ${accessorials[acc].name}`}
                  checked={accessorials[acc].checked || false}
                  onChange={(e) => {
                    dispatch({
                      type: "ACCESSORIALS_TOGGLE",
                      payload: { id: acc, checked: e.target.checked },
                    });
                  }}
                  key={i}
                />
                {accessorials[acc].checked && (
                  <div className="pl-10 flex-col flex">
                    {accessorials[acc].contents &&
                      Object.keys(accessorials[acc].contents).length > 0 &&
                      Object.keys(accessorials[acc].contents).map((prop) => {
                        return typeof accessorials[acc].contents[prop].value ===
                          "boolean" ? (
                          <Checkbox
                            name={accessorials[acc].contents[prop].label}
                            label={accessorials[acc].contents[prop].label}
                            checked={
                              accessorials[acc].contents[prop].value || false
                            }
                            onChange={(e) => {
                              dispatch({
                                type: "ACCESSORIALS_CONTENTS_UPDATE",
                                payload: {
                                  id: acc,
                                  prop,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            key={i}
                          />
                        ) : (
                          <label
                            className="px-4  rounded-md hover:bg-blue-100 m-1 "
                            key={i + prop}
                          >
                            {" "}
                            {accessorials[acc].contents[prop].label}{" "}
                            <input
                              className="p-2 w-24 focus:outline-none border rounded-md text-center  "
                              value={
                                accessorials[acc].contents[prop].value || ""
                              }
                              onChange={(e) => {
                                dispatch({
                                  type: "ACCESSORIALS_CONTENTS_UPDATE",
                                  payload: {
                                    id: acc,
                                    prop,
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                          </label>
                        );
                      })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </FileUpload>
    </div>
  );
};

const Input = ({ crate, name, ...props }) => (
  <input
    {...props}
    name={name}
    value={!!crate[name] ? crate[name] : ""}
    autoComplete="off"
    className={
      props.className +
      "bg-transparent outline-none px-2 py-1 m-1 focus:border-gray-400 hover:bg-blue-50 hover:bg-opacity-10 border rounded-sm w-full transition-all text-center"
    }
  />
);

const Crates = ({ jobId, crates, name, dispatch, ...props }) => {
  const handleOnChange = (crate) => {
    return function (e) {
      dispatch({
        type: "UPDATE_CRATE",
        payload: { id: crate.id, name: e.target.name, value: e.target.value },
      });
    };
  };

  return (
    <div className="my-2">
      <FileUpload
        title={name || "Crating/Uncrating List"}
        storagePath="paramount/accessorials/"
        documentPath="paramount/"
        documentKey="asccessorialImages"
        docId={jobId}
      >
        <div>
          {crates && crates.length > 0 ? (
            <table className="w-full border-2  my-4 rounded-md overflow-hidden">
              <thead>
                <tr>
                  <th className="w-1/12">Crate #</th>
                  <th className="w-5/12">Description</th>
                  <th className="w-1/12">Inv #</th>
                  <th className="w-2/12">INCHES, L x W x H</th>
                  <th className="w-1/12">Gross CU FT</th>
                  <th className="w-1/12">USD Value</th>
                  <th className="w-1/12"></th>
                </tr>
              </thead>
              <tbody>
                {crates.map((crate, i) => {
                  return (
                    <tr key={i} className="hover:bg-gray-50">
                      <td className="text-center">{i + 1}</td>
                      <td>
                        <Input
                          crate={crate}
                          name="description"
                          onChange={handleOnChange(crate)}
                        />
                      </td>
                      <td>
                        <Input
                          crate={crate}
                          name="inventoryNumber"
                          onChange={handleOnChange(crate)}
                        />
                      </td>
                      <td>
                        <Input
                          crate={crate}
                          name="dimmensions"
                          onChange={handleOnChange(crate)}
                          placeholder="L x W x H"
                        />
                      </td>
                      <td>
                        <Input
                          crate={crate}
                          name="cubicFootage"
                          onChange={handleOnChange(crate)}
                        />
                      </td>
                      <td>
                        <Input
                          crate={crate}
                          name="usdValue"
                          onChange={handleOnChange(crate)}
                        />
                      </td>
                      <td className="flex justify-center items-center ">
                        <button
                          onClick={(e) =>
                            dispatch({
                              type: "DELETE_CRATE",
                              payload: { id: crate.id },
                            })
                          }
                        >
                          <MdClear />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="flex transition-all justify-center my-10 text-gray-400">
              {" "}
              No Crates{" "}
            </div>
          )}
        </div>
        <button
          type="button"
          className="px-6 py-2 bg-indigo-700 rounded-md text-white w-full hover:bg-indigo-600 transition-all flex items-center justify-center"
          onClick={() => {
            dispatch({ type: "ADD_CRATE" });
          }}
        >
          <MdAdd /> Add Crate
        </button>
      </FileUpload>
    </div>
  );
};
const Inventory = ({ jobId, name, dispatch, ...props }) => {
  return (
    <div>
      <h1>{name || "Inventory"}</h1>
    </div>
  );
};
const LoadChart = ({ jobId, name, dispatch, ...props }) => {
  return (
    <div>
      <h1>{name || "Load Chart"}</h1>
    </div>
  );
};
const Questionaire = ({ jobId, questionaire, name, dispatch, ...props }) => {
  return (
    <div>
      <h1>{name || "Feedback Report"}</h1>
      <div>
        {Object.keys(questionaire).map((q, i) => {
          return (
            <div>
              <div>{`${q}. ${questionaire[q].question} `}</div>
              <div>
                <button
                  type="button"
                  className={`px-4 py-2 rounded-md  m-1 w-36 border-2 bg-transparent border-blue-400 ${
                    questionaire[q].value === "true" ? "bg-blue-400" : ""
                  }`}
                  onClick={() => {
                    dispatch({
                      type: "QUESTION_ANSWER",
                      payload: { value: "true", id: q },
                    });
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={`px-4 py-2 rounded-md  m-1 w-36 border-2 bg-transparent border-red-400  ${
                    questionaire[q].value === "false" ? "bg-red-400" : ""
                  }`}
                  onClick={() => {
                    dispatch({
                      type: "QUESTION_ANSWER",
                      payload: { value: "false", id: q },
                    });
                  }}
                >
                  No
                </button>
              </div>
              {["trueFollowup", "falseFollowup"].map((followUp, j) => {
                return (
                  <>
                    {
                      //@TODO ONE MORE NESTED QUESTION
                      questionaire[q].value &&
                        questionaire[q].value ===
                          (j === 0 ? "true" : "false") &&
                        questionaire[q][followUp] && (
                          <div className="ml-10 my-2">
                            {` ${i + 1}.${j + 1} ${
                              questionaire[q][followUp].question
                            }`}
                            <div>
                              {questionaire[q][followUp].type === "check" ? (
                                <div>
                                  {" "}
                                  <button
                                    type="button"
                                    className={`px-4 py-2 rounded-md  m-1 w-36 border-2 bg-transparent border-blue-400 ${
                                      questionaire[q][followUp].value === "true"
                                        ? "bg-blue-400"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      dispatch({
                                        type: "QUESTION_ANSWER_FOLLOW_UP",
                                        payload: {
                                          value: "true",
                                          qId: q,
                                          fuId: followUp,
                                        },
                                      });
                                    }}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    type="button"
                                    className={`px-4 py-2 rounded-md  m-1 w-36 border-2 bg-transparent border-red-400  ${
                                      questionaire[q][followUp].value ===
                                      "false"
                                        ? "bg-red-400"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      dispatch({
                                        type: "QUESTION_ANSWER_FOLLOW_UP",
                                        payload: {
                                          value: "false",
                                          qId: q,
                                          fuId: followUp,
                                        },
                                      });
                                    }}
                                  >
                                    No
                                  </button>
                                </div>
                              ) : (
                                <input
                                  placeholder="jeeeees"
                                  className="px-4 py-2 border-black border-2 rounded-md w-full"
                                />
                              )}
                            </div>
                          </div>
                        )
                    }
                  </>
                );
              })}
            </div>
          );
        })}
      </div>
      <div>
        {/* <pre className="text-xs">{JSON.stringify(questionaire, null, 2)}</pre> */}
      </div>
    </div>
  );
};

export async function bolFill(pdfName, data) {
  const pdf = `pdf/sailor/${pdfName}`;
  const baseUrl =
    window.location.origin.toString() + process.env.PUBLIC_URL + "/";
  const formUrl = baseUrl + pdf;
  const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(formPdfBytes);

  const pages = pdfDoc.getPages();
  const form = pdfDoc.getForm();

  const { date, crewLeadSignature: carrierSignature, shipperSignature } = data;

  const setSignature = async (signature, x, y, width = 25, height = 10) => {
    if (!signature || !x || !y) return;
    const bytes = await fetch(signature).then((res) => res.arrayBuffer());
    const image = await pdfDoc.embedPng(bytes);
    return pages[0].drawImage(image, { x, y, height, width });
  };

  const setTextField = ({ text, x, y, size = 10, page = 0 }) => {
    if (!text || !x || !y) return;
    console.log("setting trext");
    return pages[page].drawText(text, { x, y, size });
  };

  await setSignature(shipperSignature, 72, 163, 180, 36);
  await setTextField({ text: date, x: 230, y: 180 });
  await setSignature(carrierSignature, 310, 163, 180, 36);
  await setTextField({ text: date, x: 460, y: 180 });

  form.flatten();
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
}
