// import { Popover } from "@headlessui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CountButton from "../../../app/common/form/CountButton";
import { listenToOperationInFirestore } from "../../../app/firestore/firestoreService";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";
import { PageLayout } from "../../../app/layout/PageLayout";
import { listenToOperation } from "../paramountActions";
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} from "unique-names-generator";
import {
  MdAdd,
  MdClear,
  MdCopyAll,
  MdLock,
  MdOutlineAddCircleOutline,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
  MdPhoto,
  MdZoomIn,
} from "react-icons/md";

const capitalizedName = uniqueNamesGenerator({
  dictionaries: [colors, adjectives, animals],
  style: "capital",
  separator: " ",
});

export const Operation = ({ match }) => {
  const dispatch = useDispatch();
  const { operation } = useSelector((state) => state.paramount);
  // const { currentUser } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.async);

  useFirestoreDoc({
    query: () => listenToOperationInFirestore(match.params.opId),
    data: (operation) => dispatch(listenToOperation(operation)),
    deps: [dispatch, match.params.opId],
  });

  const [codename] = React.useState(capitalizedName);

  if (loading) return <div>Loading...</div>;
  if (!operation || error) return <div>Not found</div>;

  return (
    <PageLayout
      title={`Operation ${Object.values(operation.shippers)
        .map((shipper) => shipper?.displayName)
        .join(", ")}`}
    >
      {/* <div className="flex justify-around">
        <div></div>
        <div className="text-xs">
          <pre>{JSON.stringify(operation, null, 2)}</pre>
        </div>
      </div> */}
      <div className="flex">
        <div>
          <Section title="Details" disableAdd={true}>
            <Input icon="user" label="Reference Number" value="455872" />
            <Input
              icon="user"
              label="Codename"
              value={operation?.codeName || codename}
            />
          </Section>
          <Section title="Account" defaultShow={true} disableAdd={true}>
            <Accounts />
            {operation?.accounts &&
              Object.values(operation.accounts).map((account, i) => (
                <Account key={i} account={account} />
              ))}
          </Section>
        </div>
        <div>
          <Section title="Shipper">
            <Shipper />
            {operation?.shippers &&
              Object.values(operation.shippers).map((shipper, i) => (
                <Shipper key={i} shipper={shipper} />
              ))}
          </Section>
        </div>
        <div className="w-full">
          <Section title="Invoices" defaultShow={false}>
            {operation?.invoices &&
              Object.values(operation.invoices).map((invoice, i) => (
                <Invoice key={i} invoice={invoice} />
              ))}
          </Section>
          <Section title="Services">
            {operation?.services &&
              Object.values(operation.services).map((service, i) => (
                <Service key={i} service={service} />
              ))}
          </Section>
          <Section title="Document Sets">
            {operation?.docs &&
              Object.values(operation.docs).map((doc, i) => (
                <Doc key={i} doc={doc} />
              ))}
          </Section>
          <Section title="Jobs">
            {operation?.jobs &&
              Object.values(operation.jobs).map((job, i) => (
                <Job key={i} job={job} />
              ))}
          </Section>
          <Section title="Claims" defaultShow={false}>
            {operation?.claims &&
              Object.values(operation.claims).map((claim, i) => (
                <Claim key={i} claim={claim} />
              ))}
          </Section>
          <Section title="Quotes" defaultShow={false}>
            {operation?.quotes &&
              Object.values(operation.quotes).map((quote, i) => (
                <Quote key={i} quote={quote} />
              ))}
          </Section>
          <Section title="Shipments">
            {operation?.shipments &&
              Object.values(operation.shipments).map((shipment, i) => (
                <Shipment key={i} shipment={shipment} />
              ))}
          </Section>
        </div>
      </div>
    </PageLayout>
  );
};

const Shipment = ({ shipment, shipmentRef, ...props }) => {
  console.log(shipment);
  return (
    <SubSection title={shipment?.refNumber}>
      <ShipmentUnits units={shipment.units} />
    </SubSection>
  );
};

const ShipmentUnits = ({ units, ...props }) => {
  return (
    <div>
      <span>Shipment Units</span>
      <div className="auto-grid-s select-none">
        {/* {units &&
          units.length > 0 &&
          units
            .sort(function (a, b) {
              return a.number - b.number;
            })
            .map((unit) => (
              <UnitPreview key={unit?.id} unit={unit}>
                {unit?.id}
              </UnitPreview>
            ))} */}
        {units &&
          Object.keys(units).length > 0 &&
          Object.entries(units)
            .sort(function (a, b) {
              return a[1].number - b[1].number;
            })
            .map((unit) => (
              <UnitPreview key={unit[0]} unit={{ id: unit[0], ...unit[1] }} />
            ))}
        {/* <AddUnit /> */}
      </div>
    </div>
  );
};

const UnitPreview = ({ unit, ...props }) => {
  if (!unit.images)
    return (
      <div
        className="bg-gray-200 p-2 rounded-lg w-40 h-48 mx-auto my-1 relative"
        onClick={() => console.log(unit)}
      >
        <img src="/img/unitTypes/liftvan.png" alt="Liftvan" />

        {unit.number && (
          <button
            type="button"
            className="absolute left-1 top-1 bg-white bg-opacity-70 border text-xl px-3 py-2 rounded-lg opacity-20 hover:opacity-100 transition-all"
          >
            <MdPhoto />
          </button>
        )}
        {unit.number && (
          <button
            type="button"
            className="absolute right-1 top-1 bg-white bg-opacity-70 border text-xl px-3 py-2 rounded-lg opacity-20 hover:opacity-100 transition-all"
          >
            <MdZoomIn />
          </button>
        )}
        {unit.number && (
          <span className="absolute right-2 bottom-2 bg-white bg-opacity-70 border text-md px-3 py-1 rounded-lg">
            Liftvan # {unit.number}
          </span>
        )}
      </div>
    );

  return <div></div>;
};

// const FileIcon = ({ fileType = "", ...rest }) => <Icon name={getIconName(fileType)} {...rest} />;
// function getIconName(type) {
//   switch (type) {
//     case "application/pdf":
//       return "file pdf outline";
//     case "application/x-msdownload":
//       return "window restore outline";
//     case "image/png":
//     case "image/jpg":
//     case "image/jpeg":
//       return "file image outline";
//     case "video/mp4":
//     case "video/x-matroska":
//     case "video/quicktime":
//       return "file video outline";
//     case "text/plain":
//       return "file alternate outline";
//     case "application/x-zip-compressed":
//       return "file archive outline";
//     default:
//       return "file outline";
//   }
// }

const Section = ({
  title = "",
  defaultShow = true,
  children,
  disableAdd = false,
  ...props
}) => {
  const [show, setShow] = React.useState(defaultShow);
  const toggleShow = () => setShow((s) => !s);
  return (
    <div className="flex flex-col px-2">
      <div
        className={`border border-indigo-500 flex justify-between items-center px-2 py-1 bg-indigo-100 mt-2 ${
          show ? "rounded-t-lg" : "rounded-lg"
        } cursor-pointer hover:bg-indigo-200 transition-all`}
        onClick={toggleShow}
      >
        <span
          className={`text-xl text-indigo-800  select-none flex items-center `}
        >
          <MdOutlineKeyboardArrowRight
            className={`${show ? "rotate-90" : ""} transition-all`}
          />
          {title}
        </span>
        {!disableAdd && (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setShow(true);
            }}
            className="px-4 py-2  text-indigo-500 hover:text-white"
          >
            <MdOutlineAddCircleOutline />
          </button>
        )}
      </div>
      <div className=" transition-all bg-white rounded-b-lg">
        {show &&
          (children || (
            <div className="w-full text-center py-10">No {title}</div>
          ))}
      </div>
    </div>
  );
};

const SubSection = ({ title = "", children, ...props }) => {
  const [show, setShow] = React.useState(true);
  const toggleShow = () => setShow((s) => !s);
  return (
    <div className="flex flex-col">
      <div
        className="flex justify-between items-center space-x-2 px-6 py-2 bg-indigo-300 hover:bg-indigo-600 hover:text-white cursor-pointer transition-all"
        onClick={toggleShow}
      >
        <span className="text-xs select-none flex items-center">
          {show ? (
            <MdOutlineKeyboardArrowDown />
          ) : (
            <MdOutlineKeyboardArrowRight />
          )}{" "}
          <span className="text-xl">{title} </span>
          <button
            type="button"
            className="px-1 py-1 mx-2 text-white flex items-center  rounded-full border border-white hover:bg-black hover:bg-opacity-20 transition-all"
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(title);
              toast("Copied - " + title);
            }}
          >
            <MdCopyAll />
          </button>
        </span>
      </div>
      {show && children}
    </div>
  );
};

const AddUnit = ({ ...props }) => {
  const [activeAdd, setActiveAdd] = React.useState(false);
  const [shipmentCompleted, setShipmentCompleted] = React.useState(false);
  const [successAdd, setsuccessAdd] = React.useState(false);
  const [count, setCount] = React.useState(1);
  const activeToggle = (e) => setActiveAdd((a) => !a);
  return (
    <div
      type="button"
      className="bg-gray-100 border p-2 rounded-lg w-40 h-48  text-gray-400 hover:bg-gray-200 transition-all "
    >
      {activeAdd ? (
        <>
          {successAdd ? (
            <div className="flex flex-col justify-around w-full h-full items-center">
              <div className="text-xl">
                <MdLock />
              </div>
              <span className="text-center text-xl">
                Will there be more units?
              </span>
              <div className="flex justify-around w-full text-xl">
                <button
                  type="button"
                  className="px-4 py-2 border-2 border-green-400 rounded-lg hover:bg-green-300 hover:text-white"
                  onClick={() => {
                    setCount(1);
                    setShipmentCompleted(false);
                    setActiveAdd(false);
                    setsuccessAdd(false);
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="px-4 py-2 border-2 border-red-400 rounded-lg hover:bg-red-300 hover:text-white"
                  onClick={() => {
                    setCount(1);
                    setsuccessAdd(false);
                    setActiveAdd(false);
                    setShipmentCompleted(true);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-end relative w-full h-full">
              <button
                type="button"
                className=" mx-auto my-1  flex items-center text-2xl flex-col justify-center group  absolute top-1 right-1 hover:text-red-400"
                onClick={activeToggle}
              >
                <MdClear />
              </button>
              <CountButton count={count} changeCount={setCount} min={1} />
              <button
                type="button"
                className="py-2  mt-4 border border-green-400 bg-green-50 text-gray-400 w-full rounded-lg  flex items-center text-lg justify-around group   top-1 right-1 hover:text-green-600 hover:bg-green-300 transition-all"
                onClick={() => setsuccessAdd(true)}
              >
                Add {count} Unit{count > 1 ? "s" : ""}
              </button>{" "}
            </div>
          )}
        </>
      ) : shipmentCompleted ? (
        <button
          type="button"
          className="w-full h-full mx-auto my-1 relative flex items-center text-2xl flex-col justify-center group "
          onClick={() => setShipmentCompleted(false)}
        >
          <MdLock />
          <span className="invisible group-hover:visible mt-4 text-md absolute bottom-10">
            Unlock
          </span>
          <span className="invisible group-hover:visible mt-4 text-xs absolute bottom-5">
            Shipment is completed
          </span>
        </button>
      ) : (
        <button
          type="button"
          className="w-full h-full mx-auto my-1 relative flex items-center text-2xl flex-col justify-center group "
          onClick={activeToggle}
        >
          <MdAdd />
          <span className="invisible group-hover:visible mt-4 text-md absolute bottom-10">
            Add Unit
          </span>
        </button>
      )}
    </div>
  );
};

// function UnitDetails({
//   to,
//   label,
//   icon,
//   links,
//   imageIconSrc,
//   role = [],
//   userRole = {},
//   setOpen,
// }) {
//   return (
//     <Popover className="relative select-none">
//       {({ open, close }) => {
//         return (
//           <>
//             <Popover.Button className="px-4 py-2 flex hover:bg-white hover:bg-opacity-10 rounded-md transition-all">
//               {imageIconSrc ? (
//                 <img src={imageIconSrc} className="w-6 mr-2" alt="icon img" />
//               ) : (
//                 <MdPhoto />
//               )}
//               <span>{label}</span>
//             </Popover.Button>
//             {open && (
//               <Popover.Panel static className="absolute z-10">
//                 <div className="bg-white text-gray-600 flex flex-col  rounded-md mt-2 border">
//                   {links.map(({ to, label, icon, role = [] }, i) => {
//                     return (
//                       <div
//                         to={to}
//                         key={i}
//                         className="px-6 py-3 flex flex-nowrap hover:bg-blue-50 hover:text-gray-900 "
//                         onClick={() => {
//                           close();
//                           setOpen(false);
//                         }}
//                       >
//                         <MdAdd />

//                         {label}
//                       </div>
//                     );
//                   })}
//                 </div>
//               </Popover.Panel>
//             )}
//           </>
//         );
//       }}
//     </Popover>
//   );
// }

const Job = (props) => {
  return <div>Job</div>;
};
const Doc = (props) => {
  return <div>Doc</div>;
};
const Service = (props) => {
  return <div>Service</div>;
};
const Invoice = (props) => {
  return <div>Invoice</div>;
};
const Account = (props) => {
  return <div>Account</div>;
};
const Claim = (props) => {
  return <div>Claim</div>;
};
const Quote = (props) => {
  return <div>Quote</div>;
};

const Input = ({
  name,
  value = "",
  label = "",
  placeholder = "",
  Icon = null,
  type,
  ...props
}) => {
  const [inputValue, setInputValue] = React.useState(value);
  if (type === "hidden") return null;
  return (
    <label htmlFor={name} className="flex flex-col">
      {label && <span>{label}</span>}
      <div className="rounded-md border px-2 py-1 mb-1 bg-white  hover:border-indigo-500 focus-within:border-indigo-500 flex ">
        {Icon && <Icon />}
        <input
          name={name}
          {...props}
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          placeholder={placeholder}
          className={`flex-grow bg-transparent outline-none ${
            !Icon ? "ml-7" : "ml-2"
          }`}
          autoComplete="off"
        />
      </div>
    </label>
  );
};

const accounts = {
  AIRES: {
    name: "AIRES",
    logo: "/img/pts-accounts/aires.jpg",
  },
  GO: {
    name: "GO",
    logo: "/img/pts-accounts/go relocation.png",
  },
  INTERCONNEX: {
    name: "INTERCONNEX",
    logo: "/img/pts-accounts/INTERCONNEX.png",
  },
  KINETIX: {
    name: "KINETIX",
    logo: "/img/pts-accounts/kinetix.jfif",
  },
  CHAMPION: {
    name: "CHAMPION",
    logo: "/img/pts-accounts/champion-logo.png",
  },
  GRAEBAL: {
    name: "GRAEBAL",
    logo: "/img/pts-accounts/graebel.png",
  },
  ARMSTRONG: {
    name: "ARMSTRONG",
    logo: "/img/pts-accounts/armstrong.jfif",
  },
  CROWN: {
    name: "CROWN",
    logo: "/img/pts-accounts/crown relocatino.png",
  },
  K2: {
    name: "K2",
    logo: "/img/pts-accounts/k2.png",
  },
};

const Accounts = ({ ...props }) => {
  const [account, setAccount] = React.useState(Object.values(accounts)[0]);

  return (
    <>
      {account ? (
        <div
          className=" bg-cover bg-no-repeat bg-center overflow-hidden  h-36 border-2 border-gray-500 rounded-xl w-full hover:border-indigo-500  hover:text-indigo-500 relative"
          style={{ backgroundImage: `url(${account.logo})` }}
        >
          <button className="w-full h-full bg-black text-white text-xl bg-opacity-70 hover:bg-opacity-80 transition-all hover:bg-indigo-900">
            {account.name}
          </button>
          <button
            type="button"
            onClick={() => setAccount(null)}
            className="absolute top-2 right-2 p-4 text-white"
          >
            <MdClear />
          </button>
        </div>
      ) : (
        <div>
          <div className="text-center w-full py-3">Select account:</div>
          <div className="auto-grid-s">
            {Object.values(accounts).map((account, i) => {
              return (
                <div
                  key={i}
                  className=" bg-contain bg-no-repeat bg-center overflow-hidden  h-36 border-2 border-gray-500 rounded-xl w-full hover:border-indigo-500  hover:text-indigo-500"
                  style={{ backgroundImage: `url(${account.logo})` }}
                >
                  <button
                    key={i}
                    onClick={() => setAccount(account)}
                    className="w-full h-full bg-black text-white text-xl bg-opacity-70 hover:bg-opacity-80 transition-all hover:bg-indigo-900"
                  >
                    {account.name}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const Shipper = ({ shipper, ...props }) => {
  return (
    <div>
      <Input
        name="shipperName"
        label="Full Name"
        placeholder="Firstname Lastname"
        icon="user"
      />
      <Input
        name="shipperPhone"
        label="Phone Number"
        placeholder="(***) ***-****"
        icon="phone"
      />
      <Input
        name="shipperEmail"
        label="Email"
        placeholder="email@email.com"
        icon="mail"
      />
    </div>
  );
};
