import React, { useState } from "react";
import { MdAdd, MdClear } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTable } from "react-table";
import {
  deleteFileData,
  listenToPTSJobsFromFirestore,
} from "../../app/firestore/firestoreService";
import useFirestoreCollection from "../../app/hooks/useFirestoreCollection";
import { AddJob } from "./AddJob";
import { listenToPTSJobs } from "./ptsActions";

const downloadState = (exportObj) => {
  var dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(exportObj));
  var downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", "state.json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const Jobs = () => {
  const dispatch = useDispatch();
  const { jobs } = useSelector((state) => state.pts);
  const [showAddForm, setShowAddForm] = useState(false);

  // const { loading } = useSelector((state) => state.async);

  useFirestoreCollection({
    query: () => listenToPTSJobsFromFirestore(),
    data: (jobs) => dispatch(listenToPTSJobs(jobs)),
    deps: [dispatch],
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: idLink,
      },
      {
        Header: "Customer",
        accessor: "fullName",
      },
      {
        Header: "Ref Number",
        accessor: function (row) {
          return row?.refNumber.join(", ");
        },
      },
      {
        Header: "Type, OS/DS",
        accessor: "type",
      },
      {
        Header: "Units",
        accessor: customAccessor,
      },
      {
        Header: "Keyword",
        accessor: setKeywords,
      },
    ],
    []
  );

  function idLink(row) {
    return <Link to={`/paramount/operation/${row.id}`}>{row.id}</Link>;
  }
  function customAccessor(row) {
    return Number(
      row?.units?.length || (row?.units && Object.keys(row.units).length) || 0
    );
  }

  // function uniq(a) {
  //   var seen = {};
  //   return a.filter(function (item) {
  //     return seen.hasOwnProperty(item) ? false : (seen[item] = true);
  //   });
  // }

  function setKeywords(data) {
    // function getKeywords(e) {
    //   let keywords = [];
    //   const fullName = data.fullName.trim();
    //   keywords.push(fullName);
    //   keywords.push(fullName.toLowerCase());
    //   keywords.push(fullName.toUpperCase());
    //   const nameParts = fullName.split(" ");
    //   nameParts.forEach((part) => keywords.push(part, part.toLowerCase(), part.toUpperCase()));
    //   let refArray = [];
    //   data.refNumber.forEach((ref) => {
    //     const refParts = ref.split("-");
    //     refArray.push(ref);
    //     refParts.forEach((part) => refArray.push(part, part.toLowerCase(), part.toUpperCase()));
    //   });

    //   keywords.push(...uniq(refArray));

    //   return keywords;
    // }

    return (
      <span
        onClick={() => deleteFileData(`paramount/${data.id}`, "keywords")}
        className="px-2 py-1 rounded-md bg-yellow-500"
      >
        {data?.keywords?.length || 0}
      </span>
    );
  }

  const data = React.useMemo(() => [...jobs], [jobs]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  if (!jobs) return <div>Loading jobs</div>;

  return (
    <div className="bg-blue-900 m-1 rounded-md pb-10  h-full">
      <div className="bg-white rounded-t-md h-24  flex items-center px-16 relative justify-between">
        <div className="flex items-center select-none">
          <img
            src="/img/pts-logo.png"
            className="object-contain h-24 "
            alt="PTS Logo"
          />
          <h1 className="font-bold px-4 py-2 text-2xl">PARAMOUNT JOBS</h1>
        </div>
        <button
          onClick={(e) => {
            downloadState(jobs);
          }}
        >
          Download Data
        </button>
        <button
          className="bg-blue-500 px-4 py-2 rounded-md text-white"
          onClick={(e) => setShowAddForm((v) => !v)}
        >
          {" "}
          {showAddForm ? <MdClear /> : <MdAdd />}
          {showAddForm ? "Close" : "New"}
        </button>
      </div>
      {showAddForm && <AddJob />}
      {jobs && jobs.length > 0 ? (
        <table
          {...getTableProps()}
          className="mx-auto my-5  rounded-md overflow-hidden transition-all bg-white mb-10"
        >
          <thead className=" bg-white bg-opacity-50  ">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    className="font-bold px-4 py-2 hover:bg-opacity-50 hover:bg-white bg-opacity-0 transition-all cursor-pointer "
                    key={i}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="bg-white even:bg-gray-200 hover:bg-blue-200 "
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="px-2 py-1">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No jobs found</div>
      )}
    </div>
  );
};
