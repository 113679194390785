import { ManageImages } from "../../app/images/ManageImages";
import { EmailThreads } from "./emailThreads/EmailThreads";
import { Sandbox } from "./Sandbox";
import { ManageUploads } from "./uploads/ManageUploads";
import { ManageUsers } from "./users/ManageUsers";

// const TempComponent = () => {
//   return <div>Temp Admin Component</div>;
// };

export const adminRoutes = [
  { path: ["/admin/users", "/a/users", "/a/u"], component: ManageUsers, exact: true },
  { path: ["/admin/users/:uId", "/a/u/:uId"], component: ManageUsers, exact: true },
  { path: "/admin/images", component: ManageImages, exact: true },
  { path: "/admin/uploads", component: ManageUploads, exact: true },
  { path: "/admin/email", component: EmailThreads, exact: true },
  { path: "/admin/sandbox", component: Sandbox, exact: true },
];
