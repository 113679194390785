import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../app/common/components/Button";
import { Checkbox } from "../../../app/common/form/Checkbox";
import { Input } from "../../../app/common/form/Input";
import { InputGroup } from "../../../app/common/form/InputGroup";
import { RadioGroup } from "../../../app/common/form/RadioGroup";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { ModalWrapper } from "../../../app/common/modals/ModalWrapper";
import { deleteUnitsInFirestore, requestUnitsToBeDeleted } from "../../../app/firestore/firestoreService";
// import { addNewPTSShipment } from "../../../app/firestore/firestoreService";

const reasons = [
  { name: "Created too many units", value: "Too Many" },
  { name: "Created units for the wrong shipment", value: "Wrong Shipment" },
  { name: "Created units for the wrong shipper", value: "Wrong Shipper" },
  { name: "Other", value: "other" },
];

export const ManageShipmentUnitsModal = ({ opId, units, shipmentId }) => {
  const { currentUserProfile } = useSelector((state) => state.profile);
  const userRole = currentUserProfile?.role || {};
  const isAdmin = userRole?.admin;
  const modalDispatch = useDispatch();

  const [selected, setSelected] = React.useState([]);
  const [reason, setReason] = React.useState(null);
  const [other, setOther] = React.useState("");
  const [error, setError] = React.useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (selected.length === 0) {
      return setError("Please select at least one unit");
    }
    if (reason === null) return setError("Please provide a reason for deletion.");
    if (reason === "other" && !other) return setError("Please elaborate on other.");
    requestUnitsToBeDeleted({ opId, shipmentId, unitsIdArray: selected, reason: reason === "other" ? other : reason });
    modalDispatch(closeModal());
  };

  function adminDelete(unitIdArray) {
    return function (e) {
      e.preventDefault();
      if (selected.length === 0) {
        return setError("Please select at least one shipment");
      }
      deleteUnitsInFirestore({ unitIdArray, shipmentId, opId });
      modalDispatch(closeModal());
    };
  }

  function handleChange(e) {
    setSelected((s) => (s.includes(e.target.value) ? s.filter((v) => v !== e.target.value) : [...s, e.target.value]));
  }
  function handleReasonChange(e) {
    setReason(e.target.value);
  }

  function selectAll() {
    return setSelected(Object.keys(units));
  }
  function selectNone() {
    return setSelected([]);
  }

  React.useEffect(() => {
    if (selected.length > 0) setError(null);
  }, [selected]);

  return (
    <ModalWrapper header="Manage units">
      <div className="bg-white p-4 relative">
        <form onSubmit={onSubmit}>
          {/* {selected.join(", ")}
          <div className="text-xs"> {JSON.stringify({ shipmentId, opId, shipment }, null, 2)}</div> */}
          <InputGroup
            label="Select units"
            toolbar={[
              { label: "Select All", onClick: selectAll },
              { label: "Select None", onClick: selectNone },
            ]}
          >
            {units &&
              Object.entries(units)
                .sort(function (a, b) {
                  return a[1].number - b[1].number;
                })
                .map(([unitId, obj]) => (
                  <Checkbox
                    key={unitId}
                    onChange={handleChange}
                    checked={selected.includes(unitId)}
                    value={unitId}
                    label={`${"#" + obj?.number || ""} ${"- " + obj?.unitType?.label || ""} - ${unitId || ""} ` || "sId:" + unitId}
                  />
                ))}
          </InputGroup>
          <RadioGroup label="Reason for deletion" onChange={handleReasonChange} options={reasons} value={reason} />
          {reason && reason === "other" && <Input label="Describe Other" value={other} onChange={(e) => setOther(e.target.value)} />}
          {/* <div className="text-xs">{JSON.stringify(reason, null, 2)}</div> */}
          {error && <div className="text-xs text-red-500">{error}</div>}
          <div className="my-2 mt-4">
            <Button type="submit" color="red" className="w-full">
              Request to be deleted
            </Button>
            {isAdmin && (
              <Button color="red" onClick={adminDelete(selected)} className="w-full bg-red-900">
                DELETE NOW [ADMIN ACTION]
              </Button>
            )}
            {/* <Button type="submit" color="green">
              Close
            </Button> */}
          </div>
        </form>
      </div>
    </ModalWrapper>
    // {.map((reason, i) => (
    //           <Checkbox key={i} name={"reason-" + i} value={reason} onChange={handleReasonChange} label={reason} />
    //         ))}
  );
};
