const initialState = {
  operation: null,
  operations: [],
  job: null,
  jobs: [],
  services: [],
  service: null,
};

export function paramountReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "LISTEN_TO_OPERATION":
      return {
        ...state,
        operation: payload,
      };
    case "LISTEN_TO_OPERATIONS":
      return {
        ...state,
        operations: payload,
      };

    default:
      return state;
  }
}
