//HOOKS STILL IN ALPHA UPGRADE WHEN AVAILABLE
//https://www.algolia.com/doc/guides/building-search-ui/getting-started/react-hooks/

import { GlobalHotKeys } from "react-hotkeys";
import { MdClear, MdSearch } from "react-icons/md";
import React from "react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Index,
  /*RefinementList, Stats,  SearchBox,*/ Hits,
  Configure,
  Highlight,
} from "react-instantsearch/dom";
import { connectAutoComplete } from "react-instantsearch/connectors";
import Autocomplete from "downshift";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// import { Configure } from "react-instantsearch-hooks";

function RawAutoComplete({ refine, hits, setOpen, focusOnMount = false }) {
  const searchRef = React.useRef();
  const history = useHistory();
  const [searchValue, setSearchValue] = React.useState("");
  const keyMap = { FOCUS_SEARCH: "alt+z" };
  const handlers = {
    FOCUS_SEARCH: () => {
      searchRef.current.focus();
    },
  };
  function handleKeys(e) {
    if (e.key === "Escape" || (e.altKey && e.key === "z")) {
      searchRef.current.blur();
    }
    if (e.ctrlKey && e.key === "x") {
      setSearchValue("");
    }
  }

  React.useEffect(() => {
    refine(searchValue);
  }, [searchValue, refine]);

  return (
    <Autocomplete
      itemToString={(i) => (i ? i.name : i)}
      onChange={(item) => {
        console.log(item);
        history.push(`/paramount/operation/${item.objectID}`);
        setSearchValue(item.fullName);
        searchRef.current.blur();
        if (setOpen) setOpen(false);
      }}
      inputValue={searchValue}
    >
      {({
        getInputProps,
        getItemProps,
        selectedItem,
        highlightedIndex,
        isOpen,
        inputValue,
      }) => (
        <div className="w-full  max-w-[100vw] bg-white md:w-48 text-sm border rounded-lg h-8 hover:border-blue-500 transition-all flex items-center focus-within:border-indigo-400 focus-within:w-96 z-20 md:relative">
          <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
          <MdSearch className="w-6 h-6 mx-1" />
          <input
            id="mainSearch"
            autoFocus={focusOnMount}
            ref={searchRef}
            className="w-full bg-transparent h-full py-1 outline-none mx-2   "
            placeholder="Search users, ops..."
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            onFocus={(e) => e.target.select()}
            spellCheck={false}
            {...getInputProps({
              onChange(e) {
                setSearchValue(e.target.value);
              },
              onKeyDown(e) {
                handleKeys(e);
              },
            })}
          />

          {inputValue?.length > 0 ? (
            <MdClear
              className="w-6 h-6 mx-1 cursor-pointer hidden md:inline"
              onClick={() => setSearchValue("")}
            />
          ) : (
            <span className="hidden md:block whitespace-nowrap text-[8px] mr-1">
              <Kbd button="alt" /> + <Kbd button="z" />
            </span>
          )}

          {isOpen && (
            <div className=" absolute top-8 w-full rounded max-h-screen overflow-auto bg-white">
              <Index indexName="ptsOps">
                <IndexTitle title="PTS OPERATIONS" />
                <Configure hitsPerPage={5} />
                <Hits hitComponent={HitPtsOp} />
              </Index>
              <Index indexName="users">
                <IndexTitle title="USERS" />
                <Configure hitsPerPage={5} />
                <Hits hitComponent={HitUser} />
              </Index>

              {/* {hits.map((item, index) => (
                <div
                  className={`flex justify-between py-2 md:py-0.5 px-2 ${highlightedIndex === index ? "bg-indigo-200" : "bg-white"} ${
                    selectedItem === item ? "font-bold" : "font-normal"
                  }`}
                  key={item.objectID}
                  {...getItemProps({
                    item,
                    index,
                  })}
                >
                  <Highlight attribute="fullName" hit={item} tagName="mark" />
                  <span>
                    {item.refNumber &&
                      item.refNumber.length > 0 &&
                      item.refNumber.map((number, i) => (i > 0 ? ", " + number.slice(-2) : number))}
                  </span>
                </div>
              ))} */}
            </div>
          )}
        </div>
      )}
    </Autocomplete>
  );
}

const AutoCompleteWithData = connectAutoComplete(RawAutoComplete);

const searchClient = algoliasearch(
  "CYROWMWPWI",
  "a2bdc0fa6e8c4c91acd8af2ce8a8dda5"
);

export function AppbarSearch({ setOpen, focusOnMount }) {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="ptsOps"
      createURL={(searchState) => `?q=${searchState.query}`}
    >
      <AutoCompleteWithData setOpen={setOpen} focusOnMount={focusOnMount} />
    </InstantSearch>
  );
}

function HitUser({ hit }) {
  return (
    <Link
      to={"/profile/" + hit.objectID}
      className="flex px-2  py-0.5"
      onClick={() => console.log(hit)}
    >
      <div
        className="w-6 h-6 mr-2 bg-gray-500 rounded  relative bg-cover group bg-center"
        style={{ backgroundImage: `url(${hit?.photoURL || "/img/user.png"})` }}
      ></div>
      <Highlight attribute="displayName" hit={hit} />
    </Link>
  );
}
const HitPtsOp = ({ hit, ...props }) => {
  console.log({ props });
  return (
    <Link
      to={`/warehouse/labels/${hit.objectID}`}
      className="flex justify-between px-2 py-0.5 bg-black hover:bg-opacity-20 bg-opacity-0"
    >
      <div className="flex">
        <div
          className="w-6 h-6 mr-2  rounded  relative bg-cover group bg-center"
          style={{ backgroundImage: `url(${"/img/pts-logo.png"})` }}
        ></div>
        {hit?.shippers &&
          Object.entries(hit.shippers).map(([shipperId, shipper]) => (
            shipper?.displayName 
          ))}
      </div>
      {/* <span>
        {hit.refNumber && hit.refNumber.length > 0 && hit.refNumber?.map((number, i) => (i > 0 ? ", " + number?.slice(-2) : number))}
      </span> */}
    </Link>
  );
};

const IndexTitle = ({ title }) => (
  <span className="text-[8px] p-0.5">{title}</span>
);
const Kbd = ({ button }) => (
  <kbd className="bg-gray-50 text-[8px] border rounded p-0.5">{button}</kbd>
);

// const Hits = connectAutoComplete(({ hits, indexName, highlightedIndex, selectedItem, getItemProps }) => {
//   const { hits: indexHits = [] } = hits.find((x) => x.index === indexName) || {};

//   return (
//     <ul>
//       {indexHits.map((item, index) => (
//         <div
//           className={`flex justify-between py-2 md:py-0.5 px-2 ${highlightedIndex === index ? "bg-indigo-200" : "bg-white"} ${
//             selectedItem === item ? "font-bold" : "font-normal"
//           }`}
//           key={item.objectID}
//           {...getItemProps({
//             item,
//             index,
//           })}
//         >
//           {item?.displayName && (
//             <div className="flex">
//               <div
//                 className="w-6 h-6 mr-2 bg-gray-500 rounded  relative bg-cover group bg-center"
//                 style={{ backgroundImage: `url(${item?.photoURL || "/img/user.png"})` }}
//               ></div>
//               <Highlight attribute="displayName" hit={item} tagName="mark" />
//             </div>
//           )}
//           {item?.fullName && <Highlight attribute="fullName" hit={item} tagName="mark" />}

//           <span>
//             {item.refNumber && item.refNumber.length > 0 && item.refNumber.map((number, i) => (i > 0 ? ", " + number.slice(-2) : number))}
//           </span>
//         </div>
//       ))}
//       {/* {indexHits.map(hit => (
//         <li key={hit.objectID}>
//           <Highlight attribute="displayName" hit={hit} />
//         </li>
//       ))} */}
//     </ul>
//   );
// });

// function Hit(props) {
//   return (
//     <article>
//       <h1>
//         <Highlight attribute="name" hit={props.hit} />
//       </h1>
//       <p>
//         <Highlight attribute="description" hit={props.hit} />
//       </p>
//     </article>
//   );
// }
