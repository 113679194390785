import { Form, Formik } from "formik";
import React from "react";
import { ModalWrapper } from "../../app/common/modals/ModalWrapper";
import * as Yup from "yup";
import { MyTextInput } from "../../app/common/form/MyTextInput";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import { signInWithEmail } from "../../app/firestore/firebaseService";
import { MdLockOpen } from "react-icons/md";
import { Button } from "../../app/common/components/Button";
// import { SocialLogin } from "./SocialLogin";
// import { Form, Field } from 'react-final-form'

export const LoginForm = () => {
  const dispatch = useDispatch();
  return (
    <ModalWrapper size="mini" header="Log In" HeaderIcon={MdLockOpen}>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={Yup.object({
          email: Yup.string().required().email(),
          password: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await signInWithEmail(values);
            setSubmitting(false);
            dispatch(closeModal());
          } catch (error) {
            setErrors({ auth: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className="">
            <div className="ml-4 flex flex-col space-y-4">
              <MyTextInput
                name="email"
                placeholder="Email address"
                label="Email"
              />
              <MyTextInput
                name="password"
                placeholder="Password"
                type="password"
                label="Password"
              />
              {errors.auth && (
                <label basic color="red" style={{ marginBottom: 10 }}>
                  {errors.auth}
                </label>
              )}
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row justify-end w-full">
              <Button
                disabled={!isValid || !dirty || isSubmitting}
                onClick={() => dispatch(closeModal())}
                color="gray"
                label="Cancel"
              />
              <Button
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                label="Login"
              />
            </div>

            {/* <Divider horizontal>Or</Divider>

            <SocialLogin /> */}
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
};
