import {
  MdAddBox,
  MdChevronRight,
  MdDashboardCustomize,
  // MdDateRange,
  // MdEdit,
  MdSettings,
  MdClear,
  MdLock,
  MdEmail,
} from "react-icons/md";

import React from "react";
// import CountButton from "../../../components/Inputs/CountButton";

import download from "downloadjs";

import QRCode from "qrcode";

import { PDFDocument, rgb, TextAlignment } from "pdf-lib";
// import { getFormattedDate } from "../../../old_utils/helperFunctions";
import { formatDistanceToNow } from "date-fns";
import cuid from "cuid";
import CountButton from "../../app/common/form/CountButton";
import { getFormattedDate } from "../../app/common/util/helperFunctions";
import { Button } from "../../app/common/components/Button";
import {
  addNewPTSShipment,
  addUnitsToFirestore,
  completeReceiveShipment,
  // addWarehouseUnitsoFirestore,
  listenToOperationInFirestore,
} from "../../app/firestore/firestoreService";
import { FaWarehouse } from "react-icons/fa";
import { PageLayout } from "../../app/layout/PageLayout";

import { OpsSearch } from "./OpsSearch";
import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";
import { listenToOperation } from "./warehouseActions";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../app/common/modals/modalReducer";
import { Select } from "../../app/common/components/Select";
import { Link, Redirect } from "react-router-dom";
import { UnitPreviewWUpload } from "./units/UnitPreview";

export const Labels = ({ match }) => {
  const { operation } = useSelector((state) => state.warehouse);

  const dispatch = useDispatch();
  useFirestoreDoc({
    query: () => listenToOperationInFirestore(match.params.opId),
    data: (op) => dispatch(listenToOperation(op)),
    deps: [dispatch, match.params.opId],
    shouldExecute: !!match.params.opId,
  });

  if (operation?.id && !match.params.opId) return <Redirect to={`/warehouse/labels/${operation?.id}`} />;

  return (
    <>
      <PageLayout IconTitle={FaWarehouse} title="Storage Labels">
        <div className="md:p-2 border-b ">
          <OpsSearch /> {/*//selected={selected} setSelected={setSelected} /> */}
          {/* <Button label="Add Operation" className="my-2" onClick={() => dispatch(openModal({ modalType: "AddOperationModal" }))} /> */}
        </div>
        <div>
          <div className="flex flex-col justify-around bg-white md:border md:mx-2  md:rounded-lg  h-full">
            <div key={match.params.opId || null} className="">
              {operation && (
                <div className="   ">
                  <Header operation={operation} />
                  <HistoryLog created={operation?.created} updated={operation?.updated} codeName={operation?.codeName} />
                  <div className="w-full">
                    {/* <h2 className="text-xs text-gray-500 rounded px-2 py-1">Shipments</h2> */}
                    {operation?.shipments &&
                      Object.keys(operation.shipments).length > 0 &&
                      Object.keys(operation.shipments)
                        .sort()
                        .map((shipmentId) => (
                          <Shipment
                            key={shipmentId}
                            shipment={operation.shipments[shipmentId]}
                            shipmentId={shipmentId}
                            shipperName={operation?.shippers && Object.values(operation.shippers)?.[0].displayName}
                            codeName={operation?.codeName}
                            opId={operation.id}
                            created={operation?.created}
                            shippers={operation?.shippers}
                          />
                        ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {operation && <pre className="text-xs">{JSON.stringify(operation, null, 2)}</pre>} */}
      </PageLayout>
    </>
  );
};

const Header = ({ operation }) => {
  const dispatch = useDispatch();

  return (
    <div className="relative flex flex-col md:flex-row  justify-between md:items-center px-4 pt-4 pb-2 md:pb-0  border-b ">
      <div className="flex flex-col md:flex-row ">
        {operation?.codeName && <HeaderSection label="Codename">{operation.codeName} </HeaderSection>}
        <HeaderSection
          label="Shipper"
          onAdd={() =>
            dispatch(
              openModal({
                modalType: "AddShipperModal",
                modalProps: { operation, opId: operation.id },
              })
            )
          }
        >
          {operation.shippers &&
            Object.keys(operation.shippers).length > 0 &&
            Object.keys(operation.shippers)
              .sort()
              .map((shipperId) => (
                <Link
                  className="text-blue-700 hover:text-blue-500  max-w-fit whitespace-nowrap "
                  to={`/paramount/shipper/${shipperId}/${operation.shippers[shipperId].displayName}`}
                  key={shipperId}
                >
                  {operation.shippers[shipperId].displayName}
                </Link>
              ))}
        </HeaderSection>
        <div className="flex">
          <HeaderSection
            manage={!operation?.refNumber}
            onAdd={() =>
              dispatch(
                openModal({
                  modalType: "AddRefNumberModal",
                  modalProps: { operation, opId: operation.id },
                })
              )
            }
            label="Operation"
          >
            {operation?.refNumber && (
              <Link
                className="text-blue-700 hover:text-blue-500  max-w-fit whitespace-nowrap"
                to={`/paramount/operation/${operation.id}/${operation.refNumber}`}
              >
                {operation?.refNumber}
              </Link>
            )}
          </HeaderSection>
          <HeaderSection
            label="Shipments"
            manage={operation?.shipments}
            onEdit={() =>
              dispatch(
                openModal({
                  modalType: "ManageShipmentsModal",
                  modalProps: {
                    opId: operation.id,
                    shipments: operation.shipments,
                  },
                })
              )
            }
          >
            {operation?.shipments && Object.keys(operation.shipments).length}{" "}
          </HeaderSection>
        </div>
        {/* <HeaderSection label="Est. Weight">
          <span className="text-yellow-500">6,300</span>
        </HeaderSection>
        <HeaderSection label="Job Scheduled">Fri, 3/11/22</HeaderSection> */}
      </div>
      <div className="absolute md:block right-2">
        <Select
          className="w-32"
          label={
            <span className="flex items-center space-x-2">
              <MdAddBox className="mr-2" /> Shipment
            </span>
          }
          onSelect={({ label }) => {
            addNewPTSShipment({
              refNumber: label,
              opId: operation.id,
              shippers: operation.shippers,
            });
            // alert(JSON.stringify(label, null, 2));
          }}
          options={
            operation?.refNumber &&
            ["AIR", "SEA", "ROAD", "PERM"].map((op) => ({
              label: `SE-${operation.refNumber}-${op[0]}1`,
              disabled:
                operation?.shipments &&
                Object.values(operation.shipments).filter(({ refNumber }) => refNumber === `SE-${operation.refNumber}-${op[0]}1`).length >
                  0,
            }))
          }
          canCreateCustom={true}
          onCustomSelect={() =>
            dispatch(
              openModal({
                modalType: "AddCustomShipmentModal",
                modalProps: {
                  opId: operation.id,
                  shippers: operation.shippers,
                },
              })
            )
          }
        />
      </div>
    </div>
  );
};
const ShipmentHeader = ({ shipment, shipmentId, opId, open, setOpen }) => {
  const dispatch = useDispatch();

  const toggleOpen = () => setOpen((v) => !v);
  return (
    <div className={`flex justify-between items-center  border-b px-2 pt-2 ${open ? "" : "pb-2"}`}>
      <div className="flex  ">
        <HeaderSection
          label="Shipment"
          addName="Name"
          onAdd={() =>
            dispatch(
              openModal({
                modalType: "RenameShipmentModal",
                modalProps: {
                  opId,
                  shipment,
                  shipmentId,
                },
              })
            )
          }
          onEdit={() =>
            dispatch(
              openModal({
                modalType: "RenameShipmentModal",
                modalProps: {
                  opId,
                  shipment,
                  shipmentId,
                },
              })
            )
          }
          manage={true}
        >
          {shipment?.refNumber}
        </HeaderSection>
        <HeaderSection
          label="Type"
          onEdit={() =>
            dispatch(
              openModal({
                modalType: "RenameShipmentModal",
                modalProps: {
                  opId,
                  shipment,
                  shipmentId,
                },
              })
            )
          }
          manage={true}
        >
          <span>{parseShipmentType(shipment?.refNumber)?.[0]}</span>
        </HeaderSection>
        {shipment?.refNumber && (
          <HeaderSection
            label="Receipt"
            manage={true}
            onEdit={() => {
              dispatch(
                openModal({
                  modalType: "CompleteReceivedShipmentModal",
                  modalProps: {
                    opId,
                    shipment,
                    shipmentId,
                  },
                })
              );
            }}
          >
            <MdEmail className="my-1" />
          </HeaderSection>
        )}
        {/* <HeaderSection label="Net Weight">1,200</HeaderSection>
        <HeaderSection label="Status">SIT</HeaderSection>
        <HeaderSection label="Limitations">3 Liftvans</HeaderSection> */}
      </div>
      <div className="flex items-center px-2 py-1">
        <button onClick={toggleOpen} className="flex items-center px-4 py-2 rounded outline-none">
          <HeaderSection label="Units">{(shipment.units && Object.keys(shipment.units).length) || "0"}</HeaderSection>
          <MdChevronRight className={`${open ? "rotate-90" : ""} transform transition-all`} />
        </button>
        {/* <IconButton Icon={MdFitnessCenter} />
        <IconButton Icon={MdAssignment} />
        <IconButton Icon={MdChair} />
        <IconButton Icon={MdMap} />
        <IconButton Icon={MdDeleteForever} />
        <IconButton Icon={MdLockOpen} />
        <IconButton Icon={MdDashboardCustomize} /> */}
        {/* <Select options={["A1", "S1", "R1", "P1"].map((op) => `SE-${123}-${op}`)}  canCreateCustom={true}/> */}
      </div>
    </div>
  );
};

const HeaderSection = ({ label, data, manage = false, onAdd = () => {}, onEdit = () => {}, addName, children }) => {
  return (
    <div
      onClick={manage && (data || children) ? onEdit : null}
      className={`flex flex-col ${manage ? "cursor-pointer " : ""} border border-transparent  py-1 md:mb-2  px-2 mx-1 mr-2 rounded group`}
    >
      <div className="flex justify-between items-center ">
        <label className="text-xs text-gray-500 whitespace-nowrap select-none md:py-1"> {label}</label>
        {/* {manage && (data || children) && (
          <ManageHeader
            onEdit={onEdit}
            className="ml-2 px-2 md:py-1 md:invisible group-hover:visible hover:bg-slate-200 rounded text-xs text-gray-500 select-none"
          />
        )} */}
      </div>
      {data ? data : null} {children}{" "}
      {!data && !children && (
        <button type="button" onClick={onAdd} className="px-2 py-1 transition-all invisible group-hover:visible rounded text-xs">
          {addName ? addName : "Add"} {label}
        </button>
      )}
    </div>
  );
};

// const ManageHeader = ({
//   onEdit = () => console.log("Managing this"),
//   ...props
// }) => {
//   return (
//     <div onClick={onEdit} {...props}>
//       <MdEdit />
//     </div>
//   );
// };

const Shipment = ({ shipment, shipmentRef, shipmentId, shipperName, codeName, opId, created, shippers, ...props }) => {
  const [open, setOpen] = React.useState(true /*window.innerWidth > 500*/);

  return (
    <div className="border-b">
      <ShipmentHeader shipment={shipment} opId={opId} shipmentId={shipmentId} open={open} setOpen={setOpen} />
      {open && (
        <ShipmentUnits
          shipmentId={shipmentId}
          units={shipment?.units}
          refNumber={shipment.refNumber}
          shipperName={shipperName}
          codeName={codeName}
          opId={opId}
          created={created}
          shippers={shippers}
        />
      )}
    </div>
  );
};
// Object.keys(shipment.units).map((unitId) => (
//   <div key={unitId}>
//     {shipment.units[unitId].number} {unitId}
//   </div>
// ))
const ShipmentUnits = ({ units = {}, shipmentId, shipperName, refNumber, codeName, opId, created, shippers, ...props }) => {
  const [selectedUnits, setSelectedUnits] = React.useState([]);
  const [labelSettings, setLabelSettings] = React.useState({
    labelsPerUnit: 4,
    disableNumbers: false,
  });
  const dispatch = useDispatch();
  const toggleSelect = (unitId) => {
    setSelectedUnits((s) => (s.includes(unitId) ? s.filter((v) => v !== unitId) : [...s, unitId]));
  };

  const handleDelete = () => {
    if (units && Object.keys(units).length > 0) {
      dispatch(
        openModal({
          modalType: "ManageShipmentUnitsModal",
          modalProps: {
            opId,
            units,
            shipmentId,
          },
        })
      );
    }
  };

  const handleLabelSettings = (e) => {
    e.preventDefault();
    dispatch(
      openModal({
        modalType: "ManageLabelSettingsModal",
        modalProps: {
          labelSettings,
          setLabelSettings,
        },
      })
    );
  };

  return (
    <div className="">
      <div className="flex justify-between py-1 px-4">
        <div>
          <span className="text-xs text-gray-500 select-none my-2 "> Units </span>
        </div>
        {units && Object.keys(units).length > 0 && (
          <div className="text-xs">
            <button className="outline-none px-2 py-1" onClick={handleDelete}>
              Delete
            </button>
            Select:
            <button className="outline-none px-2 py-1" onClick={() => setSelectedUnits(Object.keys(units))}>
              All
            </button>
            <button disabled={selectedUnits.length === 0} className="outline-none px-2 py-1" onClick={() => setSelectedUnits([])}>
              None
            </button>
          </div>
        )}
      </div>
      <div className="auto-grid-s select-none mb-2 px-4">
        {units &&
          Object.keys(units).length > 0 &&
          Object.keys(units)
            .sort(function (a, b) {
              return units[a].number - units[b].number;
            })
            .map((unitId) => (
              <UnitPreviewWUpload
                selected={selectedUnits.includes(unitId)}
                toggleSelect={() => toggleSelect(unitId)}
                key={unitId}
                unit={{ id: unitId, ...units[unitId] }}
                opId={opId}
                shipmentId={shipmentId}
                created={created}
                shippers={shippers}
                refNumber={refNumber}
              />
            ))}
        <AddUnit shipmentId={shipmentId} refNumber={refNumber} unitCount={Object.keys(units).length} setSelectedUnits={setSelectedUnits} />
      </div>
      <div className="flex relative justify-around w-full boder-t bg-slate-50 mt-4 py-4 ">
        {selectedUnits.length === 0 ? (
          <span className="text-xs text-gray-600 py-2 my-1">Select units to generate labels</span>
        ) : (
          <Button
            type="button"
            onClick={async () => {
              const onlySelectedUnits = Object.fromEntries(selectedUnits.map((unitId) => [unitId, units[unitId]]));
              console.log({ selectedUnits, units: onlySelectedUnits });
              await getPDF({
                ...labelSettings,
                shipperName,
                refNumber,
                units: onlySelectedUnits,
                codeName,
              });
              // console.log(selectedUnits.map((unitId) => units[unitId].number)).sort(compareNumbers);
              setSelectedUnits([]);
            }}
            disabled={selectedUnits.length === 0}
          >
            Get Labels
          </Button>
        )}
        <button onClick={handleLabelSettings} type="button" className="absolute top-2 right-2 hover:opacity-100 opacity-30 px-2 py-1">
          <MdSettings />
        </button>
      </div>
    </div>
  );
};

// function compareNumbers(a, b) {
//   return a - b;
// }

/*
{
  operation: {
    refNumber: '000000',
    shipments: {
      ckz7kh90u00ddm0eaf0bahqus: {
        unitCount: 1,
        refNumber: 'SE-000000-R1',
        updated: {
          '0udbma5s': {
            userId: 'NxACZod7bSRBWPFj75wmjUYj3dN2',
            displayName: 'Arnas Jelizarovas',
            at: {
              seconds: 1642645541,
              nanoseconds: 802000000
            }
          }
        },
        created: {
          userId: 'NxACZod7bSRBWPFj75wmjUYj3dN2',
          displayName: 'Arnas Jelizarovas',
          at: {
            seconds: 1642645541,
            nanoseconds: 802000000
          }
        },
        units: {
          ckymcqrq70000386bfyv9ungd: {
            number: 1,
            images: {}
          }
        }
      }
    },
    shippers: {
      ckz7kh90u00dcm0eabc34bg9c: {
        displayName: 'Testicular Cancer'
      }
    },
    id: 'ckwve6kzi001y386bhgn46z8u'
  }
}
*/

const AddUnit = ({ shipmentId, refNumber, unitCount, setSelectedUnits, ...props }) => {
  const dispatch = useDispatch();
  const [activeAdd, setActiveAdd] = React.useState(false);
  // const [shipmentCompleted, setShipmentCompleted] = React.useState(false);
  const [successAdd, setsuccessAdd] = React.useState(false);
  const [count, setCount] = React.useState(1);
  const activeToggle = (e) => setActiveAdd((a) => !a);

  const { operation } = useSelector((state) => state.warehouse);

  const addUnits = async (count) => {
    try {
      const res = await addUnitsToFirestore({
        shipmentId,
        opId: operation.id,
        shippers: operation.shippers,
        refNumber,
        crew: {
          // ckyd98t3400083863omabnpi1: { displayName: "Jeff Ahia" },
          // ckyytaink00093862zc71toq9: { displayName: "Theo Robinson" },
          // ckyytfpep000a3862jchi9udx: { displayName: "Carde Boles" },
        },
        startingNumber: unitCount + 1,
        totalUnitsToAdd: count,
      });
      setCount(1);
      setActiveAdd(false);
      setsuccessAdd(false);
      setSelectedUnits(res);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div type="button" className=" border p-2 rounded-lg w-40 h-48  text-gray-400 hover:bg-gray-100 transition-all ">
      {activeAdd ? (
        <>
          {successAdd ? (
            <div className="flex flex-col justify-around w-full h-full items-center">
              <div className="text-xl">
                <MdDashboardCustomize />
              </div>
              <span className="text-center text-xl">Add {count} units?</span>
              <div className="flex justify-around w-full text-xl">
                <button
                  type="button"
                  className="px-4 py-2 border-2 border-green-400 rounded-lg hover:bg-green-300 hover:text-white"
                  onClick={() => {
                    addUnits(count);
                    // setShipmentCompleted(false);
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="px-4 py-2 border-2 border-red-400 rounded-lg hover:bg-red-300 hover:text-white"
                  onClick={() => {
                    // addUnits(count);
                    // setShipmentCompleted(true);
                    setActiveAdd(false);
                    setsuccessAdd(false);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-end relative w-full h-full">
              <button
                type="button"
                className=" mx-auto my-1  flex items-center text-2xl flex-col justify-center group  absolute top-1 right-1 hover:text-red-400"
                onClick={activeToggle}
              >
                <MdClear />
              </button>
              <button
                type="button"
                onClick={() => {
                  dispatch(
                    openModal({
                      modalType: "AddUnitsModal",
                      modalProps: {
                        shipmentId,
                        opId: operation.id,
                        shippers: operation.shippers,
                        refNumber,
                        crew: {},
                        startingNumber: unitCount + 1,
                        totalUnitsToAdd: count,
                      },
                    })
                  );
                  activeToggle();
                }}
                className="text-gray-500 mb-6 text-xs hover:text-blue-500  hover:bg-slate-200 rounded px-2 py-1"
              >
                Add Manual Ids
              </button>
              <CountButton count={count} changeCount={setCount} min={1} />
              <button
                type="button"
                className="py-2  mt-4 border border-green-400 bg-green-50 text-gray-400 w-full rounded-lg  flex items-center text-base justify-around group   top-1 right-1 hover:text-green-600 hover:bg-green-300 transition-all"
                onClick={() => setsuccessAdd(true)}
              >
                Add {count} Unit{count > 1 ? "s" : ""}
              </button>{" "}
            </div>
          )}
        </>
      ) : (
        /*shipmentCompleted ? (
        <button
          type="button"
          className="w-full h-full mx-auto my-1 relative flex items-center text-2xl flex-col justify-center group "
          onClick={() => setShipmentCompleted(false)}
        >
          <MdLock />
          <span className="invisible group-hover:visible mt-4 text-md absolute bottom-10">Unlock</span>
          <span className="invisible group-hover:visible mt-4 text-xs absolute bottom-5">Shipment is completed</span>
        </button>
      ) :*/ <button
          type="button"
          className="w-full h-full mx-auto my-1 relative flex items-center text-2xl flex-col justify-center group "
          onClick={activeToggle}
        >
          <MdAddBox />
          <span className="invisible group-hover:visible mt-4 text-xs absolute bottom-10">Add Unit</span>
        </button>
      )}
    </div>
  );
};

// async function warehouseLabelFill(data) {
//   const {
//     clientName,
//     date,
//     refNumber,
//     crew,
//     units,
//     unknownTotalUnits,
//     unitCount,
//     shipmentType,
//     unitToStartWith = 1,
//     labelsPerUnit = 4,
//     disableNumbers = false,
//     id,
//     isSFM,
//   } = data;

//   console.log(data);

//   let newUnits = [];

//   for (let index = 0; index < unitCount; index++) {
//     newUnits[index] = {
//       id: cuid(),
//       number: unitToStartWith > 1 ? index + unitToStartWith : index + 1,
//     };
//   }

//   if (!isSFM)
//     addWarehouseUnitsoFirestore({
//       clientName,
//       refNumber,
//       crew,
//       shipmentType,
//       id,
//       newUnits,
//     });
//   const pdf = !isSFM
//     ? "pdf/NEW LABELS Form.pdf"
//     : "pdf/NEW LABELS Form - SFM.pdf";
//   const baseUrl =
//     window.location.origin.toString() + process.env.PUBLIC_URL + "/";
//   const formUrl = baseUrl + pdf;
//   const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

//   const pdfDoc = await PDFDocument.load(formPdfBytes);
//   const form = pdfDoc.getForm();

//   const mergeDoc = await PDFDocument.create();
//   setField("shipperFullName", clientName, form);
//   setField("referenceNumber", refNumber, form);
//   setField("crew", crew, form);
//   setField("date", date, form);
//   setField("unitTypes", units, form);
//   setField("shipmentType", shipmentType, form);
//   form.flatten();

//   let unitToStart = unitToStartWith > 1 ? unitToStartWith : 1;

//   for (let index = 0; index < unitCount; index++) {
//     console.log("WHAT IS ID");
//     console.log({ ...newUnits[index] });
//     const unitId = newUnits[index].id;
//     const tempDoc = await PDFDocument.create();

//     const coppiedPages = await tempDoc.copyPages(
//       pdfDoc,
//       pdfDoc.getPageIndices()
//     );
//     coppiedPages.forEach((page) => {
//       tempDoc.addPage(page);
//     });
//     // const tempForm = tempDoc.getForm();
//     if (!disableNumbers) {
//       const tempPages = tempDoc.getPages();
//       tempPages[0].drawText(
//         `${index + unitToStart < 10 ? " " : ""}${index + unitToStart}${
//           unknownTotalUnits ? "" : `/${unitCount + unitToStart - 1}`
//         }`,
//         {
//           x: 528,
//           y: 165 - 126 + 25,
//           size: 96,
//         }
//       );
//       // await tempDoc.save();
//       const qrCode = await generateQR(`sfmtools.com/w/unit/${unitId}`);

//       await setQRCode(qrCode, 320, 20, 160, 160, tempDoc, tempPages[0]);
//     }

//     const coppiedTempPages = await mergeDoc.copyPages(
//       tempDoc,
//       tempDoc.getPageIndices()
//     );
//     for (let index = 0; index < labelsPerUnit; index++) {
//       coppiedTempPages.forEach((page) => mergeDoc.addPage(page));
//     }
//   }
//   return await mergeDoc.save();
// }

const generateQR = async (text) => {
  try {
    return await QRCode.toDataURL(text, { errorCorrectionLevel: "H" });
  } catch (err) {
    console.error(err);
  }
};

// const setField = (pdfField, value, form, condition = false) => {
//   if (!pdfField || !value || condition) return;
//   if (Array.isArray(value)) value = value.join(", ");
//   return form.getTextField(pdfField).setText(value.toString());
// };

// const setQRCode = async (
//   qrCode,
//   x,
//   y,
//   width = 25,
//   height = 10,
//   pdfDoc,
//   page
// ) => {
//   if (!qrCode || !x || !y) return;
//   const bytes = await fetch(qrCode).then((res) => res.arrayBuffer());
//   const image = await pdfDoc.embedPng(bytes);
//   return page.drawImage(image, { x, y, height, width });
// };

// const getLabels = async (data) => {
//   await getPDF(data);
// };

// const parseRefNumber = (e) => {
//   const v = e.target.value;
//   const vLen = v.length;
//   let type;
//   if (vLen > 2) {
//     type = v.charAt(vLen - 2).toLowerCase();
//     switch (type) {
//       case "a":
//         return "AIR";
//       case "p":
//         return "PERM";
//       case "r":
//         return "ROAD";
//       case "s":
//         return "SEA";
//       case "d":
//         return "DEST";
//       default:
//         return "";
//     }
//   }
// };

// const shipmentColor = (shipmentType) => {
//   switch (shipmentType) {
//     case "SEA":
//       return "bg-blue-500";
//     case "AIR":
//       return "bg-green-500";
//     case "ROAD":
//       return "bg-yellow-500";
//     case "PERM":
//       return "bg-purple-500";
//     case "DEST":
//       return "bg-red-500";

//     default:
//       return "bg-gray-500";
//   }
// };

const getPDF = async (data) => {
  const fileName = `WHL ${data?.shipperName} ${data?.refNumber} ${getFormattedDate(new Date())} ${cuid.slug(2)}.pdf`;
  download(await makeLabels(data), fileName, "application/pdf");
};

// const containerTypes = [
//   { value: "liftvan", name: "Liftvans", Icon: MdAddBox },
//   { value: "pallet", name: "Pallets", Icon: MdDateRange },
//   { value: "vault", name: "Vaults", Icon: MdDateRange },
//   { value: "air", name: "Air Shipment", Icon: MdDateRange },
//   { value: "sofabox", name: "Sofa Box", Icon: MdDateRange },
// ];

// const shipmentType = [
//   { value: "SEA", name: "Sea", Icon: MdAddBox },
//   { value: "AIR", name: "Air", Icon: MdDateRange },
//   { value: "ROAD", name: "Road", Icon: MdDateRange },
//   { value: "PERM", name: "Perm Storage", Icon: MdDateRange },
//   { value: "DEST", name: "Destination", Icon: MdDateRange },
// ];

const HistoryLog = ({ created = {}, updated = {}, codeName = "", ...props }) => {
  if (!created) return;

  return (
    <div className="text-xs px-4 py-2 bg-slate-50 border-b overflow-y-auto max-h-24 flex relative">
      <ul className="w-full">
        {updated &&
          Object.keys(updated).length > 0 &&
          Object.keys(updated)
            .sort((aI, bI) => updated[bI]?.at?.toDate() - updated[aI]?.at?.toDate())
            .map((update, uIndex) => (
              <li key={uIndex} className="truncate">
                <Link className="text-blue-600 hover:underline mr-1" to={`/profile/${updated[update]?.userId}`}>
                  {updated[update]?.displayName?.split(" ")?.[0]}
                </Link>
                {updated[update]?.description}
                <span className="text-gray-400 ml-1">
                  {updated[update]?.at &&
                    formatDistanceToNow(updated[update].at?.toDate(), {
                      addSuffix: true,
                    })}
                </span>
              </li>
            ))}
        <li>
          Operation {codeName} Created by
          <Link className="text-blue-600 hover:underline ml-1" to={`/profile/${created?.userId}`}>
            {created?.displayName?.split(" ")?.[0] || created?.by || " someone."}
          </Link>
          <span className="text-gray-400 ml-1">{created?.at && formatDistanceToNow(created?.at?.toDate(), { addSuffix: true })}</span>
        </li>
      </ul>
      {/* <div className="absolute right-1">
Expand
      </div> */}
    </div>
  );
};

const today = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return mm + "/" + dd + "/" + yyyy;
};

const datax = {
  units: {
    unitIdxzvxvcxv: { number: 1 },
    egergwersrsdr: { number: 2 },
    gsersdfgsdfhh32: { number: 21 },
  },
  shipperName: "Arnas Jelizarovas",
  refNumber: "SE-470076-R1",
  date: today(),
};

const paramountCompany = {
  name: "Paramount Transportation Systems",
  address: "1105 Andover Park W, Tukwila, WA 98188",
  logo: "images/pts_logo_wob_name_4x.png",
};

export async function makeLabels(data = datax, company = paramountCompany) {
  //   console.log("Starting Label Generator....");
  //   console.log(data);
  const { codeName, shipperName, refNumber, date = today(), disableNumbers = false, labelsPerUnit = 4 } = data;
  const pdfDoc = await PDFDocument.create();
  const form = pdfDoc.getForm();
  const imgUrl = "/images/pts_logo_wob_name_4x.png";
  const imgBytes = await fetch(imgUrl).then((res) => res.arrayBuffer());
  const img = await pdfDoc.embedPng(imgBytes);
  const { width, height } = img.scale(1);
  const ratio = width / height;
  let newHeight = 128;

  const shipperNameField = form.createTextField("shipperName");
  shipperNameField.setText(shipperName || codeName);
  shipperNameField.setAlignment(TextAlignment.Center);
  const refNumberField = form.createTextField("refNumber");
  refNumberField.setText(refNumber);
  refNumberField.setAlignment(TextAlignment.Center);

  const shipmentTypeField = form.createTextField("shipmentType");
  const [shipmentTypeLabel = "", shipmentTypeBackground = "#aaa"] = parseShipmentType(refNumber);
  shipmentTypeField.setText(shipmentTypeLabel);
  shipmentTypeField.setAlignment(TextAlignment.Center);

  const dateField = form.createTextField("dateField");
  dateField.setText(date);
  dateField.setAlignment(TextAlignment.Center);

  const companyName = form.createTextField("companyName");
  companyName.setText(company.name);
  companyName.setAlignment(TextAlignment.Center);
  const companyAddress = form.createTextField("companyAddress");
  companyAddress.setText(company.address);
  companyAddress.setAlignment(TextAlignment.Center);
  let units = data.units;
  console.log(units);
  if (!units) {
    let arr = [];
    for (let i = 0; i < data.unitCount; i++) {
      arr[i] = cuid();
    }
    // console.log(arr, data.unitToStartWith);
    units = arr.reduce((a, unitId, index) => {
      return {
        ...a,
        [`${unitId}`]: { number: data.unitToStartWith + index },
      };
    }, {});
  }
  const sortedUnits = Object.keys(units).sort(function (a, b) {
    return units[a].number - units[b].number;
  });
  console.log({ units, sortedUnits });

  for (let unitId of sortedUnits) {
    const number = units[unitId].number;
    const numberField = form.createTextField(`number-${number}-${cuid.slug()}`);
    numberField.setText(`${number}`);
    numberField.setAlignment(TextAlignment.Right);
    const unitIdField = form.createTextField(`unitId-${number}-${cuid.slug()}`);
    unitIdField.setText(`sfmtools.com/w/unit/${unitId}`);
    unitIdField.setAlignment(TextAlignment.Center);

    const qrCode = await generateQR(`sfmtools.com/w/unit/${unitId}`);
    const qrImage = await pdfDoc.embedPng(qrCode);

    for (let index = 0; index < labelsPerUnit; index++) {
      const page = pdfDoc.addPage([792, 612] /*[612, 792]*/);
      page.drawImage(img, {
        x: 50,
        y: 445,
        width: newHeight * ratio,
        height: newHeight,
      });
      if (shipmentTypeLabel !== "UNK") {
        shipmentTypeField.addToPage(page, {
          x: 492,
          y: 445,
          width: 792 - 492 - 50,
          height: 573 - 445,
          textColor: pdfLibColorParse("#ffffff"),
          backgroundColor: pdfLibColorParse(shipmentTypeBackground), // rgb(0.15, 0.9, 0.2),
          borderWidth: 0,
        });
      }

      shipperNameField.addToPage(page, {
        x: 50,
        y: 280,
        width: 692,
        height: 110,
        borderWidth: 0,
      });
      refNumberField.addToPage(page, {
        x: 50,
        y: 210,
        width: 692,
        height: 70,
        textColor: pdfLibColorParse("#000000"),
        borderWidth: 0,
      });

      dateField.addToPage(page, {
        x: 50,
        y: 30,
        width: 200,
        height: 60,
        color: rgb(1, 1, 1),
        borderWidth: 0,
      });
      companyName.addToPage(page, {
        x: 50,
        y: 420,
        width: 200,
        height: 20,
        color: rgb(1, 1, 1),
        borderWidth: 0,
      });
      companyAddress.addToPage(page, {
        x: 50,
        y: 405,
        width: 200,
        height: 15,
        color: rgb(1, 1, 1),
        borderWidth: 0,
      });
      if (!disableNumbers) {
        numberField.addToPage(page, {
          x: 492,
          y: 30,
          width: 250,
          height: 160,
          color: rgb(1, 1, 1),
          borderWidth: 0,
        });

        page.drawImage(qrImage, {
          x: 310,
          y: 25,
          width: 180,
          height: 180,
        });

        unitIdField.addToPage(page, {
          x: 310,
          y: 30,
          width: 180,
          height: 10,
          borderWidth: 0,
        });
      }
    }
  }
  form.flatten();
  return await pdfDoc.save();
}

function pdfLibColorParse(colorHex) {
  const { r, g, b } = hexToRgb(colorHex);
  //TODO error handling (will fail with none hex string)
  return rgb(r, g, b);
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16) / 255,
        g: parseInt(result[2], 16) / 255,
        b: parseInt(result[3], 16) / 255,
      }
    : null;
}

function parseShipmentType(ref = "") {
  const refNumber = ref?.trim();
  let sT = "unkown";
  const refLength = refNumber.length;
  let type;

  if (refLength > 2) {
    type = refNumber.charAt(refLength - 2).toLowerCase();
    switch (type) {
      case "a":
        sT = "air";
        break;
      case "p":
        sT = "permanentStorage";
        break;
      case "r":
        sT = "road";
        break;
      case "s":
        sT = "sea";
        break;
      default:
        sT = "unkown";
    }
  }
  return [shipmentTypes[sT].label, shipmentTypes[sT].background, shipmentTypes[sT].color];
}

const shipmentTypes = {
  air: { background: "#e53935", color: "#ffffff", label: "AIR" },
  sea: { background: "#1e88e5", color: "#ffffff", label: "SEA" },
  road: { background: "#43a047", color: "#ffffff", label: "ROAD" },
  permanentStorage: { background: "#f9a825", color: "#ffffff", label: "PERM" },
  unkown: { background: "#212121", color: "#ffffff", label: "UNK" },
};
