import React, { memo } from "react";
import { Link } from "react-router-dom";
import { SignedInMenu } from "./SignedInMenu";
import { SignedOutMenu } from "./SignedOutMenu";

import { useDispatch, useSelector } from "react-redux";

import { MdMenu, MdMenuOpen, MdOutlineQrCodeScanner } from "react-icons/md";

import { IconButton } from "../../common/components/IconButton";
import { openModal } from "../../common/modals/modalReducer";
import { AppbarSearch } from "./AppbarSearch";
// import { useBreakpoint } from "../../hooks/useBreakpoint";
import { FullScreenSearch } from "./FullScreenSearch";

export const Appbar = memo(({ sidebarOpen, setSidebarOpen, props }) => {
  const dispatch = useDispatch();
  const { authenticated } = useSelector((state) => state.auth);
  const url = `${process.env.PUBLIC_URL}/favicon.ico`;
  // const isDesktop = useBreakpoint("md");
  React.useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, []);
  const toggleOpen = React.useCallback(
    () => setSidebarOpen((o) => !o),
    [setSidebarOpen]
  );

  return (
    <nav className="bg-white border-b-2 border-gray-200 px-1    h-10 ">
      <div className="flex flex-wrap justify-between items-center mx-auto mt-1">
        <div className={`flex justify-between transition-all`}>
          {authenticated && (
            <IconButton
              Icon={sidebarOpen ? MdMenuOpen : MdMenu}
              onClick={toggleOpen}
            />
          )}

          <Link
            to={authenticated ? "/dashboard" : "/"}
            className="flex items-center ml-4"
          >
            <img
              className="bg-white w-6 h-6 p-1 mr-2 rounded-md"
              src={url}
              alt=""
            />
            <div className="flex flex-col items-center font-mono space-y-0">
              <span className="self-center text-lg font-semibold whitespace-nowrap  leading-3 ">
                SFMtools
              </span>
              <span className="text-gray-700 whitespace-nowrap text-[6px] leading-3">
                {`${process.env.REACT_APP_NAME} v ${process.env.REACT_APP_VERSION}`}
              </span>
            </div>
          </Link>
        </div>
        <div className="flex items-center space-x-4 mr-2">
          {authenticated ? (
            <>
              {window.innerWidth > 640 ? (
                <AppbarSearch />
              ) : (
                <FullScreenSearch />
              )}

              <IconButton
                Icon={MdOutlineQrCodeScanner}
                onClick={() => dispatch(openModal({ modalType: "QRModal" }))}
              />
              <SignedInMenu />
            </>
          ) : (
            <SignedOutMenu />
          )}
        </div>
      </div>
    </nav>
  );
});
