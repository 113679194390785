import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "../../../app/common/components/Button";
import { Input } from "../../../app/common/form/Input";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { ModalWrapper } from "../../../app/common/modals/ModalWrapper";
import {
  listenToUnitFromFirestore,
  updateUnitFromOp,
} from "../../../app/firestore/firestoreService";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";
import { listenToUnit } from "../../warehouse/warehouseActions";

export const EditUnitModal = ({
  shipmentId,
  opId,
  unit: opUnit,
  created,
  shippers,
  refNumber,
}) => {
  const dispatch = useDispatch();
  const { unit } = useSelector((state) => state.warehouse);
  const { loading } = useSelector((state) => state.async);
  const modalDispatch = useDispatch();
  useFirestoreDoc({
    query: () => listenToUnitFromFirestore(opUnit?.id),
    data: (unit) => dispatch(listenToUnit(unit)),
    deps: [dispatch, opUnit?.id],
  });
  if (loading && !unit)
    return (
      <div className="bg-white py-10 text-center">
        loading unit {opUnit?.id} ...
      </div>
    );

  // const [state, dispatch] = React.useReducer(reducer, {});

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log(state);
    // addUnitsToFirestore({ shipmentId, opId, shippers, refNumber, crew, startingNumber, totalUnitsToAdd: unitsToAdd, unitIdArray: state });
    modalDispatch(closeModal());
  };

  const handleChange = (unitId) => {
    return function (e) {
      // dispatch({ type: "INPUT_UPDATE", payload: { unitId, value: e.target.value } });
    };
  };

  return (
    <ModalWrapper header="Edit Unit">
      <div key={unit?.id} className="bg-white p-4 relative">
        <form onSubmit={onSubmit}>
          <Input
            // autoFocus={true}
            readOnly
            key={unit?.id}
            onChange={handleChange(unit?.id)}
            value={unit?.id}
            name="unitId"
            label="Unit Id"
            placeholder={`Unit id`}
          />
          <Link
            to={`/w/unit/${unit?.id}`}
            onClick={() => modalDispatch(closeModal())}
          >
            See unit details
          </Link>
          <div className="my-10">
            <Button type="submit" color="green">
              Submit
            </Button>
          </div>
          <button
            type="button"
            onClick={() =>
              updateUnitFromOp({
                shipmentId,
                opId,
                unit: opUnit,
                created,
                shippers,
                refNumber,
              })
            }
          >
            Update Unit from Op
          </button>
          <div className="text-xs">
            <pre>{JSON.stringify(unit, null, 2)}</pre>
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
