import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../app/common/components/Button";
import { Checkbox } from "../../../app/common/form/Checkbox";
import { Input } from "../../../app/common/form/Input";
import { InputGroup } from "../../../app/common/form/InputGroup";
import { RadioGroup } from "../../../app/common/form/RadioGroup";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { ModalWrapper } from "../../../app/common/modals/ModalWrapper";
import {
  requestShipmentsToBeDeleted,
  deleteshipmentsInFirestore,
} from "../../../app/firestore/firestoreService";
// import { addNewPTSShipment } from "../../../app/firestore/firestoreService";
const reasons = [
  { name: "Accidentally created shipment(s)", value: "Accidental Creation" },
  { name: "Created units for the wrong shipper", value: "Wrong Shipper" },
  {
    name: "Created wrong shipment type (i.e. ROAD instead of SEA)",
    value: "Wrong Shipment Type",
  },
  { name: "Spelled shipment name wrong", value: "Typo" },
  { name: "Other", value: "other" },
];

export const ManageShipmentsModal = ({ opId, shipments }) => {
  const [selected, setSelected] = React.useState([]);
  const [reason, setReason] = React.useState(null);
  const [other, setOther] = React.useState("");
  const [error, setError] = React.useState(null);
  const [warning, setWarning] = React.useState(null);
  const modalDispatch = useDispatch();

  const { currentUserProfile } = useSelector((state) => state.profile);
  const userRole = currentUserProfile?.role || {};
  const isAdmin = userRole?.admin;

  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setWarning(null);
    if (selected.length === 0)
      return setError("Please select at least one shipment");
    if (reason === null)
      return setError("Please provide a reason for deletion.");
    if (reason === "other" && !other)
      return setError("Please elaborate on other.");
    if (reason === "Wrong Shipment Type" && !other)
      return setWarning("You can change shipment type.");
    if (reason === "Typo" && !other)
      return setWarning("You can rename shipment.");
    if (
      !warning &&
      selected
        .map((shipmentId) => Object.keys(shipments[shipmentId]?.units).length)
        .filter((unitCount) => unitCount > 0).length > 0
    ) {
      return setWarning("⚠️ Deleting Shipment(s) will delete all the units ⚠️");
    }
    requestShipmentsToBeDeleted({
      opId,
      shipmentsIdArray: selected,
      reason: reason === "other" ? other : reason,
    });
    modalDispatch(closeModal());
  };

  function handleChange(e) {
    setSelected((s) =>
      s.includes(e.target.value)
        ? s.filter((v) => v !== e.target.value)
        : [...s, e.target.value]
    );
  }

  function handleReasonChange(e) {
    setReason(e.target.value);
  }

  React.useEffect(() => {
    if (selected.length > 0) setError(null);
  }, [selected]);

  function adminDelete(shipmentsIdArray) {
    return function (e) {
      e.preventDefault();
      if (selected.length === 0) {
        return setError("Please select at least one shipment");
      }
      deleteshipmentsInFirestore({ shipmentsIdArray, opId });
      modalDispatch(closeModal());
    };
  }

  return (
    <ModalWrapper header="Manage Shipments">
      <div className="bg-white p-4 relative">
        <form onSubmit={onSubmit}>
          <InputGroup label="Select shipments">
            {shipments &&
              Object.entries(shipments).map(([shipmentId, obj]) => (
                <Checkbox
                  key={shipmentId}
                  onChange={handleChange}
                  checked={selected.includes(shipmentId)}
                  value={shipmentId}
                  label={
                    (obj?.refNumber &&
                      `${obj.refNumber} with ${
                        (obj?.units && Object.keys(obj.units).length) || 0
                      } units`) ||
                    "sId:" + shipmentId
                  }
                />
              ))}
          </InputGroup>
          {/* <div className="text-xs">{selected.join(", ")}</div> */}
          <div className="my-4">
            {/* <TextArea label='Reason for deletion' id="deleteReason" name="deleteReason" value={message} onChange={(e) => setMessage(e.target.value)} /> */}
            <RadioGroup
              label="Reason for deletion"
              onChange={handleReasonChange}
              options={reasons}
              value={reason}
            />
            {reason && reason === "other" && (
              <Input
                label="Describe Other"
                value={other}
                onChange={(e) => setOther(e.target.value)}
              />
            )}
          </div>
          {error && <div className="text-xs text-red-500">{error}</div>}
          {warning && (
            <div className="text-lg bg-yellow-100 py-2 px-4 border border-yellow-400 rounded text-center text-orange-500">
              {warning}
            </div>
          )}
          <div className="my-2">
            <Button type="submit" color="red" className="w-full">
              Request to be deleted {warning && "anyway"}
            </Button>
            {isAdmin && (
              <Button
                color="red"
                onClick={adminDelete(selected)}
                className="w-full bg-red-900"
              >
                DELETE NOW [ADMIN ACTION]
              </Button>
            )}
            {/* <Button type="submit" color="green">
              Close
            </Button> */}
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
