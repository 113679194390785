import React, { useState } from "react";
import { ProfileForm } from "./ProfileForm";

export const AboutTab = ({ profile, isCurrentUser }) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <div>
      <div>
        <div width={16}>
          <h2>About {profile.displayName}</h2>
          {isCurrentUser && (
            <button onClick={() => setEditMode(!editMode)}>
              {editMode ? "Cancel" : "Edit"}
            </button>
          )}
        </div>
        <div width={16}>
          {editMode ? (
            <ProfileForm profile={profile} />
          ) : (
            <>
              <div style={{ marginBottom: 10 }}>
                {/* <strong>Member since: {format(profile?.created?.at || profile?.createdAt, "dd MMM yyyy")}</strong> */}
              </div>
              <div>{profile?.description || null}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
