import React from "react";

export const InputGroup = ({ label = "", toolbar = null, children, ...props }) => {
  return (
    <div {...props} className="rounded-lg border-gray-300 border pb-2 px-2 my-2">
      {!!label && (
        <div className="flex justify-between m-1 ">
          <span className="text-sm font-sans py-1  text-gray-500 pl-2 ">{label}</span>
          {toolbar && <Toolbar toolbar={toolbar} />}
        </div>
      )}
      {children}
    </div>
  );
};

const Toolbar = ({ toolbar, ...props }) => {
  return (
    <div className="text-xs">
      {toolbar.map(({ label, onClick }, i) => (
        <ToolbarButton key={i} label={label} onClick={onClick} />
      ))}
    </div>
  );
};

const ToolbarButton = (props) => {
  const handleClick = (e) => {
    e.preventDefault();
    if (props?.onClick) props.onClick(e);
  };
  return (
    <button onClick={handleClick} className="hover:bg-gray-100 my-1 px-2 py-1 rounded" type="button">
      {props?.label}
    </button>
  );
};
