import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmailsRef } from "../../../app/firestore/firebaseService";
import useFirebaseDatabase from "../../../app/hooks/useFirebaseDatabase";
import { listenToEmails } from "../adminActions";

export const EmailThreads = () => {
  const dispatch = useDispatch();
  const { emails } = useSelector((state) => state.admin);

  useFirebaseDatabase({
    query: () => getEmailsRef(),
    data: (emails) => dispatch(listenToEmails(emails)),
    deps: [dispatch],
  });

  return (
    <div>
      Manage emails
      {emails && emails.length > 0 && (
        <div className="px-4 py-2 m-2">
          {emails.map(({ headers: { messageID, subject } }) => (
            <div className="bg-slate-200 px-4 py-2 m-2">
              <a
                href={`https://mail.google.com/mail/u/0/#search/${subject.trim()}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                See thread: [{subject.trim()}]
              </a>
            </div>
          ))}
        </div>
      )}
      <div className="text-xs">
        <pre>{JSON.stringify(emails, null, 2)}</pre>
      </div>
    </div>
  );
};
