import { combineReducers } from "redux";
import authReducer from "../../features/auth/authReducer";
import profileReducer from "../../features/profiles/profileReducer";
import { asyncReducer } from "../async/asyncReducer";
import modalReducer from "../common/modals/modalReducer";
import { connectRouter } from "connected-react-router";
import { settingsReducer } from "../../features/settings/settingsReducer";
import { ptsReducer } from "../../features/pts/ptsReducer";
import { warehouseReducer } from "../../features/warehouse/warehouseReducer";
import adminReducer from "../../features/admin/adminReducer";
import { paramountReducer } from "../../features/paramount/paramountReducer";
// import { inventoryReducer, manageReducer } from "../../features/manage/manageReducer";

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    modals: modalReducer,
    auth: authReducer,
    async: asyncReducer,
    profile: profileReducer,
    settings: settingsReducer,
    warehouse: warehouseReducer,
    pts: ptsReducer,
    paramount: paramountReducer,
    admin: adminReducer,
  });
