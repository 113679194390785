import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getDatabase } from "firebase/database";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "sfm-tools-448ab.firebaseapp.com",
  projectId: "sfm-tools-448ab",
  storageBucket: "sfm-tools-448ab.appspot.com",
  messagingSenderId: "980029527425",
  appId: "1:980029527425:web:52b20aa5a85cca58b20480",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp);
const database = getDatabase(firebaseApp);

if (window.location.hostname.startsWith("localhost")) {
  if (false) connectStorageEmulator(storage, "localhost", 9199);
  if (false) connectFunctionsEmulator(functions, "localhost", 5001);
  if (false) connectFirestoreEmulator(db, "localhost", 8080);
  if (false) connectAuthEmulator(auth, "http://localhost:9099");
  // (async () => {
  //   const provider = new EmailAuthProvider();
  //   const ttt = await signInAnonymously();
  //   console.log(ttt);
  //   if (ttt?.user?.uid) {
  //     await setDoc(doc(db, "users/" + ttt?.user?.uid), {
  //       email: "jelizarovas@gmail.com",
  //       createdAt: Timestamp.fromDate(new Date("06/29/1992")),
  //       displayName: "Arnas Jelizarovas",
  //       role: {
  //         admin: true,
  //       },
  //       photoURL:
  //         "https://firebasestorage.googleapis.com/v0/b/sfm-tools-448ab.appspot.com/o/NxACZod7bSRBWPFj75wmjUYj3dN2%2Fuser_images%2Fckwzf5dkt00003i60yx2bg6hb.jpg?alt=media&token=4b9c4e3d-6b10-4e09-81cc-3a9a37ad628c",
  //     });
  //   }
  // })();
}
// https://stackoverflow.com/questions/70084447/firebase-appcheck-when-working-with-emulator-on-localhost
if (process.env.NODE_ENV !== "production") {
  window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_KEY;
}

initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_FIREBASE_APPCHECK_KEY),
  isTokenAutoRefreshEnabled: true,
});

export { firebaseApp, auth, db, database, storage, functions };
