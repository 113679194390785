import React from "react";
import { checkIfFileExistsInFirebaseStorage } from "../../firestore/firebaseService";

export const Image = ({ image, imageId, deleteImageFromDoc = (imageId) => console.log(imageId), setMain = () => {}, isMain = false }) => {
  if (!image) return <div>No image.</div>;

  const checkIfFileExists = async (storageRef) => {
    if (!(await checkIfFileExistsInFirebaseStorage(storageRef))) {
      console.log(`No file exists, attempting to delete: ${storageRef}${imageId}`);
      deleteImageFromDoc(imageId);
    }
  };

  return (
    <div className={`relative group  ${isMain ? "border-2 border-yellow-500 rounded-lg" : ""}`}>
      {image?.thumbs?.[0]?.url && isMain ? (
        <span className="absolute">⭐</span>
      ) : (
        <button
          className="absolute left-1 top-1 invisible group-hover:visible z-10 px-2 py-1 bg-black hover:bg-opacity-50 bg-opacity-10 transition-all rounded"
          onClick={(e) => {
            e.preventDefault();
            setMain({ imageId, url: image?.thumbs?.[0]?.url });
          }}
        >
          ⭐
        </button>
      )}
      <a href={image?.url} target="_blank" rel="noopener noreferrer">
        <img
          onDragStart={(e) => e.preventDefault()}
          key={image.fileName}
          className="w-full h-auto rounded-md  transition-all" // hover:scale-105"
          src={image?.thumbs?.[0]?.url || image?.url || image?.photoURL}
          alt={image?.fileName || "sky"}
          onError={async (e) => {
            if (image?.url && image?.storageRef) {
              await checkIfFileExists(image.storageRef);
            }
          }}
        />
      </a>
    </div>
  );
};
