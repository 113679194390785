export function listenToOperations(operations) {
  return {
    type: "LISTEN_TO_OPERATIONS",
    payload: operations,
  };
}

export function listenToOperation(operation) {
  return {
    type: "LISTEN_TO_OPERATION",
    payload: operation,
  };
}

export function listenToShipments(shipments) {
  return {
    type: "LISTEN_TO_SHIPMENTS",
    payload: shipments,
  };
}

export function listenToShipment(shipment) {
  return {
    type: "LISTEN_TO_SHIPMENT",
    payload: shipment,
  };
}
