import React from "react";
// import { FileUpload } from "../../app/common/form/FileUpload";
// import { Form } from "react-final-form";
import { Button } from "../../app/common/components/Button";
import { FFInput } from "../../app/common/form/Input";
import { Form, Formik } from "formik";
import { ModalWrapper } from "../../app/common/modals/ModalWrapper";
import * as Yup from "yup";
import { MyTextInput } from "../../app/common/form/MyTextInput";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import { signInWithEmail } from "../../app/firestore/firebaseService";
import { MdGroup, MdLockOpen } from "react-icons/md";

export const HomePage = ({ history }) => {
  const { authenticated } = useSelector((state) => state.auth);
  const mountedRef = React.useRef(true);
  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (authenticated) {
      history.push(`/warehouse/labels`);
    }
  }, [authenticated]);

  return (
    <section className=" fancy-bg mx-auto h-screen flex md:items-center  justify-center    ">
      {/* <LoginForm /> */}

      <div className="flex flex-col select-none">
        <div className="flex flex-col md:flex-row  text-white container  justify-center">
          <div className="md:max-w-lg group md:bg-black hover:bg-opacity-5 transition-all md:bg-opacity-20 w-full md:rounded-l-3xl md:border-2 p-10 flex md:flex-col items-center justify-center relative">
            <img
              src="img/sfmtools.png"
              alt=""
              className="w-32 h-32 md:w-64 md:h-64 bg-white shadow-lg mx-auto  md:-mt-32 md:group-hover:-mt-36 md:group-hover:mb-4 transition-all rounded-lg p-2"
            />
            <div className="flex flex-col px-4">
              <h1 className="textr-xl md:text-3xl pb-4 md:py-4 md:text-center whitespace-nowrap">
                Welcome to <strong className="">📦 SFM Tools</strong>
              </h1>
              <p className="opacity-75 md:text-center">
                An innovative application meticulously crafted in-house to
                revolutionize the way we manage warehouse and contracting
                operations.
              </p>
            </div>
          </div>
          <div className="p-4 mx-2 md:mx-auto  md:px-0 md:max-w-6xl  group md:w-full bg-white text-black md:py-6  rounded-xl md:rounded-r-3xl md:rounded-l-none">
            <p className=" md:px-10 md:py-6 text-lg  opacity-75 flex justify-center items-center space-x-4">
              <MdGroup className="text-3xl bg-slate-800 transition-all group-hover:bg-lime-700 text-white rounded-full p-2 w-12 h-12" />
              <div className="flex flex-col">
                <span>Team members,</span>

                <span className="text-xs leading-none">
                  {" "}
                  please log in to begin.
                </span>
              </div>
            </p>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={Yup.object({
                email: Yup.string().required().email(),
                password: Yup.string().required(),
              })}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                  await signInWithEmail(values);
                  setSubmitting(false);
                } catch (error) {
                  setErrors({ auth: error.message });
                  setSubmitting(false);
                }
              }}
            >
              {({ isSubmitting, isValid, dirty, errors }) => (
                <Form className="md:w-96 md:px-6 text-xs md:text-inherit my-4 md:my-0">
                  <div className="md:ml-4 flex flex-col md:space-y-4">
                    <MyTextInput
                      name="email"
                      placeholder="Email address"
                      label="Email"
                    />
                    <MyTextInput
                      name="password"
                      placeholder="Password"
                      type="password"
                      label="Password"
                    />
                    {errors.auth && (
                      <label basic color="red" style={{ marginBottom: 10 }}>
                        {errors.auth}
                      </label>
                    )}
                  </div>
                  <div className="py-2 md:py-3 sm:flex sm:flex-row justify-end w-full">
                    <Button
                      disabled={!isValid || !dirty || isSubmitting}
                      type="submit"
                      label="Login"
                      Icon={MdLockOpen}
                      color="teal"
                      className="uppercase md:text-base w-full md:w-min"
                    />
                  </div>

                  {/* <Divider horizontal>Or</Divider>

            <SocialLogin /> */}
                </Form>
              )}
            </Formik>
            <p className="text-xs md:px-10 text-center  opacity-75">
              Please contact{" "}
              <a href="mailto:jake@superfriendsmoving.com">Jake</a> for access.
            </p>
          </div>
        </div>
        <div className="p-4  py-10 text-white text-center">
          <p className="text-xl">
            Not part of the team yet or looking to book a move?
          </p>
          <p className="text-sm opacity-75">
            Visit us at
            <a
              className="mx-2  text-white font-bold  bg-opacity-60"
              href="https://super-friends-moving.com/"
            >
              superfriendsmoving.com
            </a>{" "}
            for more information and services.
          </p>
        </div>
      </div>
    </section>
  );
};
