import React from "react";
import { MdLockOpen, MdPersonAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../common/components/Button";
import { openModal } from "../../common/modals/modalReducer";

export const SignedOutMenu = ({ setAuthenticated }) => {
  const dispatch = useDispatch();
  const { currentUserProfile } = useSelector((state) => state.profile);
  const isAdmin = currentUserProfile?.role?.admin;

  return (
    <div className="flex space-x-0">
      <Button
        onClick={() => dispatch(openModal({ modalType: "LoginForm" }))}
        label="Login"
        Icon={MdLockOpen}
      />
      {isAdmin && (
        <Button
          onClick={() => dispatch(openModal({ modalType: "RegisterForm" }))}
          label="Register"
          Icon={MdPersonAdd}
          color="gray"
        />
      )}
    </div>
  );
};
