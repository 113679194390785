export const ADD_CAR = "ADD_CAR";
export const UPDATE_CAR = "UPDATE_CAR";
export const DELETE_CAR = "DELETE_CAR";
export const FETCH_INVENTORY = "FETCH_INVENTORY";
export const FETCH_GLOBAL_VARIABLES = "FETCH_GLOBAL_VARIABLES";
export const FETCH_PDFS = "FETCH_PDFS";
export const FETCH_PDF = "FETCH_PDF";
export const LISTEN_TO_SELECTED_CAR = "LISTEN_TO_SELECTED_CAR";
export const CLEAR_TO_SELECTED_CAR = "CLEAR_TO_SELECTED_CAR";
export const CLEAR_CARS = "CLEAR_CARS";
export const LISTEN_TO_PACKETS = "LISTEN_TO_PACKETS";
export const LISTEN_TO_PACKET = "LISTEN_TO_PACKET";
export const UPDATE_FIELDS_TABLE = "UPDATE_FIELDS_TABLE";
