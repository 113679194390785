import React from "react";
import { MdClear, MdSearch } from "react-icons/md";
import { IconButton } from "../../common/components/IconButton";
import { AppbarSearch } from "./AppbarSearch";

export const FullScreenSearch = ({ children }) => {
  const [isOpen, setOpen] = React.useState(false);
  if (!isOpen)
    return <IconButton Icon={MdSearch} onClick={() => setOpen(true)} />;

  return (
    <div
      className="w-screen h-screen bg-white absolute top-0 left-0 overflow-hidden will-change-scroll z-20"
      scroll="no"
    >
      <div className="relative">
        <div className="absolute top-0 right-0">
          <IconButton Icon={MdClear} onClick={() => setOpen(false)} />
        </div>

        <AppbarSearch isFullScreen={true} setOpen={setOpen} focusOnMount={true} />
      </div>
    </div>
  );
};
