import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../app/common/components/Button";
import { Checkbox } from "../../../app/common/form/Checkbox";
import { Input } from "../../../app/common/form/Input";
import { InputGroup } from "../../../app/common/form/InputGroup";
import { RadioGroup } from "../../../app/common/form/RadioGroup";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { ModalWrapper } from "../../../app/common/modals/ModalWrapper";
import { completeReceiveShipment, deleteUnitsInFirestore, requestUnitsToBeDeleted } from "../../../app/firestore/firestoreService";
// import { addNewPTSShipment } from "../../../app/firestore/firestoreService";

const emails = [
  "Aaron Brown <aaron@superfriendsmoving.com>",
  "Arnas Jelizarovas <arnas@superfriendsmoving.com>",
  "Operations Manager <paramount@superfriendsmoving.com>",
  "Jakub Raich <jake@superfriendsmoving.com>",
];

export const CompleteReceivedShipmentModal = ({ opId, shipment, shipmentId }) => {
  const { currentUserProfile } = useSelector((state) => state.profile);
  const userRole = currentUserProfile?.role || {};
  const isAdmin = userRole?.admin;
  const modalDispatch = useDispatch();

  //   const [emails, setEmails] = React.useState([]);
  const [selected, setSelected] = React.useState(emails);
  const [other, setOther] = React.useState("");
  const [error, setError] = React.useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();

    completeReceiveShipment(
      shipmentId,
      opId,
      {
        refNumber: shipment.refNumber,
        to: selected || "Arnas Jelizarovas <arnas@superfriendsmoving.com>",
      },
      true
    );

    modalDispatch(closeModal());
  };

  function selectAll() {
    return setSelected(emails);
  }
  function selectNone() {
    return setSelected([]);
  }

  function handleChange(e) {
    return !e.target.checked ? setSelected((s) => s.filter((v) => v !== e.target.value)) : setSelected((s) => [...s, e.target.value]);
  }

  return (
    <ModalWrapper header="Manage units">
      <div className="bg-white p-4 relative">
        <form onSubmit={onSubmit} className="flex flex-col">
          <InputGroup
            label="Recipients"
            toolbar={[
              { label: "Select All", onClick: selectAll },
              { label: "Select None", onClick: selectNone },
            ]}
          >
            {emails.map((email, i) => (
              <Checkbox key={i} onChange={handleChange} checked={selected.includes(email)} value={email} label={email} />
            ))}
          </InputGroup>

          <Button color="green" type="submit">
            Send Warehouse Receipt
          </Button>
        </form>
      </div>
    </ModalWrapper>
  );
};
