import React, { useCallback, useEffect, useReducer, useState } from "react";
// import { toast } from "react-toastify";
import cuid from "cuid";
import { getFileDownloadUrl, newUploadSession, updateUploadSession, uploadFileToFirebaseStorage } from "../../firestore/firebaseService";
// import { addFileDataToFirestore } from "../../firestore/firestoreService";
import { useDropzone } from "react-dropzone";
import { money_round } from "../util/helperFunctions";
import {
  MdAddPhotoAlternate,
  MdCheck,
  MdCheckCircleOutline,
  MdClose,
  MdDelete,
  MdMinimize,
  MdPhotoAlbum,
  MdViewList,
} from "react-icons/md";
import { FileIcon } from "../util/FileIcon";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "IN_PROGRESS":
      return { ...state, loading: true };
    case "COMPLETE":
      return {
        ...state,
        loading: false,
        isComplete: true,
        files: [
          ...state.files.map((file) => {
            if (file.fileUploadProgress === 100) file.uploaded = true;
            return file;
          }),
        ],
      };
    case "CANCEL_FN_ARRAY":
      return {
        ...state,
        cancelFunctions: [...state.cancelFunctions, payload],
      };
    case "CANCEL_UPLOAD":
      return {
        ...state,
        cancelFunctions: [],
        files: state.files.map((file) => {
          delete file.fileUploadProgress;
          return file;
        }),
      };
    case "ERROR":
      return { ...state, loading: false, error: payload };
    case "CLEAR_ERROR":
      return { ...state, error: null };
    case "CURRENT_FILE_UPLOAD_PROGRESS":
      return {
        ...state,
        files: [
          ...state.files.map((file) => {
            if (payload.fileId === file.id) {
              file.fileUploadProgress = payload.progress;
            }
            return file;
          }),
        ],
      };
    case "ADD_FILES":
      return { ...state, files: [...state.files, ...payload] };
    case "UPDATE_TOTAL_PROGRESS":
      return {
        ...state,
        files: [
          ...state.files.map((file) => {
            if (payload.id === file.id) {
              // file.uploaded = true;
              file.url = payload.downloadURL;
            }
            return file;
          }),
        ],
      };
    case "UPDATE_TOTAL_SIZE":
      return { ...state, totalSize: money_round(payload / 1024) };
    case "REMOVE_FILE_BY_INDEX":
      return {
        ...state,
        files: state.files.filter((_, index) => index !== payload),
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  error: null,
  totalSize: 0,
  isComplete: false,
  files: [],
  cancelFunctions: [],
};

export const FileUpload = ({
  docId = cuid(),
  storagePath = "temp/",
  docPath = "files/",
  docKey = "files",
  title = "files",
  children,
  hasMainPhoto = false,
  dbTargets,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [showFilesToUpload, setShowFilesToUpload] = useState(true);
  const [displayGrid, setDisplayGrid] = useState(true);
  const [sessionId /*setSessionId*/] = useState(docId); //should not use docId only for dev purposes as page refreshes too often

  const { files } = state;

  const setFiles = (files) => {
    dispatch({ type: "ADD_FILES", payload: files });
  };

  storagePath += docId;
  docPath += docId;

  async function handleUploadFile() {
    dispatch({ type: "IN_PROGRESS" });
    if (files.filter((f) => !f.uploaded).length === 0) {
      return dispatch({
        type: "ERROR",
        payload: "Nothing selected. Please attach files",
      });
    }

    function uploadFileAsPromise(file, index) {
      // console.log(hasMainPhoto, index);
      return new Promise(async function (resolve, reject) {
        const fileId = file.id || cuid();
        // await newUploadSession(sessionId);
        const uploadTask = uploadFileToFirebaseStorage({
          file,
          fileId,
          storagePath,
          docPath,
          docKey,
          sessionId,
        });
        dispatch({
          type: "CANCEL_FN_ARRAY",
          payload: {
            id: fileId,
            cancelUpload: () => {
              uploadTask.cancel();
              updateUploadSession(sessionId, {
                [`files/${fileId}/status`]: "Upload Cancelled",
                [`files/${fileId}/failed`]: true,
              });
            },
          },
        });

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            dispatch({
              type: "CURRENT_FILE_UPLOAD_PROGRESS",
              payload: { progress, fileId },
            });
          },
          (error) => {
            dispatch({ type: "ERROR", payload: error.message });
            updateUploadSession(sessionId, {
              [`files/${fileId}/error`]: error.message,
              [`files/${fileId}/status`]: "Failed to Upload",
            });
            reject(error);
          },
          async () => {
            console.log(`${file.name} uploaded.`);
            const downloadURL = await getFileDownloadUrl(uploadTask.snapshot.ref);

            console.log(`${file.name} download url is ${downloadURL}.`);

            const uploadedFile = {
              id: file.id,
              fileName: file.name,
              size: file.size,
              type: file.type,
              url: downloadURL,
              storagePath: storagePath,
              thumbs: [],
            };
            let isMain = {};
            if (index === 0 && !hasMainPhoto && docKey === "images") {
              isMain = { [`files/${fileId}/main`]: true };
            }
            updateUploadSession(sessionId, {
              [`files/${fileId}/status`]: "File Uploaded",
              [`files/${fileId}/url`]: downloadURL,
              [`files/${fileId}/storagePath`]: storagePath,
              [`files/${fileId}/size`]: file.size,
              [`files/${fileId}/type`]: file.type,
              ...isMain,
            });
            // uploadedFile.thumbs = await makeThumbsForUploadedFile(uploadedFile);

            dispatch({
              type: "UPDATE_TOTAL_PROGRESS",
              payload: { downloadURL, id: file.id },
            });

            return resolve(uploadedFile);
          }
        );
      });
    }

    // function makeThumbsForUploadedFile(file) {
    //   return new Promise(async function (resolve, reject) {
    //     try {
    //       const thumb = await makeThumbs({
    //         storagePath: `${storagePath}/${file.id}/${file.fileName}`,
    //         docPath,
    //         docKey,
    //         fileId: file.id,
    //       });
    //       return resolve(thumb.data);
    //     } catch (error) {
    //       console.log(error);
    //       return reject(error);
    //     }
    //   });
    // }

    // const proccess = {
    //   upload: { started: true, completed: false, failed: false },
    //   "generate thumbnails": { started: false, completed: false, failed: false },
    //   "upload thumbnails": { started: false, completed: false, failed: false },
    //   "parse file": { started: false, completed: false, failed: false },
    // };

    try {
      await newUploadSession(sessionId, {
        dbTargets,
        files: files.reduce(
          (a, { id, ...f }) => ({
            ...a,
            [id]: {
              fileName: f.path,
              status: "Uploading",
              completed: false,
              failed: false,
            },
          }),
          {}
        ),
      });
      const uploadedFiles = await Promise.all(files.filter((f) => !f.uploaded).map(uploadFileAsPromise));
      console.log(uploadedFiles);
      // console.log({ docPath, docKey, uploadedFiles });
      // (await onUploadCallBack)
      //   ? onUploadCallBack(uploadedFiles)
      //   : addFileDataToFirestore({
      //       docPath,
      //       docKey,
      //       uploadedFiles,
      //     });

      //additional main photo spread update
      // if (!hasMainPhoto) additionalDocUpdateCB(uploadedFiles.find((f) => f.main));

      //  await Promise.all().map((s) => addtionalUpdate(s, uploadedFiles.find((f) => f.main)));

      dispatch({ type: "COMPLETE" });
    } catch (error) {
      console.log(error);
      dispatch({ type: "ERROR", payload: error });
    }
  }

  useEffect(() => {
    dispatch({ type: "UPDATE_TOTAL_SIZE", payload: getTotalFilesSize(files) });
  }, [files, dispatch]);

  function handleCancelCrop() {
    dispatch({ type: "CLEAR" });
  }

  function removeFileFromUploadQueue(indexToRemove) {
    return function (e) {
      dispatch({ type: "REMOVE_FILE_BY_INDEX", payload: indexToRemove });
    };
  }

  return (
    <div className="container bg-white mx-auto rounded-md">
      {state.error && (
        <div className="text-xs p-4 bg-red-400">
          <pre>{JSON.stringify(state.error, null, 2)}</pre>
        </div>
      )}
      {/* <button type="button" className="px-10 py-2 bg-pink-400" onClick={async () => console.log(await newUploadSession(sessionId))}>
        New Upload Session
      </button> */}
      {/* <button
        type="button"
        className="px-10 py-2 bg-lime-400"
        onClick={async () =>
          console.log(
            await updateUploadSession(sessionId, {
              [`files/cl1e1975d000238674wlprzk9/status`]: "Generating thumbnails",
            })
          )
        }
      >
        Update Upload Session
      </button> */}
      <FileDropzone setFiles={setFiles} loading={state.loading} title={title}>
        {
          files.length > 0 ? (
            <div className="border rounded-t-md">
              <div className="flex justify-between items-center border-b">
                <span className="px-4 py-2">Files to Upload</span>

                <div>
                  <button
                    disabled={state.loading}
                    onClick={() => setShowFilesToUpload((v) => !v)}
                    type="button"
                    className="pl-1 mx-2 hover:bg-black hover:bg-opacity-5 rounded-full"
                  >
                    <MdMinimize />
                  </button>
                  <button
                    disabled={state.loading}
                    onClick={() => setDisplayGrid((v) => !v)}
                    type="button"
                    className="pl-1 mx-2 hover:bg-black hover:bg-opacity-5 rounded-full"
                  >
                    {displayGrid ? <MdPhotoAlbum /> : <MdViewList />}
                  </button>
                  <button
                    disabled={state.loading}
                    onClick={handleCancelCrop}
                    type="button"
                    className="pl-1 mx-2 hover:bg-black hover:bg-opacity-5 rounded-full"
                  >
                    <MdClose />
                  </button>
                </div>
              </div>
              {showFilesToUpload && files.length > 0 && (
                <ul className={` pb-4 ${displayGrid ? "auto-grid" : ""}`}>
                  {files.map((file, i) => {
                    return displayGrid ? (
                      <GridItem key={file.name + i} file={file} removeFileFromUploadQueue={removeFileFromUploadQueue} i={i} />
                    ) : (
                      <ListItem key={file.name + i} file={file} removeFileFromUploadQueue={removeFileFromUploadQueue} i={i} />
                    );
                  })}
                </ul>
              )}
            </div>
          ) : null
          // <div className="text-center my-10 text-gray-200 select-none">No files</div>
        }
        {files.length > 0 &&
          (files.filter((f) => !!!f.uploaded).length === 0 && state.isComplete ? (
            <button
              disabled={state.loading}
              onClick={handleCancelCrop}
              className="w-full bg-gray-500 text-white rounded-b-md hover:bg-green-400 transition-all py-2"
            >
              <MdClose /> Clear Uploaded Files
            </button>
          ) : (
            <button
              onClick={
                !state.loading
                  ? handleUploadFile
                  : () => {
                      state?.cancelFunctions?.map((fn) => fn.cancelUpload());
                      dispatch({ type: "CANCEL_UPLOAD" });
                    }
              }
              className="w-full bg-green-500 text-white rounded-b-md hover:bg-green-400 transition-all"
              disabled={files.filter((f) => !!!f.uploaded).length === 0}
            >
              {state.loading ? (
                <LoadingBar files={files} />
              ) : (
                <div className="my-2 flex justify-center items-center">
                  <MdCheck className="mr-5" />
                  {files.filter((f) => !!!f.uploaded).length > 0
                    ? `Upload ${files.length === 1 ? "file" : files.filter((f) => !!!f.uploaded).length + " files"}`
                    : "Please attach file"}{" "}
                </div>
              )}
            </button>
          ))}

        {children}
      </FileDropzone>
    </div>
  );
};

const FileDropzone = ({ title, setFiles, loading = false, children, ...props }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      return setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: cuid(),
          })
        )
      );
    },
    [setFiles]
  );
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <div
      {...getRootProps()}
      className={`box-content border rounded-md overflow-hidden border-indigo-400   ${
        isDragActive ? "border-green-500 border-dashed bg-green-100" : ""
      } `}
    >
      <div className="flex items-center justify-between px-2 py-1 bg-indigo-200 border-b border-indigo-400">
        <div className="uppercase text-sm font-medium ml-4 text-indigo-900 select-none">{title}</div>
        <button
          type="button"
          onClick={open}
          className="bg-indigo-500 hover:bg-indigo-600 transition-all flex items-center space-x-2 text-white text-sm px-4 py-2 mr-1 rounded-md uppercase"
        >
          <MdAddPhotoAlternate />
          <span className="hidden md:block text-xs ">Add File</span>
        </button>
      </div>
      <input {...getInputProps()} disabled={loading} />
      <div className="p-2">
        {/* <Icon name="upload" size="huge" disabled={loading} /> <Header content="Drop file here" disabled={loading} /> */}
        {children}
      </div>
    </div>
  );
};

const ListItem = ({ file, removeFileFromUploadQueue, i }) => {
  return (
    <li key={file.name} className="group py-1 px-2 transition-all hover:bg-indigo-100 flex justify-between cursor-ponter">
      <div className="hover:font-bold flex items-center">
        <FileIcon fileType={file.type} className="mr-4 text-gray-500" />
        <span className={!!file.uploaded ? "text-green-500" : "text-blue-500"}>{file.name} </span>

        <span className="bg-black bg-opacity-10 hover:bg-opacity-25 text-xs text-white py-0 px-1 ml-4 rounded-md select-none">
          {" "}
          {Math.ceil(file.size / 1024)} kb{" "}
        </span>
      </div>
      {!!file.uploaded || file.fileUploadProgress === 100 ? (
        <MdCheckCircleOutline className={`text-${!!file.uploaded ? "green" : "gray"}-500  px-4`} />
      ) : (
        <button
          onClick={removeFileFromUploadQueue(i)}
          type="button"
          className="invisible group-hover:visible text-opacity-30 rounded-full pl-1 hover:bg-gray-900 hover:bg-opacity-10 text-gray-500 hover:text-gray-900"
        >
          <MdDelete />
        </button>
      )}
    </li>
  );
};
const GridItem = ({ file, removeFileFromUploadQueue, i }) => {
  return (
    <li
      key={file.name || i}
      className={`group py-1 px-2 transition-all hover:bg-indigo-50 m-2 rounded-md flex flex-col w-64 h-64 mx-auto justify-center items-center cursor-pointer relative ${
        !!file.uploaded ? " bg-green-50" : ""
      }`}
    >
      {file.type.startsWith("image") && (
        <img src={file.preview} alt={file.name} className="w-36 rounded-md hover:scale-110 transition-all" />
      )}
      {file.type === "application/pdf" && <img src="img/pdf-placeholder.jpg" className="w-32 h-32" alt={file.name} />}
      {!file.type.startsWith("image") && file.type !== "application/pdf" && <FileIcon fileType={file.type} size="huge" />}
      <div className="hover:font-bold text-xs truncate w-48 mt-4 text-center">
        {file.name} - {}
      </div>
      {!!file.uploaded || file.fileUploadProgress === 100 ? (
        <MdCheckCircleOutline className={`top-2 absolute left-2 text-${!!file.uploaded ? "green" : "gray"}-500`} size="large" />
      ) : (
        <button
          onClick={removeFileFromUploadQueue(i)}
          type="button"
          className="invisible group-hover:visible text-opacity-30 rounded-full pl-1 hover:bg-gray-900 hover:bg-opacity-10 text-gray-500 hover:text-gray-900 absolute right-2 top-2"
        >
          <MdDelete />
        </button>
      )}
    </li>
  );
};

const LoadingBar = (props) => {
  const { files } = props;

  const filesToUpload = files.filter((f) => !!!f.uploaded);
  const totalFileSize = getTotalFilesSize(filesToUpload);
  return (
    <div className="relative">
      <span className="absolute mx-auto top-2">Cancel Uploading </span>
      <div className="overflow-hidden h-10 text-xs flex rounded-b-md bg-purple-200">
        {props.files &&
          props.files.length > 0 &&
          props.files
            .filter((f) => !!!f.uploaded)
            .map((file, i) => {
              return (
                <div
                  key={file.id}
                  style={{
                    width: `${(100 * ((file.fileUploadProgress / 100) * file.size)) / totalFileSize}%`,
                  }}
                  className={`
                  transition-all
                shadow-none
                flex flex-col
                text-center
                whitespace-nowrap
                text-white
                justify-center
                bg-${file.fileUploadProgress === 100 ? "green" : "blue"}-${i % 2 === 0 ? "600" : "500"}`}
                ></div>
              );
            })}
      </div>
    </div>
  );
};

function getTotalFilesSize(files) {
  let totalSize = 0;
  for (let file of files) {
    totalSize += file.size;
  }
  return totalSize;
}
