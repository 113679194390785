//HOOKS STILL IN ALPHA UPGRADE WHEN AVAILABLE
//https://www.algolia.com/doc/guides/building-search-ui/getting-started/react-hooks/

import { MdChevronRight, MdClear, MdSearch } from "react-icons/md";
import React from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch/dom";
import { connectAutoComplete } from "react-instantsearch/connectors";
import Autocomplete from "downshift";
import { openModal } from "../../app/common/modals/modalReducer";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

function RawAutoComplete({
  refine,
  hits,
  setOpen,
  focusOnMount = false,
  setSelected = () => {},
}) {
  const searchRef = React.useRef();
  const history = useHistory();
  const [searchValue, setSearchValue] = React.useState("");
  const dispatch = useDispatch();

  function handleKeys(e) {
    if (e.key === "Escape") {
      searchRef.current.blur();
    }
    if (e.ctrlKey && e.key === "x") {
      setSearchValue("");
    }
  }

  React.useEffect(() => {
    refine(searchValue);
  }, [searchValue, refine]);

  return (
    <Autocomplete
      itemToString={(i) => (i ? i.name : i)}
      onChange={(item) => {
        if (item?.onSelect) item.onSelect();
        history.push(`/warehouse/labels/${item.objectID}`);
        setSearchValue("");
        searchRef.current.blur();
        if (setOpen) setOpen(false);
      }}
      inputValue={searchValue}
    >
      {({
        getInputProps,
        getItemProps,
        getToggleButtonProps,
        selectedItem,
        highlightedIndex,
        isOpen,
        inputValue,
      }) => (
        <div className="w-full relative  max-w-[100vw]  bg-white  text-sm md:border md:rounded-lg h-8 hover:border-blue-500 transition-all flex items-center focus-within:border-indigo-400  z-10">
          <MdSearch className="w-6 h-6 mx-2" />
          <input
            id="mainSearch"
            autoFocus={focusOnMount}
            ref={searchRef}
            className="w-full bg-transparent h-full py-1 outline-none mx-2   "
            placeholder="Search PTS Ops..."
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            onFocus={(e) => e.target.select()}
            spellCheck={false}
            {...getInputProps({
              onChange(e) {
                setSearchValue(e.target.value);
              },
              onKeyDown(e) {
                handleKeys(e);
              },
              value: searchValue,
            })}
          />

          {inputValue?.length > 0 ? (
            <MdClear
              className="w-6 h-6 mx-1 cursor-pointer hidden md:inline"
              onClick={() => {
                setSearchValue("");
              }}
            />
          ) : (
            <span className="hidden md:block whitespace-nowrap text-[8px] mr-1"></span>
          )}
          <button
            className="  px-4 py-1 outline-none"
            {...getToggleButtonProps()}
          >
            <MdChevronRight
              className={`${
                isOpen ? " rotate-90 " : ""
              } transform transition-all`}
            />
          </button>

          {isOpen && (
            <div className="border md:shadow-lg md:border-indigo-500 absolute top-8 w-full md:rounded  overflow-auto z-20">
              {hits && hits.length > 0 ? (
                <>
                  {hits.map((item, index) => (
                    <div
                      className={`flex justify-between py-2 md:py-0.5 px-2 ${
                        highlightedIndex === index
                          ? "bg-indigo-100"
                          : "bg-white"
                      } ${selectedItem === item ? "font-bold" : "font-normal"}`}
                      key={item.objectID}
                      {...getItemProps({
                        item,
                        index,
                      })}
                    >
                      <span>
                        {item?.shippers
                          ? Object.values(item?.shippers).map(
                              (shipper) => shipper.displayName
                            )
                          : item?.codeName || "No shipper or codename provided"}
                      </span>
                      {/* <Highlight attribute="shipper" hit={item} tagName="mark" /> */}
                      <span>
                        {
                          item?.refNumber
                          // item.refNumber.length > 0 &&
                          // item.refNumber.map((number, i) => (i > 0 ? ", " + number.slice(-2) : number))}
                        }
                      </span>
                    </div>
                  ))}
                </>
              ) : (
                <div
                  className={`flex justify-between py-2 md:py-0.5 px-2 ${
                    highlightedIndex === 0 ? "bg-indigo-200" : "bg-white"
                  } `}
                  {...getItemProps({
                    item: {
                      onSelect: function () {
                        dispatch(
                          openModal({
                            modalType: "AddOperationModal",
                            modalProps: { searchValue, setSelected },
                          })
                        );
                      },
                    },
                    index: 0,
                  })}
                >
                  No operations found. Add one +
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Autocomplete>
  );
}

const AutoCompleteWithData = connectAutoComplete(RawAutoComplete);

const searchClient = algoliasearch(
  "CYROWMWPWI",
  "a2bdc0fa6e8c4c91acd8af2ce8a8dda5"
);

export function OpsSearch({ setOpen, focusOnMount, ...props }) {
  return (
    <InstantSearch searchClient={searchClient} indexName="ptsOps">
      <AutoCompleteWithData
        {...props}
        setOpen={setOpen}
        focusOnMount={focusOnMount}
      />
    </InstantSearch>
  );
}
